import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1879bb2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filtre-container" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_MDBIcon = _resolveComponent("MDBIcon")!
  const _component_ButtonFilterType = _resolveComponent("ButtonFilterType")!
  const _component_PopinFiltres = _resolveComponent("PopinFiltres")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.filters')), 1),
        _createVNode(_component_MDBIcon, {
          class: "icon",
          icon: "trash-alt",
          title: _ctx.$t('front.balanceSocietale.filtre.reset'),
          onClick: _ctx.clearFilters
        }, null, 8, ["title", "onClick"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterField, (field) => {
        return (_openBlock(), _createElementBlock("div", { key: field }, [
          _createElementVNode("div", null, [
            _createVNode(_component_ButtonFilterType, {
              disabled: _ctx.disable,
              title: field.label,
              isArrow: !_ctx.isGamme(field.type),
              filterNumber: field.filters ? field.filters.length : 0,
              addItem: () => _ctx.addItem(field),
              clearItem: () => _ctx.clearItem(field),
              onClick: ($event: any) => (_ctx.handleSelection(field, $event))
            }, null, 8, ["disabled", "title", "isArrow", "filterNumber", "addItem", "clearItem", "onClick"])
          ])
        ]))
      }), 128)),
      (_ctx.showPopinFiltres)
        ? (_openBlock(), _createBlock(_component_PopinFiltres, {
            key: 0,
            filterIndex: _ctx.filterIndex
          }, null, 8, ["filterIndex"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}