import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03a9ad5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_marked_outline = _resolveComponent("checkbox-marked-outline")!
  const _component_checkbox_blank_outline = _resolveComponent("checkbox-blank-outline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "balance-tile-container",
    style: _normalizeStyle([{ background: _ctx.getBackgroundColor() }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "checkbox",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        (_ctx.checked)
          ? (_openBlock(), _createBlock(_component_checkbox_marked_outline, {
              key: 0,
              class: "icon"
            }))
          : (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
              key: 1,
              class: "icon"
            }))
      ]),
      _createElementVNode("div", {
        class: "color-band",
        style: _normalizeStyle(_ctx.getColorFromType(_ctx.type)),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        _createElementVNode("label", null, _toDisplayString(_ctx.label?.toLowerCase()), 1)
      ], 4)
    ])
  ], 4))
}