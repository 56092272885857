<template>
  <Drawer>
    <template v-slot:header>
      <div class="header">
        <Close color="black" @click="closeDrawer" />
        <div class="product">
          <div class="title">
            <img
              alt="PPP logo"
              v-if="isPPP()"
              src="@/assets/PPP_noir_blanc.svg"
            />
            <img
              alt="Seed logo"
              v-if="isSemence()"
              src="@/assets/semence_noire-blanc.svg"
            />
            <h2>
              <span class="bold">{{ product.product.name }}</span> ({{ product.product.supplier }})
            </h2>
          </div>
          <div class="subtitle">
            <span v-if="product.product.segment || product.product.segmentSpecific">
              {{ product.product.segment }}
              {{ product.product.segment && product.product.segmentSpecific ? ',' : '' }}
              {{ product.product.segmentSpecific }}
            </span>
            <span v-if="product.product.amm || product.product.gnis">-</span>
            <span v-if="product.product.amm">
              {{ $t('front.product.productDetails.amm', { amm: product.product.amm }) }}
            </span>
            <span v-if="product.product.gnis">
              {{ $t('front.product.productDetails.gnis', { gnis: product.product.gnis }) }}
            </span>
          </div>
          <div class="actions">
            <Button
              :label="product.product.isVisible
                 ? $t('front.product.productManagement.hide', 1)
                 : $t('front.product.productManagement.visible')
              "
              color="primary"
              :onClick="openPopin"
              :disabled="isDisabled()"

            >
              <eye class="icon" />
            </Button>
<!--            TODO : uncomment when we will have the right to download product fiche -->
<!--            <Button-->
<!--              :label="(!scores || !comparisonProduct)-->
<!--                ? ''-->
<!--                : $t('front.product.productDetails.downloadProduct')"-->
<!--              :onClick="downloadFiche"-->
<!--              :loading="loaderFiche"-->
<!--              :disabled="isDisabled()"-->
<!--            >-->
<!--              <cloud-download v-if="!loaderFiche && scores && comparisonProduct" class="icon" />-->
<!--            </Button>-->
          </div>
        </div>
        <!-- Popin confirmation products masked -->
        <Popin
          v-if="showPopin === true"
          :title="$t('front.product.popin.title')"
          :text="$t('front.product.popin.text', { nbr: 1 })"
          :hasCancel="true"
          @close="closePopin"
        >
          <template v-slot:container>
            <p class="text" v-if="product.product.isVisible">
              {{ $t('front.product.popin.text', { nbr: 1 }) }}
            </p>
            <p class="text" v-else>{{ $t('front.product.popin.textVisible', { nbr: 1 }) }}</p>
          </template>
          <template v-slot:button>
            <Button
              :label="product.product.isVisible
               ? $t('front.product.productManagement.hide')
               : $t('front.product.productManagement.visible')"
              color="primary"
              :onClick="product.product.isVisible ? confirmationMasked : confirmationVisible"
              :hasCancel="true"
            >
              <eye class="icon" />
            </Button>
          </template>
        </Popin>
        <Popin
          v-if="showCGUPopin === true"
          :title="$t('front.product.productDetails.cgu.title')"
          :hasCancel="true"
          @close="showCGUPopin = false"
        >
          <template v-slot:container>
            <p class="text">
              <a target="_blank" :href="links.cguLink">{{ $t('front.product.productDetails.cgu.desc') }}</a>
            </p>
            <p class="text">
              {{ $t('front.product.productDetails.cgu.text') }}
            </p>
          </template>
          <template v-slot:button>
            <Button
              color="primary"
              :label="$t('front.product.productDetails.cgu.validate')"
              @click="exportLogos"
            />
          </template>
        </Popin>
      </div>
    </template>
    <template v-slot:page>
      <div class="product-score">
        <div class="scoring">
          <div class="title">
            <span class="accordion-title">
              {{
                $t('front.product.productDetails.scoring.title',
                  { product: product.product.name })
              }}
            </span>
            <small v-if="!!product.lastDataUpdate">
              {{
                $t('front.product.productDetails.scoring.updateData',
                  { date: dayjs(product.lastDataUpdate).format('DD/MM/YYYY') })
              }}
            </small>
            <small v-if="!!product.lastScoringUpdate">
              {{
                $t('front.product.productDetails.scoring.updateScore',
                  { date: dayjs(product.lastScoringUpdate).format('DD/MM/YYYY') })
              }}
            </small>
            <Button
              :label="$t('front.product.productDetails.scoring.downloadLogo')"
              :loading="loaderDownload"
              class="float-right"
              @click="showCGUPopin = true"
              :disabled="isDisabled()"
            >
              <download class="icon" v-if="!loaderDownload" />
            </Button>
          </div>
          <div class="main-enjeu" v-if="logoLoaded">
            <img v-if="logo.logoOneScore"
                 class="img" alt="One score image"
                 :src="logo.logoOneScore"
            >
          </div>
          <div class="enjeu-container" v-if="logoLoaded">
            <img v-if="logo.logoAlimentation"
                 class="img" alt="Alimentation image"
                 :src="logo.logoAlimentation">
            <img v-if="logo.logoAgriculteur"
                 class="img" alt="Agriculteur image"
                 :src="logo.logoAgriculteur">
            <img v-if="logo.logoEnvironnement"
                 class="img" alt="Environnement image"
                 :src="logo.logoEnvironnement">
          </div>
          <div v-else class="spinner-border" role="status" />
        </div>
        <Accordion
          :title="$t('front.product.productDetails.indicatorsDetails.title')"
          v-bind:isActive="false"
          iconRight="angle-right"
          class="indicators-details"
        >
          <section>
            <Button
              :label="$t('front.product.productDetails.indicatorsDetails.download')"
              :loading="indicateurIsLoading"
              :onClick="exportScore"
              :disabled="isDisabled()"
            >
              <download class="icon" v-if="!indicateurIsLoading" />
            </Button>
            <div class="table" v-if="indicators !== undefined">
              <div class="note-row row--legend">
                <div class="col-1">
                  {{ $t('front.product.productDetails.indicatorsDetails.indicator') }}
                </div>
                <div class="col-2 --legend">
                  {{ $t('front.product.productDetails.indicatorsDetails.score') }}
                </div>
              </div>

              <div class="note-row">
                <div class="col-1 global">{{ $t(`front.product.productDetails.indicatorsDetails.name.${indicators.label.toLowerCase()}`) }}</div>
                <div class="col-2">{{ indicators.score }}</div>
              </div>
              <div class="note-block" v-for="enjeu in indicators.children" :key="enjeu">
                <div class="note-row">
                  <div class="col-1 enjeu">{{ $t(`front.product.productDetails.indicatorsDetails.name.${enjeu.label.toLowerCase()}`) }}</div>
                  <div class="col-2">{{ enjeu.score }}</div>
                </div>
                <div class="note-block" v-for="sousEnjeu in enjeu.children" :key="sousEnjeu">
                  <div class="note-row">
                    <div class="col-1 sous-enjeu">
                      {{ $t(`front.product.productDetails.indicatorsDetails.name.${sousEnjeu.label.toLowerCase()}`) }}
                    </div>
                    <div class="col-2">{{ sousEnjeu.score }}</div>
                  </div>
                  <div class="note-block" v-for="indicator in sousEnjeu.children" :key="indicator">
                    <div class="note-row">
                      <div class="col-1 indicateur">
                        {{ $t(`front.product.productDetails.indicatorsDetails.name.${indicator.label.toLowerCase()}`) }}
                      </div>
                      <div class="col-2">{{ indicator.score }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Accordion>
        <Accordion
          ref="accordionHistogram"
          :title="$t('front.product.productDetails.comparison.title',
           { product: product.product.name })"
          v-bind:isActive="false"
          iconRight="angle-right"
        >
          <section ref="histogram" v-if="scores !== undefined && comparisonProduct !== undefined">
            <div class="div-flex-row">
              <div>
                <span class="compare-to">{{ $t('front.product.histogram.compareTo') }}</span>
                <select
                  @change="(e) => handleSelectComparison(e.target.selectedIndex)"
                  v-model="comparisonCriteria"
                >
                  <option v-for="(option, index) in compareTo" :key="option" :value="option" :disabled="!option">
                    {{ getDiplayedCriteriaByIndex(index, option) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="missing-histogram" v-if="!showHistogram()">{{ $t('front.product.histogram.permission') }}</div>
            <histogram
                v-if="showHistogram()"
                :oneScore="scores.oneScore" :alimentation="scores.alimentation"
                :agriculteur="scores.agriculteur" :environment="scores.environnement"
                :yLabels="['0', '20', '40', '50', '60', '80', '100']"
                :comparisonOneScore="comparisonProduct.oneScore"
                :comparisonAlimentation="comparisonProduct.alimentation"
                :comparisonAgriculteur="comparisonProduct.agriculteur"
                :comparisonEnvironnement="comparisonProduct.environnement"
            />
            <div class="legend" v-if="showHistogram()">
              <div class="legend-block">
                <div class="legend-square" />
                <span>{{
                    $t('front.product.histogram.dispersion',
                      { criteria: comparisonCriteria })
                  }}</span>
              </div>
              <div class="legend-block">
                <div class="legend-round" />
                <span>{{
                    $t('front.product.histogram.avg',
                      { criteria: comparisonCriteria })
                  }}</span>
              </div>
            </div>
          </section>
        </Accordion>
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
import ProductsAdminApi from '@/api/products/ProductsAdminApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Accordion from '@/components/Accordion.vue';
import Button from '@/components/Button.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import {
  Download, ArrowLeft, Sprout, Eye, CloudDownload, ScaleBalance,
} from 'mdue';
import Drawer from '@/components/Drawer.vue';
import Close from '@/components/Close.vue';
import {
  ComparisonProduct, IndicatorsScoreProduct, LOGO_TYPES, PRODUCT_TYPE, ScoredProduct, ScoreProduct,
} from '@/api/products/ProductsApi';
import Popin from '@/components/Popin.vue';
import CGUPopin from '@/views/front/login/CGUPopin.vue';
import dayjs from 'dayjs';
import Histogram from '@/views/admin/product/Histogram.vue';
import { inject } from 'vue';
import { download, getFilenameFromResponse } from '@/util';
import html2canvas from 'html2canvas';
import ConfigurationApi, { Links } from '@/api/configuration/ConfigurationApi';

@Options({
  components: {
    Accordion,
    Button,
    Download,
    ArrowLeft,
    Sprout,
    Eye,
    CloudDownload,
    Drawer,
    Close,
    Popin,
    CGUPopin,
    Histogram,
    ScaleBalance,
  },
  props: {
    idProduct: 0,
  },
  data() {
    const productsAdminApi = getGlobalInstance(ProductsAdminApi);
    const configurationApi = getGlobalInstance(ConfigurationApi);

    const authorization = inject('authorization');

    return {
      productsAdminApi,
      configurationApi,
      product: {
        lastUpdateScoring: '',
        product: {
          name: '',
          provider: '',
          amm: '',
          segment: '',
          segmentSpecific: '',
          type: '',
          isVisible: true,
        },
      },
      showPopin: false,
      masked: [],
      logo: {
        logoOneScore: '',
        logoAlimentation: '',
        logoAgriculteur: '',
        logoEnvironnement: '',
      },
      logoLoaded: false,
      showCGUPopin: false,
      scores: {
        oneScore: 0,
        alimentation: 0,
        agriculteur: 0,
        environnement: 0,
      },
      comparisonProduct: undefined,
      compareTo: [],
      comparisonCriteria: undefined,
      indicators: undefined,
      loaderFiche: false,
      authorization,
      loaderDownload: false,
      indicateurIsLoading: false,
      links: {},
    };
  },
  methods: {
    isDisabled() {
      return this.loaderFiche || !this.scores || !this.comparisonProduct || this.indicateurIsLoading || this.loaderDownload;
    },
    showHistogram(): boolean {
      return this.comparisonProduct.oneScore
          || (this.comparisonProduct.alimentation
              && this.comparisonProduct.agriculteur
              && this.comparisonProduct.environnement);
    },
    isPPP() {
      return this.product.product?.type === PRODUCT_TYPE.PPP;
    },
    isSemence() {
      return this.product.product?.type?.toUpperCase() === PRODUCT_TYPE.SEMENCE;
    },
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    handleSelectComparison(index: string) {
      this.fetchComparisonProduct(index);
    },
    getCriteriaPrefixByIndex(index: number) {
      let prefix;
      switch (index) {
        case 0:
          prefix = this.$t('front.product.productDetails.criteria.prefix.marche');
          break;
        case 1:
          prefix = this.$t('front.product.productDetails.criteria.prefix.segment');
          break;
        case 2:
          prefix = this.$t('front.product.productDetails.criteria.prefix.segmentSpecific');
          break;
        default:
          prefix = '';
          break;
      }
      return prefix ? (`${prefix} - `) : '';
    },
    getDisplayedCriteriaValue(value: string) {
      if (!value) {
        return this.$t('front.product.histogram.missing');
      }
      let displayedValue;
      switch (value) {
        case this.$t('front.product.productDetails.criteria.PPP'):
          displayedValue = this.$t('front.product.productDetails.PPP');
          break;
        case this.$t('front.product.productDetails.criteria.SEMENCE'):
          displayedValue = this.$t('front.product.productDetails.SEMENCE');
          break;
        default:
          displayedValue = value;
          break;
      }
      return displayedValue;
    },
    getDiplayedCriteriaByIndex(index: number, value: string) {
      const prefix = this.getCriteriaPrefixByIndex(index);
      const displayedValue = this.getDisplayedCriteriaValue(value);
      return `${prefix}${displayedValue}`;
    },
    fetchProductById() {
      return this.productsAdminApi
        .fetchById(this.idProduct)
        .then((response: ScoredProduct) => {
          this.product = response;
          this.comparisonCriteria = response.product.type;
          this.fetchComparisonProduct(0);
          this.getCompareToConsideringPermissions(response);
        });
    },
    fetchAndDownloadPdf() {
      return new Promise((resolve, reject) => {
        html2canvas(this.$refs.histogram)
          .then((canvas) => {
            const b64Balance = canvas.toDataURL('image/png', 1);
            this.productsAdminApi.fetchPdf(this.idProduct, b64Balance)
              .then((response: Response) => response.blob())
              .then((blob: Blob) => {
                const today = dayjs().format('YYMMDD');
                download(blob, `${today}_FICHE_ONESCORE_${this.product.product.name}.pdf`);
                resolve(undefined);
              })
              .catch(reject);
          })
          .catch(reject);
      });
    },
    getCompareToConsideringPermissions(response: ScoredProduct) {
      this.compareTo[0] = response.product.type;
      this.compareTo[1] = response.product.segment;
      this.compareTo[2] = response.product.segmentSpecific;
    },
    fetchProductScoreById() {
      return this.productsAdminApi
        .fetchProductScoreById(this.idProduct)
        .then((response: ScoreProduct) => {
          this.scores.oneScore = response.noteOneScore;
          this.scores.alimentation = response.noteAlimentation;
          this.scores.agriculteur = response.noteAgriculteur;
          this.scores.environnement = response.noteEnvironnement;
        });
    },
    fetchComparisonProduct(index: number) {
      return this.productsAdminApi
        .fetchComparisonProduct((this.getCriteriaPrefixByIndex(index) + this.comparisonCriteria))
        .then((response: ComparisonProduct) => {
          this.comparisonProduct = response;
        });
    },
    async confirmationMasked() {
      this.masked.push(this.idProduct);
      await this.productsAdminApi.updateProductsAdmin(this.masked)
        .then(() => {
          this.product.product.isVisible = !this.product.product.isVisible;
        });
      this.closePopin();
    },
    async confirmationVisible() {
      const index = this.masked.indexOf(this.idProduct);
      this.masked.splice(index, 1);
      await this.productsAdminApi.updateProductsAdmin(this.masked)
        .then(() => {
          this.product.product.isVisible = !this.product.product.isVisible;
        });
      this.closePopin();
    },
    openPopin() {
      this.showPopin = true;
    },
    closePopin() {
      this.showPopin = false;
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      this.$parent.products = [];
      this.$parent.fetchProducts();
      this.$parent.displayDetails = 0;
    },
    dayjs,
    exportScore() {
      this.indicateurIsLoading = true; // on demarre le telechargement
      this.productsAdminApi
        .exportScore(this.idProduct)
        .then((response: Response) => {
          response.blob().then((blob: Blob) => {
            const excelName = getFilenameFromResponse(response);
            download(blob, excelName);
          });
        })
        .finally(() => {
          this.indicateurIsLoading = false;
        }); // etape finie
    },
    exportLogos() {
      this.loaderDownload = true;
      this.showCGUPopin = false;
      this.productsAdminApi
        .exportLogos(this.idProduct, 'ENJEU_SPECIFIQUE')
        .then((response: Response) => {
          response.blob().then((blob: Blob) => {
            const date = dayjs().format('YYMMDD');
            download(blob, `${date}_logos_OneScore_${this.product.product.name}.zip`);
            this.loaderDownload = false;
          });
        });
    },
    fetchLogo(logoType: string) {
      this.productsAdminApi.fetchLogo(this.idProduct, logoType)
        .then((response: Response) => response.blob())
        .then((response: Blob) => {
          const logo = response.size === 0 ? null : URL.createObjectURL(response);
          switch (logoType) {
            case LOGO_TYPES.ONESCORE: {
              this.logo.logoOneScore = logo;
              break;
            }
            case LOGO_TYPES.ALIMENTATION: {
              this.logo.logoAlimentation = logo;
              break;
            }
            case LOGO_TYPES.AGRICULTEUR: {
              this.logo.logoAgriculteur = logo;
              break;
            }
            case LOGO_TYPES.ENVIRONNEMENT: {
              this.logo.logoEnvironnement = logo;
              break;
            }
            default:
              break;
          }
        });
    },
    fetchAllLogos() {
      this.fetchLogo(LOGO_TYPES.ALIMENTATION);
      this.fetchLogo(LOGO_TYPES.ONESCORE);
      this.fetchLogo(LOGO_TYPES.AGRICULTEUR);
      this.fetchLogo(LOGO_TYPES.ENVIRONNEMENT);
    },
    setLogoLoaded() {
      this.logoLoaded = this.logo.logoOneScore !== ''
        && this.logo.logoAlimentation !== ''
        && this.logo.logoAgriculteur !== ''
        && this.logo.logoEnvironnement !== '';
    },
    closeDrawer() {
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      this.$parent.displayDetails = 0;
    },
    fetchDetailScoreOfProduct() {
      this.productsAdminApi.fetchDetailProductScoreById(this.idProduct)
        .then((response: IndicatorsScoreProduct) => {
          this.indicators = response;
        });
    },
    downloadFiche() {
      if (!this.$refs.accordionHistogram.collapseAccordion) {
        this.$refs.accordionHistogram.handleClick();
      }
      this.loaderFiche = true;
      this.fetchAndDownloadPdf()
        .then(() => {
          this.loaderFiche = false;
        });
    },
    clickCgu() {
      window.open(this.configurationApi.links.cguLink);
    },
  },
  mounted() {
    this.fetchProductById();
    this.fetchAllLogos();
    this.fetchProductScoreById();
    this.fetchDetailScoreOfProduct();
    this.showPopin = false;
    this.configurationApi.fetchLinks()
      .then((links: Links) => {
        this.links = links;
      });
  },
  watch: {
    logo: {
      handler() {
        this.setLogoLoaded();
      },
      deep: true,
    },
  },
})
export default class ProductScore extends Vue {
}
</script>

<style lang="scss" scoped>
.product-score {
  width: 100%;
  height: calc(100% - 200px);
  background: white;
  position: absolute;

  .scoring {
    background-color: white;
    color: var(--grey-7);
    padding: var(--spacing-default);
    text-align: left;
    outline: none;
    border-bottom: 1px solid var(--grey-1);
    cursor: initial;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: var(--spacing-bigger);

      h3 {
        text-transform: uppercase;
        font-weight: lighter;
      }

      small {
        margin: -2px var(--spacing-default) 0;
      }

      .float-right {
        margin-left: auto;
        margin-right: 50px;
      }

      .spinner-button {
        .spinner-border {
          margin: auto 2px;
        }
      }
    }

    .main-enjeu {
      text-align: center;

      img {
        margin-top: -120px; // Pour compenser le fait que l'image ne soit pas centrée
        width: 45%;
        height: 100%;
      }
    }
    .enjeu-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: var(--spacing-bigger) 10%;

      img {
        width: 30%;
        height: 100%;
      }
    }

    .spinner-border {
      margin-left: 48%;
    }
  }

  .table {
    margin-top: 10px;
    margin-bottom: var(--spacing-default);
    padding: var(--spacing-bigger) var(--spacing-default) var(--spacing-default);

    .row--legend {
      font-family: 'SourceSansProSemiBold', sans-serif;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .note-row {
      height: 40px;
      display: flex;
      flex-direction: row;
      padding: 0;

      div {
        margin: 6px 0;
      }

      .col-1 {
        border-bottom: 1px solid var(--grey-1);
        font-family: 'SourceSansProRegular', sans-serif;
        width: 600px;
      }

      .col-2 {
        font-family: 'SourceSansProRegular', sans-serif;
        width: 100px;
        position: absolute;
        left: 600px;
        padding: 0;

        &.--legend {
          left: 585px;
        }
      }

      .global {
        text-transform: uppercase;
        padding: 0 0 0 8px;
      }

      .enjeu {
        text-transform: uppercase;
        padding: 0 0 0 32px;
      }

      .sous-enjeu {
        text-transform: capitalize;
        padding: 0 0 0 56px;
      }

      .indicateur {
        text-transform: capitalize;
        padding: 0 0 0 78px;
        font-style: italic;
      }
    }

    .note-block {
      padding: 0;
    }
  }

  .accordion-title {
    font-family: 'LatoLight', sans-serif;
    font-size: 23px;
    text-transform: uppercase;
  }
}

.header {
  height: 200px;
  background: #fbfbfb;
  position: relative;
  border-top: 2px solid var(--grey-1);
  border-bottom: 2px solid var(--grey-1);

  .close {
    position: absolute;
    text-align: left;
    padding-top: var(--spacing-small);
    display: flex;
    font-size: var(--font-size-small);
    cursor: pointer;

    .text {
      margin-left: var(--spacing-small);
      text-transform: uppercase;
    }
  }

  .product {
    padding: var(--spacing-bigger);

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 100%;
        object-fit: cover;
        margin-right: var(--spacing-default);
        margin-top: -10px;
      }

      h2, h3, h4 {
        font-weight: normal;
        margin-left: 4px;
        margin-right: 4px;
        font-family: 'SourceSansProRegular', sans-serif;

        .bold {
          font-size: var(--font-size-medium);
          text-transform: uppercase;
          font-family: 'SourceSansProSemiBold', sans-serif;
        }
      }

      h2 {
        font-size: 22px;
      }
    }

    .subtitle {
      margin-top: -(var(--spacing-small));

      span {
        font-family: 'SourceSansProRegular', sans-serif;
        margin: 0 var(--spacing-small);
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: var(--spacing-bigger);

      button {
        margin: var(--spacing-small);
      }
    }
  }
}

.popin-container {
  button {
    height: var(--btn-height-smaller);
    margin: var(--spacing-small);
  }

  .text {
    text-align: justify;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 300px;
  }
}

section {
  .div-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .compare-to {
    font-size: var(--font-size-smaller);
    font-family: SourceSansProLight, sans-serif;
    margin-right: var(--spacing-default);
  }

  .missing-histogram {
    padding: 40px 0 30px 0;
    text-align: center;
  }

  .legend {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: 0 auto var(--spacing-bigger);

    .legend-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: var(--spacing-very-big);

      .legend-square {
        height: 12px;
        width: 12px;
        background: black;
        margin-right: var(--spacing-small);
      }

      .legend-round {
        height: 10px;
        width: 10px;
        background: black;
        border-radius: 50%;
        margin-right: var(--spacing-small);
      }
    }
  }

  .balance {
    width: 200px;
    margin-right: 50px;
  }
}

@media print {
  html, body, * {
    -webkit-print-color-adjust: exact;
  }
  .indicators-details {
    display: none;
  }
  button {
    display: none !important;
  }
  .header {
    height: 125px;
  }

  @page {
    size: A3;
    margin: 0;
  }
}
</style>

<style lang="scss">
.product-score {

  .accordion-container {

    .accordion {
      position: relative;
      width: 98%;

      .accordion-title {
        font-family: 'LatoLight', sans-serif;
        font-size: 23px;
        text-transform: uppercase;
      }

      .icon-right {
        position: absolute;
        right: 50px;
        top: 25px;
      }
    }
  }
}
</style>
