<template>
  <notifications ref="notifications"/>
  <div class="update-scoring-management">
    <h3 class="page-title">
      {{ $t('admin.updateScoring.title') }}
      <div class="last-scoring" v-if="lastScoringDate">
        {{ $t('admin.updateScoring.lastScoring', { date: displayDate(lastScoringDate) }) }}
      </div>
    </h3>
  </div>
  <div class="update-scoring-container">
    <label>{{ $t('admin.updateScoring.subtitle') }}</label>
    <label>{{ $t('admin.updateScoring.subtitle2') }}</label>
    <br/>
    <div class="scoring-button-container">
      <label v-if="!!scoringDuration && !!scoringDate">
        {{ $t('admin.updateScoring.duration', { date : displayDate(scoringDate) }) }}
      </label>
      <Button type="submit"
              :label="!!scoringDuration ? $t('admin.updateScoring.inProgress') : $t('admin.updateScoring.update')"
              color="primary"
              class="update-button"
              :loading="!!scoringDuration"
              :disabled="!!scoringDuration || scoringDuration === undefined"
              @click="showPopin = true">
      </Button>
    </div>
  </div>
  <ConfirmationPopin
    v-if="showPopin"
    :title="$t('admin.updateScoring.popin.title')"
    :content="$t('admin.updateScoring.popin.content')"
    :handleDelete="updateScoring"
    :close="closePopin"
    :validationButton= true
  />
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Notifications from '@/components/Notifications.vue';
import Button from '@/components/Button.vue';
import ImportApi, { Lock } from '@/api/import/ImportApi';
import dayjs, { Dayjs } from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import { HttpPlumeError } from '@/lib/plume-http/client/PlumeHttpResponse';
import ConfirmationPopin from '@/components/ConfirmationPopin.vue';

dayjs.extend(duration);

@Options({
  components: {
    Notifications,
    Button,
    ConfirmationPopin,
  },
  data() {
    const importApi = getGlobalInstance(ImportApi);
    return {
      importApi,
      scoringDuration: undefined, // Quand il est undefined, c'est qu'on n'a pas d'information
      scoringDate: null,
      lastScoringDate: null,
      showPopin: false,
    };
  },
  mounted() {
    this.importApi.current()
      .then((lock: Lock) => {
        if (lock) {
          this.scoringDuration = lock.creationDate ? dayjs.duration(dayjs().diff(dayjs(lock.creationDate))) : null;
          this.scoringDate = dayjs(lock.creationDate);
        } else {
          this.scoringDuration = null;
          this.scoringDate = null;
        }
      })
      .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.internalError')));

    this.importApi.last()
      .then((lastScoring: Lock) => {
        if (lastScoring) {
          this.lastScoringDate = dayjs(lastScoring.creationDate);
        }
      })
      .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.internalError')));
  },
  methods: {
    displayDate(date: Dayjs) {
      return date.format('DD/MM/YYYY à HH[h]mm');
    },
    displayDuration(durationValue: Duration) {
      let displayedText = '';
      if (durationValue.hours()) {
        displayedText = `${durationValue.hours()} heure${durationValue.hours() > 1 ? 's' : ''} et `;
      }
      displayedText += `${durationValue.minutes()} minute${durationValue.minutes() > 1 ? 's' : ''}`;
      return displayedText;
    },
    closePopin() {
      this.showPopin = false;
    },
    updateScoring() {
      this.importApi.import()
        .then(() => {
          this.scoringDuration = dayjs.duration(0);
          this.scoringDate = dayjs();
          this.closePopin();
        })
        .catch((error: HttpPlumeError) => this.$refs.notifications.notifyError(this.$t(`formulaireErrors.${error.errorCode}`)));
    },
  },
})
export default class UpdateScoring extends Vue {
}
</script>

<style lang="scss" scoped>
.update-scoring-management {
  width: 100%;
  padding: 0 var(--spacing-default);
  position: relative;

  .page-title {
    text-align: left;
    padding: var(--spacing-default) 0;
    display: flex;
    align-items: baseline;
  }

  .last-scoring {
    font-style: italic;
    text-transform: lowercase;
    font-size: var(--font-size-smaller);
    padding-left: 10px;
  }
}

.update-scoring-container {
  display: flex;
  flex-direction: column;
  margin: 50px var(--spacing-default);
  text-align: left;

  .update-button {
    margin: 0 auto;
    width: 250px;
    height: var(--btn-height-default);

    span {
      width: 100%;
    }
  }

  .scoring-button-container {
    label {
      font-style: italic;
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
    }
  }
}

</style>
