import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "range r600" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = ["min", "max", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['slider-label', _ctx.disabled ? 'disabled' : ''])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label) => {
        return (_openBlock(), _createElementBlock("span", {
          style: _normalizeStyle(_ctx.style),
          key: label
        }, _toDisplayString(label), 5))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "range",
        class: "r500",
        min: _ctx.min,
        max: _ctx.max,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slider) = $event)),
        step: "1",
        disabled: _ctx.disabled
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.slider]
      ])
    ])
  ]))
}