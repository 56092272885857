<template>
  <i class="fas fa-caret-left" :style="cssVars"></i>
  <div class="popin-container">
    <p class="bandeau">
      {{
        $t('front.balanceSocietale.filtre.title',
          { type: filterFieldSelected.label.toUpperCase() }
        )
      }}
    </p>
    <div v-if="isNotes">
      <div v-for="(enjeu, index) in ENJEUX" :key="index">
        <Accordion
          isPopinFiltre="true"
          :isEnjeuSpe="isEnjeuSpe(enjeu.name)"
          :index="index"
          :title="enjeu.label"
          :isActive="accordionOpenedIndex === index"
          iconRight="angle-right"
          @click="handleClickAccordion(index, $event)"
          type="unique"
          v-if="checkPermissions(enjeu.permissions)"
          :class="`${enjeu.name}`"
        >
          <div class="note-filter-container">
            <SliderRangeComponent
                :noteLabel="enjeu.name"
                :initialSliderMin="getMinByType(enjeu.name)"
                :initialSliderMax="getMaxByType(enjeu.name)"
            />
            <div v-for="(sousEnjeu) in enjeu.sousEnjeux" :key="sousEnjeu">
              <div v-if="checkPermissions(sousEnjeu.permissions)">
              <span :class="`note-filter note-subtitle note-filter--${enjeu.name}`">
                {{ sousEnjeu.label }}
              </span>
                <SliderRangeComponent
                    :noteLabel="sousEnjeu.name"
                    :initialSliderMin="getMinByType(sousEnjeu.name)"
                    :initialSliderMax="getMaxByType(sousEnjeu.name)"
                />
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
    <div v-else>
      <div class="compo-choice" v-if="isCompo">
        <div class="title">
          <label>{{ $t('front.balanceSocietale.filtre.composition.associationChoice') }}</label>
        </div>
        <div class="checkbox-label">
          <button class="checkbox" @click="handleAlone">
            <checkbox-marked-outline v-if="showAlone()" class="icon" />
            <checkbox-blank-outline v-else class="icon" />
          </button>
          <label>{{ $t('front.balanceSocietale.filtre.composition.alone') }}</label>
        </div>
        <div class="checkbox-label">
          <button class="checkbox" @click="handleAssociated">
            <checkbox-marked-outline v-if="showAssociated()" class="icon" />
            <checkbox-blank-outline v-else class="icon" />
          </button>
          <label>{{ $t('front.balanceSocietale.filtre.composition.associated') }}</label>
        </div>
      </div>
      <div class="espace-recherche">
        <ResearchBarFiltre v-on:handleSearch="handleSearch($event)" :inputValue="inputSearchValue" />
      </div>
    </div>
    <div class="panier" v-if="!isNotes">
      <div v-for="(item, index) in filterFieldSelected.filters" :key="item.label">
        <TagFiltrePanier :product="item.label" v-on:clearItem="clearItem(index, item.label)" />
      </div>
    </div>
    <div class="espace-resultat" v-if="!isNotes">
      <div class="spinner-border" role="status" v-if="!$parent.dataLoaded" />
      <div v-else>
        <div class="elements-resulat" v-for="product in products" :key="product">
          <!--          /* TODO pareil pour les notes*/-->
          <BalanceTileFiltre
            :label="product.label"
            :type="product.type"
            :checked="(filterFieldSelected.filters).some((item) => item.label === product.label)"
            v-on:addItem="addItem(product.type, product.label)"
            v-on:removeFilter="clearItem($event, product.label)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { parseJwt } from '@/api/authentication/AuthentApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import BalanceApi, {
  ASSOCIATION_TYPES,
  BalanceBean,
  BalanceFrontBean, FIELD_TYPES,
  FILTER_TYPE,
} from '@/api/balance/BalanceApi';
import ResearchBarFiltre from '@/views/front/balance-societale/ResearchBarFiltre.vue';
import BalanceTileFiltre from '@/views/front/balance-societale/BalanceTileFiltre.vue';
import TagFiltrePanier from '@/views/front/balance-societale/TagFiltrePanier.vue';
import { inject } from 'vue';
import Button from '@/components/Button.vue';
import { CheckboxBlankOutline, CheckboxMarkedOutline } from 'mdue';
import SliderRangeComponent from '@/components/SliderRangeComponent.vue';
import Accordion from '@/components/Accordion.vue';
import ProductsApi, { ENJEUX_TYPES, ONE_SCORE } from '@/api/products/ProductsApi';

@Options({
  components: {
    SliderRangeComponent,
    ResearchBarFiltre,
    BalanceTileFiltre,
    TagFiltrePanier,
    Accordion,
    Button,
    CheckboxBlankOutline,
    CheckboxMarkedOutline,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);
    const authentApi = getGlobalInstance(BalanceApi);
    const productsApi = getGlobalInstance(ProductsApi);

    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    const authorization = inject('authorization');
    const ENJEUX = ENJEUX_TYPES;
    return {
      balanceApi,
      authentApi,
      productsApi,
      associationStatus: ASSOCIATION_TYPES.NORMAL,
      isCompo: false,
      isNotes: false,
      products: [],
      accordionOpenedIndex: 0,
      productsByType: [],
      userPermissions: [],
      filterFieldSelected,
      globalFilters,
      authorization,
      ENJEUX,
      permissions: [],
      inputSearchValue: '',
    };
  },
  props: {
    filterIndex: Number,
  },
  methods: {
    isEnjeuSpe(label: string): boolean {
      return label !== ONE_SCORE;
    },
    handleClickAccordion(id: number, e: Event) {
      const target = e.target as HTMLButtonElement;
      if (target.className.includes('unique')
        && target.nodeName !== 'INPUT'
        && target.nodeName !== 'SELECT'
      ) {
        this.accordionOpenedIndex = id;
      }
    },
    checkPermissions(permissions: string[]) {
      return this.permissions.includes(permissions[0]);
    },
    getMinByType(label: string) {
      let min = '0';
      if (this.globalFilters.length > 0) {
        this.globalFilters.forEach((filter: BalanceBean) => {
          if (label === filter.label) {
            min = filter.noteMin ? filter.noteMin.toString() : '0';
          }
        });
      }
      return min;
    },
    getMaxByType(label: string) {
      let max = '100';
      if (this.globalFilters.length > 0) {
        this.globalFilters.forEach((filter: BalanceBean) => {
          if (label === filter.label) {
            max = filter.noteMax ? filter.noteMax.toString() : '100';
          }
        });
      }
      return max;
    },
    showAlone() {
      return this.associationStatus === ASSOCIATION_TYPES.EXCLUSIVE;
    },
    showAssociated() {
      return this.associationStatus === ASSOCIATION_TYPES.MINIMA;
    },
    handleAlone() {
      this.associationStatus = this.associationStatus === ASSOCIATION_TYPES.EXCLUSIVE
        ? ASSOCIATION_TYPES.NORMAL
        : ASSOCIATION_TYPES.EXCLUSIVE;
      this.updateAssociationStatus();
    },
    handleAssociated() {
      this.associationStatus = this.associationStatus === ASSOCIATION_TYPES.MINIMA
        ? ASSOCIATION_TYPES.NORMAL
        : ASSOCIATION_TYPES.MINIMA;
      this.updateAssociationStatus();
    },
    updateAssociationStatus() {
      const filters: BalanceBean[] = [];
      this.globalFilters
        .forEach((filter: BalanceBean) => {
          if (filter.type === FIELD_TYPES.COMPOSITION) {
            filters.push({
              ...filter,
              association: this.associationStatus,
            });
          } else {
            filters.push(filter);
          }
        });
      this.globalFilters = filters;
      this.filterFieldSelected.association = this.associationStatus;
    },
    handleSearch(inputLabel: string) {
      this.inputSearchValue = inputLabel;
      this.products = this.productsByType
        .filter((product: BalanceBean) => product.label.toLowerCase()
          .includes(inputLabel.toLowerCase()));
    },
    clearItem(index: number, label: string) {
      this.filterFieldSelected.filters.splice(
        this.filterFieldSelected.filters.map((e: BalanceBean) => e.label)
          .indexOf(label),
        1,
      );
      this.globalFilters.splice(
        this.globalFilters.map((e: BalanceBean) => e.label)
          .indexOf(label),
        1,
      );
      if (this.filterFieldSelected.filters.length === 0) {
        this.associationStatus = ASSOCIATION_TYPES.NORMAL;
        this.filterFieldSelected.association = ASSOCIATION_TYPES.NORMAL;
      }
    },
    addItem(type: string, label: string) {
      this.filterFieldSelected.association = this.associationStatus;
      this.filterFieldSelected.filters.push({
        type,
        label,
        association: this.associationStatus,
      });
      this.globalFilters.push({
        type,
        label,
        association: this.associationStatus,
      });
    },
    initializeCompositionAssociation() {
      if (this.globalFilters && this.globalFilters.length > 0) {
        const compositionFilters: BalanceBean[] = this.globalFilters
          .filter((filter: BalanceBean) => filter.type === FIELD_TYPES.COMPOSITION);
        if (compositionFilters.length > 0) {
          if (compositionFilters
            .some((filter: BalanceBean) => filter.association === ASSOCIATION_TYPES.EXCLUSIVE)) {
            this.associationStatus = ASSOCIATION_TYPES.EXCLUSIVE;
          } else if (compositionFilters
            .some((filter: BalanceBean) => filter.association === ASSOCIATION_TYPES.MINIMA)) {
            this.associationStatus = ASSOCIATION_TYPES.MINIMA;
          }
        }
      }
    },
  },
  computed: {
    cssVars() {
      return {
        '--triangle-margin': `${this.filterIndex}px`,
      };
    },
  },
  mounted() {
    this.permissions = parseJwt(this.authorization);
    this.initializeCompositionAssociation();
    if (!this.globalFilters) {
      this.globalFilters = [];
    }
    if (!this.filterFieldSelected) {
      this.filterFieldSelected = [];
    }
    if (!this.filterFieldSelected.filters) {
      this.filterFieldSelected.filters = [];
    }
    this.isNotes = (this.filterFieldSelected.type === FILTER_TYPE.NOTES.type);
    this.isCompo = (this.filterFieldSelected.type === FILTER_TYPE.COMPOSITION.type);
    if (this.filterFieldSelected.type !== FILTER_TYPE.NOTES.type) {
      this.balanceApi.findFiltersByFilterCategory(this.filterFieldSelected.type, true)
        .then((response: BalanceFrontBean) => {
          this.$parent.dataLoaded = true;
          this.productsByType = response.balanceList;
          this.products = response.balanceList;
        });
    }
  },
  watch: {
    filterFieldSelected: {
      handler() {
        if (!this.filterFieldSelected.filters) {
          this.filterFieldSelected.filters = [];
        }
        this.isCompo = (this.filterFieldSelected.type === FILTER_TYPE.COMPOSITION.type);
        this.isNotes = (this.filterFieldSelected.type === FILTER_TYPE.NOTES.type);
        if (this.filterFieldSelected.type !== FILTER_TYPE.NOTES.type) {
          this.balanceApi.findFiltersByFilterCategory(this.filterFieldSelected.type, true)
            .then((response: BalanceFrontBean) => {
              this.$parent.dataLoaded = true;
              this.products = response.balanceList;
            });
        }
      },
      deep: true,
    },
    filterIndex: {
      handler() {
        this.inputSearchValue = '';
      },
    },
  },
})

export default class PopinFiltres extends Vue {
  selected?: BalanceBean;
}

</script>

<style lang="scss" scoped>
$primary: var(--grey-5);
.fas.fa-caret-left {
  font-size: 50px;
  position: absolute;
  left: 20vw;
  color: $primary;
  top: var(--triangle-margin);
}

.popin-container {
  background-color: var(--white);
  width: 320px;
  position: absolute;
  z-index: 1;
  left: 22vw;
  min-height: 400px;
  border: 1px solid $primary;
  top: 0;

  .bandeau {
    width: 100%;
    height: 50px;
    background-color: $primary;
    margin-bottom: 10px;
    color: var(--white);
    text-transform: uppercase;
    padding-top: var(--spacing-medium);
  }

  .note-filter-container {
    padding-top: 1rem;

    .note-filter {
      margin: 0.5rem 0 0rem 1.5rem;
      font-family: 'SourceSansProRegular', sans-serif;
      text-align: left;
      display: flex;
      color: black;
      font-size: var(--font-size-small);

      &--ALIMENTATION {
        color: $tertiary;
      }

      &--AGRICULTEUR {
        color: $info;
      }

      &--ENVIRONNEMENT {
        color: $success;
      }
    }

    .note-title {
    }

    .note-subtitle {
      margin-top: 1rem;
      text-decoration: underline;
      margin-left: 3rem;
    }
  }

  .espace-resultat {
    overflow-y: auto;
    height: 300px;

    .spinner-border {
      margin-top: 30px;
    }
  }

  .panier {
    display: flex;
    flex-flow: wrap;
    margin-left: 4px;
  }

  .compo-choice {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
    justify-content: space-around;
    margin-bottom: 5px;

    .title {
      padding-left: 10px;
    }

    label {
      font-size: 13px;
    }

    .checkbox-label {
      .checkbox {
        border: none;
        background: none;
        height: fit-content;
        padding: 0;

        .icon {
          margin: 0 10px;

          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
