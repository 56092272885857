import { Logger } from 'simple-logging-system';
import { genericError, HttpPlumeError, HttpPlumeResponse } from '../client/PlumeHttpResponse';

const logger = new Logger('PlumeHttpPromise');

export interface PlumeHttpPromiseConsumeOnly<T> {
  then(consumer: (result: T) => void): PlumeHttpPromiseConsumeOnly<T>;
  catch(consumer: (error: HttpPlumeError) => void): PlumeHttpPromiseConsumeOnly<T>;
  finally(consumer: (result: HttpPlumeResponse<T>) => void): PlumeHttpPromiseConsumeOnly<T>;
}

export default class PlumeHttpPromise<T> implements PlumeHttpPromiseConsumeOnly<T> {
  private thenConsumer?: (result: T) => void = undefined;

  private catchConsumer?: (error: HttpPlumeError) => void = undefined;

  private finallyConsumer?: (result: HttpPlumeResponse<T>) => void = undefined;

  private isConsumedRaw: boolean;

  constructor(private readonly promise: Promise<HttpPlumeResponse<T>>) {
    this.isConsumedRaw = false;
    // setTimeout enables PlumeHttpPromise users to set their then and catch functions
    setTimeout(() => {
      if (this.isConsumedRaw) {
        return;
      }

      promise
        .then((result) => {
          if (result.error !== undefined) {
            if (this.catchConsumer !== undefined) {
              PlumeHttpPromise.executeCatchConsumer(result.error, this.catchConsumer);
            } else {
              logger.info('Ignored http error', result.error);
            }
          } else if (result.response !== undefined) {
            if (this.thenConsumer !== undefined) {
              try {
                this.thenConsumer(result.response);
              } catch (e) {
                logger.error('Error consuming http result', result, e);
              }
            } else {
              logger.info('Ignored http result', result);
            }
          } else {
            logger.error('Weird, the http result is not recognized', result);
            if (this.catchConsumer !== undefined) {
              PlumeHttpPromise.executeCatchConsumer(genericError, this.catchConsumer);
            }
          }
          if (this.finallyConsumer !== undefined) {
            try {
              this.finallyConsumer(result);
            } catch (e) {
              logger.error('Error finalizing promise', result, e);
            }
          }
        })
        .catch((error) => logger.error('uncaught error catch by HttpPlumePromise', error));
    }, 0);
  }

  rawPromise() {
    if (this.thenConsumer !== undefined || this.catchConsumer !== undefined) {
      throw new Error('Trying to get the raw promise whereas consumers have already been defined');
    }
    this.isConsumedRaw = true;
    return this.promise;
  }

  then(consumer: (result: T) => void): PlumeHttpPromiseConsumeOnly<T> {
    this.thenConsumer = PlumeHttpPromise.toUniqueConsumer(consumer, this.thenConsumer);
    return this;
  }

  catch(consumer: (error: HttpPlumeError) => void): PlumeHttpPromiseConsumeOnly<T> {
    this.catchConsumer = PlumeHttpPromise.toUniqueConsumer(consumer, this.catchConsumer);
    return this;
  }

  finally(consumer: (result: HttpPlumeResponse<T>) => void): PlumeHttpPromiseConsumeOnly<T> {
    this.finallyConsumer = PlumeHttpPromise.toUniqueConsumer(consumer, this.finallyConsumer);
    return this;
  }

  private static executeCatchConsumer(error: HttpPlumeError, catchConsumer: (error: HttpPlumeError) => void) {
    try {
      catchConsumer(error);
    } catch (e) {
      logger.error('Error consuming http error', error, e);
    }
  }

  private static toUniqueConsumer<U>(
    newConsumer: (arg: U) => void,
    baseConsumer?: (arg: U) => void,
  ) {
    if (baseConsumer !== undefined) {
      return ((arg: U) => {
        baseConsumer(arg);
        newConsumer(arg);
      });
    }
    return newConsumer;
  }
}
