<template>
  <Drawer>
    <template v-slot:header>
      <div class="header">
        <Close color="white" />
        <h3 v-if="$route.params.id === 'new'">{{ $t('admin.cooperative.new') }}</h3>
        <h3 v-else>{{ cooperative.name }}</h3>
      </div>
    </template>
    <template v-slot:page>
      <div id="nav">
        <router-link
          :to="{ name: COOPERATIVE_DETAIL_ROUTE, params: { id: $route.params.id } }"
          v-t="'admin.cooperative.users.details'"
          replace />
        <router-link
          :to="{ name: COOPERATIVE_PRODUCT_ROUTE, params: { id: $route.params.id } }"
          v-if="$route.params.id !== 'new'"
          v-t="'admin.cooperative.users.products'"
          replace />
        <router-link
          :to="{ name: COOPERATIVE_USERS_ROUTE, params: { id: $route.params.id } }"
          v-if="$route.params.id !== 'new'"
          v-t="'admin.cooperative.users.list'"
          replace />
      </div>
      <div class="page-container">
        <router-view />
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Drawer from '@/components/Drawer.vue';
import Close from '@/components/Close.vue';
import CooperativesApi, { Cooperative as CooperativeType } from '@/api/cooperatives/CooperativesApi';
import { COOPERATIVE_DETAIL_ROUTE, COOPERATIVE_PRODUCT_ROUTE, COOPERATIVE_USERS_ROUTE } from '@/router/routes';

@Options({
  components: {
    Drawer,
    Close,
  },
  data() {
    const cooperativesApi = getGlobalInstance(CooperativesApi);
    return {
      cooperativesApi,
      cooperative: {},
      COOPERATIVE_DETAIL_ROUTE,
      COOPERATIVE_PRODUCT_ROUTE,
      COOPERATIVE_USERS_ROUTE,
    };
  },
  methods: {
    fetchCooperatives(id: string) {
      return this.cooperativesApi
        .fetchCoop(id)
        .then((response: CooperativeType) => {
          this.cooperative = response;
        });
    },
  },
  mounted() {
    this.fetchCooperatives(this.$route.params.id);
  },
  updated() {
    if (this.$route.params.id !== 'new') {
      this.fetchCooperatives(this.$route.params.id);
    }
  },
})
export default class Cooperative extends Vue {
  cooperative!: CooperativeType;
}
</script>

<style lang="scss" scoped>
$height-header-cooperative: var(--header-width);

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: $height-header-cooperative;
  background: $dark;
  color: white;
  text-transform: uppercase;
  z-index: 2;
  h3 {
    font-size: 18px;
    margin: 0;
  }
}
#nav {
  display: flex;
  padding: 0;
  margin: 0 var(--spacing-bigger);
  border-bottom: 1px solid var(--grey-1);
  margin-top: $height-header-cooperative;

  a {
    margin: 0 var(--spacing-bigger);
    min-width: 180px;
    padding: var(--spacing-bigger);
    font-weight: normal;

    &.router-link-active {
      border-bottom: 3px solid black;
      color: initial;
    }
  }
}
.page-container {
  margin: 0 var(--spacing-bigger);
}
</style>
