import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fbc9c57"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panier-container" }
const _hoisted_2 = { class: "panier" }
const _hoisted_3 = {
  key: 0,
  class: "error"
}
const _hoisted_4 = { class: "product-list" }
const _hoisted_5 = {
  key: 0,
  class: "filter-label"
}
const _hoisted_6 = {
  key: 1,
  class: "filter-label"
}
const _hoisted_7 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBIcon = _resolveComponent("MDBIcon")!
  const _component_MDBBtn = _resolveComponent("MDBBtn")!
  const _component_close = _resolveComponent("close")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('front.balanceSocietale.creation.panier.titre')) + " (" + _toDisplayString(_ctx.basket.length) + ")", 1),
        _createVNode(_component_MDBBtn, {
          class: "icon-btn",
          ripple: false,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clearAll')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MDBIcon, {
              icon: "trash-alt",
              type: "button",
              class: "icon"
            })
          ]),
          _: 1
        })
      ]),
      (_ctx.errorLimit || _ctx.errorPanier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorLimit ? _ctx.$t('front.balanceSocietale.creation.panier.errorLimit')
            : _ctx.$t('front.balanceSocietale.creation.panier.errorPanier')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.basket, (product, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "product",
            key: product.id
          }, [
            _createElementVNode("div", {
              class: "color-band",
              style: _normalizeStyle(_ctx.getColorFromType(product.type))
            }, [
              _createElementVNode("label", null, _toDisplayString(product.label?.toLowerCase()), 1),
              (_ctx.getCriterionText(index).length > 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('front.balanceSocietale.creation.products',
                  {
                    criterions: _ctx.getCriterionText(index),
                    association: _ctx.getAssociationText(index),
                    count: product.productIdList?.length,
                    nbr: product.productIdList?.length
                  }
                )), 1))
                : (!_ctx.isProduct(product.type))
                  ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(_ctx.$t('front.balanceSocietale.creation.productsList',
                  {
                    count: product.productIdList.length,
                    nbr: product.productIdList.length,
                  }
                )), 1))
                  : _createCommentVNode("", true)
            ], 4),
            _createVNode(_component_MDBBtn, {
              class: "icon-btn",
              ripple: false,
              onClick: ($event: any) => (_ctx.$emit('clearItem', index))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_close, { class: "icon" })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Button, {
        class: "action",
        label: _ctx.isResultatBalance
              ? _ctx.$t('front.balanceSocietale.resultats.panier.bouton')
      : _ctx.$t('front.balanceSocietale.creation.panier.bouton'),
        onClick: _ctx.handleClick
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}