<template>
  <div class="panier-container">
    <div class="panier">
      <header>
        <h4>{{ $t('front.balanceSocietale.creation.panier.titre') }} ({{ basket.length }})</h4>
        <MDBBtn class="icon-btn" :ripple="false" v-on:click="$emit('clearAll')">
          <MDBIcon icon="trash-alt" type="button" class="icon" />
        </MDBBtn>
      </header>
      <div class="error" v-if="errorLimit || errorPanier">
        {{
          errorLimit ? $t('front.balanceSocietale.creation.panier.errorLimit')
            : $t('front.balanceSocietale.creation.panier.errorPanier')
        }}
      </div>
      <div class="product-list">
        <div class="product" v-for="(product, index) in basket" :key="product.id">
          <div class="color-band" v-bind:style="getColorFromType(product.type)">
            <label>{{ product.label?.toLowerCase() }}</label>
            <div class="filter-label"
                 v-if="getCriterionText(index).length > 2">
              {{
                $t('front.balanceSocietale.creation.products',
                  {
                    criterions: getCriterionText(index),
                    association: getAssociationText(index),
                    count: product.productIdList?.length,
                    nbr: product.productIdList?.length
                  }
                )
              }}
            </div>
            <label class="filter-label" v-else-if="!isProduct(product.type)">
              {{
                $t('front.balanceSocietale.creation.productsList',
                  {
                    count: product.productIdList.length,
                    nbr: product.productIdList.length,
                  }
                )
              }}
            </label>
          </div>
          <MDBBtn class="icon-btn" :ripple="false" v-on:click="$emit('clearItem', index)">
            <close class="icon" />
          </MDBBtn>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <Button class="action" :label="isResultatBalance
              ? $t('front.balanceSocietale.resultats.panier.bouton')
      : $t('front.balanceSocietale.creation.panier.bouton')"
              :onClick="handleClick" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Delete, Close } from 'mdue';
import Button from '@/components/Button.vue';
import { MDBBtn, MDBIcon } from 'mdb-vue-ui-kit';
import {
  ASSOCIATION_TYPES,
  BalanceBean,
  FIELD_TYPES,
  FILTER_TYPE,
} from '@/api/balance/BalanceApi';
import { BALANCE_SOCIETALE_RECHERCHE_ROUTE, BALANCE_SOCIETALE_RESULTAT_ROUTE } from '@/router/routes';
import { inject } from 'vue';

@Options({
  components: {
    Delete,
    Button,
    MDBBtn,
    MDBIcon,
    Close,
  },
  props: {
    basket: Array,
    errorLimit: Boolean,
    isResultatBalance: Boolean,
  },
  data() {
    const globalFilters = inject('globalFilters');
    return {
      globalFilters,
      errorPanier: false,
    };
  },
  methods: {
    isProduct(productType: string) {
      return productType === FIELD_TYPES.PRODUIT;
    },
    getAssociationText(index: number) {
      let associated = '';
      const indexFilter = this.basket[index].filters.map((e: BalanceBean) => e.type)
        .indexOf(FIELD_TYPES.COMPOSITION);
      if (this.basket[index].filters
        && this.basket[index].filters[indexFilter]
        && this.basket[index].filters[indexFilter].association !== undefined) {
        associated = this.getAssociationType(this.basket[index].filters[indexFilter].association);
      }
      return associated;
    },
    getAssociationType(association: string) {
      if (association === ASSOCIATION_TYPES.EXCLUSIVE) {
        return `(${this.$t('front.balanceSocietale.filtre.composition.alone')}s)`;
      }
      if (association === ASSOCIATION_TYPES.MINIMA) {
        return `(${this.$t('front.balanceSocietale.filtre.composition.associated')}s)`;
      }
      return '';
    },
    getCriterionText(index: number) {
      this.errorPanier = false;
      const criterionText: string[] = [];
      if (this.basket[index].filters) {
        this.basket[index].filters
          .forEach((criterion: BalanceBean) => criterionText.push(criterion.label));
      }
      return `"${criterionText.join(', ')}"`;
    },
    handleClick() {
      if (this.isResultatBalance) {
        this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE });
      } else if (this.basket.length > 0) {
        this.$router.push({ name: BALANCE_SOCIETALE_RESULTAT_ROUTE });
      } else {
        this.errorPanier = true;
      }
    },
    getColorFromType(type: keyof typeof FILTER_TYPE) {
      return { background: FILTER_TYPE[type].color };
    },
  },
  mounted() {
    if (!this.globalFilters) {
      this.globalFilters = [];
    }
  },
})
export default class Panier extends Vue {
  basket?: BalanceBean[];
}

</script>

<style lang="scss" scoped>
$primary: #005297;
.icon-btn {
  padding: 0;
}

.panier-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px var(--grey-shadow);
  justify-content: space-between;
  width: 18%;
  padding: var(--spacing-big) var(--spacing-big);
  position: sticky;
  top: 200px;
  height: 500px;

  .icon {
    font-size: 18px;
    font-weight: 300;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    h4 {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 1200px) {
    header {
      display: contents;
      height: var(--spacing-big);

      h4 {
        margin-left: -8px;
        margin-bottom: 0;
      }

      .icon-btn {
        height: 20px;
      }
    }
  }

  .error {
    color: $danger;
    font-size: var(--font-size-smaller);
    font-family: 'TTNormsRegular', sans-serif;
  }

  .product-list {
    justify-content: space-between;

    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .color-band {
        height: 25px;
        width: 5px;
        border-radius: 30px;

        label {
          margin-left: 15px;
          font-family: 'SourceSansProRegular', sans-serif;
          color: var(--grey-7);
          width: 12vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          padding-right: var(--spacing-big);
          text-transform: capitalize;
        }

        .filter-label {
          display: none;
        }
      }
    }

    .product:hover {
      .filter-label {
        display: inline-block;
        background-color: var(--white);
        border: 1px solid #005297;
        min-width: max-content;
        position: absolute;
        right: var(--spacing-bigger);
        font-size: var(--font-size-hint);
        text-align: left;
        padding-left: var(--spacing-small);
        font-style: italic;
        margin-right: var(--spacing-bigger);
        padding-right: var(--spacing-small);
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;

    .action {
      background-color: $primary;
      color: var(--white);
      border: none;
      box-shadow: none !important;
      min-width: min-content;
    }
  }
}
</style>
