import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBBtn = _resolveComponent("MDBBtn")!

  return (_openBlock(), _createBlock(_component_MDBBtn, {
    class: _normalizeClass(_ctx.getButtonClass())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "label",
        style: _normalizeStyle({color: _ctx.color})
      }, _toDisplayString(_ctx.label), 5)
    ]),
    _: 1
  }, 8, ["class"]))
}