<template>
  <notifications ref="notifications"/>
  <div class="update-cgu-management">
    <h3 class="page-title">{{ $t('admin.updateCGU.title') }}</h3>
  </div>
  <div class="update-cgu-container">
    <label>{{ $t('admin.updateCGU.subtitle') }}</label>
    <label>{{ $t('admin.updateCGU.subtitle2') }}</label>
    <Button :label="$t('admin.updateCGU.update')"
            color="primary" class="update-button"
            @click="showPopin = true">
    </Button>
  </div>
  <ConfirmationPopin
    v-if="showPopin"
    :title="$t('admin.updateCGU.popin.title')"
    :content="$t('admin.updateCGU.popin.content')"
    :handleDelete="updateCGU"
    :close="closePopin"
    :validationButton= true
  />
</template>

<script lang="ts">
import UsersApi from '@/api/users/UsersApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Notifications from '@/components/Notifications.vue';
import Button from '@/components/Button.vue';
import ConfirmationPopin from '@/components/ConfirmationPopin.vue';
import { MDBIcon } from 'mdb-vue-ui-kit';

@Options({
  components: {
    Notifications,
    Button,
    ConfirmationPopin,
    MDBIcon,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    return {
      usersApi,
      showPopin: false,
    };
  },
  methods: {
    updateCGU() {
      this.usersApi.resetCGU()
        .then(() => this.$refs.notifications.notifySuccess())
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.internalError')));
    },
    closePopin() {
      this.showPopin = false;
    },
  },
})
export default class UpdateCGU extends Vue {
}
</script>

<style lang="scss" scoped>
.update-cgu-management {
  width: 100%;
  padding: 0 var(--spacing-default);
  position: relative;

  .page-title {
    text-align: left;
    padding: var(--spacing-default) 0;
  }
}

.update-cgu-container {
  display: flex;
  flex-direction: column;
  margin: 50px var(--spacing-default);
  text-align: left;

  .update-button {
    margin: var(--spacing-very-big) auto;
    width: 450px;
    height: var(--btn-height-default);

    span {
      width: 100%;
    }
  }
}
</style>
