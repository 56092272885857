<template>
  <div class="detail-container" :style="getContainerStyle()" >
    <label class="title">{{ product.label }}</label>
    <div class="score-container">
      <div class="score">
        <div class="score-label">{{ $t('front.balanceSocietale.resultats.graph.details.min')}}</div>
        <div class="product-note" :style="{color: getColor(product.noteMin, minColor)}">
          {{ product.noteMin.toFixed(1) }}
        </div>
      </div>
      <div class="score">
        <div class="score-label">{{ $t('front.balanceSocietale.resultats.graph.details.moy')}}</div>
        <div class="product-note" :style="{color: getColor(product.noteAvg, moyColor)}">
          {{ product.noteAvg.toFixed(1) }}
        </div>
      </div>
      <div class="score">
        <div class="score-label">{{ $t('front.balanceSocietale.resultats.graph.details.max')}}</div>
        <div class="product-note" :style="{color: getColor(product.noteMax, maxColor)}">
          {{ product.noteMax.toFixed(1) }}
        </div>
      </div>
    </div>
    <div class="triangle" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Triangle } from 'mdue';

@Options({
  components: {
    Triangle,
  },
  props: {
    product: Object,
    minColor: String,
    moyColor: String,
    maxColor: String,
    margin: String,
  },
  methods: {
    getContainerStyle() {
      return { left: `${this.product.noteAvg * 5.56 - (220 / 2 + 2) + 16.7}px` };
    },
    getColor(note: number, color: string): string {
      return note > 45 && note < 55 ? '#a9abb2' : color;
    },
  },
})

export default class DetailScore extends Vue {
}
</script>

<style lang="scss" scoped>
.detail-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px #00000029;
  width: 220px;
  padding: 12px 15px;
  align-items: flex-start;
  background-color: white;
  bottom: 25px;
  height: 95px;

  .triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -2em;
    left: 45%;
    box-sizing: border-box;
    font-size: 10px;
    border: 1em solid black;
    border-color: transparent transparent white white;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: -3px 3px 3px 0 #00000029;
  }

  .title {
    color: var(--balance-blue);
    font-size: 15px;
    font-family: "TTNormsMedium", sans-serif;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .score-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .score {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 15px;
    }
  }
}

</style>
