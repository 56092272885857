import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-540ebafb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-container" }
const _hoisted_2 = { class: "user" }
const _hoisted_3 = { class: "uppercase" }
const _hoisted_4 = { class: "infos" }
const _hoisted_5 = { class: "entity" }
const _hoisted_6 = { class: "light" }
const _hoisted_7 = { class: "package" }
const _hoisted_8 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.user.firstname), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.user.lastname), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.user.profileName), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.user.entityName), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, _toDisplayString(_ctx.user.packageLabel), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Button, {
        label: _ctx.$t('admin.users.tile.detailsButton'),
        color: "white",
        title: _ctx.$t('admin.users.tile.detailButtonTitle'),
        onClick: _cache[0] || (_cache[0] = () => _ctx.handleDetails(_ctx.user.id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_magnify, { class: "icon" })
        ]),
        _: 1
      }, 8, ["label", "title"])
    ])
  ]))
}