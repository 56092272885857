import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43c4ecc9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-advanced-search" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "search-criteria" }
const _hoisted_4 = {
  class: "criteria-label",
  for: "market"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "search-criteria" }
const _hoisted_7 = {
  class: "criteria-label",
  for: "segment"
}
const _hoisted_8 = { class: "search-criteria" }
const _hoisted_9 = {
  class: "criteria-label",
  for: "segmentSpecific"
}
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = { class: "search-criteria" }
const _hoisted_12 = {
  class: "criteria-label",
  for: "culture"
}
const _hoisted_13 = { class: "search-criteria" }
const _hoisted_14 = {
  class: "criteria-label",
  for: "composition"
}
const _hoisted_15 = { class: "search-criteria" }
const _hoisted_16 = {
  class: "criteria-label",
  for: "supplier"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('front.product.advancedSearch.market')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "market",
            id: "market",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criterion.market) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.market, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option.label), 9, _hoisted_5))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.market]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('front.product.advancedSearch.segment')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "segment",
            id: "segment",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.criterion.segment) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segment, (option) => {
              return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option.label), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.segment]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('front.product.advancedSearch.segmentSpecific')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "segmentSpecific",
            id: "segmentSpecific",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.criterion.segmentSpecific) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segmentSpecific, (option) => {
              return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.segmentSpecific]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('front.product.advancedSearch.culture')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "culture",
            id: "culture",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.criterion.culture) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.culture, (option) => {
              return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.culture]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('front.product.advancedSearch.composition')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "composition",
            id: "composition",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.criterion.composition) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.composition, (option) => {
              return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.composition]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('front.product.advancedSearch.supplier')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "supplier",
            id: "supplier",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.criterion.supplier) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplier, (option) => {
              return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.criterion.supplier]
          ])
        ])
      ])
    ]),
    _createVNode(_component_Button, {
      type: "submit",
      label: _ctx.$t('common.button.search'),
      color: "primary",
      class: "search-button",
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_magnify, { class: "icon" })
      ]),
      _: 1
    }, 8, ["label", "disabled"])
  ], 32))
}