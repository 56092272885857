import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/reglette_balance.png'


const _withScopeId = n => (_pushScopeId("data-v-7ead4167"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "detail"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailScore = _resolveComponent("DetailScore")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showScore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_DetailScore, {
            product: _ctx.product,
            minColor: _ctx.styles.bcgColorMin,
            moyColor: _ctx.styles.bcgColorMoy,
            maxColor: _ctx.styles.bcgColorMax
          }, null, 8, ["product", "minColor", "moyColor", "maxColor"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isProduct)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "product-score",
          style: _normalizeStyle(_ctx.getProductScoreStyle())
        }, [
          _createElementVNode("img", {
            class: "img",
            alt: "Product score image",
            src: _ctx.logoProduct
          }, null, 8, _hoisted_2)
        ], 4))
      : (_ctx.product.noteMin === _ctx.product.noteMax)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "bar",
            style: _normalizeStyle(_ctx.getBarStyle()),
            type: "button",
            onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args))),
            onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideDetail && _ctx.hideDetail(...args)))
          }, null, 36))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              alt: "reglette",
              src: _imports_0,
              style: _normalizeStyle(_ctx.getBarStyle()),
              onMouseover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args))),
              onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideDetail && _ctx.hideDetail(...args)))
            }, null, 36),
            _createElementVNode("div", {
              class: "moy",
              onMouseover: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args))),
              style: _normalizeStyle(_ctx.getAvgIndicationStyle())
            }, null, 36)
          ]))
  ], 64))
}