import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-003f31ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "close-button" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = {
  key: 1,
  class: "content"
}
const _hoisted_4 = { class: "description" }
const _hoisted_5 = {
  key: 0,
  class: "video"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "footer" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close = _resolveComponent("close")!
  const _component_chevron_left = _resolveComponent("chevron-left")!
  const _component_chevron_right = _resolveComponent("chevron-right")!
  const _component_Popin = _resolveComponent("Popin")!

  return (_openBlock(), _createBlock(_component_Popin, {
    title: "Tutoriel",
    onClose: _ctx.close,
    class: "custom-popin"
  }, {
    button: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close, {
          class: "icon",
          onClick: _ctx.close
        }, null, 8, ["onClick"])
      ])
    ]),
    container: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hasContentLeft())
          ? (_openBlock(), _createBlock(_component_chevron_left, {
              key: 0,
              class: "icon left",
              onClick: _cache[0] || (_cache[0] = () => _ctx.changePage(true))
            }))
          : _createCommentVNode("", true),
        (_ctx.currentItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentItem.text), 1),
              (_ctx.videoLink)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("video", {
                      width: "500",
                      controls: "",
                      src: _ctx.videoLink
                    }, null, 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasContentRight())
          ? (_openBlock(), _createBlock(_component_chevron_right, {
              key: 2,
              class: "icon right",
              onClick: _cache[1] || (_cache[1] = () => _ctx.changePage(false))
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tutorialItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "dot-container",
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['dot', index === _ctx.activeIndex ? 'current' : '']),
              onClick: () => _ctx.selectPage(index)
            }, null, 10, _hoisted_8)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}