<template>
  <div class="histogram">
    <span class="y-legend">{{ $t('front.product.histogram.legend') }}</span>
    <div v-for="yLabel in yLabels" :key="yLabel"
         v-bind:class="['lign', yLabel ? 'lign--'+yLabel : '']">
      <span class="y-label">{{ yLabel }}</span>
    </div>

    <div class="bar bar--onescore" v-bind:style="styleBar(oneScore)" v-if="comparisonOneScore"
         :title="$t('front.product.histogram.oneScoreTitle', { note: oneScore })">
      <div class="round-avg" :style="styleAvg(comparisonOneScore.avg)"
           :title="$t('front.product.histogram.oneScoreAvg',
            { note: comparisonOneScore.avg })" />
      <div class="small-bar" :style="styleComparison(comparisonOneScore)"
           :title="$t('front.product.histogram.oneScoreComparison',
            { min: comparisonOneScore.min, max: comparisonOneScore.max })" />
    </div>
    <div class="bar bar--alimentation" :style="styleBar(alimentation)" v-if="comparisonAlimentation"
         :title="$t('front.product.histogram.alimentationTitle', { note: alimentation })">
      <div class="round-avg" :style="styleAvg(comparisonAlimentation.avg)"
           :title="$t('front.product.histogram.alimentationAvg',
            { note: comparisonAlimentation.avg })" />
      <div class="small-bar" :style="styleComparison(comparisonAlimentation)"
           :title="$t('front.product.histogram.alimentationComparison',
            { min: comparisonAlimentation.min, max: comparisonAlimentation.max })" />
    </div>
    <div class="bar bar--agriculteur" :style="styleBar(agriculteur)" v-if="comparisonAgriculteur"
         :title="$t('front.product.histogram.agriculteurTitle', { note: agriculteur })">
      <div class="round-avg" :style="styleAvg(comparisonAgriculteur.avg)"
           :title="$t('front.product.histogram.agriculteurAvg',
            { note: comparisonAgriculteur.avg })" />
      <div class="small-bar" :style="styleComparison(comparisonAgriculteur)"
           :title="$t('front.product.histogram.agriculteurComparison',
            { min: comparisonAgriculteur.min, max: comparisonAgriculteur.max })" />
    </div>
    <div class="bar bar--environnement" :style="styleBar(environment)" v-if="comparisonEnvironnement"
         :title="$t('front.product.histogram.environnementTitle', { note: environment })">
      <div class="round-avg" :style="styleAvg(comparisonEnvironnement.avg)"
           :title="$t('front.product.histogram.environnementAvg',
            { note: comparisonEnvironnement.avg })" />
      <div class="small-bar" :style="styleComparison(comparisonEnvironnement)"
           :title="$t('front.product.histogram.environnementComparison',
            { min: comparisonEnvironnement.min, max: comparisonEnvironnement.max })" />
    </div>
  </div>
  <div v-bind:class="['x-label', comparisonOneScore ? '' : 'no-one-score']">
    <span v-if="comparisonOneScore">{{ $t('front.product.histogram.one_score') }}</span>
    <span v-if="comparisonAlimentation">{{ $t('front.product.histogram.alimentation') }}</span>
    <span v-if="comparisonAgriculteur">{{ $t('front.product.histogram.agriculteur') }}</span>
    <span v-if="comparisonEnvironnement">{{ $t('front.product.histogram.environnement') }}</span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ComparisonProductByScoreType } from '@/api/products/ProductsApi';
import { PropType } from 'vue';

@Options({
  props: {
    oneScore: Number,
    alimentation: Number,
    agriculteur: Number,
    environment: Number,
    yLabels: Array,
    comparisonOneScore: {
      type: Object as PropType<ComparisonProductByScoreType>,
      required: true,
    },
    comparisonAlimentation: {
      type: Object as PropType<ComparisonProductByScoreType>,
      required: true,
    },
    comparisonAgriculteur: {
      type: Object as PropType<ComparisonProductByScoreType>,
      required: true,
    },
    comparisonEnvironnement: {
      type: Object as PropType<ComparisonProductByScoreType>,
      required: true,
    },
  },
  data() {
    return {
      // 400px (histogram height) / 100 (note max)
      coeff: 400 / 100,
    };
  },
  methods: {
    styleBar(value: number) {
      return `height: ${value * this.coeff}px`;
    },
    styleAvg(value: number) {
      // On retranche la moitié de la taille du rond 10 / 2 = 5
      return `bottom: ${value * this.coeff - 5}px`;
    },
    styleComparison(comparisonByScore: ComparisonProductByScoreType) {
      const height = comparisonByScore.max - comparisonByScore.min;
      return `bottom: ${comparisonByScore.min * this.coeff}px; height: ${height * this.coeff}px;`;
    },
  },
})

export default class Histogram extends Vue {}
</script>

<style lang="scss" scoped>
// Attention mettre à jour le coeff si on modifie histogram !!
$histogram: 400px;

.histogram {
  position: relative;
  height: $histogram;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin: 50px auto 10px;
  border-bottom: 4px solid grey;

  .bar {
    width: 25px;
    position: relative;

    &.bar--onescore { background: $histogram-oneScore; }
    &.bar--alimentation { background: $histogram-alimentation; }
    &.bar--agriculteur { background: $histogram-agriculteur; }
    &.bar--environnement { background: $histogram-environement; }

    .round-avg {
      height: 10px;
      width: 10px;
      background: black;
      border-radius: 50%;
      position: absolute;
      left: 8px;
      z-index: 1;
    }
  }

  .small-bar {
    position: absolute;
    width: 4px;
    background: black;
    left: 11px;
  }

  .lign {
    position: absolute;
    border-bottom: 1px solid $histogram-lign;
    width: 100%;

    &.lign--0 {
      .y-label { left: -25px; }
    }
    &.lign--20 { bottom: calc(#{$histogram} * 0.2); }
    &.lign--40 { bottom: calc(#{$histogram} * 0.4); }
    &.lign--50 { bottom: calc(#{$histogram} * 0.5); border-bottom-color: black; }
    &.lign--60 { bottom: calc(#{$histogram} * 0.6); }
    &.lign--80 { bottom: calc(#{$histogram} * 0.8); }
    &.lign--100 {
      bottom: $histogram;
      .y-label { left: -35px; }
    }

    .y-label {
      font-size: var(--font-size-smaller);
      position: absolute;
      left: -30px;
      top: -10px;
    }
  }
}

.x-label {
  width: 60%;
  margin: 0 auto var(--spacing-default);
  display: flex;
  justify-content: center;

  span {
    width: 20%;
    text-align: center;
  }

  &.no-one-score {
    span {
      width: 26%;
    }
  }
}

.y-legend {
  font-size: var(--font-size-smaller);
  font-family: SourceSansProLight, sans-serif;
  position: absolute;
  left: -270px;
  width: $histogram;
  top: calc(#{$histogram} / 2);
  transform: rotate(270deg);
  text-align: center;
}

</style>
