import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4255009a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-container" }
const _hoisted_2 = { class: "accordion-title" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBIcon = _resolveComponent("MDBIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(['accordion', _ctx.collapseAccordion ? 'active' : '', _ctx.type ?? _ctx.type]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.sortUsed), 1),
      _createVNode(_component_MDBIcon, {
        icon: "angle-down",
        class: "icon-right"
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['panel', _ctx.collapseAccordion ? 'active' : ''])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortList, (sort) => {
        return (_openBlock(), _createElementBlock("div", { key: sort }, [
          _createElementVNode("button", {
            onClick: () => _ctx.handleSort(sort)
          }, _toDisplayString(sort), 9, _hoisted_3)
        ]))
      }), 128))
    ], 2)
  ]))
}