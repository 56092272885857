<template>
  <notifications ref="notifications" />
  <div v-bind:class="['filtre-container', getHasFilters() ? '' : 'none']">
    <div class="title" v-if="getHasFilters()">
      <h4>{{ $t('front.balanceSocietale.filtre.filters') }}</h4>
      <MDBIcon
          class="icon"
          icon="trash-alt"
          :title="$t('front.balanceSocietale.filtre.reset')"
          @click="clearFilters"
      />
    </div>
    <div v-for="field in currentFiltersSelected" :key="field">
      <div :class="isInFiltersList(field.type) ? '' : 'hide'">
        <ButtonFilterType
          :disabled="disable"
          :title="field.label"
          :isArrow="!isGamme(field.type)"
          :filterNumber="field.filters ? field.filters.length : 0"
          :addItem="() => addItem(field)"
          :clearItem="() => clearItem(field)"
          @click="handleSelection(field, $event)"
        />
      </div>
    </div>
    <PopinFiltresBalanceSearch
      v-if="showPopinFiltres"
      :filterIndex="filterIndex"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { MDBIcon } from 'mdb-vue-ui-kit';
import { Options, Vue } from 'vue-class-component';
import ButtonFilterType from '@/views/front/balance-societale/ButtonFilterType.vue';
import { inject } from 'vue';
import BalanceApi, { BalanceBean, FIELD_TYPES, FILTER_TYPE } from '@/api/balance/BalanceApi';
import PopinFiltresBalanceSearch from '@/views/front/balance-societale/PopinFiltresBalanceSearch.vue';
import Notifications from '@/components/Notifications.vue';

@Options({
  components: {
    ButtonFilterType,
    PopinFiltresBalanceSearch,
    Notifications,
    MDBIcon,
  },
  props: {
    showPopinFiltres: Boolean,
    disable: Boolean,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);

    const currentFilters = inject('currentFilters'); // Filtres affichés dans le composant filtre de la page de la balance sociétale
    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    const currentFiltersSelected: BalanceBean[] = [];
    return {
      balanceApi,
      filterFieldSelected,
      currentFiltersSelected,
      globalFilters,
      currentFilters,
      filterIndex: '',
      dataLoaded: false,
    };
  },
  methods: {
    clearFilters() {
      this.globalFilters = [];
      this.filterFieldSelected = [];
      this.currentFilters = [...this.currentFilters]
        .map((filterField) => ({
          ...filterField,
          filters: [],
        }));
    },
    notifyNoData() {
      this.$refs.notifications.notifyWarning(this.$t('front.balanceSocietale.filtre.data'));
    },
    isGamme(fieldType: string) {
      return fieldType === FIELD_TYPES.GAMME;
    },
    sortMethod() {
      // deep copy afin de ne pas impacter researchField qui est injecte dans le reste de l'appli
      const copy = JSON.parse(JSON.stringify(this.currentFilters));
      return copy.sort((a: BalanceBean, b: BalanceBean) => {
        if (a.type === FIELD_TYPES.GAMME) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      });
    },
    isInFiltersList(filterType: string) {
      if (this.$parent.selected) {
        return FILTER_TYPE[this.$parent.selected.type as keyof typeof FILTER_TYPE]
          .filters
          .includes(filterType);
      }
      return false;
    },
    getHasFilters() {
      let hasFilters = false;
      this.currentFilters.forEach((field: BalanceBean) => {
        if (this.isInFiltersList(field.type)) {
          hasFilters = true;
        }
      });
      return hasFilters;
    },
    handleSelection(field: BalanceBean, event: Event) {
      this.dataLoaded = false;
      this.handlePopin(field, event);

      if (field !== this.filterFieldSelected) {
        this.filterFieldSelected = field;
      }
    },
    // e.srcElement is deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handlePopin(field: BalanceBean, event: any) {
      if (this.isGamme(field.type)) {
        this.$parent.showPopinFiltres = false;
        return;
      }

      if (field !== this.filterFieldSelected) {
        this.$parent.showPopinFiltres = true;
      } else {
        this.$parent.showPopinFiltres = !this.$parent.showPopinFiltres;
      }
      // On calcule la position du triangle
      // par rapport a la position et la hauteur de l'object sur lequel on clique
      this.filterIndex = event.target.offsetTop + 0.5 * (event.srcElement.clientHeight) - 25;
    },
    addItem(field: BalanceBean) {
      this.globalFilters.push({
        type: field.type,
        label: field.label,
        association: false,
      });
    },
    clearItem(fieldToRemove: BalanceBean) {
      const indexGlobalFilterToRemove = this.globalFilters
        .map((item: BalanceBean) => item.type)
        .indexOf(fieldToRemove.type);

      if (indexGlobalFilterToRemove >= 0) {
        this.globalFilters.splice(indexGlobalFilterToRemove, 1);
      }
    },
  },
  watch: {
    currentFilters: {
      handler() {
        this.currentFiltersSelected = this.sortMethod();
      },
    },
    globalFilters: {
      handler() {
        this.researchFieldsSorted = this.sortMethod();
      },
    },
  },
  mounted() {
    this.balanceApi.fetchResearchFields(true, true)
      .then((response: BalanceBean[]) => {
        this.currentFilters = response;
      })
      .finally(() => {
        if (!this.currentFilters) {
          this.currentFilters = [];
        }
        if (!this.filterFieldSelected) {
          this.filterFieldSelected = [];
        }
        this.currentFiltersSelected = this.sortMethod();
      });
  },
})
export default class FiltresBalanceSearch extends Vue {
  selected?: BalanceBean;
}
</script>

<style lang="scss" scoped>
$primary: #005297;
.filtre-container {
  background-color: $primary;
  padding: 20px;
  height: 500px;
  width: 18%;
  position: sticky;
  top: 200px;
  z-index: 1;

  .title {
    color: var(--white);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 4px;

    .icon {
      cursor: pointer;
      font-weight: initial;
    }
  }

  .hide {
    display: none;
  }

  &.none {
    background-color: white;
  }

  h4 {
    color: var(--white);
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 20px;
  }
}
</style>
