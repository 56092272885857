<template>
  <Drawer>
    <template v-slot:header>
      <div class="header">
        <Close color="white" />
        <h3>{{ fournisseur.name }}</h3>
      </div>
    </template>
    <template v-slot:page>
      <div id="nav">
        <router-link
          :to="{ name: FOURNISSEUR_DETAIL_ROUTE, params: { id: $route.params.id } }"
          v-t="'admin.fournisseur.users.details'"
          replace />
        <router-link
          :to="{ name: FOURNISSEUR_PRODUCT_ROUTE, params: { id: $route.params.id } }"
          v-t="'admin.fournisseur.users.products'"
          replace />
        <router-link
          :to="{ name: FOURNISSEUR_USERS_ROUTE, params: { id: $route.params.id } }"
          v-t="'admin.fournisseur.users.list'"
          replace />
      </div>
      <div class="page-container">
        <router-view />
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Drawer from '@/components/Drawer.vue';
import Close from '@/components/Close.vue';
import FournisseursApi, { Fournisseur as FournisseurType } from '@/api/fournisseurs/FournisseursApi';
import { FOURNISSEUR_DETAIL_ROUTE, FOURNISSEUR_PRODUCT_ROUTE, FOURNISSEUR_USERS_ROUTE } from '@/router/routes';

@Options({
  components: {
    Drawer,
    Close,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);
    return {
      fournisseursApi,
      fournisseur: {},
      FOURNISSEUR_DETAIL_ROUTE,
      FOURNISSEUR_PRODUCT_ROUTE,
      FOURNISSEUR_USERS_ROUTE,
    };
  },
  methods: {
    fetchFournisseur(id: string) {
      return this.fournisseursApi
        .fetchFournisseur(id)
        .then((response: FournisseurType) => {
          this.fournisseur = response;
        });
    },
  },
  mounted() {
    this.fetchFournisseur(this.$route.params.id);
  },
})
export default class Fournisseur extends Vue {
  fournisseur!: FournisseurType;
}
</script>

<style lang="scss" scoped>
$height-header-fournisseur: var(--header-width);

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: $height-header-fournisseur;
  background: $dark;
  color: white;
  text-transform: uppercase;
  z-index: 2;
  h3 {
    font-size: 18px;
    margin: 0;
  }
}
#nav {
  display: flex;
  padding: 0;
  margin: 0 var(--spacing-bigger);
  border-bottom: 1px solid var(--grey-1);
  margin-top: $height-header-fournisseur;

  a {
    margin: 0 var(--spacing-bigger);
    min-width: 180px;
    padding: var(--spacing-bigger);
    font-weight: normal;

    &.router-link-active {
      border-bottom: 3px solid black;
      color: initial;
    }
  }
}
.page-container {
  margin: 0 var(--spacing-bigger);
}
</style>
