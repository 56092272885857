<template>
  <notifications ref="notifications" />
  <div class="filtre-container">
    <div class="title">
      <h4>{{ $t('front.balanceSocietale.filtre.filters') }}</h4>
      <MDBIcon
          class="icon"
          icon="trash-alt"
          :title="$t('front.balanceSocietale.filtre.reset')"
          @click="clearFilters"
      />
    </div>
    <div v-for="field in filterField" :key="field">
      <div>
        <ButtonFilterType
            :disabled="disable"
            :title="field.label"
            :isArrow="!isGamme(field.type)"
            :filterNumber="field.filters ? field.filters.length : 0"
            :addItem="() => addItem(field)"
            :clearItem="() => clearItem(field)"
            @click="handleSelection(field, $event)"
        />
      </div>
    </div>
    <PopinFiltres
      v-if="showPopinFiltres"
      :filterIndex="filterIndex"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { MDBIcon } from 'mdb-vue-ui-kit';
import { Options, Vue } from 'vue-class-component';
import ButtonFilterType from '@/views/front/balance-societale/ButtonFilterType.vue';
import { inject } from 'vue';
import BalanceApi, { BalanceBean, FIELD_TYPES } from '@/api/balance/BalanceApi';
import PopinFiltres from '@/views/front/PopinFiltres.vue';
import Notifications from '@/components/Notifications.vue';

@Options({
  components: {
    ButtonFilterType,
    PopinFiltres,
    Notifications,
    MDBIcon,
  },
  props: {
    showPopinFiltres: Boolean,
    disable: Boolean,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);

    const filterField = inject('filterField');
    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    return {
      balanceApi,
      filterFieldSelected,
      filterField,
      globalFilters,
      filterIndex: 0,
      dataLoaded: false,
    };
  },
  methods: {
    clearFilters() {
      this.globalFilters = [];
      this.filterFieldSelected = [];
      this.filterField = [...this.filterField]
        .map((filterField) => ({
          ...filterField,
          filters: [],
        }));
    },
    notifyNoData() {
      this.$refs.notifications.notifyWarning(this.$t('front.balanceSocietale.filtre.data'));
    },
    isGamme(fieldType: string) {
      return fieldType === FIELD_TYPES.GAMME;
    },
    handleSelection(field: BalanceBean, event: Event) {
      this.dataLoaded = false;
      this.handlePopin(field, event);

      if (field !== this.filterFieldSelected) {
        this.filterFieldSelected = field;
      }
    },
    handlePopin(field: BalanceBean, event: Event) {
      if (this.isGamme(field.type)) {
        this.$parent.showPopinFiltres = false;
        return;
      }

      if (field !== this.filterFieldSelected) {
        this.$parent.showPopinFiltres = true;
      } else {
        this.$parent.showPopinFiltres = !this.$parent.showPopinFiltres;
      }
      // On calcule la position du triangle
      // par rapport a la position et la hauteur de l'object sur lequel on clique
      this.filterIndex = (event.target as HTMLDivElement).offsetTop + 0.5 * ((event.target as HTMLDivElement).clientHeight ?? 0) - 25;
    },
    addItem(field: BalanceBean) {
      this.globalFilters.push({
        type: field.type,
        label: field.label,
        association: undefined,
      });
    },
    clearItem(fieldToRemove: BalanceBean) {
      const indexGlobalFilterToRemove = this.globalFilters
        .map((item: BalanceBean) => item.type)
        .indexOf(fieldToRemove.type);

      if (indexGlobalFilterToRemove >= 0) {
        this.globalFilters.splice(indexGlobalFilterToRemove, 1);
      }
    },
  },
  mounted() {
    if (!this.filterField) {
      this.filterField = [];
    }
    if (!this.filterFieldSelected) {
      this.filterFieldSelected = [];
    }
  },
})
export default class Filtres extends Vue {
}
</script>

<style lang="scss" scoped>
  $primary: var(--grey-5);
.filtre-container {
  background-color: $primary;
  padding: 20px;
  height: 500px;
  width: 18%;
  position: sticky;
  top: 60px;
  z-index: 1;

  .title {
    color: var(--white);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 4px;

    .icon {
      cursor: pointer;
      font-weight: initial;
    }
  }

  h4 {
    color: var(--white);
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 20px;
  }
}
</style>
