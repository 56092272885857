<template>
  <DropDownMenu
    v-bind:userName="firstName + ' ' + lastName + getRole()" iconName="user"
    :options="options"
  >
  </DropDownMenu>
</template>

<script lang="ts">
import { ENTITY_TYPES } from '@/api/entity/EntityApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import DropDownMenu from '@/components/DropDownMenu.vue';
import MenuAdministration from '@/views/admin/MenuAdministration.vue';
import {
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,
} from 'mdb-vue-ui-kit';
import {
  ACCUEIL,
  BALANCE_SOCIETALE_ACCUEIL_ROUTE,
  PRODUCT_LIST_ADMIN_ROUTE,
  PRODUCT_ROUTE,
} from '@/router/routes';
import AuthentApi, { PERMISSIONS } from '@/api/authentication/AuthentApi';
import UsersApi, { User } from '@/api/users/UsersApi';
import { inject } from 'vue';

type Action = {
  label: string;
  action: () => void;
}
@Options({
  components: {
    MDBDropdownMenu,
    MDBDropdown,
    MDBIcon,
    MDBDropdownItem,
    MDBDropdownToggle,
    DropDownMenu,
    MenuAdministration,
  },
  data() {
    const authorization = inject('authorization');
    const authentApi = getGlobalInstance(AuthentApi);
    const usersApi = getGlobalInstance(UsersApi);
    return {
      authentApi,
      usersApi,
      firstName: '',
      lastName: '',
      role: '',
      authorization,
    };
  },
  emits: ['logout'],
  computed: {
    options() {
      const actions: Array<Action> = [];
      if (this.displayUserOption()) {
        actions.push({
          label: this.$t('espaceClient.options.oneScore'),
          action: () => this.redirection(),
        });
      } else if (this.displayAdminOption()) {
        actions.push({
          label: this.$t('espaceClient.options.admin'),
          action: () => this.redirection(),
        });
      }
      actions.push({
        label: this.$t('espaceClient.options.logout'),
        action: () => this.logout(),
      });
      return actions;
    },
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
    displayUserOption() {
      const permissions: string[] = this.authentApi.getPermissions(this.authorization);
      return this.$route.fullPath.includes('/admin')
        && (permissions.includes(PERMISSIONS.PAGE_ACCUEIL)
        || permissions.includes(PERMISSIONS.PAGE_RECHERCHE)
        || permissions.includes(PERMISSIONS.PAGE_COMPARATEUR));
    },
    displayAdminOption() {
      return !this.$route.fullPath.includes('/admin')
        && this.authentApi.getPermissions(this.authorization).includes(PERMISSIONS.ADMIN);
    },
    redirection() {
      const permissions: string[] = this.authentApi.getPermissions(this.authorization);
      if (this.$route.fullPath.includes('/admin')) {
        if (permissions.includes(PERMISSIONS.PAGE_ACCUEIL)) {
          this.$router.push({ name: ACCUEIL });
        } else if (permissions.includes(PERMISSIONS.PAGE_RECHERCHE)) {
          this.$router.push({ name: PRODUCT_ROUTE });
        } else {
          this.$router.push({ name: BALANCE_SOCIETALE_ACCUEIL_ROUTE });
        }
      } else {
        this.$router.push({ name: PRODUCT_LIST_ADMIN_ROUTE });
      }
    },
    getRoleFromEntityType(user: User) {
      switch (user.entityType) {
        case ENTITY_TYPES.UNION_ADMIN:
          return 'Admin';
        case ENTITY_TYPES.UNION:
          return 'Invivo';
        case ENTITY_TYPES.COOPERATIVE:
          return user.entityName;
        case ENTITY_TYPES.FOURNISSEUR:
          return user.entityName;
        default:
          return '';
      }
    },
    getRole() {
      return this.role ? ` (${this.role})` : '';
    },
    fetchMe(token?: string) {
      this.usersApi.fetchMe(token)
        .then((user: User) => {
          this.firstName = user.firstName;
          this.lastName = user.lastName.toUpperCase();
          this.role = this.getRoleFromEntityType(user);
        });
    },
  },
  mounted() {
    this.fetchMe(this.authorization);
  },
})

export default class EspaceClient extends Vue {
}
</script>

<style scoped lang="scss">
</style>
