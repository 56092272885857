import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-group-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_close_circle = _resolveComponent("close-circle")!
  const _component_MDBBtn = _resolveComponent("MDBBtn")!
  const _component_MDBInput = _resolveComponent("MDBInput")!

  return (_openBlock(), _createBlock(_component_MDBInput, {
    formOutline: false,
    placeholder: _ctx.text,
    class: "input",
    modelValue: _ctx.input,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
    inputGroup: "lg",
    type: "text",
    onKeyup: _withKeys(_ctx.handleSearch, ["enter"])
  }, {
    prepend: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_magnify, { class: "icon" })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_MDBBtn, {
        ripple: false,
        onClick: _ctx.clear
      }, {
        default: _withCtx(() => [
          _createVNode(_component_close_circle)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["placeholder", "modelValue", "onKeyup"]))
}