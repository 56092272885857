<template>
  <div class="loader-container">
    <div class="loader" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class Button extends Vue {
}
</script>

<style lang="scss" scoped>
@keyframes flash {
  0% {box-shadow: 20px 0 $primary,-20px 0 #00000022;background: $primary}
  33%{box-shadow: 20px 0 $primary,-20px 0 #00000022;background: #00000022}
  66%{box-shadow: 20px 0 #00000022,-20px 0 $primary;background: #00000022}
}

.loader-container {
  display: flex;
  justify-content: center;

  .loader {
    width:12px;
    height:12px;
    border-radius: 50%;
    box-shadow: 20px 0 #00000022,-20px 0 $primary;
    animation:flash 1s infinite linear alternate;
  }
}
</style>
