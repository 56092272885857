<template>
  <MDBDropdown v-model="dropdown">
    <MDBDropdownToggle @click="dropdown = !dropdown" class="dropdown-bouton btn-light">
      <MDBIcon :icon="iconName" class="icon-user"></MDBIcon>
      {{ userName }}
    </MDBDropdownToggle>
    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
      <MDBDropdownItem href="#"
                       v-for="(item) in options" :key="item" @click="item.action"
      >
        {{ item.label }}
      </MDBDropdownItem>
    </MDBDropdownMenu>
  </MDBDropdown>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,
} from 'mdb-vue-ui-kit';
import { ref } from 'vue';

@Options({
  props: {
    userName: String,
    iconName: String,
    options: Array,
    action: Function,
  },
  components: {
    MDBDropdownMenu,
    MDBDropdown,
    MDBIcon,
    MDBDropdownItem,
    MDBDropdownToggle,
  },

  data() {
    const dropdown = ref(false);
    return {
      selected: {
        name: null,
        id: null,
      },
      showed: false,
      dropdown,
    };
  },
  methods: {
    show() {
      this.showed = !this.showed;
    },
  },
})
export default class DropDownMenu extends Vue {
}
</script>

<style scoped lang="scss">
.dropdown-bouton {
  min-width: 12rem;
  display: flex;
  text-transform: none;
  justify-content: space-between;
  align-items: center;

  .icon-user {
    padding-right: var(--spacing-small);
    font-size: var(--font-size-smaller);
  }
}
</style>
