import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-961546f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "show-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_eye = _resolveComponent("eye")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: _ctx.label,
      color: "secondary",
      onClick: _ctx.showMore,
      class: _normalizeClass([!_ctx.isAdmin() ? 'button front' : 'button']),
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        (_ctx.isAdmin())
          ? (_openBlock(), _createBlock(_component_eye, {
              key: 0,
              class: "icon"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label", "onClick", "class", "loading"])
  ]))
}