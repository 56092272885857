<template>
  <HeaderTemplate :title="$t('front.balanceSocietale.accueil.titre')"
                  :subtitle1="$t('front.balanceSocietale.accueil.desc')"
                  :subtitle2="$t('front.balanceSocietale.accueil.desc2')"
                  :image="$t('front.balanceSocietale.accueil.bouton.image')"
  />
  <div class="title">
  </div>
  <div class="page-container">
    <router-view/>
  </div>
  <FooterTemplate/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HeaderTemplate from '@/views/front/HeaderTemplate.vue';
import FooterTemplate from '@/views/front/FooterTemplate.vue';

@Options({
  components: {
    HeaderTemplate,
    FooterTemplate,
  },
  emits: ['menuChanged'],
  mounted() {
    this.$emit('menuChanged', 'balance');
  },
})

export default class Balance extends Vue {
}

</script>

<style lang="scss" scoped>
$primary: #005297;
.title {
  padding-top: var(--spacing-bigger);
  h3 {
    color: $primary;
  }
}
</style>
