<template>
  <div class="page-container">
    <router-view/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BalanceRecherche from '@/views/front/balance-societale/BalanceRecherche.vue';
import BalanceComparateur from '@/views/front/balance-societale/BalanceComparateur.vue';
import { provide, ref } from 'vue';

@Options({
  components: {
    BalanceRecherche,
    BalanceComparateur,
  },
  data() {
    const basketProducts = ref([]);
    provide('basketProducts', basketProducts);
    const researchFields = ref([]);
    provide('researchFields', researchFields);
    const currentFilters = ref([]);
    provide('currentFilters', currentFilters);
    const filterFieldSelected = ref([]);
    provide('filterFieldSelected', filterFieldSelected);
    const globalFilters = ref([]);
    provide('globalFilters', globalFilters);
    return {
      basketProducts,
      researchFields,
      filterFieldSelected,
      globalFilters,
      currentFilters,
    };
  },
})

export default class BalanceMain extends Vue {
}
</script>

<style lang="scss" scoped>
</style>
