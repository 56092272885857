<template>
  <div class="input-group">
    <span class="input-group-icon">
        <magnify class="icon"/>
    </span>
    <input
        id="search-input"
        type="text"
        class="form-control"
        :placeholder="$t('common.button.search')"
        v-model="researchInput"
        v-on:keyup.enter="$emit('handleSearch', researchInput)"
    >
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Magnify } from 'mdue';

@Options({
  components: {
    Magnify,
  },
  data() {
    return {
      researchInput: '',
    };
  },
  props: {
    inputValue: String,
  },
  watch: {
    inputValue(newValue) {
      if (newValue === '') {
        // Si inputValue est renvoyée vide, on reset le contenu du champ de recherche
        this.researchInput = '';
      }
    },
  },
})
export default class ResearchBarFiltre extends Vue {

}
</script>

<style lang="scss" scoped>
.input-group {
  width: 94%;
  margin: 0 auto;
  height: var(--search-bar-height);

  .input-group-icon {
    position: absolute;
    z-index: 10;
    padding: var(--spacing-medium) var(--spacing-default);

    .icon {
      font-size: var(--font-size-default);
    }
  }

  .form-control {
    border: 1px solid var(--grey-5);
    height: var(--search-bar-height);
    font-family: "TTNormsLight", sans-serif;
    padding: 0 40px;
    border-radius: initial;
    .span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:focus {
      border: 1px solid var(--grey-5);
      -webkit-box-shadow: none;
    }
  }
}

</style>
