<template>
  <div class="vertical-line" v-bind:style="[{ background: color }]"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    color: String,
  },
})

export default class VerticalLine extends Vue {
}
</script>

<style lang="scss" scoped>
.vertical-line {
  width: 1px;
  height: 100%;
}

</style>
