<template>
  <notifications ref="notifications"/>
  <div class="login-page">
    <div class="login-container">
      <div>
        <h4 class="login-title">{{ $t('front.login.validationCode.title') }}</h4>
        <p class="error-message subtitle-message">
          {{ $t('front.login.validationCode.subtitle') }}
        </p>
        <p class="error-message complement-message">
          {{ $t('front.login.validationCode.complement') }}
        </p>
        <form class="form-connexion" @submit.prevent="codeValidationAction">
          <div class="container-input" id="container-input">
            <input class="form-control form-control-lg champs-form" :value="code1"
                   type="number" maxlength="1" size="lg"
                   v-on:keyup="(e) => focusNext(e, 'code1')" />
            <input class="form-control form-control-lg champs-form"
                   type="number" maxlength="1" size="lg" :value="code2"
                   v-on:keyup="(e) => focusNext(e, 'code2')" />
            <input class="form-control form-control-lg champs-form" id="input-3"
                   type="number" maxlength="1" size="lg" :value="code3"
                   v-on:keyup="(e) => focusNext(e, 'code3')" />
            <input class="form-control form-control-lg champs-form" id="input-4"
                   type="number" maxlength="1" size="lg" v-bind:value="code4"
                   v-on:keyup="(e) => focusNext(e, 'code4')" />
          </div>
          <div class="btn-container">
            <Button class="send-button" :onClick="sendAgainCodeVerification">
              {{ $t('front.login.validationCode.sendAgain') }}
            </Button>
            <Button type="submit">
              {{ $t('front.login.validationCode.validate') }}
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AuthentApi, { LoginResponse } from '@/api/authentication/AuthentApi';
import Button from '@/components/Button.vue';
import Notifications from '@/components/Notifications.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import RecoverPassword from '@/views/front/login/RecoverPassword.vue';
import { MDBInput } from 'mdb-vue-ui-kit';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    goToHome: Function,
  },
  components: {
    Button,
    MDBInput,
    RecoverPassword,
    Notifications,
  },
  data() {
    const authentApi = getGlobalInstance(AuthentApi);
    return {
      authentApi,
      code1: '',
      code2: '',
      code3: '',
      code4: '',
    };
  },
  methods: {
    focusNext(e: KeyboardEvent, code: string) {
      const values = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const authorizedCodeNext = [...values, 'ArrowRight'];
      const authorizedCodePrevious = ['Backspace', 'ArrowLeft'];
      if (![...authorizedCodeNext, ...authorizedCodePrevious].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }

      const el = e.target as HTMLElement;
      const next = el.nextElementSibling as HTMLElement;
      const previous = el.previousElementSibling as HTMLElement;
      const elInput = e.target as HTMLInputElement;
      if (values.includes(e.key)) {
        elInput.value = e.key;
        this[code] = e.key;
      }
      if (['Backspace', 'Delete'].includes(e.key)) {
        elInput.value = '';
        this[code] = '';
      }

      if (next && authorizedCodeNext.includes(e.key)) {
        next.focus();
        return;
      }
      if (previous && authorizedCodePrevious.includes(e.key)) {
        previous.focus();
      }
    },
    codeValidationAction() {
      const loginBean = {
        login: this.$parent.login,
        password: this.$parent.password,
      };
      const code = this.code1 + this.code2 + this.code3 + this.code4;
      this.authentApi.codeValidation(loginBean, code)
        .then((loginResponse: LoginResponse) => {
          if (loginResponse.token !== '') {
            this.$parent.token = loginResponse.token;
            this.$parent.needCodeVerification = false;
            this.goToHome();
          } else {
            this.$refs.notifications
              .notifyError(this.$t('front.login.error.INCORRECT_VERIFICATION'));
          }
        });
    },
    sendAgainCodeVerification() {
      const loginBean = {
        login: this.$parent.login,
        password: this.$parent.password,
      };
      this.authentApi.sendAgainCodeValidation(loginBean)
        .then((response: boolean) => {
          if (response) {
            this.$refs.notifications.notifyCreation(
              this.$t('front.login.validationCode.mailSent'),
            );
          }
        });
    },
  },
})
export default class CodeValidationLogin extends Vue {}
</script>

<style lang="scss" scoped>

.login-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
}

.login-container {
  margin: 0 auto;
  position: relative;
  width: 505px;
  height: auto;
  min-height: 370px;
  background: white;
  display: flex;
  flex-direction: column;

  .subtitle-message {
    margin: 2rem 50px 0;
    display: flex;
    justify-content: center;
    color: black;
  }

  .complement-message {
    margin: 1rem 50px 0;
    display: flex;
    justify-content: center;
    color: black;
    font-style: italic;
    font-size: var(--font-size-hint);
  }

  .login-title {
    font-family: TTNormsBold, sans-serif;
    text-transform: uppercase;
    color: var(--grey-5);
    display: flex;
    justify-content: center;
    padding-top: 30px;
    position: relative;
    margin: 0 auto 30px;
    width: 80%;
  }

  .container-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 15px 0;
  }

  .champs-form {
    width: 20%;
    height: 70px;
    line-height: unset;
    text-align: center;
    font-size: 30px;
    padding: 0 0.75em;
    margin: 0 15px;
  }

  .form-connexion {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 248px;
    width: 420px;
    margin: auto;

    .container-input {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .form-outline {
        position: relative;
        width: 20%;
        height: 60px;

        .form-control.form-control-lg ~ .form-label {
          top: 50%;
          transform: translateY(-50%);
          color: rgba(88, 96, 100, 0.70);
          font-family: TTNormsLight, sans-serif;
          text-align: center;
          padding: 0 3px;
        }

        .form-control.form-control-lg.active,
        .form-control.form-control-lg:focus {
          & ~ .form-label {
            font-family: TTNormsRegular, sans-serif;
            color: $connexion-primary;
            font-size: var(--font-size-hint);
            text-align: center;
            padding-left: 3px;
            transform: translateY(-50%) translateY(-60%);
          }
        }

        .form-notch .form-notch-middle {
          display: none;
        }
      }
    }

    ::v-deep .champs-connexion {
      width: 100%;

      input {
        font-family: TTNormsRegular, sans-serif;
        height: var(--btn-height-big);
        padding: 20px 30px 0 15px
      }
      input::-webkit-credentials-auto-fill-button {
        background-color: white;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
      input::-webkit-caps-lock-indicator {
        content: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    width: 193px;
    margin: 15px auto 0;

    button {
      background-color: $connexion-primary;
      color: var(--white);
      width: 100%;
      margin: 0 10px;

      &.send-button {
        border: 1px solid $connexion-primary;
        color: $connexion-primary;
        background: none;
      }
    }
  }

  button > text {
    width: 100%;
  }
}

.champs-form {
  -moz-appearance: textfield;
}

.champs-form::-webkit-outer-spin-button,
.champs-form::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
