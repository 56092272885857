<template>
  <notifications ref="notifications" />
  <div class="wrapper">
    <div class="display-none">
      <h4 class="title">{{ $t('admin.fournisseur.informations.title') }}</h4>
      <p>{{ $t('admin.fournisseur.informations.desc') }}</p>
      <div class="block">
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.fournisseur.informations.packageLabel') }}</span>
            </td>
            <td>
              <select name="package" id="package" v-model="selected">
                <option
                  v-for="(packageItem, index) in packages"
                  :key="index"
                  v-bind:value="packageItem.id">
                  {{ packageItem.name }}
                </option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="block">
        <p>{{ $t('admin.fournisseur.details.whiteLabelProductInfo') }}</p>
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.fournisseur.color.primary') }}</span>
            </td>
            <td>
              <ColorPreviewInput :color="fournisseur.primaryColor"/>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.fournisseur.color.secondary') }}</span>
            </td>
            <td>
              <ColorPreviewInput :color="fournisseur.secondaryColor"/>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.fournisseur.color.image') }}</span>
            </td>
            <td class="image-upload-container">
              <input
                type="file"
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="overflow-hidden"
                @change="onChange"
                ref="file"
                accept=".jpg,.jpeg,.png,.svg,.JPG,.JPEG,.PNG,.SVG"
              />
              <label
                v-if="fileList.length === 0"
                for="assetsFieldHandle"
                class="image-container cursor-pointer"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <plus class="big-icon"/>
              </label>
              <div class="preview" v-if="fileList.length > 0">
                <img v-if="urlImage" :src="urlImage" alt="preview-uploaded-logo"/>
                <button @click="remove">
                  <close/>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h4 class="title">{{ $t('admin.fournisseur.referent.title') }}</h4>
    <div class="block">
      <table>
        <tbody>
        <tr>
          <td class="text-td">
            <span class="label">{{ $t('admin.fournisseur.referent.lastName') }}</span>
          </td>
          <td><input disabled v-model="referent.lastName"/></td>
        </tr>
        <tr>
          <td class="text-td">
            <span class="label">{{ $t('admin.fournisseur.referent.firstName') }}</span>
          </td>
          <td><input disabled v-model="referent.firstName"/></td>
        </tr>
        <tr>
          <td class="text-td">
            <span class="label">{{ $t('admin.fournisseur.referent.phone') }}</span>
          </td>
          <td><input disabled v-model="referent.phoneNumber"/></td>
        </tr>
        <tr>
          <td class="text-td">
            <span class="label">{{ $t('admin.fournisseur.referent.mail') }}</span>
          </td>
          <td><input disabled v-model="referent.email"/></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="display-none">
      <div class="buttons-wrapper block">
        <Button
          :onClick="() => $refs.notifications.notifyInfo()"
          :label="$t('common.button.save')"
          color="primary"
        >
          <content-save class="icon"/>
        </Button>
        <Button :onClick="todoClick" :label="$t('common.button.cancel')" color="secondary">
          <backspace class="icon"/>
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import ColorPreviewInput from '@/components/ColorPreviewInput.vue';
import Button from '@/components/Button.vue';
import {
  Backspace, Close, ContentSave, Plus,
} from 'mdue';
import Drawer from '@/components/Drawer.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import FournisseursApi, {
  FournisseurUser, FournisseurPackage, Fournisseur, Pack,
} from '@/api/fournisseurs/FournisseursApi';
import { User } from '@/api/users/UsersApi';
import Notifications from '@/components/Notifications.vue';

@Options({
  components: {
    Button,
    Backspace,
    ContentSave,
    ColorPreviewInput,
    Drawer,
    Plus,
    Close,
    Notifications,
  },
  props: {
    selected: String,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);
    return {
      fournisseursApi,
      referent: {},
      packages: [],
      selected: '',
      fileList: [],
      urlImage: '',
      fournisseur: {},
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    fetchPackages() {
      return this.fournisseursApi
        .fetchPackages()
        .then((response: Pack[]) => {
          this.packages = response;
        });
    },
    fetchReferent(id: string) {
      return this.fournisseursApi
        .fetchReferent(id)
        .then((response: User) => {
          this.referent = response ?? {};
        });
    },
    fetchFournisseur(id: string) {
      return this.fournisseursApi
        .fetchFournisseur(id)
        .then((response: Fournisseur) => {
          this.fournisseur = response;
          this.selected = response.idPackage;
        });
    },
    onChange() {
      this.fileList = (this.$refs.file.files ?? []) as File[];
      this.urlImage = URL.createObjectURL(this.fileList[0]);
    },
    remove() {
      this.fileList = [];
    },
    dragover(event: DragEvent) {
      event.preventDefault();
      const target = event.currentTarget as HTMLInputElement;
      if (!target.classList.contains('hover')) {
        target.classList.add('hover');
      }
    },
    dragleave(event: DragEvent) {
      const target = event.currentTarget as HTMLInputElement;
      target.classList.remove('hover');
    },
    drop(event: DragEvent) {
      event.preventDefault();
      const target = event.currentTarget as HTMLInputElement;
      this.$refs.file.files = event.dataTransfer?.files ?? [];
      this.onChange();
      target.classList.remove('hover');
    },
    notify(type: string, message: string) {
      this.$toasts[type](message);
    },
  },
  mounted() {
    this.fetchFournisseur(this.$route.params.id);
    this.fetchPackages();
    this.fetchReferent(this.$route.params.id);
  },
})
export default class DetailsFournisseur extends Vue {
  selected!: string;

  referent?: FournisseurUser;

  packages: FournisseurPackage[] = [];

  fournisseur?: Fournisseur;
}
</script>

<style lang="scss" scoped>
$alignement: 250px;
$image-upload-color: #929292;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-smaller);

  .display-none {
    display: none;
  }

  .block {
    margin-bottom: var(--spacing-bigger);
    width: 90%;

    .image-container {
      height: 150px;
      width: 150px;
      border-radius: 8px;
      background: $image-upload-color;
      display: flex;
      justify-content: center;
      align-items: center;

      .big-icon {
        height: 50px;
        width: 50px;
        color: white;
      }

      &.hover {
        background: darken($image-upload-color, 10%);
      }
    }

    .preview {
      display: flex;
      flex-direction: row;
      height: 150px;
      width: 150px;
      align-items: flex-end;

      button {
        margin-left: 5px;
        height: 28px;
        width: 28px;
        background: $image-upload-color;
        border-radius: 50%;
        border: 1px solid $image-upload-color;
        color: white;
      }

      img {
        max-height: 150px;
      }
    }
  }

  .title {
    text-transform: uppercase;
    margin: var(--spacing-small) 0 var(--spacing-bigger) 0;
    font-family: 'SourceSansProLight', sans-serif;
  }

  select {
    height: 40px;
    width: 250px;
  }

  table {
    table-layout: auto;
    width: 700px;
    border-collapse: separate;
    border-spacing: 0 var(--spacing-default);

    .text-td {
      text-align: right;
      padding-right: var(--spacing-default);
      //width: $alignement;
    }

    .image-upload-container {
      display: flex;
      flex-direction: row;
    }

    input {
      height: 40px;
      width: 400px;
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

input {
  &.overflow-hidden {
    display: none;
    overflow: hidden;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
