import ApiHttpClient from '@/api/ApiHttpClient';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';

type Profile = {
  id: string;
  name: string;
  entityType: string;
}

export type Permission = {
  id: string;
  name: string;
  shortName: string;
  parentPermissionId: string | null;
}

export type ProfilePermission = {
  idProfile: string;
  idPermission: string;
}

export type ProfileWithPermissions = {
  profile: Profile;
  permissions: ProfilePermission[];
}

export type NotePermissionByProfile = {
  idProfile: string;
  oneScore?: string;
  enjeux?: string;
  enjeuxSpecifiques?: string;
}

// not declared in the upper scope, weird
// eslint-disable-next-line no-shadow
export enum PackageNote {
  'OneScore',
  'Enjeu',
  'Enjeux specifiques',
}

export default class PermissionsApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: PermissionsApi | undefined;

  public static getInstance(): PermissionsApi {
    if (PermissionsApi.INSTANCE === undefined) {
      PermissionsApi.INSTANCE = new PermissionsApi();
    }

    return PermissionsApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetch() {
    return this
      .httpClient
      .restRequest<Permission[]>(HttpMethod.GET, '/admin/permissions')
      .execute();
  }

  updateProfilePermissions(
    idProfile: string,
    profileWithPermissions: ProfileWithPermissions,
    notePermission: NotePermissionByProfile,
  ) {
    const jsonBody = { profileWithPermissions, notePermission };
    return this
      .httpClient
      .restRequest<NotePermissionByProfile[]>(HttpMethod.PUT, `/admin/permissions/${idProfile}`)
      .jsonBody(jsonBody)
      .execute();
  }
}
