<template>
  <div class="header">
    <div class="logo-onescore"
         :onClick="goToHomePage"
    >
      <img alt="onescore logo" src="@/assets/logo_one_score_marque.png"/>
    </div>
    <div class="menu-onescore">
      <div class="tab">
        <router-link
          v-if="isAllowedProduct"
            :class="productClass()"
            :to='productName.link'
            :title='productName.name'
            v-t="'front.menuFront.produits'"
          />
      </div>
      <div class="tab">
        <router-link
          v-if="isAllowedBalance"
            :class="balanceClass()"
            :to='balanceName.link'
            :title='balanceName.name'
            v-t="'front.menuFront.balance'"
          />
      </div>
    </div>
    <div class="espace-client">
      <EspaceClient @logout="logout" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import EspaceClient from '@/views/EspaceClient.vue';
import { ACCUEIL, BALANCE_SOCIETALE_ACCUEIL_ROUTE, PRODUCT_ROUTE } from '@/router/routes';
import { inject } from 'vue';
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';

  @Options({
    components: {
      EspaceClient,
    },
    props: {
      selectedTab: String,
    },
    computed: {
      isAllowedProduct() {
        this.permissions = parseJwt(this.authorization);
        return this.permissions.includes(PERMISSIONS.PAGE_RECHERCHE);
      },
      isAllowedBalance() {
        return this.permissions.includes(PERMISSIONS.PAGE_COMPARATEUR);
      },
    },
    methods: {
      logout() {
        this.$emit('logout');
      },
      goToHomePage() {
        this.$router.push({ name: ACCUEIL });
      },
      balanceClass() {
        return this.selectedTab === 'balance'
         ? 'balance active true'
         : 'balance';
      },
      productClass() {
        return this.selectedTab === 'product'
          ? 'product active true'
          : 'product';
      },
    },
    emits: ['logout'],
    data() {
      const authorization = inject('authorization');
      return {
        authorization,
        permissions: [],
        balanceName: {
          name: this.$t('front.menuFront.balance'),
          link: { name: BALANCE_SOCIETALE_ACCUEIL_ROUTE },
        },
        productName: {
          name: this.$t('front.menuFront.produits'),
          link: { name: PRODUCT_ROUTE },
        },
      };
    },
  })
export default class MenuOneScore extends Vue {
}
</script>

<style lang="scss">
$balance-primary: #005297;
  .header {
    height: var(--header-front-width);
    width: 100%;
    background: $white;
    display: flex;
    justify-content: center;
    position: relative;

    .logo-onescore {
      cursor: pointer;
      position: absolute;
      left: var(--spacing-left-header-logo);
      top: var(--spacing-top-header-logo);
      img {
        width: 342px;
      }
    }
    .menu-onescore {
      display: flex;
      position: relative;
      justify-content: space-between;
      height: min-content;
      top: var(--spacing-top-menu-header);
      .tab {
        font-size: var(--font-size-medium);
        font-family: TTNormsBold;
        padding: 0 2rem;
        a {
          color: var(--grey-5);
          padding: 0 0.25rem;
          color: var(--grey-5);
          border-bottom: 0;
        }

        a.router-link-active.true.product {
          color: var(--grey-5);
          border-bottom: 6px solid var(--grey-5);
        }
        a.router-link-active.true.balance {
          color: $balance-primary;
          border-bottom: 6px solid $balance-primary;
        }
      }
    }

    .espace-client {
      position: absolute;
      top: var(--spacing-top-espace-client);
      right: var(--spacing-right-espace-client);
      button {
        background: white;
        font-family: TTNormsRegular;
        font-size: 14px;
        font-weight: normal;
        color:var(--grey-5);
      }
      :focus {
        background: white;
      }
      :hover {
        background: white;
      }
      a {
        font-family: "TTNormsRegular";
        font-size: 14px;
        font-weight: normal;
        color: var(--grey-5);
      }
    }
  }
</style>
