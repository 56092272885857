import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-page" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "foreground-color" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResearchBar = _resolveComponent("ResearchBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      alt: "Concern logo",
      src: require(`@/assets/${_ctx.image}`)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.subtitle1), 1),
        _createElementVNode("h4", null, _toDisplayString(_ctx.subtitle2), 1)
      ]),
      (_ctx.showSearchBar)
        ? (_openBlock(), _createBlock(_component_ResearchBar, {
            key: 0,
            onHandleSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearch($event))),
            buttonColor: _ctx.colorButtonResearch,
            initialValue: _ctx.inputValueFromHome
          }, null, 8, ["buttonColor", "initialValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}