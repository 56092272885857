<template>
  <div class="show-more">
    <Button
      :label="label"
      color="secondary"
      :onClick="showMore"
      v-bind:class="[!isAdmin() ? 'button front' : 'button']"
      :loading="loading"
    >
      <eye class="icon" v-if="isAdmin()"/>
    </Button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Eye } from 'mdue';
import { RouteLocationNormalized } from 'vue-router';

@Options({
  components: {
    Button,
    Eye,
  },
  props: {
    label: String,
    nbrTileDefault: Number,
    totalItem: Number,
    tileToDisplay: Number,
    loading: Boolean,
  },
  methods: {
    isAdmin() {
      return this.$route.matched.filter((route: RouteLocationNormalized) => route.name === 'Admin').length > 0;
    },
    showMore() {
      this.$parent.tileToDisplay = Math.min(
        this.$parent.tileToDisplay + this.nbrTileDefault, this.totalItem ?? 0,
      );
      if (typeof this.$parent.showMore === 'function') {
        this.$parent.showMore();
      } else {
        if (typeof this.$parent.fetchProducts === 'function') {
          this.$parent.fetchProducts();
        }
        this.$parent.handleSearch('');
      }
    },
  },
})
export default class Pagination extends Vue {}
</script>

<style lang="scss" scoped>
.show-more {
  display: flex;
  justify-content: center;
  .button {
    &.front {
      border: 1px solid $primary;
      text-transform: unset;
    }
  }
  .icon {
    margin-right: 0.5rem;
  }

}
</style>
