<template>
  <div class="detail" v-if="showScore">
    <DetailScore
        :product=product
        :minColor="styles.bcgColorMin"
        :moyColor="styles.bcgColorMoy"
        :maxColor="styles.bcgColorMax"
    />
  </div>
  <div v-if="isProduct"
       class="product-score"
       :style="getProductScoreStyle()"
  >
    <img class="img" alt="Product score image" :src="logoProduct">
  </div>
  <div v-else-if="product.noteMin === product.noteMax"
       class="bar"
       :style="getBarStyle()"
       type="button"
       @mouseover="showDetail"
       @mouseleave="hideDetail"
  />
  <div v-else>
    <img
         alt="reglette"
         src="@/assets/reglette_balance.png"
         :style="getBarStyle()"
         @mouseover="showDetail" @mouseleave="hideDetail"
    />
    <div class="moy"
         @mouseover="showDetail"
         :style="getAvgIndicationStyle()"
    />
  </div>
</template>

<script lang="ts">
import BalanceApi from '@/api/balance/BalanceApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import { Triangle } from 'mdue';
import DetailScore from '@/views/front/balance-societale/DetailScore.vue';

@Options({
  components: {
    Triangle,
    DetailScore,
  },
  props: {
    product: Object,
    isProduct: Boolean,
    logoProduct: Object,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);
    return {
      balanceApi,
      showScore: false,
      // 55,6px = largeur d'une graduation (Une graduation sur 10 donc on divise par 10)
      widthGraduation: 5.56,
      // 16,7px correspond a la largeur avant le 0
      marginBeforeZero: 16.7,
    };
  },

  computed: {
    styles() {
      return {
        // On prend la couleur qui correspond a la dizaine la plus proche
        bcgColorMax: `${this.$parent.GRAPH_COLORS[10 * Math.round(this.product.noteMax / 10)]}`,
        bcgColorMin: `${this.$parent.GRAPH_COLORS[10 * Math.round(this.product.noteMin / 10)]}`,
        bcgColorMoy: `${this.$parent.GRAPH_COLORS[10 * Math.round(this.product.noteAvg / 10)]}`,
      };
    },
  },
  methods: {
    showDetail() {
      this.showScore = true;
    },
    hideDetail() {
      this.showScore = false;
    },
    getProductScoreStyle() {
      // On place le coté gauche du cercle à la bonne note puis on le centre
      return { marginLeft: `${this.product.noteAvg * this.widthGraduation + this.marginBeforeZero - 40}px` };
    },
    getBarStyleMin() {
      return {
        width: '17px',
        background: this.styles.bcgColorMin,
        // On place le cercle au centre ( diametre / 2)
        marginLeft: `${this.product.noteMin * this.widthGraduation + this.marginBeforeZero - 13 / 2}px`,
        height: '9.5pt',
        top: '6px',
        zIndex: '-1',
      };
    },
    getBarStyleMax() {
      return {
        width: '17px',
        background: this.styles.bcgColorMax,
        // On place le cercle au centre ( diametre / 2)
        marginLeft: `${this.product.noteMax * this.widthGraduation + this.marginBeforeZero - 13 / 2}px`,
        top: '-7px',
        height: '9.5pt',
        zIndex: '-1',
      };
    },
    getBarStyle() {
      if (this.product.noteMin !== this.product.noteMax) {
        return {
          clip: `rect(0px, ${this.product.noteMax * this.widthGraduation + this.marginBeforeZero}px,
           13px, ${this.product.noteMin * this.widthGraduation + this.marginBeforeZero}px)`,
          position: 'absolute',
          top: '-6px',
          left: 0,
        };
      }
      return {
        width: '13px',
        background: this.styles.bcgColorMoy,
        // On place le cercle au centre ( diametre / 2)
        marginLeft: `${this.product.noteMin * this.widthGraduation + this.marginBeforeZero - 13 / 2}px`,
      };
    },
    getAvgIndicationStyle() {
      return { marginLeft: `${this.product.noteAvg * this.widthGraduation + this.marginBeforeZero}px` };
    },
  },
})
export default class BalanceScoreBar extends Vue {
}
</script>

<style lang="scss" scoped>
.bar {
  box-shadow: 0 3px 6px #00000029;
  border-radius: var(--border-radius-small);
  height: var(--spacing-medium);
  .detail {
    display: flex;
  }
}

.moy {
  width: 1px;
  border-right: 2px dotted var(--white);
  height: 13px;
  position: absolute;
  bottom: -6px;
}

.product-score {
  width: 85px;
  font-size: var(--font-size-medium);
}

</style>
