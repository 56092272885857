<template>
  <div class="admin">
    <div class="app">
      <div class="app-container">
        <div class="app-menu">
          <MenuAdministration />
        </div>
        <div class="page-container">
          <div class="general-header">
            <div class="app-client">
              <EspaceClient @logout="logout" />
            </div>
          </div>
          <div class="router-container">
            <router-view @authenticated="setAuthenticated" @logout="logout"/>
          </div>
        </div>
      </div>
      <FooterTemplate is-admin="true"/>
    </div>
  </div>
</template>

<script lang="ts">
import MenuAdministration from '@/views/admin/MenuAdministration.vue';
import EspaceClient from '@/views/EspaceClient.vue';
import FooterTemplate from '@/views/front/FooterTemplate.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    FooterTemplate,
    MenuAdministration,
    EspaceClient,
  },
  emits: ['authenticated', 'logout'],
  methods: {
    setAuthenticated(token: string) {
      this.$emit('authenticated', token);
    },
    logout() {
      this.$emit('logout');
    },
  },
})
export default class Admin extends Vue {
}
</script>

<style lang="scss">
.admin {
  .app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .app-container {
      display: flex;
      flex-direction: row;
      min-height: 100vh;
    }

    .app-menu {
      width: var(--menu-width);
      background: $dark;
      background-color: $dark;
    }

    .app-client {
      position: absolute;
      top: 27px;
      right: var(--spacing-default);
    }
  }

  .page-container {
    width: 100%;
    background: var(--background-admin);

    .general-header {
      height: var(--header-width);
      width: 100%;
      background: $dark;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: white;

      .close {
        font-size: var(--font-size-small);
        position: absolute;
        left: var(--spacing-bigger);
        color: white;
        opacity: 1;
        cursor: pointer;

        .text {
          margin-left: var(--spacing-small);
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }

    .router-container {
      margin-left: var(--spacing-bigger);
      margin-top: var(--spacing-small);
    }
  }
}

@font-face {
  font-family: "SourceSansProLight";
  src: local("SourceSansProLight"),
  url(../../styles/fonts/SourceSansPro-Light.otf) format("opentype");
}

@font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansProRegular"),
  url(../../styles/fonts/SourceSansPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansProSemiBold"),
  url(../../styles/fonts/SourceSansPro-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"),
  url(../../styles/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "LatoLight";
  src: local("LatoLight"),
  url(../../styles/fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoCondensedRegular";
  src: local("RobotoCondensedRegular"),
  url(../../styles/fonts/RobotoCondensed-Regular.ttf) format("truetype");
}

</style>
