import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-632decda"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_MDBIcon = _resolveComponent("MDBIcon")!
  const _component_ButtonFilterType = _resolveComponent("ButtonFilterType")!
  const _component_PopinFiltresBalanceSearch = _resolveComponent("PopinFiltresBalanceSearch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", {
      class: _normalizeClass(['filtre-container', _ctx.getHasFilters() ? '' : 'none'])
    }, [
      (_ctx.getHasFilters())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.filters')), 1),
            _createVNode(_component_MDBIcon, {
              class: "icon",
              icon: "trash-alt",
              title: _ctx.$t('front.balanceSocietale.filtre.reset'),
              onClick: _ctx.clearFilters
            }, null, 8, ["title", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentFiltersSelected, (field) => {
        return (_openBlock(), _createElementBlock("div", { key: field }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.isInFiltersList(field.type) ? '' : 'hide')
          }, [
            _createVNode(_component_ButtonFilterType, {
              disabled: _ctx.disable,
              title: field.label,
              isArrow: !_ctx.isGamme(field.type),
              filterNumber: field.filters ? field.filters.length : 0,
              addItem: () => _ctx.addItem(field),
              clearItem: () => _ctx.clearItem(field),
              onClick: ($event: any) => (_ctx.handleSelection(field, $event))
            }, null, 8, ["disabled", "title", "isArrow", "filterNumber", "addItem", "clearItem", "onClick"])
          ], 2)
        ]))
      }), 128)),
      (_ctx.showPopinFiltres)
        ? (_openBlock(), _createBlock(_component_PopinFiltresBalanceSearch, {
            key: 1,
            filterIndex: _ctx.filterIndex
          }, null, 8, ["filterIndex"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}