<template>
  <router-view @authenticated="setAuthenticated" @logout="logout"/>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { ref, provide } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { _RouteLocationBase, RouteLocationNormalized } from 'vue-router';
import { parseJwt, permisionsRoutes, PermissionRouteType } from '@/api/authentication/AuthentApi';
import ApiHttpClient from './api/ApiHttpClient';
import {
  ACCUEIL, ADMIN,
  LOGIN, NO_ACCESS,
  RECOVER_PASSWORD,
} from './router/routes';
import { ACCESS_TOKEN } from './SessionConstantes';

@Options({
  data() {
    const savedToken: string = this.$sessionStorage.get(ACCESS_TOKEN, null);
    const authorization = ref(savedToken);
    const httpClient = getGlobalInstance(ApiHttpClient);
    httpClient.setAuthorization(savedToken);

    provide('authorization', authorization);
    return {
      authorization,
      httpClient,
    };
  },
  methods: {
    setAuthenticated(token: string) {
      this.authorization = token;
      this.$sessionStorage.set(ACCESS_TOKEN, token);
      this.httpClient.setAuthorization(token);
    },
    logout() {
      this.setAuthenticated(null);
      this.$router.push({ name: LOGIN });
    },
  },
  mounted() {
    this.httpClient.setLogoutFunction(() => {
      this.logout();
    });

    this.$router.beforeEach((to: RouteLocationNormalized, _: _RouteLocationBase, next: (options?: { name: string }) => void) => {
      let permissions: string[] = [];
      // On regarde si on est dans une route de l'admin
      const isAdmin = to.matched[0].name === ADMIN ? 0 : 1;
      if (this.authorization) {
        permissions = parseJwt(this.authorization);
      }
      if (to.name === NO_ACCESS) {
        next();
      }
      if ((to.name === LOGIN || to.name === RECOVER_PASSWORD)
        && !!this.authorization) {
        next({ name: ACCUEIL });
      } else if (to.name === LOGIN || to.name === RECOVER_PASSWORD) {
        next();
      } else if (to.name !== LOGIN && to.name !== RECOVER_PASSWORD && !this.authorization) {
        next({ name: LOGIN });
      } else if (permissions?.includes(
          permisionsRoutes.filter((p: PermissionRouteType) => p.route === to.matched[isAdmin].name)
          ? (permisionsRoutes.filter((p) => p.route === to.matched[isAdmin].name)[0]?.permission ?? '')
          : '',
        )) { // On verifie que l'utilisateur a bien les permissions correspondantes aux routes
        next();
      } else {
        next({ name: NO_ACCESS });
      }
    });
  },
})
export default class App extends Vue {
}

</script>

<style lang="scss">
@font-face {
  font-family: "Invivotype";
  src: local("Invivotype"),
  url(styles/fonts/Invivotype-Regular.otf) format("opentype");
}

@font-face {
  font-family: "TTNormsMedium";
  src: local("TTNormsMedium"),
  url(styles/fonts/TTNorms-Medium.otf) format("opentype");
}

@font-face {
  font-family: "TTNormsLight";
  src: local("TTNormsLight"),
  url(styles/fonts/TTNorms-Light.otf) format("opentype");
}

@font-face {
  font-family: "TTNormsRegular";
  src: local("TTNormsRegular"),
  url(styles/fonts/TTNorms-Regular.otf) format("opentype");
}

@font-face {
  font-family: "TTNormsBold";
  src: local("TTNormsBold"),
  url(styles/fonts/TTNorms-Bold.otf) format("opentype");
}

@font-face {
  font-family: "FjallaOneRegular";
  src: local("FjallaOneRegular"),
  url(styles/fonts/FjallaOne-Regular.ttf) format("truetype");
}
</style>
