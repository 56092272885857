<template>
  <notifications ref="notifications"/>
  <div class="product-management">
    <h3 class="page-title">{{ $t('front.product.productManagement.title') }}</h3>
    <ProductAdvancedSearch
      v-bind:market="market"
      v-bind:segment="segment"
      v-bind:composition="composition"
      v-bind:culture="culture"
      v-bind:segmentSpecific="segmentSpecific"
      v-bind:supplier="supplier"
      v-bind:disabled="loaderExportAllScore"
      @getCriterion="getCriterion($event)"
    />
    <div class="actions">
      <span class="search-results" v-if="!dataLoaded">
        {{ $t('common.loader') }}
      </span>
      <span class="search-results" v-else>
        {{
          $t('front.product.productManagement.results', { count: nbrResultProducts })
        }}
      </span>
      <div class="actions-button">
        <Button
          :label="$t('front.product.productManagement.extract')"
          :onClick="exportAllScore"
          :loading="loaderExportAllScore"
        >
          <cloud-download class="icon"/>
        </Button>
        <Button
          v-if="isActionMasked"
          :label="$t('front.product.productManagement.hide', 0)"
          color="primary"
          :disabled="loaderExportAllScore"
          @click="handleShowPopin"
        >
          <eye class="icon"/>
        </Button>
        <Button
          v-else
          :label="$t('front.product.productManagement.visible')"
          color="primary"
          @click="handleShowPopin"
        >
          <eye class="icon"/>
        </Button>
        <!-- Popin confirmation products masked -->
        <Popin
          v-if="showPopin === true"
          :title="$t('front.product.popin.title')"
          :hasCancel="true"
        >
          <template v-slot:container>
            <p class="text" v-if="isActionMasked">
              {{ $t('front.product.popin.text', { count: checkedList.length }) }}
            </p>
            <p class="text" v-else>
              {{ $t('front.product.popin.textVisible', { count: checkedList.length }) }}
            </p>
          </template>
          <template v-slot:button v-if="checkedList.length > 0">
            <Button
              v-if="isActionMasked"
              :label="$t('front.product.productManagement.hide', checkedList.length)"
              color="primary"
              :onClick="confirmationMasked"
            >
              <eye class="icon"/>
            </Button>
            <Button
              v-else
              :label="$t('front.product.productManagement.visible')"
              color="primary"
              :onClick="confirmationVisible"
            >
              <eye class="icon"/>
            </Button>
          </template>
        </Popin>
      </div>
    </div>
    <hr/>
    <div class="product-tiles-container">
      <div class="filter-container">
        <ProductSort ref="ProductSort" :sortUsed="sortUsed" @set-sort="(newSort: string) => (this.sortUsed = newSort)"/>
        <div class="select-container" v-if="nbrResultProducts > 0">
          <label for="checkboxAll">
            {{
              checkedAll
                ? $t('front.product.productManagement.uncheckedAll')
                : $t('front.product.productManagement.checkedAll', { count: checkedList.length })
            }}
          </label>
          <button class="checkbox" id="checkboxAll" @click="checkAll">
            <checkbox-marked v-if="checkedAll" class="icon"/>
            <checkbox-blank-outline v-if="!checkedAll" class="icon"/>
          </button>
        </div>
      </div>
      <div v-for="(product, index) in products" :key="product.id">
        <ProductTile
          :product="product"
          :canCompare="false"
          :canRemoveFavorite="false"
          :canDownloadLogo="false"
          :canCheck="(checkedList.length === 0)
            || (isActionMasked && product.product.isVisible)
            || (!isActionMasked && !product.product.isVisible)"
          :checked="checkedList.includes(product.product.id)"
          :showCheckbox="true"
          :index="index"
        />
      </div>
      <div class="spinner-border" role="status" v-if="!dataLoaded"/>
    </div>
    <Pagination
      v-if="tileToDisplay !== nbrResultProducts && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="nbrResultProducts"
    />
  </div>
  <ProductDetails
    v-if="displayDetails !== 0"
    v-bind:idProduct="displayDetails"
  />
</template>

<script lang="ts">
import ProductsAdminApi from '@/api/products/ProductsAdminApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import ProductAdvancedSearch from '@/components/ProductAdvancedSearch.vue';
import Button from '@/components/Button.vue';
import {
  CheckboxBlankOutline, CheckboxMarked, CloudDownload, Eye,
} from 'mdue';
import ProductTile from '@/components/ProductTile.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import Popin from '@/components/Popin.vue';
import Pagination from '@/components/Pagination.vue';
import Notifications from '@/components/Notifications.vue';
import ProductSort from '@/components/ProductSort.vue';
import ProductDetails from '@/views/admin/product/ProductDetails.vue';
import {
  Market, MarketSegment, ProductResultList, ScoredProduct,
} from '@/api/products/ProductsApi';
import { inject } from 'vue';
import { PRODUCT_SORT } from '@/api/fournisseurs/FournisseursApi';
import { download, getFilenameFromResponse } from '@/util';

@Options({
  components: {
    ProductAdvancedSearch,
    Button,
    CloudDownload,
    Eye,
    ProductTile,
    Popin,
    CheckboxMarked,
    CheckboxBlankOutline,
    Pagination,
    Notifications,
    ProductDetails,
    ProductSort,
  },
  data() {
    const productsAdminApi = getGlobalInstance(ProductsAdminApi);
    const authorization = inject('authorization');
    return {
      productsAdminApi,
      market: [],
      segment: [],
      composition: [],
      culture: [],
      segmentSpecific: [],
      supplier: [],
      criterionText: '',
      products: [],
      nbrResultProducts: 0,
      sortUsed: PRODUCT_SORT.TrierPar,
      showPopin: false,
      checkedAll: false,
      checkedList: [],
      isActionMasked: true,
      maskedProducts: [],
      nbrTileDefault: 15,
      tileToDisplay: 15,
      dataLoaded: false,
      logoLoaded: false,
      displayDetails: 0,
      loaderExportAllScore: false,
      criterion: {
        market: '',
        segment: '',
        composition: '',
        culture: '',
        segmentSpecific: '',
        supplier: '',
        limit: 0,
        offset: 0,
      },
      authorization,
    };
  },
  methods: {
    closePopin() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.showPopin = false;
    },
    // TODO remove mocked data
    fetchMarket() {
      return this.productsAdminApi
        .fetchMarketList()
        .then((response: Market[]) => {
          this.market = [{
            name: '',
            label: this.$t('common.all'),
          }].concat(response);
        });
    },
    fetchSegment() {
      return this.productsAdminApi
        .fetchMarketSegmentEnum()
        .then((response: MarketSegment[]) => {
          this.segment = [{ label: this.$t('common.all') }].concat(response); // Option vide pour réinitialiser le choix
        });
    },
    fetchComposition() {
      this.composition = [this.$t('common.all')];
      this.productsAdminApi
        .fetchCompositionList()
        .then((response: string[]) => {
          this.composition = [this.$t('common.all')].concat(response);
        });
    },
    fetchCulture() {
      this.culture = [this.$t('common.all')];
      this.productsAdminApi
        .fetchCultureList()
        .then((response: string[]) => {
          this.culture = [this.$t('common.all')].concat(response);
        });
    },
    fetchSegmentSpecific() {
      return this.productsAdminApi
        .fetchSegmentSpecificList()
        .then((response: string[]) => {
          this.segmentSpecific = [this.$t('common.all')].concat(response);
        });
    },
    fetchSupplier() {
      return this.productsAdminApi
        .fetchSupplierList()
        .then((response: string[]) => {
          this.supplier = [this.$t('common.all')].concat(response);
        });
    },
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    getCriterion(criterion: string) {
      this.criterionText = criterion;
    },
    fetchProducts() {
      this.dataLoaded = false;
      return this.productsAdminApi
        .fetchAdmin({
          ...this.criterion,
          limit: this.nbrTileDefault,
          offset: this.products.length,
          productSort: this.sortUsed,
        })
        .then((response: ProductResultList) => {
          this.dataLoaded = true;
          this.nbrResultProducts = response.nbrProducts;
          this.tileToDisplay = Math.min(this.tileToDisplay, response.nbrProducts);
          this.products = [...this.products, ...response.productsList];
          this.criterion.pagination = Math.min(this.tileToDisplay, response.nbrProducts);
          this.checkedList = [];
          this.maskedProducts = [];
          this.maskedProducts = response.productsList
            .filter((item) => !item.product.isVisible)
            .map((i) => i.product.id);
          this.$refs.ProductSort.handleReset();
        });
    },
    checkAll() {
      this.checkedAll = !this.checkedAll;
      this.checkedList = [];
      if (this.checkedAll) { // Check all
        this.products.forEach((item: ScoredProduct) => {
          if (item.product.isValid) {
            this.checkedList.push(item.product.id);
          }
        });
      }
    },
    confirmationMasked() {
      const array: string[] = [...this.checkedList].concat(this.maskedProducts);
      this.productsAdminApi.updateProductsAdmin(array)
        .then(this.$refs.notifications.notifySuccess())
        .then(() => {
          this.products = [];
          this.fetchProducts();
          this.checkedAll = false;
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSave')));
      this.closePopin();
    },
    confirmationVisible() {
      const array = [...this.maskedProducts];
      this.checkedList.forEach((checked: string) => {
        const index = array.indexOf(checked);
        array.splice(index, 1);
      });
      this.productsAdminApi.updateProductsAdmin(array)
        .then(this.$refs.notifications.notifySuccess())
        .then(() => {
          this.products = [];
          this.fetchProducts();
          this.checkedAll = false;
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSave')));
      this.closePopin();
    },
    handleShowPopin() {
      if (this.checkedList.length > 0) {
        this.showPopin = true;
      } else {
        this.$refs.notifications.notifyWarning(this.$t('front.product.popin.warningText'));
      }
    },
    exportAllScore() {
      this.loaderExportAllScore = true;
      this.productsAdminApi
        .exportAllScoreAdmin({
          ...this.criterion,
          productSort: this.sortUsed,
        })
        .then((response: Response) => {
          const excelName = getFilenameFromResponse(response);
          response.blob()
            .then((blob: Blob) => {
              download(blob, excelName);
              this.loaderExportAllScore = false;
            });
        });
    },
  },
  mounted() {
    this.fetchMarket();
    this.fetchSegment();
    this.fetchComposition();
    this.fetchCulture();
    this.fetchSegmentSpecific();
    this.fetchSupplier();
    this.fetchProducts();
  },
  watch: {
    checkedList: {
      handler() {
        if (this.checkedList.length === this.products.length) {
          this.checkedAll = true;
        }
        // Check if all selected products are already masked
        if (this.checkedList.length > 0) {
          this.isActionMasked = true;
          this.checkedList.forEach((check: string) => {
            if (this.maskedProducts.includes(check)) {
              this.isActionMasked = false;
            }
          });
        }
      },
      deep: true,
    },
  },
})
export default class ProductManagement extends Vue {
}
</script>

<style lang="scss" scoped>
.product-management {
  width: 100%;
  padding: 0 var(--spacing-default);
  margin-bottom: 1rem;
  .page-title {
    text-align: left;
    padding: var(--spacing-default) 0;
  }

  .actions {
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .search-results {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      max-width: 30rem;
      font-family: 'LatoLight', sans-serif;
    }

    .actions-button {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      button {
        margin: var(--spacing-small);

        &:last-child {
          margin-right: 0;
        }

        .icon {
          padding-right: var(--spacing-small);
          font-size: var(--font-size-bigger);
        }
      }
    }
  }

  .popin-container {
    button {
      height: var(--btn-height-smaller);
      margin: var(--spacing-small);
    }
  }

  hr {
    width: 96%;
    color: black;
  }

  .product-tiles-container {
    width: 96%;

    .filter-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-big);
      align-items: flex-end;
    }

    .tile-container {
      margin: 25px 0;
    }
  }

  .select-container {
    text-align: end;

    .checkbox {
      margin: 0 var(--spacing-small);
      border: none;
      background: none;
      height: fit-content;
      padding: 0;
    }
  }
}

@media print {
  html, body, * {
    -webkit-print-color-adjust: exact;
  }
  .product-management {
    display: none !important;
  }

  @page {
    size: A3;
    margin: 0;
  }
}

</style>
