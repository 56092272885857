<template>
  <div class="container-tag">
    <p>{{ product }}</p>
    <MDBBtn class="icon-btn" :ripple="false" v-on:click="$emit('clearItem')">
      <close class="icon"/>
    </MDBBtn>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { MDBBtn } from 'mdb-vue-ui-kit';
import { Close } from 'mdue';

@Options({
  components: {
    MDBBtn,
    Close,
  },
  props: {
    product: '',
  },
  data() {
    return {};
  },
})

export default class TagFiltrePanier extends Vue {
}
</script>

<style lang="scss" scoped>
$light-grey: #5961650D;
.container-tag {
  border: 1px solid $light-grey;
  width: fit-content;
  margin: 5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  background-color: $light-grey;
  height: 40px;
  max-width: 250px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  .icon-btn {
    width: 10px;
    padding-left: 10px;
  }
}

</style>
