import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0057f8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = { class: "input-group-icon" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_magnify, { class: "icon" })
    ]),
    _withDirectives(_createElementVNode("input", {
      style: _normalizeStyle([_ctx.buttonColor ? {border: 0 } : {}]),
      type: "text",
      class: "form-control",
      placeholder: _ctx.getPlaceholder(),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.researchInput) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('handleSearch', _ctx.researchInput)), ["enter"]))
    }, null, 44, _hoisted_3), [
      [_vModelText, _ctx.researchInput]
    ]),
    _createElementVNode("button", {
      style: _normalizeStyle([_ctx.buttonColor ? {color: `#FFF`, background: _ctx.buttonColor, borderColor: _ctx.buttonColor,} : {}]),
      class: "btn btn-outline-primary",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleSearch', _ctx.researchInput)))
    }, _toDisplayString(_ctx.$t('common.button.search')), 5)
  ]))
}