export const HOME_ROUTE = 'Home';
export const PRODUCT_LIST_ADMIN_ROUTE = 'ProductManagement';
export const PRODUCT_DETAILS_ADMIN_ROUTE = 'ProductDetails';
export const FOURNISSEUR_LIST_ROUTE = 'FournisseurManagement.vue';
export const FOURNISSEUR_DETAIL_ROUTE = 'DetailsFournisseur.vue';
export const FOURNISSEUR_PRODUCT_ROUTE = 'FournisseurGamme.vue';
export const FOURNISSEUR_USERS_ROUTE = 'FournisseurUsersList.vue';
export const PERMISSIONS_ROUTE = 'Permissions';
export const UTILISATEUR_DETAIL_ROUTE = 'DetailsUtilisateur.vue';
export const UTILISATEUR_PRODUCT_ROUTE = 'UsersProducts';
export const LOGIN = 'Login';
export const RECOVER_PASSWORD = 'RecoverPassword';
export const USERS_LIST_ROUTE = 'UserManagement.vue';
export const BALANCE_SOCIETALE_ROUTE = 'BalanceSocietale';
export const BALANCE_SOCIETALE_ACCUEIL_ROUTE = 'AccueilBalanceSocietale';
export const BALANCE_SOCIETALE_MAIN_ROUTE = 'NewBalanceSocietale';
export const BALANCE_SOCIETALE_RECHERCHE_ROUTE = 'BalanceSocietaleRecherche';
export const BALANCE_SOCIETALE_RESULTAT_ROUTE = 'BalanceSocietaleComparateur';
export const PRODUCT_ROUTE = 'Products';
export const COOPERATIVE_LIST_ROUTE = 'CooperativesManagement';
export const COOPERATIVE_DETAIL_ROUTE = 'DetailsCooperative';
export const COOPERATIVE_PRODUCT_ROUTE = 'CooperativeGamme';
export const COOPERATIVE_USERS_ROUTE = 'CooperativeUsersList';
export const MISSING_DATA_ROUTE = 'MissingDataManagement';
export const UPDATE_CGU_ROUTE = 'UpdateCGU';
export const UPDATE_SCORING_ROUTE = 'UpdateScoring';
export const ACCUEIL = 'Accueil';
export const ADMIN = 'Admin';
export const NO_ACCESS = 'NoAccess';
