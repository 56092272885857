import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin" }
const _hoisted_2 = { class: "app" }
const _hoisted_3 = { class: "app-container" }
const _hoisted_4 = { class: "app-menu" }
const _hoisted_5 = { class: "page-container" }
const _hoisted_6 = { class: "general-header" }
const _hoisted_7 = { class: "app-client" }
const _hoisted_8 = { class: "router-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuAdministration = _resolveComponent("MenuAdministration")!
  const _component_EspaceClient = _resolveComponent("EspaceClient")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterTemplate = _resolveComponent("FooterTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MenuAdministration)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_EspaceClient, { onLogout: _ctx.logout }, null, 8, ["onLogout"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_view, {
              onAuthenticated: _ctx.setAuthenticated,
              onLogout: _ctx.logout
            }, null, 8, ["onAuthenticated", "onLogout"])
          ])
        ])
      ]),
      _createVNode(_component_FooterTemplate, { "is-admin": "true" })
    ])
  ]))
}