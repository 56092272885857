<template>
  <notifications ref="notifications"/>
  <div class="missing-data-management">
    <h3 class="page-title">{{ $t('admin.missingData.title') }}</h3>
  </div>
  <div class="missing-data-container">
    <label>{{ $t('admin.missingData.subtitle') }}</label>
    <Button
        type="submit"
        :label="$t('admin.missingData.download')"
        color="primary" class="download-button"
        :loading="exportMissingDataLoading"
        @click="exportMissingData"
    >
      <download v-if="!exportMissingDataLoading" class="icon" />
    </Button>
  </div>

</template>

<script lang="ts">
import MissingDataApi from '@/api/missingData/MissingDataApi';
import Button from '@/components/Button.vue';
import Notifications from '@/components/Notifications.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { download, getFilenameFromResponse } from '@/util';
import { Download } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Notifications,
    Button,
    Download,
  },
  data() {
    const missingDataApi = getGlobalInstance(MissingDataApi);
    return {
      missingDataApi,
      exportMissingDataLoading: false,
    };
  },
  methods: {
    // Téléchargement des données manquantes au format excel
    exportMissingData() {
      this.exportMissingDataLoading = true;
      this.missingDataApi.exportMissingData()
        .then((response: Response) => {
          response.blob().then((blob: Blob) => {
            const excelName = getFilenameFromResponse(response);
            download(blob, excelName);
          });
        })
        .finally(() => { this.exportMissingDataLoading = false; });
    },
  },
})
export default class MissingDataManagement extends Vue {
}
</script>

<style lang="scss" scoped>
.missing-data-management {
  width: 100%;
  padding: 0 var(--spacing-default);
  position: relative;

  .page-title {
    text-align: left;
    padding: var(--spacing-default) 0;
  }
}

.missing-data-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px var(--spacing-default);

  .download-button {
    margin-left: var(--spacing-bigger);
  }
}

.spinner-border {
  margin-right: 10px;
}
</style>
