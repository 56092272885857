import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "front" }
const _hoisted_2 = { class: "app" }
const _hoisted_3 = {
  key: 0,
  class: "front-header"
}
const _hoisted_4 = { class: "body-container" }
const _hoisted_5 = { class: "router-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOneScore = _resolveComponent("MenuOneScore")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!!_ctx.authorization)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MenuOneScore, {
              selectedTab: this.selectedTab,
              onLogout: _ctx.logout
            }, null, 8, ["selectedTab", "onLogout"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_view, {
            onMenuChanged: _ctx.onMenuChanged,
            onAuthenticated: _ctx.setAuthenticated
          }, null, 8, ["onMenuChanged", "onAuthenticated"])
        ])
      ])
    ])
  ]))
}