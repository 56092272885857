<template>
  <div class="users">
    <router-view/>
    <h3 class="page-title">{{ $t('admin.users.title') }}</h3>
    <div class="search">
      <UserSearch v-bind:profiles="profiles" @getSearchList="getSearchList($event)"/>
    </div>
    <div class="user-list">
      <span class="search-results" v-if="!dataLoaded">
        {{ $t('common.loader') }}
      </span>
      <span class="search-results" v-else>
        {{ $t('admin.users.search.results', { count: users.length }) }}
      </span>
      <hr/>
      <div class="tile" v-for="user in users.slice(0, tileToDisplay)" :key="user.id">
        <UserTile :user="user"/>
      </div>
    </div>
    <Pagination
      v-if="tileToDisplay !== users.length && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="users.length"
    />
  </div>
</template>

<script lang="ts">
import UsersApi, { User, UserProfile } from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import Pagination from '@/components/Pagination.vue';
import UserSearch from '@/components/UserSearch.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import UserTile from '@/views/admin/user/UserTile.vue';
import { Magnify, Plus } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    UserTile,
    Button,
    Magnify,
    Plus,
    UserSearch,
    Pagination,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    return {
      usersApi,
      users: [],
      profiles: [],
      nbrTileDefault: 15,
      tileToDisplay: this.nbrTileDefault,
      dataLoaded: false,
    };
  },
  methods: {
    fetchUsers() {
      this.usersApi.fetch()
        .then((response: User[]) => {
          this.dataLoaded = true;
          this.users = response;
          this.tileToDisplay = Math.min(this.nbrTileDefault, response.length);
        });
    },
    fetchProfiles() {
      this.usersApi.fetchProfiles()
        .then((response: UserProfile[]) => {
          this.profiles = [{
            id: '',
            name: this.$t('common.all'),
          }].concat(response);
        });
    },
    getSearchList(usersList: User[]) {
      this.users = usersList;
      this.tileToDisplay = Math.min(this.nbrTileDefault, usersList.length);
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchProfiles();
  },
  watch: {
    $route() {
      this.fetchUsers();
    },
  },
})
export default class UserManagement extends Vue {
}
</script>

<style lang="scss" scoped>
.users {
  .page-title {
    text-align: left;
    padding: var(--spacing-default);
  }

  .user-list {
    width: 99%;
    padding: var(--spacing-default);
    text-align: left;

    .search-results {
      font-size: 18px;
      font-family: 'LatoLight', sans-serif;
    }

    .tile {
      margin-bottom: var(--spacing-default);
    }
  }
}
</style>
