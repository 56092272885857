<template>
  <notifications ref="notifications" />
  <div v-bind:class="['tile-container', isValid() ? '' : 'disable']">
    <div v-if="!product.product.isVisible" class="pastille">
      <p>{{ $t('front.product.masked') }}</p>
    </div>
    <div
      v-if="product.hasOwnProperty('entityProduct') && product.isGamme"
      class="pastille gamme"
    >
      <p>{{ $t('front.product.gamme') }}</p>
    </div>
    <div
      v-bind:class="['product', product.product.isVisible ? '' : 'masked']"
      @click="() => handleDetails(product.product.id)"
    >
      <div class="product-container">
        <div class="catalog-flag" v-if="product.product.isInCatalog">Ma gamme</div>
        <div
          class="product-header"
          :title="product.product.name + ' (' + product.product.supplier + ')'"
        >
          <span>{{ product.product.name }}</span>
          <span class="light">({{ product.product.supplier }})</span>
          <img
            alt="PPP logo"
            v-if="isPPP() && product.product.isVisible"
            src="@/assets/PPP_blanc.svg"
          />
          <img
            alt="PPP logo"
            v-if="isPPP() && !product.product.isVisible"
            src="@/assets/PPP_noir_blanc.svg"
          />
          <img
            alt="Seed logo"
            v-if="isSemence() && product.product.isVisible"
            src="@/assets/semence_blanc.svg"
          />
          <img
            alt="Seed logo"
            v-if="isSemence() && !product.product.isVisible"
            src="@/assets/semence_noire-blanc.svg"
          />
        </div>
        <div class="product-info">
        <span v-if="product.product.amm">
          {{ $t('front.product.amm') }} {{ product.product.amm }}
        </span>
        <span v-if="product.product.gnis">
          {{ $t('front.product.gnis') }} {{ product.product.gnis }}
        </span>
        <span v-if="isPPP()">
          <span v-if="product.product.segment">{{ product.product.segment }}</span>
          <span v-if="product.product.segment && product.product.segmentSpecific">&emsp;</span>
          <span v-if="product.product.segmentSpecific">{{ product.product.segmentSpecific }}</span>
        </span>
        <span v-else>
          <span v-if="product.product.culture">{{ product.product.culture }}</span>
          <span v-if="product.product.culture && product.product.segmentSpecific">&emsp;</span>
          <span v-if="product.product.segmentSpecific">{{ product.product.segmentSpecific }}</span>
        </span>
        </div>
        <span v-if="product.lastDataUpdate" class="product-update">
        {{
            $t('front.product.updateData',
              { date: dayjs(product.lastDataUpdate).format('DD/MM/YYYY') })
          }}
      </span>
      </div>
    </div>
    <div class="score" @click="() => handleDetails(product.product.id)" v-if="logoLoaded">
      <div class="one-score-container" v-if="isValid()">
        <img v-if="logo.logoOneScore"
             class="img" alt="One score image"
             :src="logo.logoOneScore">
        <img v-if="logo.logoAlimentation"
             class="img" alt="Alimentation image"
             :src="logo.logoAlimentation">
        <img v-if="logo.logoAgriculteur"
             class="img" alt="Agriculteur image"
             :src="logo.logoAgriculteur">
        <img v-if="logo.logoEnvironnement"
             class="img" alt="Environnement image"
             :src="logo.logoEnvironnement">
      </div>
      <div class="one-score-container" v-else>
        <div class="info">{{ $t('admin.products.valid') }}</div>
      </div>
      <div class="one-score-container float-left">
        <span v-if="!!product.lastScoringUpdate">
          {{
            $t('front.product.updateScore',
              { date: dayjs(product.lastScoringUpdate).format('DD/MM/YYYY') })
          }}
      </span>
      </div>
    </div>
    <div v-else class="spinner-container">
      <div class="spinner-border" role="status" />
    </div>
    <div class="button-container">
      <Button
        v-if="canCompare === true"
        :label="$t('front.actions.compare')"
        :onClick="compare">
        <chart-box-plus-outline class="icon" />
      </Button>
      <Button
        v-if="canRemoveFavorite === true"
        :label="$t('front.actions.removeFavorite')"
        :onClick="removeFavorite">
        <close class="icon" />
      </Button>
      <Button
        v-if="canDownloadLogo === true"
        :label="$t('front.actions.downloadLogo')"
        :onClick="downloadLogo">
        <download class="icon" />
      </Button>
    </div>
    <button v-bind:class="['checkbox', canCheck ? '' : 'forbidden']" @click="handleClick"
            v-if="isValid() && showCheckbox"
    >
      <checkbox-marked v-if="checked" class="icon" />
      <checkbox-blank-outline v-if="!checked" class="icon" />
    </button>
  </div>
</template>

<script lang="ts">
import ProductsAdminApi from '@/api/products/ProductsAdminApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import dayjs from 'dayjs';
import { Options, Vue } from 'vue-class-component';
import {
  ChartBoxPlusOutline, Close, Download, CheckboxMarked, CheckboxBlankOutline,
} from 'mdue';
import Button from '@/components/Button.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import { inject, PropType } from 'vue';
import Notifications from '@/components/Notifications.vue';
import {
  ScoredProduct, LOGO_TYPES, PRODUCT_TYPE, Product,
} from '@/api/products/ProductsApi';

@Options({
  components: {
    Button,
    Download,
    Close,
    ChartBoxPlusOutline,
    CheckboxMarked,
    CheckboxBlankOutline,
    Notifications,
  },
  props: {
    product: {
      type: Object as PropType<ScoredProduct>,
      required: true,
    },
    canCompare: Boolean,
    canRemoveFavorite: Boolean,
    canDownloadLogo: Boolean,
    canCheck: Boolean,
    checked: Boolean,
    showCheckbox: Boolean,
    index: Number,
  },
  data() {
    const productsAdminApi = getGlobalInstance(ProductsAdminApi);
    const authorization = inject('authorization');

    return {
      productsAdminApi,
      logo: {
        logoOneScore: '',
        logoAlimentation: '',
        logoAgriculteur: '',
        logoEnvironnement: '',
      },
      logoLoaded: false,
      authorization,
    };
  },
  methods: {
    isValid() {
      return this.product.product.isValid;
    },
    isPPP() {
      return this.product.product?.type === PRODUCT_TYPE.PPP;
    },
    isSemence() {
      return this.product.product?.type?.toUpperCase() === PRODUCT_TYPE.SEMENCE;
    },
    compare() {
      SprintHelperModule.buttonInDev();
    },
    removeFavorite() {
      SprintHelperModule.buttonInDev();
    },
    downloadLogo() {
      SprintHelperModule.buttonInDev();
    },
    handleDetails(idProduct: string) {
      if (this.product.product.isValid) {
        this.$parent.displayDetails = idProduct;
      }
    },
    handleClick() {
      if (this.canCheck) {
        if (this.checked) {
          if (this.$parent.checkedList.length === this.$parent.products.length) {
            this.$parent.checkedAll = false;
          }
          const index = this.$parent.checkedList.indexOf(this.product.product.id);
          this.$parent.checkedList.splice(index, 1);
        } else {
          this.$parent.checkedList.push(this.product.product.id);
        }
      } else {
        this.$refs.notifications.notifyError(this.$t('notifications.oneTypeTileProduct'));
      }
    },
    dayjs,
    fetchLogo(logoType: string) {
      this.productsAdminApi.fetchLogo(this.product.product.id, logoType)
        .then((response: Response) => response.blob())
        .then((response: Blob) => {
          const logo = response.size === 0 ? null : URL.createObjectURL(response);
          switch (logoType) {
            case LOGO_TYPES.ONESCORE: {
              this.logo.logoOneScore = logo;
              break;
            }
            case LOGO_TYPES.ALIMENTATION: {
              this.logo.logoAlimentation = logo;
              break;
            }
            case LOGO_TYPES.AGRICULTEUR: {
              this.logo.logoAgriculteur = logo;
              break;
            }
            case LOGO_TYPES.ENVIRONNEMENT: {
              this.logo.logoEnvironnement = logo;
              break;
            }
            default:
              break;
          }
        });
    },
    fetchAllLogos() {
      this.fetchLogo(LOGO_TYPES.ALIMENTATION);
      this.fetchLogo(LOGO_TYPES.ONESCORE);
      this.fetchLogo(LOGO_TYPES.AGRICULTEUR);
      this.fetchLogo(LOGO_TYPES.ENVIRONNEMENT);
    },
    setLogoLoaded() {
      this.logoLoaded = this.logo.logoOneScore !== ''
        && this.logo.logoAlimentation !== ''
        && this.logo.logoAgriculteur !== ''
        && this.logo.logoEnvironnement !== '';
      if (this.updateLogoLoaded) {
        this.updateLogoLoaded(this.index, this.logoLoaded);
      }
      this.$parent.logoLoaded = this.logoLoaded;
    },
  },
  mounted() {
    this.fetchAllLogos();
  },
  watch: {
    logo: {
      handler() {
        this.setLogoLoaded();
      },
      deep: true,
    },
    product: {
      handler() {
        this.fetchAllLogos();
      },
      deep: true,
    },
  },
})
export default class ProductTile extends Vue {
  product!: Product;

  canCompare!: boolean;

  canRemoveFavorite!: boolean;

  canDownloadLogo!: boolean;
}
</script>

<style lang="scss" scoped>
.tile-container {
  width: 100%;
  height: 150px;
  border: solid grey 1px;
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: white;
  position: relative;
  margin: 25px 0;

  &.disable {
    cursor: no-drop;
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pastille {
    position: absolute;
    top: -16px;
    left: -1px;
    width: 85px;
    height: 32px;
    text-transform: uppercase;
    background: var(--grey-7);
    color: white;
    font-size: var(--font-size-hint);
    border-radius: var(--border-radius-small);
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }

    &.gamme {
      background: white;
      color: var(--grey-7);
      width: 125px;
    }
  }

  .product {
    background-color: $primary;
    color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    max-width: 5%;
    min-width: 250px;

    &.masked {
      background-color: white;
      color: $primary;
      border-right: 1px solid grey;
    }

    .product-container {
      margin-left: 20px;
      height: 100%;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      text-align: left;

      .product-header {
        display: flex;
        flex-direction: row;
        justify-content: center;

        span {
          text-transform: uppercase;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 6rem;

          &.light {
            font-family: 'SourceSansProLight', sans-serif;
            margin: 0 var(--spacing-small);
          }
        }

        img {
          width: 20px;
          height: 100%;
          object-fit: cover;
        }
      }

      .product-info {
        display: flex;
        flex-direction: column;
        font-size: small;
      }

      .catalog-flag {
        color: black;
        background-color: white;
        width: fit-content;
        padding: 5px;
        border-radius: var(--border-radius-default);
      }

      .product-update {
        font-size: x-small;
      }
    }
  }

  .score {
    flex-grow: 2;
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 10px;

    .one-score-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      position: relative;

      .info {
        font-family: 'SourceSansProRegular', sans-serif;
        color: var(--grey-5);
      }

      span {
        text-align: left;
        font-size: x-small;
      }

      &.float-left {
        justify-content: flex-start;
        margin-left: var(--spacing-medium);
        position: absolute;
        bottom: 0;
      }
    }

    img {
      max-width: 25%;
      max-height: 180px;
      margin-top: 1rem;
    }

    :first-child {
      margin-top: -1rem;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;

    button {
      margin: 2px;
    }
  }

  .checkbox {
    border: none;
    background: none;
    height: fit-content;

    &.forbidden {
      cursor: not-allowed;
    }
  }
}

img {
  max-width: 10vw;
  max-height: 150px;
}
</style>
