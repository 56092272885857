<template>
  <Popin
    title="Tutoriel"
    @close="close"
    class="custom-popin"
  >
    <template v-slot:button>
      <div class="close-button">
        <close class="icon" @click="close"/>
      </div>
    </template>
    <template v-slot:container>
      <div class="body">
        <chevron-left
          class="icon left"
          v-if="hasContentLeft()"
          @click="() => changePage(true)"
        />
        <div v-if="currentItem" class="content">
          <div class="description">{{ currentItem.text }}</div>
          <div v-if="videoLink" class="video">
            <video width="500" controls :src="videoLink"></video>
          </div>
        </div>
        <chevron-right
          class="icon right"
          v-if="hasContentRight()"
          @click="() => changePage(false)"
        />
      </div>
      <div class="footer">
        <div class="dot-container" v-for="(item, index) in tutorialItems" :key="index">
          <div
            v-bind:class="['dot', index === activeIndex ? 'current' : '']"
            @click="() => selectPage(index)"
          />
        </div>
      </div>
    </template>
  </Popin>
</template>

<script lang="ts">
import Button from '@/components/Button.vue';
import Popin from '@/components/Popin.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { TutorialItems } from '@/tutorial/Tutorial';
import { ChevronLeft, ChevronRight, Close } from 'mdue';
import { Options, Vue } from 'vue-class-component';
import ConfigurationApi, { VideoLink } from '@/api/configuration/ConfigurationApi';

@Options({
  components: {
    Popin,
    ChevronLeft,
    ChevronRight,
    Close,
    Button,
  },
  data() {
    const configurationApi = getGlobalInstance(ConfigurationApi);
    return {
      configurationApi,
      tutorialItems: TutorialItems,
      currentItem: {},
      activeIndex: 0,
      videoLink: '',
    };
  },
  props: {
    close: Function,
  },
  methods: {
    hasContentRight() {
      return this.activeIndex < this.tutorialItems.length - 1;
    },
    hasContentLeft() {
      return this.activeIndex > 0;
    },
    changePage(back: boolean) {
      if (back && this.activeIndex > 0) {
        this.activeIndex -= 1;
      } else if (this.activeIndex < this.tutorialItems.length) {
        this.activeIndex += 1;
      }
      this.updateCurrentItem();
    },
    selectPage(selectedIndex: number) {
      this.activeIndex = selectedIndex;
      this.updateCurrentItem();
    },
    updateCurrentItem() {
      this.currentItem = this.tutorialItems[this.activeIndex];
      this.configurationApi.getVideoTutorielVideo(this.activeIndex + 1)
        .then((videoLink: VideoLink) => {
          this.videoLink = videoLink.value;
        });
    },
  },
  mounted() {
    if (this.tutorialItems.length > this.activeIndex + 1) {
      this.updateCurrentItem();
    }
    this.configurationApi.getVideoTutorielVideo(this.activeIndex + 1)
      .then((videoLink: VideoLink) => {
        this.videoLink = videoLink.value;
      });
  },
})

export default class TutorialPopin extends Vue {
}

</script>

<style lang="scss">
#app .custom-popin {
  .popin {
    position: relative;

    .header {
      background-color: white;
      color: $primary;

      h4 {
        font-weight: bold;
      }
    }

    .popin-container {
      margin: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-3);
  padding: 20px 10px;

  .icon {
    position: absolute;
    font-size: 60px;
    cursor: pointer;
    color: $primary;

    &.left {
      left: 0
    }

    &.right {
      right: 0
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .description {
      font-family: 'TTNormsRegular', sans-serif;
      padding-bottom: 10px;
    }

    img {
      height: 200px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  .dot-container {
    margin: 0 5px;

    .dot {
      height: 12px;
      width: 12px;
      background-color: $primary;
      border-radius: 50%;
      cursor: pointer;

      &.current {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;

  .icon {
    cursor: pointer;
    font-size: 35px;
    color: $primary;
  }
}
</style>
