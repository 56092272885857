export default {
  "messageSprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité ne fait pas partie du sprint 2."])},
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non disponible pour le moment"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page n'est pas disponible dans le sprint actuel"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "noAccess": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès à cette fonctionnalité."])}
  },
  "menuAdmin": {
    "oneScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneScore"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Admin"])},
    "option": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des produits"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des profils"])},
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des permissions"])},
      "fournisseur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des fournisseurs"])},
      "cooperative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des coopératives"])},
      "industriel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des industriels"])},
      "package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des packages"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
      "simulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le simulateur"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de la donnée"])},
      "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des CGUs"])},
      "scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion du scoring"])},
      "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])}
    }
  },
  "common": {
    "button": {
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
      "addAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les éléments sélectionnés"])},
      "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir 15 résultats de plus"])},
      "displayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher 15 résultats de plus"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])}
    },
    "loader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])}
  },
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications ont bien été prises en compte."])},
    "inaccessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité n'est pas disponible pour ce sprint."])},
    "errorSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la sauvegarde."])},
    "errorSaveOrExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la sauvegarde. Assurez vous que le lien n'est pas expiré."])},
    "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la suppression."])},
    "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les droits nécessaires pour accéder à cette fonctionnalité."])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les droits nécessaires."])},
    "oneTypeTileProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne sélectionner que des produits à masquer ou à rendre visible."])},
    "internalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur interne"])}
  },
  "formulaireErrors": {
    "ALREADY_EXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une coopérative existe déjà avec ce nom."])},
    "phoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone valide."])},
    "emailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une adresse mail valide."])},
    "passwordValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un mot de passe valide."])},
    "passwordConfirmationValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
    "USER_MAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail saisie existe déjà pour un autre utilisateur."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe n'est pas valide."])},
    "SCORING_IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un scoring est déjà en cours."])},
    "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite, veuillez réessayer dans quelques instants"])}
  },
  "espaceClient": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace Client"])},
    "options": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace Administrateurs"])},
      "oneScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace Utilisateurs"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me déconnecter"])}
    }
  },
  "admin": {
    "fournisseur": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des fournisseurs"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fournisseur..."])},
      "gammeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit de la gamme"])},
      "excludeGammeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit exclus de la gamme"])},
      "list": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au détail"])},
        "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche les détails du fournisseur"])},
        "userCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun utilisateur"]), _normalize(["1 utilisateur"]), _normalize([_interpolate(_named("count")), " utilisateurs"])])},
        "productCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun produit"]), _normalize(["1 produit"]), _normalize([_interpolate(_named("count")), " produits"])])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails Fournisseurs"])},
        "whiteLabelProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de paramétrer les fournisseurs en mode \"marque blanche\" et d'appliquer à l'application OneScore, l'univers du fournisseur. Pour cela vous pouvez paramétrer une couleur primaire et une couleur secondaire ainsi que le logo du fournisseur."])}
      },
      "gamme": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des produits de la gamme du fournisseur"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit, segment, segment spécifique..."])},
        "searchOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les produits inclus ou exclus de la gamme du fournisseur"])}
      },
      "informations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du fournisseur"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le package est commun pour tous les utilisateurs du fournisseur."])},
        "packageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package fournisseur"])}
      },
      "referent": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référent fournisseur"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs du fournisseur"])},
        "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["(aucun utilisateur)"]), _normalize(["(1 utilisateur)"]), _normalize(["(", _interpolate(_named("count")), " utilisateurs)"])])},
        "subtitleCount": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("admin.fournisseur.users.subtitle", "upper", _type), " ", _linked("admin.fournisseur.users.count", "upper", _type)])},
        "accessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à l'utilisateur"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOM"])},
        "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référent utilisateur du fournisseur"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des produits du fournisseur"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des utilisateurs"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawer"])},
        "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur pour ce fournisseur"])}
      },
      "color": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code couleur primaire"])},
        "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code couleur secondaire"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])}
      }
    },
    "cooperative": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle coopérative"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des coopératives"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la coopérative..."])},
      "list": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au détail"])},
        "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche les détails de la coopérative"])},
        "userCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun utilisateur"]), _normalize(["1 utilisateur"]), _normalize([_interpolate(_named("count")), " utilisateurs"])])},
        "productCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun produit"]), _normalize(["1 produit"]), _normalize([_interpolate(_named("count")), " produits"])])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails Coopérative"])},
        "whiteLabelProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de paramétrer les coopératives en mode \"marque blanche\" et d'appliquer à l'application OneScore, l'univers de la coopérative. Pour cela vous pouvez paramétrer une couleur primaire et une couleur secondaire ainsi que le logo de la coopérative."])}
      },
      "gamme": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des produits de la gamme de la coopérative"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit, segment, segment spécifique, fournisseur..."])},
        "searchOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les produits inclus ou exclus de la gamme de la coopérative"])}
      },
      "informations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de la coopérative"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la coopérative"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le package est commun pour tous les utilisateurs de la coopérative."])},
        "packageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package de la coopérative"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la coopérative"])},
        "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer cette coopérative ?"])}
      },
      "referent": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référent de la coopérative"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs de la coopérative"])},
        "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["(aucun utilisateur)"]), _normalize(["(1 utilisateur)"]), _normalize(["(", _interpolate(_named("count")), " utilisateurs)"])])},
        "subtitleCount": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("admin.cooperative.users.subtitle", "upper", _type), " ", _linked("admin.cooperative.users.count", "upper", _type)])},
        "accessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à l'utilisateur"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOM"])},
        "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référent utilisateur de la coopérative"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des produits de la coopérative"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des utilisateurs"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawer"])},
        "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur pour cette coopérative"])}
      },
      "color": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code couleur primaire"])},
        "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code couleur secondaire"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])}
      },
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La coopérative a bien été créée."])},
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La coopérative a bien été supprimée."])}
    },
    "users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
      "tile": {
        "detailsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au détail"])},
        "detailButtonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche les détails de l'utilisateur"])}
      },
      "search": {
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil utilisateur"])},
        "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat correspondant à la recherche"]), _normalize(["1 résultat correspondant à la recherche"]), _normalize([_interpolate(_named("count")), " résultats correspondants à la recherche"])])}
      },
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des produits"])}
    },
    "utilisateur": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel utilisateur"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
      "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer cet utilisateur ?"])},
      "information": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'utilisateur"])},
        "inputName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "inputSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "inputPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])}
      },
      "liaison": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liaison de l'utilisateur"])},
        "profileLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
      },
      "package": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package utilisateur"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le package est paramétrable au niveau du fournisseur"])},
        "packageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])}
      },
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a bien été créé."])}
    },
    "missingData": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des données manquantes"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez accès aux données manquantes via la téléchargement d'un fichier excel qui les contient."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les données manquantes"])},
      "isDownloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement en cours..."])}
    },
    "updateCGU": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des CGUs"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez réinitiliser l'acceptation des CGU de tous les utilisateurs en cas de mise à jour de celle-ci."])},
      "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apres la réinitialisation, les utilisateurs devront accepter les nouvellles conditions générales d'utilisation lors de leur prochaine connexion"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser l'acceptation des CGU de tous les utilisateurs"])},
      "popin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinitialisation des CGUs"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sur de vouloir reinitialiser les CGUs de l'ensemble des utilisateurs OneScore ?"])}
      }
    },
    "updateScoring": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion du scoring"])},
      "lastScoring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(dernier scoring lancé le ", _interpolate(_named("date")), ")"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez relancer l'algorithme de scoring pour mettre à jour les notes de tous les produits et synchroniser les données avec SMAG."])},
      "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le temps de traitement peut durer plusieurs heures."])},
      "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le scoring est en cours depuis le ", _interpolate(_named("date")), "."])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le scoring"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring en cours"])},
      "popin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancement d'un nouveau scoring"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes vous sur de vouloir lancer un nouveau scoring ? Un fois lancé il ne sera pas possible de l'arreter."])}
      }
    },
    "products": {
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La note pour ce produit n'est pas disponible"])}
    }
  },
  "front": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur votre espace OneScore"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
      "placeholder": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
      },
      "button": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
        "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mon mot de passe"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir à la page de connexion"])}
      },
      "error": {
        "FIELD_REQUIRED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ ", _interpolate(_named("field")), " est manquant"])},
        "INCORRECT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le couple Adresse mail/mot de passe n'existe pas"])},
        "INCORRECT_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de vérification renseigné n'est pas valide.\nVeuillez vérifier que vous avez bien saisi le code."])}
      },
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mon mot de passe"])},
      "recovering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mail vous a été envoyé"])},
      "cgu": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales d'utilisation"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer, vous devez accepter les conditions générales d'utilisation."])},
        "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez les consulter via le lien suivant : "])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGU"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cochant puis en acceptant, je déclare avoir pris connaissance des\nconditions générales d'utilisation du site internet et les accepter"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
      },
      "validationCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'identité de l'utilisateur"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si votre couple Adresse mail / mot de passe est associé à un compte OneScore vous allez recevoir un mail. Cet mail contient le code à renseigner qui permettra de valider votre identité."])},
        "complement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Si vous n'avez pas reçu le mail, veuillez vérifier vos spams il peut s'y trouver)"])},
        "sendAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le code"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous venez de recevoir votre code par mail"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier "])}
      }
    },
    "forgotPassword": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe, veuillez saisir votre adresse mail : "])},
      "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'adresse mail que vous avez saisi correspond à un compte, vous recevrez un code sur l'adresse mail reliée à votre compte."])}
    },
    "newPassword": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais saisir votre nouveau mot de passe."])},
      "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit contenir minimum 8 caractères, une majuscule, une minuscule et un caractère spécial."])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "confirmNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du nouveau mot de passe"])}
    },
    "menuFront": {
      "produits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits"])},
      "productLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "gamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma gamme"])},
      "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes favoris"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance sociétale"])},
      "balanceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])}
    },
    "actions": {
      "downloadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les logos"])},
      "removeFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer des favoris"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer"])}
    },
    "productsPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*hfjdsfkj"])}
    },
    "product": {
      "alimentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentation"])},
      "environnement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
      "agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur"])},
      "amm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMM :"])},
      "gnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GNIS :"])},
      "updateScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour du score : ", _interpolate(_named("date"))])},
      "updateData": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour des données : ", _interpolate(_named("date"))])},
      "feeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentation"])},
      "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
      "masked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masqué"])},
      "gamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma gamme"])},
      "addGamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la gamme"])},
      "removeGamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer de la gamme"])},
      "gammeResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat correspondant à la recherche"]), _normalize(["1 résultat correspondant à la recherche ", _interpolate(_named("criterion"))]), _normalize([_interpolate(_named("count")), " résultats correspondants à la recherche ", _interpolate(_named("criterion"))])])},
      "advancedSearch": {
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment"])},
        "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition"])},
        "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture"])},
        "segmentSpecific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment spécifique"])},
        "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
        "criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critères de recherche :"])}
      },
      "productManagement": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des produits"])},
        "extract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraire les résultats"])},
        "hide": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Masquer pour tous"]), _normalize(["Masquer le produit"]), _normalize(["Masquer les produits"])])},
        "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat correspondant à la recherche"]), _normalize(["1 résultat correspondant à la recherche ", _interpolate(_named("criterions")), " ", _interpolate(_named("association"))]), _normalize([_interpolate(_named("count")), " résultats correspondants à la recherche ", _interpolate(_named("criterions")), " ", _interpolate(_named("association"))])])},
        "checkedAll": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tout sélectionner"]), _normalize(["Tout sélectionner (1 produit)"]), _normalize(["Tout sélectionner (", _interpolate(_named("count")), " produits)"])])},
        "uncheckedAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désélectionner"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre visible"])}
      },
      "productDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Score"])},
        "hideProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le produit pour l'ensemble des utilisateurs autorisés"])},
        "downloadProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la fiche produit"])},
        "gamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma gamme"])},
        "amm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AMM : ", _interpolate(_named("amm"))])},
        "gnis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["GNIS : ", _interpolate(_named("gnis"))])},
        "criteria": {
          "PPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPP"])},
          "SEMENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEMENCE"])},
          "prefix": {
            "marche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
            "segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment"])},
            "culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture"])},
            "segmentSpecific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment Spécifique"])}
          }
        },
        "scoring": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur sociétale"])},
          "downloadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les logos"])},
          "updateData": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour des données le ", _interpolate(_named("date"))])},
          "updateScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour du score le ", _interpolate(_named("date"))])}
        },
        "indicatorsDetails": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des indicateurs"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le détail des indicateurs"])},
          "indicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicateur"])},
          "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
          "name": {
            "one_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneScore"])},
            "alimentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentation Durable"])},
            "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualités nutritionnelles"])},
            "acides_gras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'acides gras insaturés dans l'alimentation"])},
            "organoleptique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Améliore les qualités organoleptiques"])},
            "production_proteines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de protéines végétales dans l'alimentation"])},
            "productions_mineures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet la production de cultures mineures"])},
            "sanitaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualités sanitaires"])},
            "ogm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans OGM"])},
            "ppp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans résidu de pesticides"])},
            "risque_pathogene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans risque pathogènes/mycotoxines"])},
            "agriculteur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travail de l'Agriculteur"])},
            "remuneration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rémunération juste et durable"])},
            "gain_economique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmente la rentabilité technico-économique"])},
            "mention_uab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation en AB"])},
            "robustesse_aleas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabilité de performance face aux aléas"])},
            "valeur_ajoutee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à des marchés de qualité technologique"])},
            "valeur_ajoutee_chartes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à des cahiers des charges IAA"])},
            "volatilite_intrants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible volatilité du prix d'achat de l'intrant"])},
            "volatilite_production_agricole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible volatilité du prix de vente de la culture"])},
            "vie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité de vie"])},
            "contraintes_reglementaires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de contraintes règlementaires"])},
            "danger_utilisateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de risque santé utilisateur"])},
            "eviter_re_semis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite un re-semis"])},
            "label_environnemental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficie d'une image environnementale positive"])},
            "plage_application_produit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large plage d'application"])},
            "pulverisateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite le nombre de sorties de pulvérisateur"])},
            "selectivite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectivité"])},
            "environnement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculture régénératrice"])},
            "biodiversite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodiversité"])},
            "abeilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de risque pour les abeilles"])},
            "biomassse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potentiel de retour au sol de biomasse"])},
            "contamination_sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de contamination du sol"])},
            "diversite_cultivee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodiversité cultivée"])},
            "ecotoxicite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de risque d'écotoxicité"])},
            "etm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de risque de présence d'ETM"])},
            "pollinisateurs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Alimentaire pour les pollinisateurs"])},
            "traitements_phyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite ou réduit les traitements phytopharmaceutiques de synthèse"])},
            "vers_de_terre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de risque pour les vers de terre"])},
            "carbone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbone"])},
            "azote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Améliorer l'Efficience azotée"])},
            "erosion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lutte contre l'érosion"])},
            "ges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduit l'empreinte carbone (fabrication +utilisation)"])},
            "potentiel_energetique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potentiel de valorisation énergétique"])},
            "potentiel_biomasse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potentiel de retour au sol de biomasse"])},
            "resistance_stress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résistance aux stress hydrique et thermique"])}
          }
        },
        "comparison": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance sociétale"])},
          "addToBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la balance sociétale"])}
        },
        "scoringEvolution": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Evolution des valeurs sociétales de ", _interpolate(_named("product"))])}
        },
        "cgu": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales d'utilisation"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document des CGUs"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour télécharger les logos, vous devez accepter les CGUs."])},
          "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
        },
        "PPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit de protection des plantes"])},
        "SEMENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semence"])}
      },
      "histogram": {
        "compareTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je compare le produit à son"])},
        "avg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moyenne des notes de ", _interpolate(_named("criteria"))])},
        "dispersion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dispersion des notes de ", _interpolate(_named("criteria"))])},
        "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur sociétale"])},
        "addToBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la balance sociétale"])},
        "oneScoreTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["OneScore : ", _interpolate(_named("note"))])},
        "alimentationTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alimentation : ", _interpolate(_named("note"))])},
        "agriculteurTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agriculteur : ", _interpolate(_named("note"))])},
        "environnementTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Environnement : ", _interpolate(_named("note"))])},
        "one_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneScore"])},
        "alimentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentation"])},
        "sanitaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité sanitaires"])},
        "nutrition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité nutrition"])},
        "agriculteur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur"])},
        "remuneration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rémunération juste et durable"])},
        "vie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité de vie"])},
        "environnement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
        "carbone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbone"])},
        "biodiversite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodiversite"])},
        "oneScoreAvg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moyenne OneScore: ", _interpolate(_named("note"))])},
        "alimentationAvg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moyenne Alimentation: ", _interpolate(_named("note"))])},
        "agriculteurAvg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moyenne Agriculteur: ", _interpolate(_named("note"))])},
        "environnementAvg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moyenne Environnement: ", _interpolate(_named("note"))])},
        "oneScoreComparison": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min OneScore: ", _interpolate(_named("min")), " \nMax OneScore: ", _interpolate(_named("max"))])},
        "alimentationComparison": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min Alimentation: ", _interpolate(_named("min")), " \nMax Alimentation: ", _interpolate(_named("max"))])},
        "agriculteurComparison": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min Agriculteur: ", _interpolate(_named("min")), " \nMax Agriculteur: ", _interpolate(_named("max"))])},
        "environnementComparison": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Min Environnement: ", _interpolate(_named("min")), " \nMax Environnement: ", _interpolate(_named("max"))])},
        "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les droits nécessaires pour accéder à ces données."])},
        "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inexistant"])}
      },
      "popin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les produits"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir masquer ce produit pour tous les utilisateurs du OneScore ?"]), _normalize(["Etes-vous sûr de vouloir masquer les ", _interpolate(_named("count")), " produits sélectionnés pour tous les utilisateurs du OneScore ?"])])},
        "textVisible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir rendre visible ce produit pour tous les utilisateurs du OneScore ?"]), _normalize(["Etes-vous sûr de vouloir rendre visibles les ", _interpolate(_named("count")), " produits sélectionnés pour tous les utilisateurs du OneScore ?"])])},
        "warningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit n'a été sélectionné."])},
        "gamme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits de la gamme ont été mis à jour"])},
        "favoriteMultiType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits sélectionnés n'ont pas le même statut, veuillez désélectionner tous ceux qui appartiennent à la gamme du fournisseur ou bien tous ceux qui n'y appartiennent pas"])},
        "favoriteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des produits de la gamme du fournisseur"])},
        "addFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir ajouter ce produit à la gamme du fournisseur ?"]), _normalize(["Etes-vous sûr de vouloir ajouter les ", _interpolate(_named("count")), " produits sélectionnés à la gamme du fournisseur ?"])])},
        "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir retirer ce produit de la gamme du fournisseur ?"]), _normalize(["Etes-vous sûr de vouloir retirer les ", _interpolate(_named("count")), " produits sélectionnés de la gamme du fournisseur ?"])])},
        "cooperative": {
          "favoriteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des produits de la gamme de la coopérative"])},
          "favoriteMultiType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits sélectionnés n'ont pas le même statut, veuillez désélectionner tous ceux qui appartiennent à la gamme de la coopérative ou bien tous ceux qui n'y appartiennent pas"])},
          "addFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir ajouter ce produit à la gamme de la coopérative ?"]), _normalize(["Etes-vous sûr de vouloir ajouter les ", _interpolate(_named("count")), " produits sélectionnés à la gamme de la coopérative ?"])])},
          "removeFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun produit sélectionné"]), _normalize(["Etes-vous sûr de vouloir retirer ce produit de la gamme de la coopérative ?"]), _normalize(["Etes-vous sûr de vouloir retirer les ", _interpolate(_named("count")), " produits sélectionnés de la gamme de la coopérative ?"])])}
        }
      }
    },
    "permissions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des permissions"])},
      "profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profil ", _interpolate(_named("profile"))])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité reliée au profil"])},
      "entityChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de l'entité à relier au profil"])},
      "functionalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des fonctionnalités :"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la note pour le profil"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un profil"])},
      "newProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau profil"])},
      "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression"])},
      "deleteProfileUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, la suppression de ce profil entrainera la suppression des utilisateurs suivants :"])},
      "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce profil ?"])},
      "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le profil"])},
      "nameProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du profil"])},
      "creationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le profil vient d'être créé"])},
      "FILTRE_NOTE_ONESCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_ONESCORE"])},
      "FILTRE_NOTE_ALIMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_ALIMENTATION"])},
      "FILTRE_NOTE_SANITAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_SANITAIRE"])},
      "FILTRE_NOTE_NUTRITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_NUTRITION"])},
      "FILTRE_NOTE_AGRICULTEUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_AGRICULTEUR"])},
      "FILTRE_NOTE_REMUNERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_REMUNERATION"])},
      "FILTRE_NOTE_VIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_VIE"])},
      "FILTRE_NOTE_ENVIRONNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_ENVIRONNEMENT"])},
      "FILTRE_NOTE_CARBONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_CARBONE"])},
      "FILTRE_NOTE_BIODIVERSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRE_NOTE_BIODIVERSITE"])}
    },
    "package": {
      "labels": {
        "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
        "segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment"])},
        "segmentSpecific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment Spécifique"])}
      },
      "notes": {
        "oneScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneScore"])},
        "enjeu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjeu"])},
        "enjeuxSpecifiques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjeux specifiques"])}
      }
    },
    "balanceSocietale": {
      "accueil": {
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance sociétale"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez mettre en balance les valeurs sociétales de deux produits ou plus ? Vous souhaitez positionner la valeur sociétale d'un produit par rapport à son segment de marché ? Ou encore afficher les valeurs sociétales de tous les produits sur une culture ?"])},
        "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser la balance sociétale OneScore !"])},
        "bouton": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer une balance sociétale"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La balance sociétale permet de rendre compte de la valeur sociétale de plusieurs produits ou groupes de produits (marché, segment, etc.).\nVous pourrez afficher la valeur sociétale globale ou affiner ces valeurs par enjeu (Alimentation, Agriculteur, Environnement) ou enjeu spécifique."])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["raisin.jpg"])}
        }
      },
      "creation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle balance sociétale"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je sélectionne ici les différents produits ou ensemble de produits à afficher sur la balance sociétale."])},
        "recherche": {
          "placeholderTabMale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Préciser le ", _interpolate(_named("selectedTab")), " recherché"])},
          "placeholderTab": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Préciser la ", _interpolate(_named("selectedTab")), " recherchée"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un produit, un fournisseur, une culture, un segment de marché ou une composition."])},
          "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez affiner votre recherche de ", _interpolate(_named("productType")), " grâce à la barre de recherche ci-dessous : "])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, vous ne pourrez pas comparer des groupes de produits provenant de moins de 3 fournisseurs"])}
        },
        "panier": {
          "errorLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez ajouter plus de 5 produits à votre panier."])},
          "errorPanier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le panier doit comporter au moins un produit pour accéder au comparateur."])},
          "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma balance"])},
          "bouton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la balance"])}
        },
        "accueil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La balance sociétale permet de rendre compte de la valeur sociétale de plusieurs produits, groupes de produits (marché, segment, etc.)."])},
        "products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Uniquement pour le produit correspondant aux critères : ", _interpolate(_named("criterions")), " ", _interpolate(_named("association")), "."]), _normalize(["Uniquement pour les produits (", _interpolate(_named("count")), " produits) correspondants aux critères : ", _interpolate(_named("criterions")), ". ", _interpolate(_named("association"))])])},
        "productsList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Contient ", _interpolate(_named("count")), " produit."]), _normalize(["Contient ", _interpolate(_named("count")), " produits."])])}
      },
      "filtre": {
        "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines données sont manquantes pour cet élément. Vous ne pouvez pas le consulter."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtrer par ", _interpolate(_named("type"))])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])},
        "composition": {
          "alone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenant uniquement ce(s) composant(s)"])},
          "associated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenant a minima ce(s) composant(s)"])},
          "associationChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de composant"])}
        },
        "notes": {
          "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min : "])},
          "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max : "])}
        }
      },
      "resultats": {
        "panier": {
          "bouton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la balance"])},
          "popin": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le panier"])},
            "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr(e) de vouloir supprimer votre panier ?"])},
            "desc-bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous confirmez la suppression, vous serez redirigé vers la page de création de la balance et vous perdrez les éléments de votre panier."])},
            "bouton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
          }
        },
        "graph": {
          "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur sociétale "])},
          "details": {
            "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
            "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
            "moy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moy"])}
          },
          "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les droits nécessaires."])},
          "products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " produit correspondant aux critères : ", _interpolate(_named("criterions")), " ", _interpolate(_named("association")), "."]), _normalize([_interpolate(_named("count")), " produits correspondants aux critères : ", _interpolate(_named("criterions")), ". ", _interpolate(_named("association"))])])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la balance sociétale"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le niveau de précision de la balance sociétale désiré, sélectionnez OneScore ou l'enjeu/enjeu spécifique souhaité."])}
      }
    },
    "produits": {
      "accueil": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les produits"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez rechercher les produits ou ensemble de produits (marché, segment, etc.) de votre choix."])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ble.jpg"])}
      }
    },
    "balance": {
      "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun élément sélectionné"]), _normalize(["1 élément sélectionné"]), _normalize([_interpolate(_named("count")), " éléments sélectionnés"])])},
      "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 résultat"]), _normalize([_interpolate(_named("count")), " résultats"])])},
      "PRODUIT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 produit"]), _normalize([_interpolate(_named("count")), " produits"])])},
      "COMPOSITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 composition"]), _normalize([_interpolate(_named("count")), " compositions"])])},
      "MARCHE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 marché"]), _normalize([_interpolate(_named("count")), " marchés"])])},
      "SEGMENT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 segment"]), _normalize([_interpolate(_named("count")), " segments"])])},
      "FOURNISSEUR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 fournisseur"]), _normalize([_interpolate(_named("count")), " fournisseurs"])])},
      "CULTURE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 culture"]), _normalize([_interpolate(_named("count")), " cultures"])])},
      "SEGMENT_SPECIFIC": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résultat"]), _normalize(["1 segment spécifique"]), _normalize([_interpolate(_named("count")), " segments spécifiques"])])}
    },
    "footer": {
      "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 InVivo. Tous droits réservés."])},
      "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGUs"])},
      "legal-infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
      "confidentiality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutoriel"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous à notre newsletter :"])},
      "oneScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le OneScore"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous ?"])},
      "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté"])},
      "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
    },
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur OneScore,"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous pouvez chercher un produit et découvrir sa valeur sociétale."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["champs.png"])}
    }
  }
}