/**
 * function used for dependency import.
 * @param clazz: the class needed
 * @return {*}: the class instance
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default function getGlobalInstance<T>(clazz: T) {
  if (typeof clazz.getInstance !== "function") {
    throw new Error("Static function `getInstance` not found for class : " + clazz.name + ". You must implement it.")
  }
  return clazz.getInstance();
}
