import { HttpPlumeResponse } from '@/lib/plume-http/client/PlumeHttpResponse';
import HttpRequest from '@/lib/simple-http-request-builder/HttpRequest';
import OneScoreHttpClientHelper from './OneScoreHttpClientHelper';

export default class OneScoreHttpClient {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: OneScoreHttpClient | undefined;

  public static getInstance(): OneScoreHttpClient {
    if (OneScoreHttpClient.INSTANCE === undefined) {
      OneScoreHttpClient.INSTANCE = new OneScoreHttpClient();
    }

    return OneScoreHttpClient.INSTANCE;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  execute(httpRequest: HttpRequest<any>): Promise<Response> {
    return OneScoreHttpClientHelper.execute(httpRequest);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  executeRest<T>(httpRequest: HttpRequest<any>): Promise<HttpPlumeResponse<T>> {
    return OneScoreHttpClientHelper.executeRest(httpRequest);
  }
}
