<template>
  <div class="popin-mask" @click="clickOutside">
    <div class="popin">
      <div class="header" v-bind:style="[color ? {color: `#FFF`, background: color } : {}]">
        <h4>{{ title }}</h4>
      </div>
      <div v-bind:class="['popin-container', isFront ? 'front' : '']">
        <slot name="container" />
        <div v-if="!isFront" class="actions">
          <Button
            v-if="hasCancel !== false"
            :label="$t('common.button.cancel')"
            color="secondary"
            @click="closePopin"
          />
          <slot name="button" />
        </div>
        <div v-else class="actions">
          <Button
          v-if="hasCancel !== false"
          :label="$t('common.button.cancel')"
          v-bind:class="['action supp', isFront ? 'button front' : 'button']"
          @click="closePopin"
        />
          <slot name="button" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ArrowLeft } from 'mdue';
import Button from '@/components/Button.vue';

@Options({
  components: {
    Button,
    ArrowLeft,
  },
  props: {
    title: '',
    hasCancel: Boolean,
    isFront: Boolean,
    color: String,
  },
  methods: {
    closePopin() {
      this.$emit('close');
      this.$parent.showPopin = false;
    },
    // e.path is deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickOutside(e: any) {
      // Must correspond to the main div class
      if (e.path[0].className === 'popin-mask') {
        this.closePopin();
      }
    },
  },
})
export default class Popin extends Vue {}
</script>

<style lang="scss" scoped>
.popin-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  .popin {
    background: white;
    width: 42%;
    border-radius: var(--border-radius-default);
    .header {
      border-top-left-radius: var(--border-radius-default);
      border-top-right-radius: var(--border-radius-default);
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      color: white;
      h4 {
        text-transform: uppercase;
        margin: 0;
      }
    }
    .popin-container {
      margin: var(--spacing-small) var(--spacing-bigger) var(--spacing-default);
      text-align: center;
      button {
        height: var(--btn-height-smaller);
        margin: var(--spacing-small);
      }
      &.front {
        margin: var(--spacing-bigger) var(--spacing-default);
      }

      .text {
        font-size: var(--font-size-small);
        color: $primary;
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
          height: var(--btn-height-smaller);
          margin: var(--spacing-small);
          &.front {
            min-width: min-content;
            padding: 20px;
            height: 56px;
            border: 1px solid $primary;
          }
        }

      }
    }
  }
}
</style>
