import PlumeHttpPromise from '@/lib/plume-http/promise/PlumeHttpPromise';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';
import {
  ACCUEIL,
  ADMIN,
  BALANCE_SOCIETALE_ROUTE,
  PRODUCT_ROUTE,
} from '@/router/routes';
import { jwtDecode } from 'jwt-decode';
import ApiHttpClient from '../ApiHttpClient';

export type Login = {
  login: string;
  password?: string;
}

export type LoginResponse = {
  token: string;
}

type ParsedToken = {
  permissions: [];
};

export type Authorization = {
  value: '';
};

export type PermissionRouteType = {
  route: string;
  permission: string;
};

export const PERMISSIONS = {
  ADMIN: 'ADMIN',
  ONESCORE: 'ONESCORE',
  TRI_ENJEUX: 'TRI_ENJEUX',
  PAGE_ACCUEIL: 'PAGE_ACCUEIL',
  RECHERCHE: 'RECHERCHE',
  NOUVEAUX_PRODUITS: 'NOUVEAUX_PRODUITS',
  FAVORIS: 'FAVORIS',
  TUILE_PRODUIT: 'TUILE_PRODUIT',
  NOM_PRODUIT: 'NOM_PRODUIT',
  AMM_GNIS: 'AMM_GNIS',
  NOM_FOURNISSEUR: 'NOM_FOURNISSEUR',
  MARCHE_SEMENCE: 'MARCHE_SEMENCE',
  CULTURE_SEMENCE: 'CULTURE_SEMENCE',
  SEGMENT_SPECIFIC_SEMENCE: 'SEGMENT_SPECIFIC_SEMENCE',
  SEGMENT_PPP: 'SEGMENT_PPP',
  SEGMENT_SPECIFIC_PPP: 'SEGMENT_SPECIFIC_PPP',
  MAJ_SCORE: 'MAJ_SCORE',
  MAJ_PRODUIT: 'MAJ_PRODUIT',
  AJOUTER_BALANCE: 'AJOUTER_BALANCE',
  AJOUTER_GAMME: 'AJOUTER_GAMME',
  TELECHARGER_FICHE: 'TELECHARGER_FICHE',
  TELECHARGER_LOGOS: 'TELECHARGER_LOGOS',
  DETAIL_PRODUIT: 'DETAIL_PRODUIT',
  INDICATEURS: 'INDICATEURS',
  NOTE_ONESCORE: 'NOTE_ONESCORE',
  JAUGES_ENJEUX: 'JAUGES_ENJEUX',
  NOTES_ENJEUX: 'NOTES_ENJEUX',
  NOTES_ENJEUX_SPE: 'NOTES_ENJEUX_SPE',
  COMPARATEUR_PAGE_DETAIL: 'COMPARATEUR_PAGE_DETAIL',
  EVOLUTION_PRODUIT: 'EVOLUTION_PRODUIT',
  PAGE_RECHERCHE: 'PAGE_RECHERCHE',
  FILTRE_PRODUITS: 'FILTRE_PRODUITS',
  FILTRE_CRITERES: 'FILTRE_CRITERES',
  FILTRE_NOTE_ONESCORE: 'FILTRE_NOTE_ONESCORE',
  FILTRE_NOTE_ALIMENTATION: 'FILTRE_NOTE_ALIMENTATION',
  FILTRE_NOTE_SANITAIRE: 'FILTRE_NOTE_SANITAIRE',
  FILTRE_NOTE_NUTRITION: 'FILTRE_NOTE_NUTRITION',
  FILTRE_NOTE_AGRICULTEUR: 'FILTRE_NOTE_AGRICULTEUR',
  FILTRE_NOTE_REMUNERATION: 'FILTRE_NOTE_REMUNERATION',
  FILTRE_NOTE_VIE: 'FILTRE_NOTE_VIE',
  FILTRE_NOTE_ENVIRONNEMENT: 'FILTRE_NOTE_ENVIRONNEMENT',
  FILTRE_NOTE_CARBONE: 'FILTRE_NOTE_CARBONE',
  FILTRE_NOTE_BIODIVERSITE: 'FILTRE_NOTE_BIODIVERSITE',
  PAGE_GAMME: 'PAGE_GAMME',
  AJOUT_GAMME: 'AJOUT_GAMME',
  RETIRER_GAMME: 'RETIRER_GAMME',
  PAGE_FAVORIS: 'PAGE_FAVORIS',
  AJOUT_FAVORIS: 'AJOUT_FAVORIS',
  RETIRER_FAVORIS: 'RETIRER_FAVORIS',
  PAGE_COMPARATEUR: 'PAGE_COMPARATEUR',
  COMPARER_MARCHES: 'COMPARER_MARCHES',
  COMPARER_CULTURES: 'COMPARER_CULTURES',
  COMPARER_SEGMENTS: 'COMPARER_SEGMENTS',
  COMPARER_SEGMENT_SPECIFIC: 'COMPARER_SEGMENT_SPECIFIC',
  COMPARER_PRODUITS: 'COMPARER_PRODUITS',
  COMPARER_COMPOSITIONS: 'COMPARER_COMPOSITIONS',
  COMPARER_FOURNISSEURS: 'COMPARER_FOURNISSEURS',
  CONSULTER_ONESCORE_BALANCE: 'CONSULTER_ONESCORE_BALANCE',
  CONSULTER_ALIMENTATION_BALANCE: 'CONSULTER_ALIMENTATION_BALANCE',
  CONSULTER_SANITAIRE_BALANCE: 'CONSULTER_SANITAIRE_BALANCE',
  CONSULTER_NUTRITIONNEL_BALANCE: 'CONSULTER_NUTRITIONNEL_BALANCE',
  CONSULTER_AGRICULTEUR_BALANCE: 'CONSULTER_AGRICULTEUR_BALANCE',
  CONSULTER_REMUNERATION_BALANCE: 'CONSULTER_REMUNERATION_BALANCE',
  CONSULTER_VIE_BALANCE: 'CONSULTER_VIE_BALANCE',
  CONSULTER_ENVIRONNEMENT_BALANCE: 'CONSULTER_ENVIRONNEMENT_BALANCE',
  CONSULTER_CARBONE_BALANCE: 'CONSULTER_CARBONE_BALANCE',
  CONSULTER_BIODIVERSITE_BALANCE: 'CONSULTER_BIODIVERSITE_BALANCE',
  FILTRE_CULTURE: 'FILTRE_CULTURE',
  FILTRE_MARCHE: 'FILTRE_MARCHE',
  FILTRE_SEGMENT: 'FILTRE_SEGMENT',
  FILTRE_SEGMENT_SPECIFIC: 'FILTRE_SEGMENT_SPECIFIC',
  FILTRE_COMPOSITIONS: 'FILTRE_COMPOSITIONS',
  FILTRE_FOURNISSEURS: 'FILTRE_FOURNISSEURS',
  FILTRE_GAMME: 'FILTRE_GAMME',
  PROTECTED: 'PROTECTED',
  MARCHE_PPP: 'MARCHE_PPP',
  AJOUTER_BALANCE_DETAIL: 'AJOUTER_BALANCE_DETAIL',
  TELECHARGER_DETAIL_INDICATEUR: 'TELECHARGER_DETAIL_INDICATEUR',
  NOMBRE_PRODUIT: 'NOMBRE_PRODUIT',
  RESULTATS_RECHERCHE: 'RESULTATS_RECHERCHE',
  FILTRE_CULTURE_BALANCE: 'FILTRE_CULTURE_BALANCE',
  FILTRE_MARCHE_BALANCE: 'FILTRE_MARCHE_BALANCE',
  FILTRE_SEGMENT_BALANCE: 'FILTRE_SEGMENT_BALANCE',
  FILTRE_SEGMENT_SPECIFIC_BALANCE: 'FILTRE_SEGMENT_SPECIFIC_BALANCE',
  FILTRE_COMPOSITIONS_BALANCE: 'FILTRE_COMPOSITIONS_BALANCE',
  FILTRE_FOURNISSEURS_BALANCE: 'FILTRE_FOURNISSEURS_BALANCE',
  FILTRE_GAMME_BALANCE: 'FILTRE_GAMME_BALANCE',
  RESULTATS_SELECTION: 'RESULTATS_SELECTION',
  BALANCE_AJOUTER_BALANCE: 'BALANCE_AJOUTER_BALANCE',
  AFFICHER_PRODUIT_BALANCE: 'AFFICHER_PRODUIT_BALANCE',
  LANCER_BALANCE: 'LANCER_BALANCE',
};

export const permisionsRoutes: PermissionRouteType[] = [
  {
    route: ACCUEIL,
    permission: PERMISSIONS.PAGE_ACCUEIL,
  },
  {
    route: PRODUCT_ROUTE,
    permission: PERMISSIONS.PAGE_RECHERCHE,
  },
  {
    route: BALANCE_SOCIETALE_ROUTE,
    permission: PERMISSIONS.PAGE_COMPARATEUR,
  },
  {
    route: ADMIN,
    permission: PERMISSIONS.ADMIN,
  },
];

export function parseJwt(token?: string) {
  if (!token) return [];

  const jwt: ParsedToken = jwtDecode(token);
  return jwt.permissions;
}

export default class AuthentApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: AuthentApi | undefined;

  public static getInstance(): AuthentApi {
    if (AuthentApi.INSTANCE === undefined) {
      AuthentApi.INSTANCE = new AuthentApi();
    }

    return AuthentApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  private permissions: string[];

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
    this.permissions = [];
  }

  login(login: Login): PlumeHttpPromise<LoginResponse> {
    return this
      .httpClient
      .restRequest<LoginResponse>(HttpMethod.POST, '/connexion')
      .jsonBody(login)
      .execute();
  }

  codeValidation(login: Login, code: string): PlumeHttpPromise<LoginResponse> {
    return this
      .httpClient
      .restRequest<LoginResponse>(HttpMethod.POST, `/connexion/code/${code}`)
      .jsonBody(login)
      .execute();
  }

  sendAgainCodeValidation(login: Login): PlumeHttpPromise<boolean> {
    return this
      .httpClient
      .restRequest<boolean>(HttpMethod.POST, '/connexion/send/code')
      .jsonBody(login)
      .execute();
  }

  recoverPassword(login: Login): PlumeHttpPromise<void> {
    return this
      .httpClient
      .restRequest<void>(HttpMethod.POST, '/connexion/recuperation')
      .jsonBody(login)
      .execute();
  }

  getPermissions(authorization: string | undefined): string[] {
    if (authorization) {
      this.permissions = parseJwt(authorization);
    }
    return this.permissions;
  }
}
