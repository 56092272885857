<template>
  <Popin
    :title="title"
    :hasCancel="true"
    @close="close"
  >
    <template v-slot:container>
      <p class="text">{{ content }}</p>
    </template>
    <template v-slot:button>
      <Button
        type="button"
        :label="$t(`common.button.${!!validationButton ? 'valid' : 'delete' }`)"
        color="primary"
        @click="handleDelete"
      >
        <check v-if="!!validationButton" class="icon" />
        <delete v-else class="icon" />
      </Button>
    </template>
  </Popin>
</template>

<script lang="ts">
import Button from '@/components/Button.vue';
import Popin from '@/components/Popin.vue';
import { Check, Delete } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Popin,
    Delete,
    Check,
    Button,
  },
  props: {
    title: String,
    content: String,
    handleDelete: Function,
    close: Function,
    validationButton: Boolean,
  },
  methods: {
  },
})

export default class ConfirmationPopin extends Vue {}

</script>

<style lang="scss" scoped>

</style>
