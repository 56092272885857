import ApiHttpClient from '@/api/ApiHttpClient';
import { NotePermissionByProfile } from '@/api/permissions/PermissionsApi';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';

export type Profile = {
  id: string;
  name: string;
  entityType: string;
}

export type ProfilePermission = {
  idProfile: string;
  idPermission: string;
}

export default class ProfileApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ProfileApi | undefined;

  public static getInstance(): ProfileApi {
    if (ProfileApi.INSTANCE === undefined) {
      ProfileApi.INSTANCE = new ProfileApi();
    }

    return ProfileApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetch() {
    return this
      .httpClient
      .restRequest<Profile[]>(HttpMethod.GET, '/admin/profils')
      .execute();
  }

  fetchPermissionsByProfile(idProfile: string) {
    return this
      .httpClient
      .restRequest<ProfilePermission[]>(HttpMethod.GET, `/admin/profils/${idProfile}/permissions`)
      .execute();
  }

  save(profile: Profile) {
    return this
      .httpClient
      .restRequest<Profile>(HttpMethod.POST, '/admin/profils')
      .jsonBody(profile)
      .execute();
  }

  delete(idProfile: string) {
    return this
      .httpClient
      .restRequest<void>(HttpMethod.DELETE, `/admin/profils/${idProfile}`)
      .execute();
  }

  fetchNotePermission() {
    return this.httpClient
      .restRequest(HttpMethod.GET, '/admin/profils/notes')
      .execute();
  }

  fetchAllUsersByProfileId(idProfile: string) {
    return this
      .httpClient
      .restRequest<NotePermissionByProfile[]>(HttpMethod.GET, `/admin/profils/${idProfile}/users`)
      .execute();
  }
}
