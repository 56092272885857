import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBIcon = _resolveComponent("MDBIcon")!
  const _component_MDBDropdownToggle = _resolveComponent("MDBDropdownToggle")!
  const _component_MDBDropdownItem = _resolveComponent("MDBDropdownItem")!
  const _component_MDBDropdownMenu = _resolveComponent("MDBDropdownMenu")!
  const _component_MDBDropdown = _resolveComponent("MDBDropdown")!

  return (_openBlock(), _createBlock(_component_MDBDropdown, {
    modelValue: _ctx.dropdown,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dropdown) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MDBDropdownToggle, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropdown = !_ctx.dropdown)),
        class: "dropdown-bouton btn-light"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MDBIcon, {
            icon: _ctx.iconName,
            class: "icon-user"
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.userName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_MDBDropdownMenu, { "aria-labelledby": "dropdownMenuButton" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
            return (_openBlock(), _createBlock(_component_MDBDropdownItem, {
              href: "#",
              key: item,
              onClick: item.action
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}