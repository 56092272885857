import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!
  const _component_delete = _resolveComponent("delete")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Popin = _resolveComponent("Popin")!

  return (_openBlock(), _createBlock(_component_Popin, {
    title: _ctx.title,
    hasCancel: true,
    onClose: _ctx.close
  }, {
    container: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.content), 1)
    ]),
    button: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "button",
        label: _ctx.$t(`common.button.${!!_ctx.validationButton ? 'valid' : 'delete' }`),
        color: "primary",
        onClick: _ctx.handleDelete
      }, {
        default: _withCtx(() => [
          (!!_ctx.validationButton)
            ? (_openBlock(), _createBlock(_component_check, {
                key: 0,
                class: "icon"
              }))
            : (_openBlock(), _createBlock(_component_delete, {
                key: 1,
                class: "icon"
              }))
        ]),
        _: 1
      }, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}