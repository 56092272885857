<template>
  <div @click="clickOutside">
    <div class="header-container">
      <HeaderTemplate
          :title="$t('front.produits.accueil.title')"
          :subtitle1="$t('front.produits.accueil.desc')"
          :image="$t('front.produits.accueil.image')"
      />
    </div>
    <div class="product-search">
      <span class="search-results loader" v-if="!dataLoaded">
            {{ $t('common.loader') }}
      </span>
      <span class="search-results" v-if="dataLoaded && havePermissionAfficherNombreProduits()">
        <span class="search-input"> {{ researchInput }}</span>
        <span class="search-count-input" v-if="researchInput">
        {{ $t('front.product.productManagement.results', { count: nbrResultProducts }) }}
          <span class="search-count-input--criteria" v-if="researchInput || globalFilters">
            <br />
            {{ getCriterionText(globalFilters) }}
          </span>
        </span>
        <span class="search-count-input search-count-input--whitout-search" v-else>
          {{ $t('front.product.productManagement.results', { count: nbrResultProducts }) }}
           <span class="search-count-input--criteria" v-if="globalFilters">
            <br />
            {{ getCriterionText(globalFilters) }}
          </span>
        </span>
      </span>
    </div>
    <div class="page-container" ref="products">
      <Filtres
        :showPopinFiltres="showPopinFiltres"
        :disable="!dataLoaded"
      />
      <div v-bind:class="['result-search-products', canShowTiles() ? '' : 'hide-result']">
        <div class="result-search-product--container">
          <ProductSort id="product-sort" ref="ProductSort" :sortUsed="sortUsed" @set-sort="(newSort: string) => (this.sortUsed = newSort)"/>
          <div class="result-container">
            <div v-for="product in products" :key="product.id">
              <ProductTileFront
                v-if="canShowTiles()"
                :product="product"
                :canCompare="false"
                :canRemoveFavorite="false"
                :canDownloadLogo="false"
                :canCheck="(checkedList.length === 0)
                  || (isActionMasked && product.product.isVisible)
                  || (!isActionMasked && !product.product.isVisible)"
                :checked="checkedList.includes(product.product.id)"
                :showPopinAction="showPopinAction"
                :showGammeLabel="isGammeFilterActive"
              />
            </div>
            <div v-if="products.length === 0 && !dataLoaded" class="spinner-border" role="status" />
          </div>
        </div>
        <Pagination
          v-if="tileToDisplay < nbrResultProducts && products.length > 0"
          :loading="!dataLoaded"
          :label="$t('common.button.showMore')"
          :nbrTileDefault="nbrTileDefault"
          :totalItem="nbrResultProducts"
        />
      </div>
      <ProductDetailsFront
        ref="detailProduit"
        v-if="displayDetails !== 0"
        v-bind:idProduct="displayDetails"
      />
    </div>
  </div>
  <FooterTemplate />
</template>

<script lang="ts">
import AuthentApi, { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import UsersApi from '@/api/users/UsersApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import ResearchBar from '@/views/front/ResearchBar.vue';
import HeaderTemplate from '@/views/front/HeaderTemplate.vue';
import FooterTemplate from '@/views/front/FooterTemplate.vue';
import ProductDetailsFront from '@/views/front/ProductDetailsFront.vue';
import Filtres from '@/views/front/Filtres.vue';
import ProductTileFront from '@/components/ProductTileFront.vue';
import BalanceApi, { BalanceBean, FIELD_TYPES } from '@/api/balance/BalanceApi';
import { inject, provide, ref } from 'vue';
import Pagination from '@/components/Pagination.vue';
import ProductsApi, { ALL_ENJEUX, NBR_ELEMENTS_RETURNED, ProductResultList } from '@/api/products/ProductsApi';
import ProductSort from '@/components/ProductSort.vue';
import { PRODUCT_SORT } from '@/api/fournisseurs/FournisseursApi';

@Options({
  components: {
    ProductDetailsFront,
    HeaderTemplate,
    FooterTemplate,
    Filtres,
    ResearchBar,
    ProductTileFront,
    Pagination,
    ProductSort,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);
    const authentApi = getGlobalInstance(AuthentApi);
    const usersApi = getGlobalInstance(UsersApi);
    const productsApi = getGlobalInstance(ProductsApi);

    const filterField = ref([]);
    provide('filterField', filterField);
    const filterFieldSelected = ref([]);
    provide('filterFieldSelected', filterFieldSelected);
    const globalFilters = ref([]);
    provide('globalFilters', globalFilters);
    const authorization = inject('authorization');
    return {
      balanceApi,
      authentApi,
      usersApi,
      productsApi,
      filterField,
      filterFieldSelected,
      globalFilters,
      products: [],
      nbrResultProducts: 0,
      allProducts: [],
      showPopinFiltres: false,
      nbrTileDefault: NBR_ELEMENTS_RETURNED,
      tileToDisplay: this.nbrTileDefault,
      researchInput: '',
      dataLoaded: false,
      checkedList: [],
      criterions: [],
      displayDetails: 0,
      authorization,
      showPopinAction: undefined,
      searchLaunched: false,
      isGammeFilterActive: false,
      sortUsed: PRODUCT_SORT.TrierPar,
      permissions: [] as string[],
    };
  },
  methods: {
    downloadFiche(idProduct: string) {
      this.displayDetails = idProduct;
      this.$nextTick(() => {
        this.$refs.detailProduit.loaderFiche = true;
        setTimeout(() => {
          this.$refs.detailProduit.downloadFiche();
        }, 5000);
      });
    },
    // e.path is deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickOutside(e: any) { // A type : Pointer Event
      // If click anywhere except on filter part, close popin
      const paths = e.path || (e.composedPath && e.composedPath()); // Pour Safari
      // e.path is deprecated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (paths.filter((path: any) => path.className === 'filtre-container').length === 0) {
        this.showPopinFiltres = false;
      }
      // e.path is deprecated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (paths.filter((path: any) => path.className === 'action-icon').length === 0) {
        this.showPopinAction = undefined;
      }
    },
    getAssociationText() {
      let associated = '';
      // On recupere l'index du filtre composition dans la liste des filtres du produit
      const indexFilter = this.product.filters.map((e: BalanceBean) => e.type)
        .indexOf(FIELD_TYPES.COMPOSITION);
      // On regarde si association != undefined (car 0 si composition seule et 1 si associee)
      if (this.product.filters
        && this.product.filters[indexFilter]
        && this.product.filters[indexFilter].association !== undefined) {
        // On recupere le texte en fonction de l'etat du filtre (associé ou non)
        associated = this.product.filters[indexFilter].association
          ? `(${this.$t('front.balanceSocietale.filtre.composition.associated')})`
          : `(${this.$t('front.balanceSocietale.filtre.composition.alone')})`;
      }
      return associated;
    },
    getCriterionText(criterions: BalanceBean[]) {
      this.criterions = [];
      let criterionToShow = '';
      const marcheCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.MARCHE)
        .map((criterion: BalanceBean) => criterion.label);
      if (marcheCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Marché (${marcheCriterions.join(', ')})`;
      }
      const cultureCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.CULTURE)
        .map((criterion: BalanceBean) => criterion.label);
      if (cultureCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Culture (${cultureCriterions.join(', ')})`;
      }
      const segmentCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.SEGMENT)
        .map((criterion: BalanceBean) => criterion.label);
      if (segmentCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Segment (${segmentCriterions.join(', ')})`;
      }
      const segmentSpecificCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.SEGMENT_SPECIFIC)
        .map((criterion: BalanceBean) => criterion.label);
      if (segmentSpecificCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Segment Spécifique (${segmentSpecificCriterions.join(', ')})`;
      }
      const fournisseurCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.FOURNISSEUR)
        .map((criterion: BalanceBean) => criterion.label);
      if (fournisseurCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Fournisseur (${fournisseurCriterions.join(', ')})`;
      }
      const compositionCriterions: string[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.COMPOSITION)
        .map((criterion: BalanceBean) => criterion.label);
      if (compositionCriterions.length > 0) {
        criterionToShow = `${criterionToShow} Composition (${compositionCriterions.join(', ')})`;
      }

      const notesCriterions: BalanceBean[] = criterions
        .filter((criterion: BalanceBean) => criterion.type === FIELD_TYPES.NOTES)
        .map((note: BalanceBean) => ({
          association: note.association,
          label: ALL_ENJEUX.filter((enjeu) => enjeu.name === note.label)[0].label,
          noteMax: note.noteMax,
          noteMin: note.noteMin,
          type: note.type,
          productIdList: [],
        }));

      if (notesCriterions.length > 0) {
        notesCriterions.forEach((criterion) => {
          criterionToShow = `${criterionToShow} ${criterion.label} (entre ${criterion.noteMin} et ${criterion.noteMax})`;
        });
      }
      return criterionToShow;
    },
    showMore() {
      this.handleSearch(this.researchInput);
    },
    getIsGammeFilterActive() {
      return this.globalFilters && this.globalFilters
        .some((filter: BalanceBean) => filter.type === FIELD_TYPES.GAMME);
    },
    handleSearch(value: string) {
      this.dataLoaded = false;
      let researchInput = '';
      if (value) {
        researchInput = value;
      } else if (this.$route.params.value) {
        researchInput = this.$route.params.value;
        this.$route.params.value = '';
      }
      this.researchInput = researchInput;
      this.balanceApi.findProductsByFilterSearch(
        this.globalFilters,
        this.tileToDisplay,
        this.products.length,
        value,
        this.sortUsed,
      ).then((responseFilter: ProductResultList) => {
        this.products = responseFilter.productsList;
        this.dataLoaded = true;
      });
    },
    scrollToProducts() {
      const element = this.$refs.products;
      const headerSize = 250; // Taille du header en px

      // On retire la taille du header
      const top = element.offsetTop - headerSize;

      window.scrollTo(0, top);
    },
    havePermissionAfficherNombreProduits() {
      return this.permissions.includes(PERMISSIONS.NOMBRE_PRODUIT);
    },
    canShowTiles() {
      return this.permissions.includes(PERMISSIONS.TUILE_PRODUIT);
    },
  },
  emits: ['menuChanged'],
  mounted() {
    this.permissions = parseJwt(this.authorization);
    // eslint-disable-next-line max-len
    /* Notes // Composition // Culture // Fournisseurs seulement pour le profil (Admin Union) // Marché // Segment // Segment Scor */
    this.balanceApi.fetchResearchFields(false, true)
      .then((response: BalanceBean[]) => {
        // Tri par ordre alphabetique sauf le filtre note
        response.sort((a, b) => {
          if (a.type === FIELD_TYPES.NOTES) {
            return -1;
          }
          if (a.type === FIELD_TYPES.GAMME) {
            return 1;
          }
          return a.label.localeCompare(b.label);
        });
        this.filterField = response;
      });
    this.$emit('menuChanged', 'product');
  },
  watch: {
    searchLaunched: {
      handler(value) {
        if (value) {
          this.scrollToProducts();
        }
        this.searchLaunched = false;
      },
    },
    globalFilters: {
      handler() {
        this.isGammeFilterActive = this.getIsGammeFilterActive();
        if ((this.globalFilters.length > 0) && this.filterFieldSelected.type) {
          this.dataLoaded = false;
          this.products = [];
          const tempGlobalFilters = [...this.globalFilters];
          this.balanceApi.findProductsByFilterSearch(
            this.globalFilters,
            this.tileToDisplay,
            0,
            this.researchInput,
            this.sortUsed,
          ).then((response: ProductResultList) => {
            if (JSON.stringify(this.globalFilters) === JSON.stringify(tempGlobalFilters)) {
              this.products = response.productsList;
              this.nbrResultProducts = response.nbrProducts;
              this.dataLoaded = true;
            }
          });
        } else if (this.researchInput) {
          this.productsApi.fetchProductSearch(this.researchInput, this.sortUsed)
            .then((response: ProductResultList) => {
              this.products = response.productsList;
              this.nbrResultProducts = response.nbrProducts;
              this.dataLoaded = true;
            });
        } else {
          this.products = this.allProducts.productsList;
          this.nbrResultProducts = this.allProducts.nbrProducts;
        }
      },
      deep: true,
    },
  },
})
export default class Product extends Vue {
}
</script>

<style lang="scss" scoped>
$primary: var(--grey-5);
.header-container {
  position: relative;
}

#product-sort{
  margin: 0;
}

.search-results {
  display: flex;
  flex-direction: column;
  color: $primary;
  align-items: center;

  .search-input {
    font-family: 'TTNormsBold', sans-serif;
    font-size: 30px;
  }

  .search-count-input {
    font-family: 'TTNormsRegular', sans-serif;
    font-size: var(--font-size-small);
    max-width: 50%;

    .search-count-input--criteria {
      font-style: italic;
    }
  }

  .search-count-input--whitout-search {
    margin-top: 3rem;
  }
}

.loader {
  font-family: 'TTNormsRegular', sans-serif;
  margin-top: 3rem;
}

.drawer-mask {
  z-index: 110;
}

.product-search {
  width: 100%;
  margin: 0 auto;
  height: var(--search-bar-height);
  position: absolute;
  padding-top: var(--spacing-small);

  h3 {
    color: $primary;
  }
}

.page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 100px;

  .result-search-product--container {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-very-big);
    padding: 20px 40px 0 40px;
  }

  .result-search-products {
    width: 70%;

    &.hide-result {
      visibility: hidden;
    }

    .result-search-product--container {
      width: 100%;

      .result-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
      }

      .spinner-border {
        margin-left: 30%;
        margin-top: 5rem;
      }
    }
  }
}

@media print {
  html, body {
    height: auto;
  }

  .page-container {
    height: 200px !important;
  }

  .result-container {
    display: none !important;
  }
}
</style>
