import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bdd10a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "update-cgu-management" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "update-cgu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ConfirmationPopin = _resolveComponent("ConfirmationPopin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('admin.updateCGU.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('admin.updateCGU.subtitle')), 1),
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('admin.updateCGU.subtitle2')), 1),
      _createVNode(_component_Button, {
        label: _ctx.$t('admin.updateCGU.update'),
        color: "primary",
        class: "update-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopin = true))
      }, null, 8, ["label"])
    ]),
    (_ctx.showPopin)
      ? (_openBlock(), _createBlock(_component_ConfirmationPopin, {
          key: 0,
          title: _ctx.$t('admin.updateCGU.popin.title'),
          content: _ctx.$t('admin.updateCGU.popin.content'),
          handleDelete: _ctx.updateCGU,
          close: _ctx.closePopin,
          validationButton: true
        }, null, 8, ["title", "content", "handleDelete", "close"]))
      : _createCommentVNode("", true)
  ], 64))
}