import OneScoreHttpClient from '@/api/OneScoreHttpClient';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';
import HttpRequest from '@/lib/simple-http-request-builder/HttpRequest';
import PlumeHttpPromise from '@/lib/plume-http/promise/PlumeHttpPromise';
import { PlumeHttpClientService } from '@/lib/plume-http/services/PlumeHttpClientServices';
import OneScoreHttpClientHelper, { HttpClientDelegate } from './OneScoreHttpClientHelper';

const baseUrl = '/api';

export default class ApiHttpClient implements PlumeHttpClientService, HttpClientDelegate {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ApiHttpClient | undefined;

  public static getInstance() {
    if (ApiHttpClient.INSTANCE === undefined) {
      ApiHttpClient.INSTANCE = new ApiHttpClient();
    }
    return ApiHttpClient.INSTANCE;
  }

  private logoutFunction!: () => void;

  private headers: Record<string, string> = {};

  private httpClient: OneScoreHttpClient;

  constructor() {
    this.httpClient = OneScoreHttpClient.getInstance();
  }

  setAuthorization(token: string) {
    this.headers.authorization = `Bearer ${token}`;
  }

  setLogoutFunction(logoutFunction: () => void) {
    OneScoreHttpClientHelper.delegate = this;
    this.logoutFunction = logoutFunction;
  }

  unauthorizedAccessReceived() {
    this.logoutFunction();
  }

  rawRequest(method: HttpMethod, path: string): HttpRequest<Promise<Response>> {
    return new HttpRequest<Promise<Response>>(
      (request) => this.httpClient.execute(request),
      baseUrl,
      method,
      path,
    ).headers(this.headers);
  }

  restRequest<T>(method: HttpMethod, path: string): HttpRequest<PlumeHttpPromise<T>> {
    return new HttpRequest<PlumeHttpPromise<T>>(
      (request) => new PlumeHttpPromise<T>(this.httpClient.executeRest(request)),
      baseUrl,
      method,
      path,
    ).headers(this.headers);
  }
}
