<template>
  <div class="front">
    <div class="app">
      <div class="front-header" v-if="!!authorization">
        <MenuOneScore :selectedTab="this.selectedTab" @logout="logout" />
      </div>
      <div class="body-container">
        <div class="router-container">
          <router-view @menuChanged="onMenuChanged" @authenticated="setAuthenticated" />
        </div>
    </div>
  </div>
  </div>
</template>

<script lang="ts">
import { inject } from 'vue';
import MenuOneScore from '@/views/front/MenuOneScore.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    MenuOneScore,
  },
  data() {
    const authorization = inject('authorization');

    return {
      selectedTab: 'none',
      authorization,
    };
  },
  emits: ['authenticated', 'logout'],
  methods: {
    onMenuChanged(selectedTab: string) {
      this.selectedTab = selectedTab;
    },
    setAuthenticated(token: string) {
      this.$emit('authenticated', token);
    },
    logout() {
      this.$emit('logout');
    },
  },
})
export default class Front extends Vue {
}
</script>

<style lang="scss">
.front {
  .front-header {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 3px 6px #59616566;
  }
  .body-container {
    height: calc(100% - var(--header-front-width));

    .router-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .page-container {
      flex: 1;
    }
  }
  .app {
    height: 100vh;
    @font-face {
      font-family: "Invivotype";
      src: local("Invivotype"),
      url(../../styles/fonts/Invivotype-Regular.otf) format("opentype");
    }

    @font-face {
      font-family: "TTNormsMedium";
      src: local("TTNormsMedium"),
      url(../../styles/fonts/TTNorms-Medium.otf) format("opentype");
    }

    @font-face {
      font-family: "TTNormsLight";
      src: local("TTNormsLight"),
      url(../../styles/fonts/TTNorms-Light.otf) format("opentype");
    }

    @font-face {
      font-family: "TTNormsRegular";
      src: local("TTNormsRegular"),
      url(../../styles/fonts/TTNorms-Regular.otf) format("opentype");
    }

    @font-face {
      font-family: "TTNormsBold";
      src: local("TTNormsBold"),
      url(../../styles/fonts/TTNorms-Bold.otf) format("opentype");
    }

    @font-face {
      font-family: "FjallaOneRegular";
      src: local("FjallaOneRegular"),
      url(../../styles/fonts/FjallaOne-Regular.ttf) format("truetype");
    }
  }
}

</style>
