<template>
  <div class="menu-header">
    <h1 class="oneScore">{{ $t('menuAdmin.oneScore') }}</h1>
    <h2 class="admin">{{ $t('menuAdmin.admin') }}</h2>
  </div>
  <div class="menu" v-for="(item) in pageNames" :key="item.link">
    <div v-if="!item.options">
      <router-link :to='item.link'>
        <Accordion :title="item.name"
                   v-bind:isActive="false"
                   :iconLeft="item.iconLeft"
                   :iconRight="item.iconRight"
        ></Accordion>
      </router-link>
    </div>
    <div v-else>
      <Accordion :title="item.name"
                 v-bind:isActive="false"
                 :iconLeft="item.iconLeft"
                 :iconRight="item.iconRight"
      >
        <div v-for="(option) in item.options" :key="option.name">
          <router-link :to="option.link">
            <Accordion :title="option.name"
                       v-bind:isActive="false"
                       :iconLeft="option.iconLeft"
            >
            </Accordion>
          </router-link>
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  MDBBtn,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdb-vue-ui-kit';
import Accordion from '@/components/Accordion.vue';
import {
  COOPERATIVE_LIST_ROUTE,
  FOURNISSEUR_LIST_ROUTE,
  MISSING_DATA_ROUTE,
  PERMISSIONS_ROUTE,
  PRODUCT_LIST_ADMIN_ROUTE,
  UPDATE_CGU_ROUTE,
  UPDATE_SCORING_ROUTE,
  USERS_LIST_ROUTE,
} from '@/router/routes';

@Options({
  components: {
    MDBBtn,
    MDBIcon,
    MDBListGroup,
    MDBListGroupItem,
    Accordion,
  },
  data() {
    return {
      pageNames: [
        {
          name: this.$t('menuAdmin.option.product'),
          iconLeft: 'spa',
          link: { name: PRODUCT_LIST_ADMIN_ROUTE },
        },
        {
          name: this.$t('menuAdmin.option.profile'),
          iconLeft: 'user-circle',
          link: '/no-redirection',
          iconRight: 'angle-down',
          options: [
            {
              name: this.$t('menuAdmin.option.permission'),
              iconLeft: 'book-open',
              link: { name: PERMISSIONS_ROUTE },
            },
            {
              name: this.$t('menuAdmin.option.fournisseur'),
              iconLeft: 'shapes',
              link: { name: FOURNISSEUR_LIST_ROUTE },
            },
            {
              name: this.$t('menuAdmin.option.cooperative'),
              iconLeft: 'compass',
              link: { name: COOPERATIVE_LIST_ROUTE },
            },
          ],
        },
        {
          name: this.$t('menuAdmin.option.user'),
          iconLeft: 'user-friends',
          link: { name: USERS_LIST_ROUTE },
        },
        {
          name: this.$t('menuAdmin.option.data'),
          iconLeft: 'sd-card',
          link: { name: MISSING_DATA_ROUTE },
        },
        {
          name: this.$t('menuAdmin.option.cgu'),
          iconLeft: 'sd-card',
          link: { name: UPDATE_CGU_ROUTE },
        },
        {
          name: this.$t('menuAdmin.option.scoring'),
          iconLeft: 'sd-card',
          link: { name: UPDATE_SCORING_ROUTE },
        },
      ],
    };
  },
})
export default class MenuAdministration extends Vue {
}
</script>

<style lang="scss">
.router-link-exact-active {
  div {
    .selected {
      background-color: $light !important;
    }
  }
}

.menu-header {
  padding: 32px 0 60px;
  color: white;
  .oneScore {
    font-family: "FjallaOneRegular", sans-serif;
    font-size: var(--font-size-medium);
  }
  .admin {
    font-size: var(--font-size-default);
  }
}

.menu {
  position: relative;

  div {
    .selected {
      margin-right: var(--spacing-default);
      background-color: transparent;
      width: var(--spacing-small);
      height: var(--spacing-bigger);
    }
  }

  .accordion-container {
    border-bottom: none;
    width: var(--menu-width);

    .accordion {
      text-transform: none;
      display: flex;
      align-items: center;
      background-color: $dark;
      color: $light;
      cursor: pointer;
      padding: var(--spacing-default) var(--spacing-small);
      width: 100%;
      border: none;
      text-align: left;
      font-size: 15px;
      transition: none;
      height: 100%;
      margin: 0;

      .fas.fa-angle-down {
        margin-left: auto;
      }

      .fas {
        margin-right: var(--spacing-small);
      }
    }

    .accordion:hover {
      background-color: lighten($dark, 10%);
    }

    .panel {
      display: none;
      text-align: left;
      background-color: $dark;
      margin: 0;

      .accordion {
        margin-left: var(--spacing-small);
      }
    }

    .panel.active {
      display: block;
    }
  }
}
</style>
