import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b177a4f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "espace-resultat" }
const _hoisted_3 = { class: "graph" }
const _hoisted_4 = {
  key: 0,
  class: "container-selection"
}
const _hoisted_5 = { class: "selection" }
const _hoisted_6 = {
  key: 0,
  class: "triangle"
}
const _hoisted_7 = {
  key: 0,
  class: "detail-container"
}
const _hoisted_8 = { class: "enjeu" }
const _hoisted_9 = { class: "enjeu" }
const _hoisted_10 = { class: "sous-enjeux" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = { class: "text" }
const _hoisted_14 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonEnjeuBalance = _resolveComponent("ButtonEnjeuBalance")!
  const _component_GraphResultat = _resolveComponent("GraphResultat")!
  const _component_Panier = _resolveComponent("Panier")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Popin = _resolveComponent("Popin")!
  const _component_ProductDetailsFront = _resolveComponent("ProductDetailsFront")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('front.balanceSocietale.resultats.title')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('front.balanceSocietale.resultats.desc')), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.enjeuxList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enjeuxList, (enjeu, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: enjeu }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ButtonEnjeuBalance, {
                      enjeu: enjeu,
                      isDetail: false,
                      isSelected: _ctx.enjeuSelected.label,
                      onClick: ($event: any) => (_ctx.handleSelection(enjeu)),
                      disabled: !_ctx.isInPermissions(enjeu)
                    }, null, 8, ["enjeu", "isSelected", "onClick", "disabled"]),
                    (enjeu && enjeu.label === _ctx.enjeuSelected.label && index !== 0
            || _ctx.isSousEnjeuSelected(enjeu))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                      : _createCommentVNode("", true)
                  ]),
                  ((_ctx.enjeuSelected.label === enjeu.label ||
               _ctx.isSousEnjeuSelected(enjeu)) && enjeu.sousEnjeux)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_ButtonEnjeuBalance, {
                            enjeu: enjeu,
                            isDetail: true,
                            isSelected: _ctx.enjeuSelected.label,
                            onClick: ($event: any) => (_ctx.handleSelection(enjeu))
                          }, null, 8, ["enjeu", "isSelected", "onClick"])
                        ]),
                        _createElementVNode("i", {
                          class: "fas fa-angle-right",
                          style: _normalizeStyle({color: enjeu.color})
                        }, null, 4),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(enjeu.sousEnjeux, (sousEnjeu) => {
                              return (_openBlock(), _createElementBlock("div", { key: sousEnjeu }, [
                                _createVNode(_component_ButtonEnjeuBalance, {
                                  isSelected: _ctx.enjeuSelected.label,
                                  enjeu: _ctx.getSousEnjeu(enjeu, sousEnjeu),
                                  isDetail: true,
                                  onClick: ($event: any) => (_ctx.handleSelection(sousEnjeu)),
                                  disabled: !_ctx.isInPermissions(sousEnjeu)
                                }, null, 8, ["isSelected", "enjeu", "onClick", "disabled"])
                              ]))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.showGraph())
          ? (_openBlock(), _createBlock(_component_GraphResultat, {
              key: 1,
              basket: _ctx.basketProducts,
              enjeuSelected: _ctx.getEnjeuSelected()
            }, null, 8, ["basket", "enjeuSelected"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('front.balanceSocietale.resultats.graph.permissions')), 1))
      ]),
      (_ctx.havePermissionAfficherProduit())
        ? (_openBlock(), _createBlock(_component_Panier, {
            key: 0,
            class: "panier",
            isResultatBalance: true,
            basket: _ctx.basketProducts,
            errorLimit: _ctx.errorLimit,
            onClearAll: _ctx.clearAll,
            onClearItem: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearItem($event)))
          }, null, 8, ["basket", "errorLimit", "onClearAll"]))
        : _createCommentVNode("", true),
      (_ctx.showPopin)
        ? (_openBlock(), _createBlock(_component_Popin, {
            key: 1,
            title: _ctx.$t('front.balanceSocietale.resultats.panier.popin.title'),
            hasCancel: true,
            color: `#005297`,
            isFront: true
          }, {
            container: _withCtx(() => [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('front.balanceSocietale.resultats.panier.popin.desc')), 1),
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('front.balanceSocietale.resultats.panier.popin.desc-bis')), 1)
            ]),
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Button, {
                  class: "action",
                  label: _ctx.$t('front.balanceSocietale.resultats.panier.popin.bouton'),
                  onClick: _ctx.confirmClearAll
                }, null, 8, ["label", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.displayDetails !== 0)
      ? (_openBlock(), _createBlock(_component_ProductDetailsFront, {
          key: 0,
          idProduct: _ctx.displayDetails
        }, null, 8, ["idProduct"]))
      : _createCommentVNode("", true)
  ], 64))
}