<template>
  <notifications ref="notifications" />
  <div class="toolbar-container">
    <div
      v-for="(field, index) in typeSelectionFiltered()"
      :key="field"
    >
      <ButtonNoBorder
        :locked="lockButtons"
        :label="field.label"
        :color="getColorFromType(field.type)"
        @Click="handleSelection(index)"
        :selected="field.selected">
      </ButtonNoBorder>
    </div>
  </div>
</template>

<script lang="ts">
import UsersApi from '@/api/users/UsersApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import { MDBBtnGroup, MDBBtnToolbar } from 'mdb-vue-ui-kit';
import BalanceApi, { BalanceBean, FIELD_TYPES, FILTER_TYPE } from '@/api/balance/BalanceApi';
import ButtonNoBorder from '@/components/ButtonNoBorder.vue';
import { inject } from 'vue';
import Notifications from '@/components/Notifications.vue';

@Options({
  components: {
    MDBBtnGroup,
    MDBBtnToolbar,
    ButtonNoBorder,
    Notifications,
  },
  props: {
    lockButtons: Boolean,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    const balanceApi = getGlobalInstance(BalanceApi);
    const authentApi = getGlobalInstance(BalanceApi);

    const researchFields = inject('researchFields');
    const authorization = inject('authorization');
    return {
      usersApi,
      balanceApi,
      authentApi,
      researchFields,
      authorization,
    };
  },
  methods: {
    notifyNoData() {
      this.$refs.notifications.notifyWarning(this.$t('front.balanceSocietale.filtre.data'));
    },
    isGamme(fieldType: string) {
      return fieldType === FIELD_TYPES.GAMME;
    },
    getColorFromType(type: keyof typeof FILTER_TYPE) {
      if (this.isGamme(type)) {
        return '';
      }
      return FILTER_TYPE[type].color;
    },
    typeSelectionFiltered() {
      // deep copy afin de ne pas impacter researchField qui est injecte dans le reste de l'appli
      const researchFieldsCopy = JSON.parse(JSON.stringify(this.researchFields));
      return researchFieldsCopy.filter((field: BalanceBean) => !this.isGamme(field.type));
    },
    handleSelection(indexSelected: number) {
      if (this.lockButtons) {
        return;
      }
      this.researchFields.forEach((field: BalanceBean, index: number) => {
        this.researchFields[index].selected = index === indexSelected;
      });
      this.$emit('selected', (this.researchFields[indexSelected]));
    },
  },
  mounted() {
    // TODO : se passer de l'appel
    this.balanceApi.fetchResearchFields(true, false)
      .then((response: BalanceBean[]) => {
        this.researchFields = response;
        if (this.researchFields.length > 0) {
          this.researchFields[0].selected = true;
        }
      });
  },
})
export default class ProductTypeSelection extends Vue {
  researchFields?: BalanceBean[];
}
</script>

<style lang="scss" scoped>
.toolbar-container {
  display: flex;
  justify-content: center;
  margin: var(--spacing-big) auto;
}

</style>
