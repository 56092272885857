<template>
  <div class="drawer-mask">
    <div class="drawer">
      <slot name="header" />
      <div class="drawer-container">
        <slot name="page" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  mounted() {
    const { body } = document;
    body.classList.add('scrollbar-hidden');
  },

  unmounted() {
    const { body } = document;
    body.classList.remove('scrollbar-hidden');
  },
})

export default class Drawer extends Vue {}
</script>

<style lang="scss">
body {
  &.scrollbar-hidden {
    overflow: hidden;
  }
}
.drawer-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
}
.drawer {
  width: 80%;
  height: 100vh;
  margin-left: auto;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .drawer-container {
    .page-container {
      background: white;
      margin: initial;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
  }
}

@media print {
  html, body, * {
    -webkit-print-color-adjust:exact;
  }
  .drawer {
    width: 100%;
  }
}
</style>
