<template>
  <div class="btn-container" @click="handleClick()">
    <button :class="['action', disabled ? 'disabled' : '']">
      <p>{{ title }}</p>
      <i class="fas fa-angle-right" v-if="filterNumber === 0 && isArrow"></i>
      <div v-if="filterNumber > 0 && isArrow" class="dot">+{{ filterNumber }}</div>
      <checkbox-blank-outline v-if="!isArrow && !boxChecked" class="icon" />
      <checkbox-marked v-if="!isArrow && boxChecked" class="icon" />
    </button>
  </div>
</template>

<script lang="ts">
import { CheckboxBlankOutline, CheckboxMarked } from 'mdue';
import { inject } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    CheckboxMarked,
    CheckboxBlankOutline,
  },
  props: {
    title: String,
    filterNumber: Number,
    disabled: Boolean,
    isArrow: Boolean,
    addItem: Function,
    clearItem: Function,
  },
  data() {
    const globalFilters = inject('globalFilters');

    return {
      globalFilters,
      boxChecked: false,
    };
  },
  methods: {
    handleClick() {
      if (this.isArrow) {
        return;
      }

      this.boxChecked = !this.boxChecked;
      if (this.boxChecked) {
        this.addItem();
      } else {
        this.clearItem();
      }
    },
  },
  watch: {
    globalFilters: {
      handler(value) {
        if (!value) {
          this.boxChecked = false;
        }
      },
    },
  },
})
export default class ButtonFilterType extends Vue {
}
</script>

<style lang="scss" scoped>
$primary: #005297;
.btn-container {
  display: flex;
  justify-content: center;

  .action {
    background-color: transparent;
    color: var(--white);
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    &.disabled {
      cursor: unset;
      color: var(--grey-2);
    }

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      margin-right: 5px;
    }

    .fas.fa-angle-right {
      font-size: 18px;
    }

    .dot {
      height: 25px;
      width: 25px;
      background-color: var(--white);
      border-radius: 50%;
      display: inline-block;
      color: $primary;
    }
  }

}
</style>
