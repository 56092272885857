import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-942097ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBBtn = _resolveComponent("MDBBtn")!

  return (_openBlock(), _createBlock(_component_MDBBtn, {
    style: _normalizeStyle([(_ctx.isSelected === _ctx.enjeu.label || (_ctx.isSousEnjeuSelected() && !_ctx.isDetail))
    ? {background: _ctx.enjeu.color} : {},
    _ctx.isDetail ? {height: '60px'} : {height: '100px'}]),
    class: _normalizeClass([_ctx.disabled ? 'disabled' : '']),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_ctx.enjeu.image && !_ctx.isDetail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass([[(_ctx.isSelected === _ctx.enjeu.label || (_ctx.isSousEnjeuSelected() && !_ctx.isDetail))
        ? 'selected': ''], "enjeu-logo"]),
            alt: "enjeu logo",
            src: require(`@/assets/${_ctx.enjeu.image}.svg`)
          }, null, 10, _hoisted_1))
        : (_ctx.enjeu.label)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              style: _normalizeStyle([_ctx.isDetail && (_ctx.isSelected !== _ctx.enjeu.label)
        ? {color: _ctx.enjeu.color} : {color: '#FFF'}]),
              class: "enjeu-logo"
            }, _toDisplayString(_ctx.enjeu.label), 5))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["style", "class", "disabled"]))
}