<template>
  <notifications ref="notifications" />
  <div class="wrapper">
    <form @submit.prevent="handleSubmit">
      <h4 class="title">{{ $t('admin.utilisateur.information.title') }}</h4>
      <div class="block">
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.information.inputSurname') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <input type="text" v-model="utilisateur.lastName" required maxlength="50"><br>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.information.inputName') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <input type="text" v-model="utilisateur.firstName" required maxlength="50"><br>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.information.inputPhone') }}</span>
            </td>
            <td>
              <input
                  class="tel-form"
                  type="number"
                  v-model="utilisateur.phoneNumber"
                  maxlength="11"
                  oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              >
              <br>
              <div class="error">{{ errors.phone }}</div>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.information.inputEmail') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <input type="text" v-model="utilisateur.email" required><br>
              <div class="error">{{ errors.email }}</div>
            </td>
          </tr>
          <tr v-if="$route.params.id === 'new'">
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.password') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <input type="password" v-model="utilisateur.password" required maxlength="50"><br>
              <div class="error">{{ errors.password }}</div>
              <div class="info" v-if="!errors.password">
                {{ $t('front.newPassword.desc2') }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h4 class="title">{{ $t('admin.utilisateur.liaison.title') }}</h4>
      <div class="block">
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.liaison.profileLabel') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <select name="profile" id="profile" v-model="utilisateur.idProfile" required
                      @change="() =>
                      fetchEntities(fetchEntityTypeByProfileId(utilisateur.idProfile))"
              >
                <option
                  v-for="option in profiles" :key="option.id" :value="option.id">
                  {{ option.name }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.information.inputSurname') }}</span>
              <MDBIcon v-if="utilisateur.idProfile &&
              ( utilisateur.entityType !== ENTITY_TYPES_ENUM.UNION &&
                (utilisateur.entityType !== ENTITY_TYPES_ENUM.UNION_ADMIN ))"
                       class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <div v-if="!utilisateur.idProfile || (utilisateur.idProfile &&
               (utilisateur.entityType === ENTITY_TYPES_ENUM.UNION ||
                (utilisateur.entityType === ENTITY_TYPES_ENUM.UNION_ADMIN )))"
              >
                <input disabled />
              </div>
              <div v-else>
                <select name="name" id="name" v-model="utilisateur.idEntity"
                        required @change="fetchPackage">
                  <option
                    v-for="option in entities" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="block-package">
        <h4 class="title">{{ $t('admin.utilisateur.package.title') }}</h4>
        <span>{{ $t('admin.utilisateur.package.info') }}</span>
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.utilisateur.package.packageLabel') }}</span>
            </td>
            <td>
              <input disabled v-model="utilisateur.packageLabel" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="buttons-wrapper block">
        <Button
          type="submit"
          :label="$route.params.id === 'new'
            ? $t('common.button.create')
            : $t('common.button.save')"
          color="primary">
          <content-save class="icon" />
        </Button>
        <Button @Click="close" :label="$t('common.button.cancel')" color="secondary">
          <backspace class="icon" />
        </Button>
      </div>
    </form>
    <Button
      v-if="$route.params.id !== 'new' && !utilisateur.isProtected"
      @Click="showPopinDelete = true"
      :label="$t('admin.utilisateur.delete')"
      color="primary"
    >
      <delete class="icon" />
    </Button>
    <!-- Popin confirmation delete -->
    <ConfirmationPopin
      v-if="showPopinDelete"
      :title="$t('front.permissions.deleteProfile')"
      :content="$t('admin.utilisateur.deleteText')"
      :handleDelete="handleDelete"
      :close="closePopin"
    />
  </div>
</template>

<script lang="ts">
import EntityApi, { Entity, ENTITY_TYPES } from '@/api/entity/EntityApi';
import FournisseursApi, { Pack } from '@/api/fournisseurs/FournisseursApi';
import ProfileApi, { Profile } from '@/api/profile/ProfileApi';
import UsersApi, { User } from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import Close from '@/components/Close.vue';
import ConfirmationPopin from '@/components/ConfirmationPopin.vue';
import Drawer from '@/components/Drawer.vue';
import Notifications from '@/components/Notifications.vue';
import { HttpPlumeError } from '@/lib/plume-http/client/PlumeHttpResponse';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { validateEmail, validatePassword, validatePhone } from '@/utils/FormValidator';
import { MDBIcon, MDBInput } from 'mdb-vue-ui-kit';
import { Backspace, ContentSave, Delete } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    MDBInput,
    Drawer,
    Close,
    Button,
    Backspace,
    ContentSave,
    Delete,
    MDBIcon,
    Notifications,
    ConfirmationPopin,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);
    const usersApi = getGlobalInstance(UsersApi);
    const profileApi = getGlobalInstance(ProfileApi);
    const entityApi = getGlobalInstance(EntityApi);
    return {
      fournisseursApi,
      usersApi,
      profileApi,
      entityApi,
      ENTITY_TYPES_ENUM: ENTITY_TYPES,
      profiles: [],
      entities: [],
      packEntity: '',
      errors: {},
      utilisateur: {
        hasAcceptedCgu: false,
        isReferent: false,
        isProtected: false,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        idEntity: '',
        entityType: '',
        idProfile: '',
        profileType: '',
        packageLabel: '',
        profileName: '',
        password: '',
      },
      showPopinDelete: false,
    };
  },
  methods: {
    close() {
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      this.$router.back();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    closePopin() {
      this.showPopinDelete = false;
    },
    fetchEntityTypeByProfileId(profileId: string) {
      this.utilisateur.entityType = this.profiles
        .find((profile: Profile) => (profile.id === profileId)).entityType;
      return this.utilisateur.entityType;
    },
    fetchProfiles() {
      return this.profileApi
        .fetch()
        .then((response: Profile[]) => {
          this.profiles = response;
        });
    },
    fetchEntities(entityType: string) {
      return this.entityApi
        .fetchEntityNamesByType(entityType)
        .then((response: Entity[]) => {
          this.entities = response;
        });
    },
    fetchPackage() {
      return this.fournisseursApi
        .fetchPack(this.utilisateur.idEntity)
        .then((response: Pack) => {
          this.utilisateur.packageLabel = response.name;
        });
    },
    fetchUtilisateur(id: string) {
      return this.usersApi
        .fetchById(id)
        .then((response: User) => {
          this.utilisateur = response;
          this.fetchEntities(this.utilisateur.entityType);
        });
    },
    checkForm() {
      if (!validateEmail(this.utilisateur.email)) {
        this.errors.email = this.$t('formulaireErrors.emailValidation');
      }
      if (this.utilisateur.phoneNumber && !validatePhone(this.utilisateur.phoneNumber)) {
        this.errors.phone = this.$t('formulaireErrors.phoneValidation');
      }
      if (this.utilisateur.password && !validatePassword(this.utilisateur.password)) {
        this.errors.password = this.$t('formulaireErrors.passwordValidation');
      }
    },
    handleDelete() {
      this.usersApi.deleteUser(this.utilisateur.id)
        .then(() => {
          this.$refs.notifications.notifySuccess();
          this.close();
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorDelete')));
    },
    handleSubmit(e: Event) {
      this.errors.email = '';
      this.errors.phone = '';
      this.errors.password = '';
      this.checkForm();
      e.preventDefault();
      if (this.errors.email.length !== 0
        || this.errors.phone.length !== 0
        || this.errors.password.length !== 0) {
        return false;
      }
      if (this.$route.params.id === 'new') {
        this.usersApi.createNewUser(this.utilisateur)
          .then(() => {
            this.$refs.notifications.notifyCreation(this.$t('admin.utilisateur.creation'));
            this.close();
          })
          .catch((error: HttpPlumeError) => {
            this.$refs.notifications.notifyError(this.$t(`formulaireErrors.${error.errorCode}`));
            this.errors.email = this.$t(`formulaireErrors.${error.errorCode}`);
          });
      } else {
        if (this.utilisateur.entityType === (ENTITY_TYPES.UNION
          || ENTITY_TYPES.UNION_ADMIN)) {
          this.utilisateur.idEntity = '';
        }
        this.usersApi.updateUser(this.utilisateur)
          .then(() => {
            this.$refs.notifications.notifySuccess();
            this.close();
          })
          .catch((error: HttpPlumeError) => {
            this.$refs.notifications.notifyError(this.$t(`formulaireErrors.${error.errorCode}`));
            this.errors.email = this.$t(`formulaireErrors.${error.errorCode}`);
          });
      }
      return true;
    },
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.fetchUtilisateur(this.$route.params.id);
    }
    this.fetchProfiles();
  },
})

export default class DetailsUtilisateur extends Vue {
}

</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-smaller);
  align-items: flex-start;

  .block {
    margin-bottom: var(--spacing-bigger);
    width: 90%;
    padding-left: var(--spacing-very-big);

    input {
      width: 250px;
      outline: none;
      border: $light 1px solid;
    }

    .error {
      color: $danger;
      position: absolute;
      padding-left: var(--spacing-very-big);
      font-size: var(--font-size-hint);
    }

    .info {
      position: absolute;
      padding-left: var(--spacing-very-big);
      font-size: var(--font-size-hint);
      width: 320px;
      text-align: left;
    }
  }

  .block-package {
    display: none;
  }

  .title {
    text-transform: uppercase;
    margin: 0 0 var(--spacing-default) 0;
    padding-top: var(--spacing-default);
  }

  select {
    height: 40px;
    width: 250px;
    border: $light 1px solid;
  }

  table {
    table-layout: auto;
    width: 400px;
    border-collapse: separate;
    border-spacing: 0 var(--spacing-default);
    text-align: right;

    .text-td {
      padding-right: var(--spacing-small);
      padding-top: var(--spacing-small);
      display: flex;
      justify-content: flex-end;

      .required {
        color: $danger;
        font-size: var(--spacing-small);
        padding-left: var(--spacing-small);
      }
    }

    input {
      height: 40px;
      width: 400px;
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
  }
}
.tel-form::-webkit-outer-spin-button,
.tel-form::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
