<template>
  <form @submit.prevent="handleSearch">
    <div class="product-advanced-search">
      <div class="input-container">
        <div class="search-criteria">
          <label for="lastname">{{ $t('admin.users.search.lastname') }}</label>
          <input type="text" name="lastname" id="lastname" class="input" v-model="lastname" maxlength="50"/>
        </div>
        <div class="search-criteria">
          <label for="firstname">{{ $t('admin.users.search.firstname') }}</label>
          <input type="text" name="firstname" id="firstname" class="input" v-model="firstname" maxlength="50"/>
        </div>
      </div>
      <div class="input-container">
        <div class="search-criteria">
          <label for="profile">{{ $t('admin.users.search.profile') }}</label>
          <select name="profile" id="profile" v-model="profileSelected">
            <option v-for="option in profiles" :key="option" v-bind:value="option">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="actions">
      <Button type="submit" :label="$t('common.button.search')" color="primary">
        <magnify class="icon" />
      </Button>
      <Button :onClick="addUser" :label="$t('common.button.add')" color="secondary">
        <plus class="icon" />
      </Button>
    </div>
  </form>
</template>

<script lang="ts">
import UsersApi, { UserInfo } from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { UTILISATEUR_DETAIL_ROUTE } from '@/router/routes';
import { Magnify, Plus } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    profiles: Array,
  },
  components: {
    Button,
    Magnify,
    Plus,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    return {
      usersApi,
      lastname: '',
      firstname: '',
      profileSelected: {
        id: '',
      },
    };
  },
  methods: {
    handleSearch() {
      this.usersApi.findUsersByCriterion(this.lastname, this.firstname, this.profileSelected.id)
        .then((response: UserInfo[]) => {
          this.$emit('getSearchList', response);
        });
    },
    addUser() {
      this.$router.push({
        name: UTILISATEUR_DETAIL_ROUTE,
        params: { id: 'new' },
      });
    },
  },
})
export default class UserSearch extends Vue {
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
form {
  width: 96%;
}
.product-advanced-search {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 16px;
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 65%;
  text-align: center;
}
.search-criteria {
  width: 100%;
}

span {
  padding: 4px;
}
.search-button {
  margin: auto;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    margin: var(--spacing-small);
  }
}
</style>
