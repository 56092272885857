<template>
  <div class="fournisseur-management">
    <router-view/>
    <h3 class="page-title">{{ $t('admin.fournisseur.title') }}</h3>
    <div class="fournisseur-search">
      <div class="searchbar">
        <SearchbarComponent
          class="fournisseur-searchbar"
          :text="$t('admin.fournisseur.search')"
        />
      </div>
      <Button :onClick="fetchFournisseurs" :label="$t('common.button.search')" color="dark">
        <magnify class="icon"/>
      </Button>
    </div>
    <div class="fournisseur-list">
       <span class="search-results" v-if="!dataLoaded">
        {{ $t('common.loader')}}
      </span>
       <span class="search-results" v-else>
        {{ $t('admin.users.search.results', { count: fournisseurs.length }) }}
      </span>
      <hr/>
      <div
        class="tile"
        v-for="fournisseur in fournisseurs.slice(0, tileToDisplay)"
        :key="fournisseur.id"
      >
        <FournisseurTile :fournisseur="fournisseur"/>
      </div>
      <div class="spinner-border" role="status" v-if="!dataLoaded"/>
    </div>
    <Pagination
      v-if="tileToDisplay !== fournisseurs.length && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="fournisseurs.length"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Eye, Magnify } from 'mdue';
import FournisseurTile from '@/views/admin/fournisseur/FournisseurTile.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import FournisseursApi, { Fournisseur } from '@/api/fournisseurs/FournisseursApi';
import Pagination from '@/components/Pagination.vue';
import SearchbarComponent from '@/components/SearchbarComponent.vue';

@Options({
  components: {
    Button,
    FournisseurTile,
    Eye,
    Magnify,
    Pagination,
    SearchbarComponent,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);
    return {
      fournisseursApi,
      fournisseurs: [],
      nbrTileDefault: 15,
      tileToDisplay: this.nbrTileDefault,
      search: '',
      dataLoaded: false,
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    handleSearch() {
      this.fetchFournisseurs();
    },
    fetchFournisseurs() {
      return this.fournisseursApi
        .fetch(this.search)
        .then((response: Fournisseur[]) => {
          this.dataLoaded = true;
          this.fournisseurs = response;
          const displayedTiles = Math.max(this.tileToDisplay || 0, this.nbrTileDefault);
          this.tileToDisplay = Math.min(displayedTiles, response.length);
        });
    },
  },
  mounted() {
    this.fetchFournisseurs();
  },
  watch: {
    $route() {
      this.$nextTick(this.fetchFournisseurs);
    },
  },
})
export default class FournisseurManagement extends Vue {
}
</script>

<style lang="scss" scoped>

.fournisseur-management {
  width: 100%;

  .page-title {
    text-align: left;
    padding: var(--spacing-default);
  }

  .fournisseur-search {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--spacing-bigger) 5% 0;

    .searchbar {
      font-family: "SourceSansProLight", sans-serif;
      width: 70%;
    }

    .button {
      margin-top: var(--spacing-default);
    }
  }

  .fournisseur-list {
    width: 99%;
    padding: var(--spacing-default);
    text-align: left;
    .spinner-border {
      margin: 10% 48%;
    }
    .search-results {
      font-size: 18px;
      font-family: 'LatoLight', sans-serif;
    }

    .tile {
      margin-bottom: var(--spacing-default);
    }
  }
}

</style>
