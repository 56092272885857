export type TutorialItem = {
  text: string;
  imageUrl: string;
}
export const TutorialItems: TutorialItem[] = [
  {
    text: 'Tutoriel 1 : Comment utiliser la page produit',
    imageUrl: 'https://pbs.twimg.com/profile_images/1323175717341941760/une6eINh_400x400.jpg',
  },
  {
    text: 'Tutoriel 2 : Comment utiliser les filtres',
    imageUrl: 'https://pbs.twimg.com/profile_images/1323175717341941760/une6eINh_400x400.jpg',

  },
  {
    text: 'Tutoriel 3 : Comment mettre en balance les valeurs sociétales',
    imageUrl: 'https://pbs.twimg.com/profile_images/1323175717341941760/une6eINh_400x400.jpg',
  },
];
