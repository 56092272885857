<template>
  <form @submit.prevent="handleSearch">
    <div class="product-advanced-search">
      <div class="input-container">
        <div class="search-criteria">
          <label class="criteria-label" for="market">{{ $t('front.product.advancedSearch.market') }}</label>
          <select name="market" id="market" v-model="criterion.market">
            <option v-for="option in market" :key="option" :value="option">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="search-criteria">
          <label class="criteria-label" for="segment">{{ $t('front.product.advancedSearch.segment') }}</label>
          <select name="segment" id="segment" v-model="criterion.segment">
            <option v-for="option in segment" :key="option">{{ option.label }}</option>
          </select>
        </div>
        <div class="search-criteria">
          <label class="criteria-label" for="segmentSpecific">{{ $t('front.product.advancedSearch.segmentSpecific') }}</label>
          <select name="segmentSpecific" id="segmentSpecific" v-model="criterion.segmentSpecific">
            <option v-for="option in segmentSpecific" :key="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="input-container">
        <div class="search-criteria">
          <label class="criteria-label" for="culture">{{ $t('front.product.advancedSearch.culture') }}</label>
          <select name="culture" id="culture" v-model="criterion.culture">
            <option v-for="option in culture" :key="option">{{ option }}</option>
          </select>
        </div>
        <div class="search-criteria">
          <label class="criteria-label" for="composition">{{ $t('front.product.advancedSearch.composition') }}</label>
          <select name="composition" id="composition" v-model="criterion.composition">
            <option v-for="option in composition" :key="option">{{ option }}</option>
          </select>
        </div>
        <div class="search-criteria">
          <label class="criteria-label" for="supplier">{{ $t('front.product.advancedSearch.supplier') }}</label>
          <select name="supplier" id="supplier" v-model="criterion.supplier">
            <option v-for="option in supplier" :key="option">{{ option }}</option>
          </select>
        </div>
      </div>
    </div>
    <Button type="submit" :label="$t('common.button.search')" color="primary" class="search-button"
    :disabled="disabled">
      <magnify class="icon" />
    </Button>
  </form>
</template>

<script lang="ts">
import ProductsAdminApi from '@/api/products/ProductsAdminApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Magnify } from 'mdue';
import { ProductResultList } from '@/api/products/ProductsApi';

@Options({
  props: {
    market: Array,
    segment: Array,
    composition: Array,
    culture: Array,
    segmentSpecific: Array,
    supplier: Array,
    disabled: Boolean,
  },
  components: {
    Button,
    Magnify,
  },
  data() {
    const productsAdminApi = getGlobalInstance(ProductsAdminApi);
    return {
      productsAdminApi,
      criterion: {
        market: '',
        segment: '',
        composition: '',
        culture: '',
        segmentSpecific: '',
        supplier: '',
      },
    };
  },
  emits: ['getCriterion'],
  methods: {
    handleSearch() {
      this.$parent.products = [];
      this.$parent.logoLoaded = false;
      const market: string = ((!!this.criterion.market && this.criterion.market.label.toLowerCase() === 'tous') || (this.criterion.market === '')) ? '' : this.criterion.market.name;
      const segment: string = !!this.criterion.segment && this.criterion.segment.toLowerCase() === 'tous' ? '' : this.criterion.segment;
      const composition: string = !!this.criterion.composition && this.criterion.composition.toLowerCase() === 'tous' ? '' : this.criterion.composition;
      const culture: string = !!this.criterion.culture && this.criterion.culture.toLowerCase() === 'tous' ? '' : this.criterion.culture;
      const segmentSpecific: string = !!this.criterion.segmentSpecific && this.criterion.segmentSpecific.toLowerCase() === 'tous'
        ? ''
        : this.criterion.segmentSpecific;

      const supplier: string = !!this.criterion.supplier && this.criterion.supplier.toLowerCase() === 'tous' ? '' : this.criterion.supplier;
      this.$parent.criterion = {
        market,
        segment,
        composition,
        culture,
        segmentSpecific,
        supplier,
        limit: this.$parent.nbrTileDefault,
        offset: this.$parent.products.length,
      };
      this.$parent.dataLoaded = false;
      this.productsAdminApi
        .fetchAdmin({
          market,
          segment,
          composition,
          culture,
          segmentSpecific,
          supplier,
          limit: this.$parent.nbrTileDefault,
          offset: 0,
          productSort: this.$parent.sortUsed,
        })
        .then((response: ProductResultList) => {
          this.$parent.logoLoaded = true;
          this.$parent.dataLoaded = true;
          this.$parent.products = response.productsList;
          this.$parent.nbrResultProducts = response.nbrProducts;
          this.$parent.tileToDisplay = Math.min(this.$parent.nbrTileDefault, response.nbrProducts);
          if (market !== '' || segment !== '' || composition !== '' || culture !== '' || segmentSpecific !== '' || supplier !== '') {
            const criterionArray = [
              this.criterion.market.label,
              segment,
              composition,
              culture,
              segmentSpecific,
              supplier,
            ].filter((criteria) => criteria !== '');
            this.$emit(
              'getCriterion',
              `"${criterionArray.join('; ')}"`,
            );
          } else {
            this.$emit('getCriterion', '');
          }
        });
    },
  },
})
export default class ProductAdvancedSearch extends Vue {
  market!: string[];

  segment!: string[];

  composition!: string[];

  culture!: string[];

  segmentSpecific!: string[];

  supplier!: string[];

  marketSelected!: string;

  segmentSelected!: string;

  compositionSelected!: string;

  cultureSelected!: string;

  segmentSpecificSelected!: string;

  supplierSelected!: string;
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
form {
  margin-bottom: 2rem;
  width: 96%;
}

.product-advanced-search {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 16px;
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 65%;
  text-align: center;
}
.search-criteria {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .criteria-label {
    width: 25%;
  }
}
span {
  padding: 4px;
}
.search-button {
  margin: auto;
}
</style>
