import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54dad349"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "desc" }
const _hoisted_4 = {
  key: 0,
  class: "video"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('front.balanceSocietale.accueil.bouton.desc')), 1)
      ]),
      (_ctx.videoLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("video", {
              width: "600",
              controls: "",
              src: _ctx.videoLink
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      (_ctx.havePermissionLancerBalance())
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Button, {
              class: "action",
              label: _ctx.$t('front.balanceSocietale.accueil.bouton.title'),
              onClick: _ctx.handleClick
            }, null, 8, ["label", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}