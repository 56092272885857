<template>
  <MDBBtn v-bind:class="getButtonClass()">
    <div class="label" v-bind:style="{color: color}">
      {{ label }}
    </div>
  </MDBBtn>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MDBBtn } from 'mdb-vue-ui-kit';

@Options({
  components: {
    MDBBtn,
  },
  props: {
    disabled: Boolean,
    color: String,
    label: String,
    selected: Boolean,
    locked: Boolean,
  },
  methods: {
    getButtonClass() {
      if (this.selected) {
        return 'selected';
      }
      if (this.disabled) {
        return 'disable';
      }
      if (this.locked) {
        return 'locked';
      }
      return '';
    },
  },
})

export default class ButtonNoBorder extends Vue {
  selected?: boolean;
}
</script>

<style lang="scss" scoped>
.btn {
  height: var(--btn-height-big) !important;
  margin: 0;

  &.selected {
    box-shadow: 0 3px 6px var(--grey-shadow) !important;
    border-radius: 0;
  }

  &.disable {
    background-color: var(--grey-2);
    cursor : unset;
  }

  &.locked {
    cursor : not-allowed;
  }

  .label {
    font-family: "TTNormsBold";
    font-size: var(--font-size-smaller);
  }
}
</style>
