<template>
  <MDBBtn
    class="button"
    v-on:click="onClick"
    v-bind:color="color ? color : 'white'"
    :style="style"
    :disabled="loading"
  >
    <div class="spinner-container" v-if="loading" >
      <div class="spinner-border" role="status" />
    </div>
    <slot class="slot" />
    <span class="text">{{ label }}</span>
  </MDBBtn>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MDBBtn } from 'mdb-vue-ui-kit';

@Options({
  components: {
    MDBBtn,
  },
  props: {
    label: String,
    onClick: Function,
    color: String,
    loading: Boolean,
  },
  computed: {
    style() {
      if (this.color === 'dark' || this.color === 'primary') {
        return 'border: none;';
      }
      if (this.color === 'secondary') {
        return 'color: #606060';
      }
      return '';
    },
  },
})
export default class Button extends Vue {
  label!: string;
}
</script>

<style lang="scss" scoped>
.button {
  border-radius: 2px;
  text-transform: uppercase;
  padding: 5px 10px;
  border: solid var(--grey-1) 1px;
  border-top: none;
  margin: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .spinner-container {
    margin-right: 10px;

    .spinner-border {
      height: 30px;
      width: 30px;
    }
  }
}

</style>
