import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e77359c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popin-container" }
const _hoisted_2 = { class: "bandeau" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "note-filter-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "compo-choice"
}
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "checkbox-label" }
const _hoisted_10 = { class: "checkbox-label" }
const _hoisted_11 = { class: "espace-recherche" }
const _hoisted_12 = {
  key: 2,
  class: "panier"
}
const _hoisted_13 = {
  key: 3,
  class: "espace-resultat"
}
const _hoisted_14 = {
  key: 0,
  class: "spinner-border",
  role: "status"
}
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderRangeComponent = _resolveComponent("SliderRangeComponent")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_checkbox_marked_outline = _resolveComponent("checkbox-marked-outline")!
  const _component_checkbox_blank_outline = _resolveComponent("checkbox-blank-outline")!
  const _component_ResearchBarFiltre = _resolveComponent("ResearchBarFiltre")!
  const _component_TagFiltrePanier = _resolveComponent("TagFiltrePanier")!
  const _component_BalanceTileFiltre = _resolveComponent("BalanceTileFiltre")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("i", {
      class: "fas fa-caret-left",
      style: _normalizeStyle(_ctx.cssVars)
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.title',
          { type: _ctx.filterFieldSelected.label.toUpperCase() }
        )), 1),
      (_ctx.isNotes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ENJEUX, (enjeu, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                (_ctx.checkPermissions(enjeu.permissions))
                  ? (_openBlock(), _createBlock(_component_Accordion, {
                      key: 0,
                      isPopinFiltre: "true",
                      isEnjeuSpe: _ctx.isEnjeuSpe(enjeu.name),
                      index: index,
                      title: enjeu.label,
                      isActive: _ctx.accordionOpenedIndex === index,
                      iconRight: "angle-right",
                      onClick: ($event: any) => (_ctx.handleClickAccordion(index, $event)),
                      type: "unique",
                      class: _normalizeClass(`${enjeu.name}`)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_SliderRangeComponent, {
                            noteLabel: enjeu.name,
                            initialSliderMin: _ctx.getMinByType(enjeu.name),
                            initialSliderMax: _ctx.getMaxByType(enjeu.name)
                          }, null, 8, ["noteLabel", "initialSliderMin", "initialSliderMax"]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(enjeu.sousEnjeux, (sousEnjeu) => {
                            return (_openBlock(), _createElementBlock("div", { key: sousEnjeu }, [
                              (_ctx.checkPermissions(sousEnjeu.permissions))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("span", {
                                      class: _normalizeClass(`note-filter note-subtitle note-filter--${enjeu.name}`)
                                    }, _toDisplayString(sousEnjeu.label), 3),
                                    _createVNode(_component_SliderRangeComponent, {
                                      noteLabel: sousEnjeu.name,
                                      initialSliderMin: _ctx.getMinByType(sousEnjeu.name),
                                      initialSliderMax: _ctx.getMaxByType(sousEnjeu.name)
                                    }, null, 8, ["noteLabel", "initialSliderMin", "initialSliderMax"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["isEnjeuSpe", "index", "title", "isActive", "onClick", "class"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.isCompo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.associationChoice')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "checkbox",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAlone && _ctx.handleAlone(...args)))
                    }, [
                      (_ctx.showAlone())
                        ? (_openBlock(), _createBlock(_component_checkbox_marked_outline, {
                            key: 0,
                            class: "icon"
                          }))
                        : (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
                            key: 1,
                            class: "icon"
                          }))
                    ]),
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.alone')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      class: "checkbox",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAssociated && _ctx.handleAssociated(...args)))
                    }, [
                      (_ctx.showAssociated())
                        ? (_openBlock(), _createBlock(_component_checkbox_marked_outline, {
                            key: 0,
                            class: "icon"
                          }))
                        : (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
                            key: 1,
                            class: "icon"
                          }))
                    ]),
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.associated')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_ResearchBarFiltre, {
                onHandleSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearch($event))),
                inputValue: _ctx.inputSearchValue
              }, null, 8, ["inputValue"])
            ])
          ])),
      (!_ctx.isNotes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterFieldSelected.filters, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.label
              }, [
                _createVNode(_component_TagFiltrePanier, {
                  product: item.label,
                  onClearItem: ($event: any) => (_ctx.clearItem(index, item.label))
                }, null, 8, ["product", "onClearItem"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isNotes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (!_ctx.$parent.dataLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "elements-resulat",
                      key: product
                    }, [
                      _createVNode(_component_BalanceTileFiltre, {
                        label: product.label,
                        type: product.type,
                        checked: (_ctx.filterFieldSelected.filters).some((item) => item.label === product.label),
                        onAddItem: ($event: any) => (_ctx.addItem(product.type, product.label)),
                        onRemoveFilter: ($event: any) => (_ctx.clearItem($event, product.label))
                      }, null, 8, ["label", "type", "checked", "onAddItem", "onRemoveFilter"])
                    ]))
                  }), 128))
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}