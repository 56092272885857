import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5709071e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infos" }
const _hoisted_2 = { class: "product" }
const _hoisted_3 = {
  key: 0,
  class: "filter-label"
}
const _hoisted_4 = {
  key: 1,
  class: "filter-label"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "balance-tile-container",
    style: _normalizeStyle([{ background: _ctx.getBackgroundColor() }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "color-band",
          style: _normalizeStyle(_ctx.getColorFromType(_ctx.product.type))
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.product.label?.toLowerCase()), 1)
        ], 4),
        (_ctx.globalFilters && _ctx.globalFilters.length > 0 && !_ctx.isProduct)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t('front.balanceSocietale.creation.products',
              {
                criterions: _ctx.getCriterionText(_ctx.globalFilters),
                association: _ctx.getAssociationText(),
                count: _ctx.product.productIdList.length,
                nbr: _ctx.product.productIdList.length
              }
            )), 1))
          : (!_ctx.isProduct)
            ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.$t('front.balanceSocietale.creation.productsList',
              {
                count: _ctx.product.productIdList.length,
                nbr: _ctx.product.productIdList.length,
              }
            )), 1))
            : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isInBasket)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          onClick: _ctx.removeProduct,
          class: "action remove-btn"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: "balance",
              src: require('@/assets/logo_balance_blanc.png')
            }, null, 8, _hoisted_5),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('common.button.remove')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (!_ctx.isInBasket && _ctx.havePermissionAddToBalance())
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          onClick: _ctx.addProduct,
          class: "action"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: "balance",
              src: require('@/assets/logo_balance_bleu.png')
            }, null, 8, _hoisted_7),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('common.button.add')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 4))
}