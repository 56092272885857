import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e2f483f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color-preview-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorModel) = $event)),
      maxlength: "7",
      placeholder: "#000000"
    }, null, 512), [
      [_vModelText, _ctx.colorModel]
    ]),
    _withDirectives(_createElementVNode("input", {
      class: "color-preview",
      type: "color",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.colorModel) = $event))
    }, null, 512), [
      [_vModelText, _ctx.colorModel]
    ])
  ]))
}