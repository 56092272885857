<template>
  <div class="title">
    <h3>{{ $t('front.balanceSocietale.resultats.title') }}</h3>
    <div>{{ $t('front.balanceSocietale.resultats.desc') }}</div>
  </div>
  <div class="espace-resultat">
    <div class="graph">
      <div class="container-selection" v-if="enjeuxList">
        <template v-for="(enjeu, index) in enjeuxList" :key="enjeu">
          <div class="selection">
            <ButtonEnjeuBalance
              :enjeu="enjeu"
              :isDetail="false"
              :isSelected="enjeuSelected.label"
              @click="handleSelection(enjeu)"
              :disabled="!isInPermissions(enjeu)"
            />
            <div v-if="enjeu && enjeu.label === enjeuSelected.label && index !== 0
            || isSousEnjeuSelected(enjeu)"
                 class="triangle"/>
          </div>
          <div class="detail-container"
               v-if="(enjeuSelected.label === enjeu.label ||
               isSousEnjeuSelected(enjeu)) && enjeu.sousEnjeux"
          >
            <div class="enjeu">
              <ButtonEnjeuBalance
                :enjeu="enjeu"
                :isDetail="true"
                :isSelected="enjeuSelected.label"
                @click="handleSelection(enjeu)"
              />
            </div>
            <i class="fas fa-angle-right" :style="{color: enjeu.color}"></i>
            <div class="enjeu">
              <div class="sous-enjeux">
                <div v-for="(sousEnjeu) in enjeu.sousEnjeux" :key="sousEnjeu">
                  <ButtonEnjeuBalance
                    :isSelected="enjeuSelected.label"
                    :enjeu="getSousEnjeu(enjeu, sousEnjeu)"
                    :isDetail="true"
                    @click="handleSelection(sousEnjeu)"
                    :disabled="!isInPermissions(sousEnjeu)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <GraphResultat
        :basket="basketProducts"
        :enjeuSelected="getEnjeuSelected()"
        v-if="showGraph()"
      />
      <div v-else>{{ $t('front.balanceSocietale.resultats.graph.permissions') }}</div>
    </div>
    <Panier
      class="panier"
      :isResultatBalance="true"
      :basket="basketProducts"
      :errorLimit="errorLimit"
      v-if="havePermissionAfficherProduit()"
      v-on:clearAll="clearAll"
      v-on:clearItem="clearItem($event)"
    />
    <Popin
      v-if="showPopin"
      :title="$t('front.balanceSocietale.resultats.panier.popin.title')"
      :hasCancel="true"
      :color="`#005297`"
      :isFront="true"
    >
      <template v-slot:container>
        <p class="text">
          {{ $t('front.balanceSocietale.resultats.panier.popin.desc') }}
        </p>
        <p class="text">
          {{ $t('front.balanceSocietale.resultats.panier.popin.desc-bis') }}
        </p>
      </template>
      <template v-slot:button>
        <div class="btn-container">
          <Button
            class="action"
            :label="$t('front.balanceSocietale.resultats.panier.popin.bouton')"
            :onClick="confirmClearAll"
          />
        </div>
      </template>
    </Popin>
  </div>
  <ProductDetailsFront v-if="displayDetails !== 0" v-bind:idProduct="displayDetails"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Panier from '@/views/front/balance-societale/Panier.vue';
import { inject } from 'vue';
import GraphResultat from '@/views/front/balance-societale/GraphResultat.vue';
import { Enjeu, ENJEUX_TYPES, SousEnjeu } from '@/api/products/ProductsApi';
import { BALANCE_SOCIETALE_RECHERCHE_ROUTE } from '@/router/routes';
import Popin from '@/components/Popin.vue';
import Button from '@/components/Button.vue';
import ButtonEnjeuBalance from '@/views/front/balance-societale/ButtonEnjeuBalance.vue';
import ProductDetailsFront from '@/views/front/ProductDetailsFront.vue';
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';

@Options({
  components: {
    ButtonEnjeuBalance,
    Panier,
    GraphResultat,
    Popin,
    Button,
    ProductDetailsFront,
  },
  data() {
    const basketProducts = inject('basketProducts');
    const enjeuxList: Enjeu[] = ENJEUX_TYPES;
    const authorization = inject('authorization');
    return {
      displayDetails: 0,
      showPopin: false,
      basketProducts,
      errorLimit: false,
      enjeuSelected: ENJEUX_TYPES[0],
      enjeuxList,
      authorization,
      permissions: [],
    };
  },
  methods: {
    isInPermissions(enjeu: Enjeu) {
      return this.permissions.includes(enjeu.permissions[1]);
    },
    havePermissionAfficherProduit() {
      return this.permissions.includes(PERMISSIONS.AFFICHER_PRODUIT_BALANCE);
    },
    showGraph(): boolean {
      return this.isInPermissions(this.enjeuSelected);
    },
    handleSelection(enjeuToSelect: Enjeu) {
      this.enjeuSelected = enjeuToSelect;
    },
    clearAll() {
      this.showPopin = true;
    },
    confirmClearAll() {
      this.basketProducts.splice(0, this.basketProducts.length);
      this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE });
    },
    clearItem(index: number) {
      if (this.basketProducts.length === 1) {
        this.showPopin = true;
      } else {
        this.basketProducts.splice(index, 1);
        if (this.basketProducts.length === 0) {
          this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE });
        }
      }
    },
    getEnjeuSelected() {
      let filterByEnjeu = this.enjeuxList
        .filter((enjeu: Enjeu) => enjeu.label === this.enjeuSelected.label);
      if (filterByEnjeu.length === 0) {
        const filterByEnjeuTemp = this.enjeuxList
          .filter((enjeu: Enjeu) => (enjeu.sousEnjeux
            && enjeu.sousEnjeux.some((e: SousEnjeu) => e.label === this.enjeuSelected.label)));
        filterByEnjeu = [{
          label: this.enjeuSelected.label,
          color: filterByEnjeuTemp[0].color,
          permissions: filterByEnjeuTemp[0].permissions,
        }];
      }
      return filterByEnjeu[0];
    },
    getSousEnjeu(enjeu: Enjeu, sousEnjeu: SousEnjeu) {
      return {
        ...sousEnjeu,
        color: enjeu.color,
      };
    },
    isSousEnjeuSelected(enjeu: Enjeu) {
      return enjeu.sousEnjeux !== undefined
        && enjeu.sousEnjeux
          .filter((sousEnjeu: SousEnjeu) => sousEnjeu.label === this.enjeuSelected.label).length > 0;
    },
  },
  mounted() {
    if (this.basketProducts.length === 0) {
      this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE });
    }
    this.permissions = parseJwt(this.authorization);
  },
})

export default class BalanceComparateur extends Vue {
}
</script>

<style lang="scss" scoped>
$primary: #005297;
.espace-resultat {
  display: flex;
  flex-direction: row;
  padding: 50px 5% 50px 0;
  justify-content: space-between;
  position: relative;

  .btn-container {
    display: flex;
    justify-content: center;

    .action {
      padding: 20px;
      background-color: $primary;
      color: var(--white);
      border: none;
      box-shadow: none !important;
      min-width: min-content;
    }
  }

  .graph {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'TTNormsRegular', sans-serif;

    .container-selection {
      margin: 0 10% 11% 20%;
      width: 700px;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      max-width: 70%;
      position: relative;
    }
  }

  .panier {
    width: 22%;
  }
}

.title {
  font-family: 'TTNormsRegular', sans-serif;

  h3 {
    color: $balance-primary;
  }
}

.selection {
  position: relative;
  width: 150px;

  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    top: calc(100% + 1rem);
    left: 50%;
    box-sizing: border-box;
    font-size: 8px;
    border: 1em solid black;
    border-color: transparent transparent;
    transform-origin: 0 0;
    transform: translateX(-50%) rotate(-45deg);
    background-color: var(--grey-1);
    z-index: 1;
  }
}

.detail-container {
  position: absolute;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  padding: 12px 15px;
  align-items: center;
  background-color: var(--grey-1);
  top: calc(100% + 1rem);
  left: calc(50% + 100px);
  transform: translateX(-50%);

  i {
    margin-left: 15px;
    font-size: var(--font-size-medium)
  }

  .enjeu {
    .title {
      color: var(--balance-blue);
      font-size: 15px;
      font-family: "TTNormsMedium", sans-serif;
    }

    .sous-enjeux {
      display: flex;
      justify-content: space-around;
      width: 350px;
    }
  }
}

</style>
