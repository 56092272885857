<template>
  <notifications ref="notifications"/>
  <div class="wrapper">
    <h4 class="title">{{ $t('admin.cooperative.gamme.list') }}</h4>
    <div class="wrapper">
      <div>
        <div class="searchbar">
          <SearchbarComponent :text="$t('admin.cooperative.gamme.search')"/>
        </div>
        <div class="search-option">
        <span>
          {{ $t('admin.cooperative.gamme.searchOption') }}
        </span>
          <div class="radio">
            <MDBRadio
              :label="$t('admin.fournisseur.gammeProduct')"
              :formCheck="false"
              name="radio"
              v-model="radioRef"
              :value="true"
            />
          </div>
          <div class="radio">
            <MDBRadio
              :label="$t('admin.fournisseur.excludeGammeProduct')"
              :formCheck="false"
              name="radio"
              v-model="radioRef"
              :value="false"
              color="primary"
            />
          </div>
        </div>
        <div class="action-button">
          <Button :onClick="handleSearch"
                  type="submit" :label="$t('common.button.search')"
                  color="dark"
                  class="search-button"
          >
            <magnify class="icon"/>
          </Button>
        </div>
      </div>
    </div>
    <div class="actions">
      <span class="search-results" v-if="!dataLoaded">
      {{ $t('common.loader') }}
      </span>
      <span class="search-results" v-else>
        {{
          $t('front.product.productManagement.results', { count: nbrResultProducts })
        }}
      </span>
    </div>
    <hr/>
    <div class="action-button action-button--space">
      <ProductSort ref="ProductSort" :sortUsed="sortUsed" @set-sort="(newSort: string) => (this.sortUsed = newSort)"/>
      <div class="button-container">
        <Button :onClick="() => showPopin = true"
                type="button" :label="$t('front.product.addGamme')"
                color="dark"
                class="search-button"
                :disabled="!checkedList || checkedList.length === 0 || !canAdd()"
        >
          <playlistPlus class="icon"/>
        </Button>
        <!-- Popin confirmation products add to gamme -->
        <Popin
          v-if="showPopin === true"
          :title="$t('front.product.popin.cooperative.favoriteTitle')"
          :hasCancel="true"
        >
          <template v-slot:container>
            <p class="text" v-if="canAdd() && canRemove()">
              {{ $t('front.product.popin.cooperative.favoriteMultiType') }}
            </p>
            <p class="text" v-else-if="canRemove()">
              {{ $t('front.product.popin.cooperative.removeFavorite',
              { count: checkedList.length }) }}
            </p>
            <p class="text" v-else>
              {{ $t('front.product.popin.cooperative.addFavorite', { count: checkedList.length }) }}
            </p>
          </template>
          <template v-slot:button v-if="checkedList.length > 0 && !(canAdd() && canRemove())">
            <Button
              v-if="canAdd()"
              :label="$t('common.button.add')"
              color="primary"
              :onClick="() => updateGamme()"
            >
              <plus class="icon"/>
            </Button>
            <Button
              v-else
              :label="$t('common.button.remove')"
              color="primary"
              :onClick="() => updateGamme()"
            >
              <minus class="icon"/>
            </Button>
          </template>
        </Popin>
        <Button :onClick="() => showPopin = true"
                type="button" :label="$t('front.product.removeGamme')"
                color="white"
                class="search-button"
                :disabled="!checkedList || checkedList.length === 0 || !canRemove()"
        >
          <playlistRemove class="icon"/>
        </Button>
      </div>
    </div>
    <div class="select-container" v-if="nbrResultProducts > 0">
      <label for="checkboxAll">
        {{ checkedAll
            ? $t('front.product.productManagement.uncheckedAll')
            : $t('front.product.productManagement.checkedAll', { count: checkedList.length })
        }}
      </label>
      <button class="checkbox" id="checkboxAll" @click="checkAll">
        <checkbox-marked v-if="checkedAll" class="icon"/>
        <checkbox-blank-outline v-if="!checkedAll" class="icon"/>
      </button>
    </div>
    <div class="products-box" v-if="dataLoaded">
    <div
      class="products-container"
      v-for="entityProduct in products"
      :key="entityProduct.product.name">
      <ProductTile
        :product="entityProduct"
        :canCompare="false"
        :canRemoveFavorite="false"
        :canDownloadLogo="false"
        :canCheck="true"
        :checked="checkedList.includes(entityProduct.product.id)"
        :showCheckbox="true"
      />
    </div>
  </div>
    <div v-else class="spinner-border" role="status" />
    <Pagination
      v-if="tileToDisplay < nbrResultProducts && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="products.length"
    />
  </div>
</template>

<script lang="ts">
import CooperativesApi from '@/api/cooperatives/CooperativesApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import SearchbarComponent from '@/components/SearchbarComponent.vue';
import Drawer from '@/components/Drawer.vue';
import Button from '@/components/Button.vue';
import { MDBRadio } from 'mdb-vue-ui-kit';
import {
  Magnify, PlaylistPlus, PlaylistRemove, CheckboxBlankOutline, CheckboxMarked,
} from 'mdue';
import { inject, ref } from 'vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import Notifications from '@/components/Notifications.vue';
import ProductSort from '@/components/ProductSort.vue';
import Popin from '@/components/Popin.vue';
import ProductTile from '@/components/ProductTile.vue';
import Pagination from '@/components/Pagination.vue';
import { EntityProductDetails, EntityProductDetailsPaginated, PRODUCT_SORT } from '@/api/fournisseurs/FournisseursApi';

@Options({
  components: {
    SearchbarComponent,
    Drawer,
    Button,
    Magnify,
    MDBRadio,
    PlaylistPlus,
    PlaylistRemove,
    CheckboxBlankOutline,
    CheckboxMarked,
    Notifications,
    ProductSort,
    Popin,
    ProductTile,
    Pagination,
  },
  data() {
    const cooperativesApi = getGlobalInstance(CooperativesApi);

    // TODO: Rendre ça dynamique quand on aura nos users
    const radioRef = ref(true);
    const authorization = inject('authorization');

    return {
      cooperativesApi,
      radioRef,
      nbrTileDefault: 15,
      tileToDisplay: 15,
      products: [],
      nbrResultProducts: 0,
      checkedAll: false,
      checkedList: [],
      isActionAdd: false,
      dataLoaded: false,
      showPopin: false,
      filteredList: [],
      sortUsed: PRODUCT_SORT.TrierPar,
      search: '',
      authorization,
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    fetchProducts() {
      this.dataLoaded = false;
      this.cooperativesApi.fetchProductByEntityId(
        this.$route.params.id,
        this.radioRef,
        this.search,
        this.nbrTileDefault,
        this.products.length,
        this.sortUsed,
      )
        .then((response: EntityProductDetailsPaginated) => {
          this.dataLoaded = true;
          this.products = [...this.products, ...response.entityProductDetailsList];
          this.nbrResultProducts = response.nbrResults;
        });
    },
    closePopin() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.showPopin = false;
    },
    updateGamme() {
      this.cooperativesApi.updateGamme(
        this.$route.params.id, this.checkedList, this.radioRef,
      )
        .then((response: EntityProductDetailsPaginated) => {
          this.$refs.notifications.notifyCreation(this.$t('front.product.popin.gamme'));
          this.products = response.entityProductDetailsList;
          this.nbrResultProducts = response.nbrResults;
        });
      // empty previous checkedList
      this.checkedList = [];
      this.closePopin();
    },
    handleSearch() {
      this.products = [];
      this.fetchProducts();
    },
    canAdd() {
      const checkedEntityProducts = this.products
        .filter((entityProduct: EntityProductDetails) => this.checkedList
          .includes(entityProduct.product.id));
      const notFavorite = checkedEntityProducts
        .filter((entityProduct: EntityProductDetails) => !entityProduct.isGamme);
      return notFavorite.length > 0;
    },
    canRemove() {
      const checkedEntityProducts = this.products
        .filter((entityProduct: EntityProductDetails) => this.checkedList
          .includes(entityProduct.product.id));
      const alreadyFavorite = checkedEntityProducts
        .filter((entityProduct: EntityProductDetails) => entityProduct.isGamme);
      return alreadyFavorite.length > 0;
    },
    checkAll() {
      this.checkedAll = !this.checkedAll;
      this.checkedList = [];
      if (this.checkedAll) { // Check all
        this.products.forEach((item: EntityProductDetails) => {
          this.checkedList.push(item.product.id);
        });
      }
    },
    showMore() {
      this.fetchProducts();
    },
  },
  mounted() {
    this.handleSearch();
  },
})
export default class CooperativeGamme extends Vue {
}
</script>

<style lang="scss">
#app .products-box .products-container .tile-container {
  cursor: default;
}
</style>

<style lang="scss" scoped>
$search-width: 800px;
$search-max-width: 815px;

.wrapper {
  margin: var(--spacing-bigger);
  display: flex;
  align-items: center;
  flex-direction: column;

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .search-results {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      max-width: 30rem;
      font-family: 'LatoLight', sans-serif;
    }
  }

  hr {
    width: 100%;
    color: black;
  }

  .action-button {
    display: flex;
    justify-content: center;

    &.action-button--space {
      width: 95%;
      justify-content: space-between;
      margin: var(--spacing-medium) 0 var(--spacing-default);

      .button-container {
        display: flex;

        button {
          margin-left: var(--spacing-big);
        }
      }
    }
  }

  .select-container {
    text-align: end;
    width: 95%;
    margin-bottom: -5px;

    label {
      font-size: var(--font-size-hint);
    }

    .checkbox {
      margin: 0 var(--spacing-small);
      border: none;
      background: none;
      height: fit-content;
      padding: 0;
      z-index: 30;
    }
  }
  .products-box {
    width: 100%;
    .products-container {
      width: 95%;
      .tile-container {
        .score {
          img {
            max-height: 160px;
          }
        }
      }
    }
  }
}

.message-sprint {
  h3 {
    color: lighten($dark, 30%);
  }
}

.display-none {
  display: none;
}

h4.title {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  margin: var(--spacing-small) 0 var(--spacing-bigger) 0;
  font-family: 'SourceSansProLight', sans-serif;
}

.searchbar {
  margin: var(--spacing-default) 0;
  border: 1px solid lighten($primary, 20%);
  border-radius: var(--border-radius-default);
  width: $search-width;
  height: 50px;
  font-family: "SourceSansProLight", sans-serif;
}

.search-option {
  display: flex;
  max-width: $search-max-width;
  margin-bottom: var(--spacing-default);
  span {
    font-family: "SourceSansProRegular", sans-serif;
    font-size: var(--font-size-smaller);
  }
}

.radio {
  margin-left: var(--spacing-very-big);
}

.todo {
  margin: var(--spacing-bigger);
}

</style>
