import {
  EntityProduct,
  EntityProductDetailsPaginated,
} from '@/api/fournisseurs/FournisseursApi';
import HttpMethod from '../../lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export type Cooperative = {
  id: string;
  idPackage: string;
  name: string;
  primaryColor: string;
  secondaryColor: string;
  count: number;
};

export type CooperativeUser = {
  id: string;
  email: string;
  firstName: string;
  idEntity: string;
  idProfile: string;
  isReferent: boolean;
  lastConnection: string;
  lastName: string;
  phoneNumber: string;
};

export type CooperativePackage = {
  id: string;
  name: string;
  comparisonRight: boolean;
  readRight: boolean;
};

export type Pack = {
  id: string;
  comparisonRight: string;
  name: string;
  readRight: string;
};

export default class CooperativesApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: CooperativesApi | undefined;

  public static getInstance(): CooperativesApi {
    if (CooperativesApi.INSTANCE === undefined) {
      CooperativesApi.INSTANCE = new CooperativesApi();
    }

    return CooperativesApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetch(name: string) {
    return this
      .httpClient
      .restRequest<Cooperative[]>(HttpMethod.GET, '/admin/cooperatives')
      .queryParams([['name', name]])
      .execute();
  }

  fetchCoop(id: string) {
    return this
      .httpClient
      .restRequest<Cooperative>(HttpMethod.GET, `/admin/cooperatives/${id}`)
      .execute();
  }

  fetchCooperativeUsers(id: string) {
    return this
      .httpClient
      .restRequest<CooperativeUser[]>(HttpMethod.GET, `/admin/cooperatives/${id}/users`)
      .execute();
  }

  fetchPackages() {
    return this
      .httpClient
      .restRequest<CooperativeUser[]>(HttpMethod.GET, '/admin/pack')
      .execute();
  }

  fetchPack(entityId: string) {
    return this
      .httpClient
      .restRequest<Pack>(HttpMethod.GET, `/admin/cooperatives/${entityId}/package`)
      .execute();
  }

  fetchReferentUser(id: string) {
    return this
      .httpClient
      .restRequest<CooperativeUser>(HttpMethod.GET, `/admin/cooperatives/${id}/referent`)
      .execute();
  }

  createNewCooperative(cooperative: Cooperative) {
    return this
      .httpClient
      .restRequest<Cooperative>(HttpMethod.POST, '/admin/cooperatives')
      .jsonBody(cooperative)
      .execute();
  }

  updateCooperative(cooperative: Cooperative, token?: string) {
    const request = this
      .httpClient
      .restRequest<Cooperative>(HttpMethod.PUT, `/admin/cooperatives/${cooperative.id}`)
      .jsonBody(cooperative);
    // On peut se retrouver a faire cet appel avant d'être authentifié pour valider les CGU
    if (token) {
      request.headers({ authorization: `Bearer ${token}` });
    }
    return request.execute();
  }

  fetchProductByEntityId(
    entityId: string,
    gamme: boolean,
    search: string,
    limit: number,
    offset: number,
    productSort: string,
  ) {
    return this.httpClient
      .restRequest<EntityProductDetailsPaginated>(HttpMethod.GET, `/admin/entityProduct/${entityId}`)
      .queryParams([
        ['gamme', gamme], ['search', search], ['limit', limit], ['offset', offset],
        ['productSort', productSort],
      ])
      .execute();
  }

  updateGamme(entityId: string, list: EntityProduct[], filterGamme: boolean) {
    return this.httpClient
      .restRequest(HttpMethod.PUT, `/admin/entityProduct/${entityId}/gamme`)
      .queryParams([['filterGamme', filterGamme]])
      .jsonBody(list)
      .execute();
  }

  deleteCooperative(entityId: string) {
    return this.httpClient
      .restRequest(HttpMethod.DELETE, `/admin/cooperatives/${entityId}`)
      .execute();
  }
}
