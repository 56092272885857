<template>
  <div class="tile-container">
    <div class="user">
      <p>{{ user.firstname }}</p>
      <p class="uppercase">{{ user.lastname }}</p>
    </div>
    <div class="infos">
      <div class="entity">
        <p>{{ user.profileName }}</p>
        <p class="light">{{ user.entityName }}</p>
      </div>
      <div class="package">
        <p>{{ user.packageLabel }}</p>
      </div>
    </div>
    <div class="button-container">
      <Button
        :label="$t('admin.users.tile.detailsButton')"
        color="white"
        :title="$t('admin.users.tile.detailButtonTitle')"
        @click="() => handleDetails(user.id)"
      >
        <magnify class="icon" />
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Magnify } from 'mdue';
import { PropType } from 'vue';
import { UTILISATEUR_DETAIL_ROUTE } from '@/router/routes';
import * as SprintHelperModule from '@/SprintHelperModule';
import { UserInfo } from '@/api/users/UsersApi';

@Options({
  components: {
    Button,
    Magnify,
  },
  props: {
    user: {
      type: Object as PropType<UserInfo>,
      required: true,
    },
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    handleDetails(id: string) {
      this.$router.push({ name: UTILISATEUR_DETAIL_ROUTE, params: { id } });
    },
  },
})
export default class UserTile extends Vue {
  user!: UserInfo;
}
</script>

<style lang="scss" scoped>
.tile-container {
  height: 100px;
  border: solid var(--grey-2) 1px;
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-default) var(--spacing-bigger);
  background-color: white;
  text-align: left;

  .user {
    display: flex;
    flex-direction: row;
    text-align: initial;
    width: 28%;
    p {
      margin-right: 4px;
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  .infos {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    .entity {
      text-align: initial;
      width: 100%;
      p {
        &.light {
          font-family: 'SourceSansProLight', sans-serif;
        }
      }
    }

    .package {
      display: none;
      text-align: initial;
      width: 28%;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;;

    button {
      border: solid var(--grey-1) 1px;
      border-top: none;
    }
  }
}
</style>
