<template>
  <div class="container">
    <div class="graph-title">
      <div class="title">
        {{ $t('front.balanceSocietale.resultats.graph.titre') }}
        <div class="title-enjeu" :style="{color: enjeuSelected.color, fontWeight: 'bold'}">
          {{ enjeuSelected.label }}
        </div>
      </div>
    </div>
    <div class="legend">
      <div class="graduation" :style="{background: getLinearGrad()}">
        <div v-for="(line, index) of graduation" :key="index"></div>
      </div>
    </div>
    <div class="product-list" v-if="dataLoaded">
      <div v-for="(product, index) in allProducts"
           class="product"
           :key="product.id"
      >
        <div v-if="product.noteMin === undefined || product.noteMin === null" class="hide-product"/>
        <div class="product-label">
          <div class="color-label-container">
            <div class="color-band" v-bind:style="getColorFromType(product.type)" />
            <label>{{ product.label.toLowerCase()}}</label>
          </div>
          <file-search-outline
            v-if="isProduct(product) && product.noteMin !== undefined && product.noteMin !== null"
            class="icon-detail" type="button" @click="handleDetails(product.id)"
          />
          <div v-if="product.noteMin === undefined || product.noteMin === null" class="filter-label">
            {{ $t('front.balanceSocietale.resultats.graph.permissions') }}
          </div>
          <div class="filter-label"
               v-else-if="getCriterionText(index).length > 2">
            {{
              $t('front.balanceSocietale.resultats.graph.products',
                {
                  criterions: getCriterionText(index),
                  association: getAssociationText(index),
                  count: product.productIdList?.length,
                  nbr: product.productIdList?.length
                }
              )
            }}
          </div>
          <label class="filter-label" v-else-if="!isProduct(product)">
            {{
              $t('front.balanceSocietale.creation.productsList',
                {
                  count: product.productIdList.length,
                  nbr: product.productIdList.length,
                }
              )
            }}
          </label>
          <div class="tooltips" v-if="getCriterionText(index).length > 2 && product.noteMin !== undefined && product.noteMin !== null">
            <span>
              {{ product.label }}
            </span>
            <br/>
            <span>
            {{
               $t('front.balanceSocietale.resultats.graph.products',
               {
                criterions: getCriterionText(index),
                association: getAssociationText(index),
                count: product.productIdList?.length,
                nbr: product.productIdList?.length
                }
                )
             }}
            </span>
          </div>
          <div class="tooltips" v-else-if="product.noteMin !== undefined && product.noteMin !== null" >
            <span>
              {{ product.label }}
            </span>
          </div>
        </div>
        <div class="graph-results">
          <hr class="graph-lines">
          <div class="score-bar" v-if="product.type === 'PRODUIT' && product.noteMin !== undefined && product.noteMin !== null">
            <BalanceScoreBar
              class="score-bar"
              :product=product
              :isProduct="true"
              :logoProduct="getLogoProduct(product.logoProduct)"
            />
          </div>
          <div class="score-bar" v-else-if="product.noteMin !== undefined && product.noteMin !== null">
            <BalanceScoreBar
              :product=product
              :isProduct="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="spinner-border" role="status"/>
    </div>
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import VerticalLine from '@/components/VerticalLine.vue';
import BalanceApi, { BalanceBean, FIELD_TYPES, FILTER_TYPE } from '@/api/balance/BalanceApi';
import { Triangle, FileSearchOutline } from 'mdue';
import BalanceScoreBar from '@/views/front/balance-societale/BalanceScoreBar.vue';
import { inject } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const GRAPH_COLORS = {
  0: '#C73225',
  10: '#D44E23',
  20: '#E77520',
  30: '#F49D22',
  40: '#FBC42C',
  50: '#eee',
  60: '#C7D2BA',
  70: '#A3CA74',
  80: '#76B42F',
  90: '#4BAD3D',
  100: '#16A54C',
};

@Options({
  components: {
    VerticalLine,
    Triangle,
    BalanceScoreBar,
    FileSearchOutline,
  },
  props: {
    basket: Array,
    enjeuSelected: Object,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);

    const globalFilters = inject('globalFilters');
    return {
      balanceApi,
      globalFilters,
      dataLoaded: false,
      productsLogos: [],
      GRAPH_COLORS,
      allProducts: [],
      graduation: [...Array(11).keys()],
    };
  },
  methods: {
    isProduct(product: BalanceBean) {
      return product.type === FIELD_TYPES.PRODUIT;
    },
    handleDetails(idProduct: string) {
      this.$parent.displayDetails = idProduct;
    },
    getAssociationText(index: number) {
      let associated = '';
      // On recupere l'index du filtre composition dans la liste des filtres du produit
      const indexFilter = this.basket[index].filters.map((e: BalanceBean) => e.type)
        .indexOf(FIELD_TYPES.COMPOSITION);
      // On regarde si association != undefined (car 0 si composition seule et 1 si associee)
      if (this.basket[index].filters
        && this.basket[index].filters[indexFilter]
        && this.basket[index].filters[indexFilter].association !== undefined) {
        // On recupere le texte en fonction de l'etat du filtre (associé ou non)
        associated = this.basket[index].filters[indexFilter].association
          ? `(${this.$t('front.balanceSocietale.filtre.composition.associated')})`
          : `(${this.$t('front.balanceSocietale.filtre.composition.alone')})`;
      }
      return associated;
    },
    getCriterionText(index: number) {
      const criterionText: string[] = [];
      if (this.basket[index] && this.basket[index].filters) {
        this.basket[index].filters
          .forEach((criterion: BalanceBean) => criterionText.push(criterion.label));
      }
      return `"${criterionText.join(', ')}"`;
    },
    getColorFromType(type: keyof typeof FILTER_TYPE) {
      return { background: FILTER_TYPE[type].color };
    },
    getLinearGrad() {
      return `transparent linear-gradient(90deg, ${GRAPH_COLORS[0]} 0%, ${GRAPH_COLORS[10]} 6%, ${GRAPH_COLORS[20]} 15%,
        ${GRAPH_COLORS[30]} 27%, ${GRAPH_COLORS[40]} 37%,
        ${GRAPH_COLORS[50]} 48%, ${GRAPH_COLORS[60]} 58%, ${GRAPH_COLORS[70]} 68%,${GRAPH_COLORS[80]} 79%, ${GRAPH_COLORS[90]} 89%,
        ${GRAPH_COLORS[100]} 100%)
      0 0 no-repeat padding-box`;
    },
    fetchLogoProduct(products: BalanceBean[]) {
      this.balanceApi.fetchLogoProduct(products)
        .then((responses: BalanceBean[]) => {
          this.allProducts = responses;
        }).then(() => {
          this.dataLoaded = true;
        });
    },
    getLogoProduct(logoProduct: string) {
      return URL.createObjectURL(
        new Blob([logoProduct || ''], { type: 'image/svg+xml' }),
      );
    },
  },
  mounted() {
    if (!this.globalFilters) {
      this.globalFilters = [];
    }
    this.balanceApi
      .getBalanceScores(this.enjeuSelected.label, this.basket)
      .then((response: BalanceBean[]) => {
        this.dataLoaded = false;
        this.fetchLogoProduct(response);
      });
    this.dataLoaded = true;
  },
  watch: {
    enjeuSelected: {
      handler() {
        this.productsLogos = [];
        this.allProducts = [];
        if (this.enjeuSelected) {
          this.balanceApi
            .getBalanceScores(
              this.enjeuSelected.label,
              this.basket,
            )
            .then((response: BalanceBean[]) => {
              this.dataLoaded = false;
              this.fetchLogoProduct(response);
            });
        }
      },
      deep: true,
    },
    basket: {
      handler() {
        const basketLabels = this.basket.map((b: BalanceBean) => b.label);
        let index;
        this.allProducts.forEach((product: BalanceBean, i: number) => {
          if (!basketLabels.includes(product.label)) {
            index = i;
          }
        });
        this.allProducts.splice(index, 1);
      },
      deep: true,
    },
  },
})

export default class GraphResultat extends Vue {
}
</script>

<style lang="scss" scoped>
$graph-size: 590px;

.container {
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
  max-width: 1000px;

  .spinner-border {
    margin-top: 10%;
  }

  .graph-title {
    color: var(--grey-5);
    display: flex;
    font-size: var(--font-size-bigger);
    width: 100%;
    margin-bottom: 20px;

    .title {
      display: flex;
      width: 600px;

      .title-enjeu {
        margin-left: 10px;
      }
    }

    &::after {
      content: '';
      width: 150px;
    }
  }

  .legend {
    display: flex;
    width: 100%;
    justify-content: end;

    .graduation {
      height: 25px;
      width: $graph-size;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      div {
        height: 10px;
        width: 3px;
        background-color: var(--grey-5);
        position: relative;

        &::before {
          font-weight: bold;
          color: var(--grey-5);
          position: absolute;
          bottom: calc(100%);
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(1) {
          &::before {
            content: '0';
          }
        }

        &:nth-child(6) {
          &::before {
            content: '50';
          }
        }

        &:nth-child(11) {
          &::before {
            content: '100';
          }
        }
      }
    }

    &::before {
      content: '';
      width: 210px;
    }
  }

  .product-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .product {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      height: 60px;
      width: 100%;
      justify-content: space-between;

      .product-label {
        position: relative;
        width: calc(100% - #{$graph-size});

        label {
          text-transform: capitalize;
        }
        .icon-detail {
          position: absolute;
          width: 25px;
          height: 25px;
          color: var(--balance-yellow);
          right: 10px;
          top: 0;
        }

        .filter-label {
          position: absolute;
          font-size: var(--font-size-hint);
          margin-left: var(--spacing-default);
          font-style: italic;
          white-space: break-spaces;
          max-width: 15vw;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
          width: 15vw;
        }
        .tooltips {
          display: none;
        }
      }

      .product-label:hover {
        .tooltips {
          display: block;
          background-color: white;
          border: 1px solid #005297;
          min-width: max-content;
          font-size: var(--font-size-hint);
          text-align: left;
          padding-left: var(--spacing-small);
          font-style: italic;
          margin-right: var(--spacing-bigger);
          padding-right: var(--spacing-small);
          z-index: 1;
          position: absolute;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .graph-results {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: $graph-size;

        .score-bar {
          position: relative;
          z-index: 1;
          margin-left: var(--margin-left-placement);

          img {
            position: absolute;
            clip: rect(0, 100px, 200px, 0);
            left: 0;
          }
        }

        .graph-lines {
          width: $graph-size;
          display: flex;
          color: var(--grey-5);
          position: absolute;
          height: 2px;
          opacity: 0.15;

          hr {
            margin-bottom: 40px;
          }
        }
      }

      .color-label-container {
        display: flex;
        width: 100%;

        label {
          margin-left: 15px;
          font-family: 'SourceSansProRegular', sans-serif;
          color: var(--grey-7);
          width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          font-size: 18px;
        }

        .color-band {
          height: 25px;
          width: 5px;
          border-radius: 30px;
        }
      }

      .hide-product {
        position: absolute;
        background-color: var(--grey-1);
        height: 60px;
        width: 100%;
        margin-top: -5px;
      }
    }
  }
}

</style>
