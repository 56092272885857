import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c42c26fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "histogram" }
const _hoisted_2 = { class: "y-legend" }
const _hoisted_3 = { class: "y-label" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = ["title"]
const _hoisted_14 = ["title"]
const _hoisted_15 = ["title"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('front.product.histogram.legend')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yLabels, (yLabel) => {
        return (_openBlock(), _createElementBlock("div", {
          key: yLabel,
          class: _normalizeClass(['lign', yLabel ? 'lign--'+yLabel : ''])
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(yLabel), 1)
        ], 2))
      }), 128)),
      (_ctx.comparisonOneScore)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "bar bar--onescore",
            style: _normalizeStyle(_ctx.styleBar(_ctx.oneScore)),
            title: _ctx.$t('front.product.histogram.oneScoreTitle', { note: _ctx.oneScore })
          }, [
            _createElementVNode("div", {
              class: "round-avg",
              style: _normalizeStyle(_ctx.styleAvg(_ctx.comparisonOneScore.avg)),
              title: _ctx.$t('front.product.histogram.oneScoreAvg',
            { note: _ctx.comparisonOneScore.avg })
            }, null, 12, _hoisted_5),
            _createElementVNode("div", {
              class: "small-bar",
              style: _normalizeStyle(_ctx.styleComparison(_ctx.comparisonOneScore)),
              title: _ctx.$t('front.product.histogram.oneScoreComparison',
            { min: _ctx.comparisonOneScore.min, max: _ctx.comparisonOneScore.max })
            }, null, 12, _hoisted_6)
          ], 12, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.comparisonAlimentation)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "bar bar--alimentation",
            style: _normalizeStyle(_ctx.styleBar(_ctx.alimentation)),
            title: _ctx.$t('front.product.histogram.alimentationTitle', { note: _ctx.alimentation })
          }, [
            _createElementVNode("div", {
              class: "round-avg",
              style: _normalizeStyle(_ctx.styleAvg(_ctx.comparisonAlimentation.avg)),
              title: _ctx.$t('front.product.histogram.alimentationAvg',
            { note: _ctx.comparisonAlimentation.avg })
            }, null, 12, _hoisted_8),
            _createElementVNode("div", {
              class: "small-bar",
              style: _normalizeStyle(_ctx.styleComparison(_ctx.comparisonAlimentation)),
              title: _ctx.$t('front.product.histogram.alimentationComparison',
            { min: _ctx.comparisonAlimentation.min, max: _ctx.comparisonAlimentation.max })
            }, null, 12, _hoisted_9)
          ], 12, _hoisted_7))
        : _createCommentVNode("", true),
      (_ctx.comparisonAgriculteur)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "bar bar--agriculteur",
            style: _normalizeStyle(_ctx.styleBar(_ctx.agriculteur)),
            title: _ctx.$t('front.product.histogram.agriculteurTitle', { note: _ctx.agriculteur })
          }, [
            _createElementVNode("div", {
              class: "round-avg",
              style: _normalizeStyle(_ctx.styleAvg(_ctx.comparisonAgriculteur.avg)),
              title: _ctx.$t('front.product.histogram.agriculteurAvg',
            { note: _ctx.comparisonAgriculteur.avg })
            }, null, 12, _hoisted_11),
            _createElementVNode("div", {
              class: "small-bar",
              style: _normalizeStyle(_ctx.styleComparison(_ctx.comparisonAgriculteur)),
              title: _ctx.$t('front.product.histogram.agriculteurComparison',
            { min: _ctx.comparisonAgriculteur.min, max: _ctx.comparisonAgriculteur.max })
            }, null, 12, _hoisted_12)
          ], 12, _hoisted_10))
        : _createCommentVNode("", true),
      (_ctx.comparisonEnvironnement)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "bar bar--environnement",
            style: _normalizeStyle(_ctx.styleBar(_ctx.environment)),
            title: _ctx.$t('front.product.histogram.environnementTitle', { note: _ctx.environment })
          }, [
            _createElementVNode("div", {
              class: "round-avg",
              style: _normalizeStyle(_ctx.styleAvg(_ctx.comparisonEnvironnement.avg)),
              title: _ctx.$t('front.product.histogram.environnementAvg',
            { note: _ctx.comparisonEnvironnement.avg })
            }, null, 12, _hoisted_14),
            _createElementVNode("div", {
              class: "small-bar",
              style: _normalizeStyle(_ctx.styleComparison(_ctx.comparisonEnvironnement)),
              title: _ctx.$t('front.product.histogram.environnementComparison',
            { min: _ctx.comparisonEnvironnement.min, max: _ctx.comparisonEnvironnement.max })
            }, null, 12, _hoisted_15)
          ], 12, _hoisted_13))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['x-label', _ctx.comparisonOneScore ? '' : 'no-one-score'])
    }, [
      (_ctx.comparisonOneScore)
        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('front.product.histogram.one_score')), 1))
        : _createCommentVNode("", true),
      (_ctx.comparisonAlimentation)
        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('front.product.histogram.alimentation')), 1))
        : _createCommentVNode("", true),
      (_ctx.comparisonAgriculteur)
        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('front.product.histogram.agriculteur')), 1))
        : _createCommentVNode("", true),
      (_ctx.comparisonEnvironnement)
        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t('front.product.histogram.environnement')), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}