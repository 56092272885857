import { Product, ScoreProduct } from '@/api/products/ProductsApi';
import HttpMethod from '../../lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export type Fournisseur = {
  id: string;
  idPackage: string;
  name: string;
  primaryColor: string;
  secondaryColor: string;
  count: number;
};

export type FournisseurUser = {
  id: string;
  email: string;
  firstName: string;
  idEntity: string;
  idProfile: string;
  isReferent: boolean;
  lastConnection: string;
  lastName: string;
  phoneNumber: string;
};

export type FournisseurPackage = {
  id: string;
  name: string;
  comparisonRight: boolean;
  readRight: boolean;
};

export type Pack = {
  id: string;
  comparisonRight: string;
  name: string;
  readRight: string;
};

export type EntityProduct = {
  idProduct: string;
  idEntity: string;
}

export type EntityProductDetails = {
  entityProduct: EntityProduct;
  product: Product;
  lastScoringUpdate: string;
  lastDataUpdate: string;
  scoreProduct: ScoreProduct;
  isGamme: boolean;
}

export type EntityProductDetailsPaginated = {
  entityProductDetailsList: EntityProductDetails[];
  nbrResults: number;
}

export const PRODUCT_SORT = {
  TrierPar: 'Trier par',
  NoteCroissanteOneScore: 'Note croissante OneScore',
  NoteDecroissanteOneScore: 'Note decroissante OneScore',
  NoteCroissanteAgriculture: 'Note croissante Agriculteur',
  NoteDecroissanteAgriculture: 'Note decroissante Agriculteur',
  NoteCroissanteAlimentation: 'Note croissante Alimentation',
  NoteDecroissanteAlimentation: 'Note decroissante Alimentation',
  NoteCroissanteEnvironnement: 'Note croissante Environnement',
  NoteDecroissanteEnvironnement: 'Note decroissante Environnement',
  AtoZ: 'A > Z',
  ZtoA: 'Z > A',
};

export default class FournisseursApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: FournisseursApi | undefined;

  public static getInstance(): FournisseursApi {
    if (FournisseursApi.INSTANCE === undefined) {
      FournisseursApi.INSTANCE = new FournisseursApi();
    }

    return FournisseursApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetch(name: string) {
    return this
      .httpClient
      .restRequest<Fournisseur[]>(HttpMethod.GET, '/admin/fournisseurs')
      .queryParams([['name', name]])
      .execute();
  }

  fetchFournisseur(id: string) {
    return this
      .httpClient
      .restRequest<Fournisseur[]>(HttpMethod.GET, `/admin/fournisseurs/${id}`)
      .execute();
  }

  fetchFournisseurUsers(id: string) {
    return this
      .httpClient
      .restRequest<FournisseurUser[]>(HttpMethod.GET, `/admin/fournisseurs/${id}/users`)
      .execute();
  }

  fetchPackages() {
    return this
      .httpClient
      .restRequest<FournisseurUser[]>(HttpMethod.GET, '/admin/pack')
      .execute();
  }

  fetchPack(entityId: string) {
    return this
      .httpClient
      .restRequest<Pack>(HttpMethod.GET, `/admin/fournisseurs/${entityId}/package`)
      .execute();
  }

  fetchReferent(id: string) {
    return this
      .httpClient
      .restRequest<FournisseurUser[]>(HttpMethod.GET, `/admin/fournisseurs/${id}/referent`)
      .execute();
  }

  fetchProductByEntityId(
    entityId: string,
    gamme: boolean,
    search: string,
    limit: number,
    offset: number,
    productSort: string,
  ) {
    return this.httpClient
      .restRequest<EntityProductDetailsPaginated>(HttpMethod.GET, `/admin/entityProduct/${entityId}`)
      .queryParams([
        ['gamme', gamme], ['search', search], ['limit', limit], ['offset', offset],
        ['productSort', productSort],
      ])
      .execute();
  }

  filterProductsByScore(asc: boolean, note: string) {
    return this.httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/entityProduct/filter')
      .queryParams([['asc', asc], ['note', note]])
      .execute();
  }

  updateGamme(entityId: string, list: EntityProduct[], filterGamme: boolean) {
    return this.httpClient
      .restRequest<EntityProductDetailsPaginated>(HttpMethod.PUT, `/admin/entityProduct/${entityId}/gamme`)
      .queryParams([['filterGamme', filterGamme]])
      .jsonBody(list)
      .execute();
  }
}
