<template>
  <notifications ref="notifications"/>
  <div class="wrapper">
    <h4 class="title">{{ $t('admin.fournisseur.gamme.list') }}</h4>
    <div class="wrapper">
      <div>
        <div class="searchbar">
          <SearchbarComponent :text="$t('admin.fournisseur.gamme.search')" />
        </div>
        <div class="search-option">
        <span>
          {{ $t('admin.fournisseur.gamme.searchOption') }}
        </span>
          <div class="radio">
            <MDBRadio
              :label="$t('admin.fournisseur.gammeProduct')"
              :formCheck="false"
              name="radio"
              v-model="radioRef"
              :value="true"
            />
          </div>
          <div class="radio">
            <MDBRadio
              :label="$t('admin.fournisseur.excludeGammeProduct')"
              :formCheck="false"
              name="radio"
              v-model="radioRef"
              :value="false"
              color="primary"
            />
          </div>
        </div>
        <div class="action-button">
          <Button :onClick="handleSearch"
                  type="submit" :label="$t('common.button.search')"
                  color="dark"
                  class="search-button"
          >
            <magnify class="icon"/>
          </Button>
        </div>
      </div>
    </div>
    <div class="actions">
      <span class="search-results" v-if="!dataLoaded">
      {{ $t('common.loader') }}
      </span>
      <span class="search-results" v-else>
        {{
          $t('front.product.productManagement.results', { count: nbrResultProducts })
        }}
      </span>
    </div>
    <hr/>
    <div class="action-button action-button--space">
      <ProductSort ref="ProductSort" :sortUsed="sortUsed" @set-sort="(newSort: string) => (this.sortUsed = newSort)"/>
    </div>
    <div class="products-box" v-if="dataLoaded">
      <div
        class="products-container"
        v-for="entityProduct in products"
        :key="entityProduct.product.name"
      >
        <ProductTile
          :product="entityProduct"
          :canCompare="false"
          :canRemoveFavorite="false"
          :canDownloadLogo="false"
          :canCheck="false"
          :showCheckbox="false"
        />
      </div>
    </div>
    <div v-else class="spinner-border" role="status" />
    <Pagination
      v-if="tileToDisplay < nbrResultProducts && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="products.length"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import SearchbarComponent from '@/components/SearchbarComponent.vue';
import Drawer from '@/components/Drawer.vue';
import Button from '@/components/Button.vue';
import ProductTile from '@/components/ProductTile.vue';
import ProductSort from '@/components/ProductSort.vue';
import Popin from '@/components/Popin.vue';
import Pagination from '@/components/Pagination.vue';
import { MDBRadio } from 'mdb-vue-ui-kit';
import {
  Magnify, PlaylistPlus, PlaylistRemove, CheckboxBlankOutline, CheckboxMarked, Plus, Minus,
} from 'mdue';
import { inject, ref } from 'vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import FournisseursApi, { EntityProductDetailsPaginated, PRODUCT_SORT } from '@/api/fournisseurs/FournisseursApi';
import Notifications from '@/components/Notifications.vue';

@Options({
  components: {
    SearchbarComponent,
    Drawer,
    Button,
    Magnify,
    PlaylistPlus,
    PlaylistRemove,
    Plus,
    Minus,
    MDBRadio,
    ProductTile,
    CheckboxBlankOutline,
    CheckboxMarked,
    Notifications,
    Popin,
    Pagination,
    ProductSort,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);

    // TODO: Rendre ça dynamique quand on aura nos users
    const radioRef = ref(true);
    const authorization = inject('authorization');

    return {
      fournisseursApi,
      nbrTileDefault: 15,
      tileToDisplay: 15,
      radioRef,
      products: [],
      nbrResultProducts: 0,
      isActionAdd: false,
      dataLoaded: false,
      showPopin: false,
      filteredList: [],
      sortUsed: PRODUCT_SORT.TrierPar,
      search: '',
      authorization,
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    fetchProducts() {
      this.dataLoaded = false;
      this.fournisseursApi.fetchProductByEntityId(
        this.$route.params.id,
        this.radioRef,
        this.search,
        this.nbrTileDefault,
        this.products.length,
        this.sortUsed,
      )
        .then((response: EntityProductDetailsPaginated) => {
          this.dataLoaded = true;
          this.products = [...this.products, ...response.entityProductDetailsList];
          this.nbrResultProducts = response.nbrResults;
        });
    },
    handleSearch() {
      this.products = [];
      this.fetchProducts();
    },
    showMore() {
      this.fetchProducts();
    },
  },
  mounted() {
    this.handleSearch();
  },
})
export default class FournisseurGamme extends Vue {
}
</script>

<style lang="scss">
#app .products-box .products-container .tile-container {
  cursor: default;
}
</style>

<style lang="scss" scoped>
$search-width: 800px;
$search-max-width: 815px;

.wrapper {
  position: relative;
  margin: var(--spacing-bigger);
  display: flex;
  align-items: center;
  flex-direction: column;

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .search-results {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      max-width: 30rem;
      font-family: 'LatoLight', sans-serif;
    }
  }

  hr {
    width: 100%;
    color: black;
  }

  .action-button {
    display: flex;
    justify-content: center;

    &.action-button--space {
      width: 95%;
      justify-content: space-between;
      margin: var(--spacing-medium) 0 var(--spacing-default);

      .button-container {
        display: flex;

        button {
          margin-left: var(--spacing-big);
        }
      }
    }
  }

  .select-container {
    text-align: end;
    width: 95%;
    margin-bottom: -5px;

    label {
      font-size: var(--font-size-hint);
    }

    .checkbox {
      margin: 0 var(--spacing-small);
      border: none;
      background: none;
      height: fit-content;
      padding: 0;
      z-index: 30;
    }
  }
  .products-box {
    width: 100%;

    .products-container {
      width: 95%;

      .tile-container {
        .score {
          img {
            max-height: 160px;
          }
        }
      }
    }
  }
}

.message-sprint {
  h3 {
    color: lighten($dark, 30%);
  }
}

h4.title {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  margin: var(--spacing-small) 0 var(--spacing-bigger) 0;
  font-family: 'SourceSansProLight', sans-serif;
}

.searchbar {
  margin: var(--spacing-default) 0;
  border: 1px solid lighten($primary, 20%);
  border-radius: var(--border-radius-default);
  width: $search-width;
  height: 50px;
  font-family: "SourceSansProLight", sans-serif;
}

.search-option {
  display: flex;
  width: $search-max-width;
  margin-bottom: var(--spacing-default);
  span {
    font-family: "SourceSansProRegular", sans-serif;
    font-size: var(--font-size-smaller);
  }
}

.radio {
  margin-left: var(--spacing-very-big);
}

.todo {
  margin: var(--spacing-bigger);
}

</style>
