import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "menu-header" }
const _hoisted_2 = { class: "oneScore" }
const _hoisted_3 = { class: "admin" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('menuAdmin.oneScore')), 1),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('menuAdmin.admin')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageNames, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "menu",
        key: item.link
      }, [
        (!item.options)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: item.link
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Accordion, {
                    title: item.name,
                    isActive: false,
                    iconLeft: item.iconLeft,
                    iconRight: item.iconRight
                  }, null, 8, ["title", "iconLeft", "iconRight"])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Accordion, {
                title: item.name,
                isActive: false,
                iconLeft: item.iconLeft,
                iconRight: item.iconRight
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: option.name
                    }, [
                      _createVNode(_component_router_link, {
                        to: option.link
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Accordion, {
                            title: option.name,
                            isActive: false,
                            iconLeft: option.iconLeft
                          }, null, 8, ["title", "iconLeft"])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["title", "iconLeft", "iconRight"])
            ]))
      ]))
    }), 128))
  ], 64))
}