<template>
  <notifications ref="notifications" />
  <div class="permissions-management">
    <h3 class="page-title">{{ $t('front.permissions.title') }}</h3>
    <Button
      class="add-profile"
      @click="showPopinAdd = true"
      :label="$t('front.permissions.add')"
      color="primary"
    >
      <plus class="icon" />
    </Button>
    <!-- Popin Add !-->
    <Popin
      v-if="showPopinAdd === true"
      :title="$t('front.permissions.newProfile')"
    >
      <template v-slot:container>
        <form @submit.prevent="addProfile">
          <div>
            <label for="name">
              {{ $t('front.permissions.nameProfile') }}
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </label>
            <input type="text" id="name" v-model="newProfile.name" required>
          </div>
          <div>
            <label for="newEntity">
              {{ $t('front.permissions.entity') }}
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </label>
            <select
              name="newEntity"
              id="newEntity"
              v-model="newProfile.entityType"
              required
            >
              <option v-for="option in entities" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="actions-boutons">
            <Button
              :label="$t('common.button.cancel')"
              color="secondary"
              @click="showPopinAdd = false"
            >
              <arrow-left class="icon" />
            </Button>
            <Button
              type="submit"
              :label="$t('common.button.save')"
              color="primary"
            >
              <content-save class="icon" />
            </Button>
          </div>
        </form>
      </template>
    </Popin>
    <!-- Popin Delete !-->
    <Popin
      v-if="showPopinDelete === true"
      :title="$t('front.permissions.deleteProfile')"
      :hasCancel="true"
      @close="closeDeletePopin"
    >
      <template v-slot:container>
        <div v-if="profilUsers.length > 0">
          <p class="text">{{ $t('front.permissions.deleteProfileUsers') }}</p>
          <div class="user-list">
            <div
              v-for="(user, index) in profilUsers"
              :key="index"
            >{{ user }}</div>
          </div>
        </div>
        <p class="text">{{ $t('front.permissions.deleteText') }}</p>
      </template>
      <template v-slot:button>
        <Button
          type="button"
          :label="$t('front.permissions.deleteConfirmation')"
          color="primary"
          @click="deleteConfirmation"
        >
          <delete class="icon" />
        </Button>
      </template>
    </Popin>

    <div v-if="profileLoaded">
      <Accordion
        v-for="(profile, index) in profiles"
        :key="profile.id"
        :id="profile.id"
        :index="index"
        :title="$t('front.permissions.profile', { profile: profile.name })"
        :isActive="accordionOpenedIndex === index"
        iconRight="angle-right"
        type="unique"
      >
        <div>
          <h4>{{ $t('front.permissions.entityChoice') }}</h4>
          <select name="entity" id="entity" v-model="profile.entityType" class="select">
            <option v-for="option in entities" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
          <br /><br /><br />
          <h4>{{ $t('front.permissions.functionalities') }}</h4>
          <div>
            <div class="fonctionnalite-container">
              <div class="column">
                <div
                  v-for="(permission, indexParent) in getPermissionsToDisplayByProfile()
                .filter((item) => !permissionsNotInMVP.includes(item.shortName))"
                  :key="permission.id"
                  class="div-parent-permission"
                >
                  <button
                    class="checkbox"
                    id="checkList"
                    @click="() => handleParentCheck(permission, indexParent)"
                  >
                    <checkbox-marked v-if="permissionsParentChecked.includes(permission)" class="icon" />
                    <checkbox-blank-outline v-else class="icon" />
                  </button>
                  <Accordion
                    :index="indexParent"
                    :title="permission.name"
                    :isActive="accordionParentIndex.includes(indexParent)"
                    :iconRight="accordionIconRight(permission)"
                    @click="() => handlePermission(indexParent)"
                  >
                    <div
                      v-if="permissions
                    .filter((item) => item.parentPermissionId === permission.id).length > 0
                    && accordionParentIndex.includes(indexParent) && dataLoaded"
                      class="permissions-wrapper"
                    >
                      <div
                        class="div-children-permission"
                        v-for="subpermission in getSubPermissionsToDisplayByProfile(permission)"
                        :key="subpermission.id"
                      >
                        <button
                          class="checkbox"
                          id="checkbox"
                          @click="() => handleCheck(subpermission)"
                        >
                          <checkbox-marked v-if="permissionsChecked.includes(subpermission)"
                                           class="icon" />
                          <checkbox-blank-outline v-else class="icon" />
                        </button>
                        <label for="checkbox">{{ subpermission.name }}</label>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            <br /><br />
            <h4>{{ $t('front.permissions.notes') }}</h4>
            <div class="note-container">
              <div class="note-row" v-for="(note, index) in notes" :key="note">
                <div class="form-container">
                  <p>{{ note }}</p>
                </div>
                <SliderRangeWithText
                  v-if="getNotePermissionByProfile(profile.id).length > 0"
                  :min="0" :max="2"
                  :labels="labels"
                  :defaultValue="getPermissionByProfileAndNote(profile.id, index)"
                  :indexNote="index"
                  :updateNotePermission="updateNotePermission"
                />
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="!dataLoaded" />

          <div class="actions">
            <Button
              type="button"
              :label="$t('common.button.save')"
              color="primary"
              @click="updatePermissions"
            >
              <content-save class="icon" />
            </Button>
            <Button
              v-if="profilIsDeletable()"
              type="button"
              :label="$t('common.button.delete')"
              color="secondary"
              @click="deleteProfile(profile.id)"
            >
              <delete class="icon" />
            </Button>
          </div>
        </div>
      </Accordion>
    </div>
    <div v-else class="spinner-border" role="status" />
  </div>
</template>

<script lang="ts">
import EntityApi from '@/api/entity/EntityApi';
import UsersApi, { UserShort } from '@/api/users/UsersApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Accordion from '@/components/Accordion.vue';
import {
  CheckboxBlankOutline, CheckboxMarked, ContentSave, Delete, Plus, ArrowLeft, Check,
} from 'mdue';
import Button from '@/components/Button.vue';
import Popin from '@/components/Popin.vue';
import Notifications from '@/components/Notifications.vue';
import PermissionsApi, {
  Permission,
  ProfilePermission,
  ProfileWithPermissions,
  NotePermissionByProfile,
  PackageNote,
} from '@/api/permissions/PermissionsApi';
import ProfileApi, { Profile } from '@/api/profile/ProfileApi';
import { MDBIcon } from 'mdb-vue-ui-kit';
import SliderRangeWithText from '@/components/SliderRangeWithText.vue';
import AuthentApi, { PERMISSIONS } from '@/api/authentication/AuthentApi';

@Options({
  components: {
    Accordion,
    Button,
    Popin,
    ContentSave,
    Delete,
    Check,
    Plus,
    Notifications,
    CheckboxMarked,
    CheckboxBlankOutline,
    ArrowLeft,
    MDBIcon,
    SliderRangeWithText,
  },
  data() {
    const authentApi = getGlobalInstance(AuthentApi);
    const usersApi = getGlobalInstance(UsersApi);
    const profileApi = getGlobalInstance(ProfileApi);
    const entityApi = getGlobalInstance(EntityApi);
    const permissionsApi = getGlobalInstance(PermissionsApi);

    return {
      authentApi,
      usersApi,
      profileApi,
      entityApi,
      permissionsApi,
      profileLoaded: false,
      profiles: [],
      entitySelected: '',
      entities: [],
      permissions: [],
      permissionsParent: [],
      permissionsParentChecked: [],
      permissionsChecked: [],
      profileOpened: '',
      profilUsers: [],
      showPopinAdd: false,
      showPopinDelete: false,
      newProfile: {
        name: '',
        entityType: '',
      },
      idProfileDelete: '',
      accordionOpenedIndex: '',
      accordionParentIndex: [],
      isChildChecked: false,
      labels: [
        this.$t('front.package.labels.market'),
        this.$t('front.package.labels.segment'),
        this.$t('front.package.labels.segmentSpecific'),
      ],
      notes: [
        this.$t('front.package.notes.oneScore'),
        this.$t('front.package.notes.enjeu'),
        this.$t('front.package.notes.enjeuxSpecifiques'),
      ],
      notesChecked: [],
      notePermission: [],
      permissionsNotInMVP: [
        PERMISSIONS.FAVORIS,
        PERMISSIONS.AJOUTER_BALANCE,
        PERMISSIONS.AJOUTER_GAMME,
        PERMISSIONS.EVOLUTION_PRODUIT,
        PERMISSIONS.PAGE_GAMME,
        PERMISSIONS.AJOUT_GAMME,
        PERMISSIONS.RETIRER_GAMME,
        PERMISSIONS.PAGE_FAVORIS,
        PERMISSIONS.AJOUT_FAVORIS,
        PERMISSIONS.RETIRER_FAVORIS,
        PERMISSIONS.ONESCORE,
        PERMISSIONS.NOUVEAUX_PRODUITS,
        PERMISSIONS.FILTRE_PRODUITS,
        PERMISSIONS.FILTRE_CRITERES,
      ],
      dataLoaded: false,
    };
  },
  methods: {
    accordionIconRight(permission: Permission) {
      return this.permissions
        .filter((item: Permission) => item.parentPermissionId === permission.id).length > 0
        ? 'angle-right'
        : '';
    },
    getProfiles() {
      this.profileLoaded = false;
      this.profileApi.fetch()
        .then((response: Profile[]) => {
          this.profiles = response;
        })
        .finally(() => {
          this.profileLoaded = true;
        });
    },
    getEntities() {
      this.entityApi.fetchDistinctEntities()
        .then((response: string[]) => {
          this.entities = response;
        });
    },
    getPermissions() {
      this.permissionsApi.fetch()
        .then((response: Permission[]) => {
          this.permissions = response;
          this.permissionsParent = response
            .filter((permission) => permission.parentPermissionId === null);
        });
    },
    getPermissionsByProfile() {
      this.permissionsChecked = [];
      this.profileApi.fetchPermissionsByProfile(this.profileOpened)
        .then((response: ProfilePermission[]) => {
          this.permissionsChecked = [];
          this.permissionsParentChecked = [];
          this.permissions.forEach((permission: Permission) => {
            if (response.some((item: ProfilePermission) => item.idPermission === permission.id)) {
              if (permission.parentPermissionId !== null) {
                this.permissionsChecked.push(permission);
              } else {
                this.permissionsParentChecked.push(permission);
              }
            }
          });
        });
    },
    handleClickAccordion(id: string, e: Event) {
      const target = e.target as HTMLButtonElement;
      if ((target.className.includes('unique')
        && target.nodeName !== 'INPUT'
        && target.nodeName !== 'SELECT')
        || (target.innerHTML.includes('Profil'))
      ) {
        if (!this.accordionOpenedIndex && this.accordionOpenedIndex !== 0) {
          return;
        }
        this.profileOpened = id;
        this.dataLoaded = false;
        this.getPermissionsByProfile();
        this.getCheckedNoteByProfile(id);
      }
    },
    closeDeletePopin() {
      this.profilUsers = [];
      this.showPopinDelete = false;
    },
    deleteProfile(profileId: string) {
      this.profileApi.fetchAllUsersByProfileId(profileId)
        .then((response: UserShort[]) => {
          this.profilUsers = response.map((user) => `${user.firstName} ${user.lastName.toUpperCase()}`);
          this.idProfileDelete = profileId;
          this.showPopinDelete = true;
        });
    },
    deleteConfirmation() {
      this.profileApi.delete(this.idProfileDelete)
        .then(() => {
          this.$refs.notifications.notifySuccess();
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorDelete')));
      this.profiles = this.profiles.filter((item: Profile) => item.id !== this.idProfileDelete);
      this.showPopinDelete = false;
      this.profilUsers = [];
    },
    addProfile() {
      this.profileApi.save(this.newProfile)
        .then((response: Profile) => {
          this.$refs.notifications.notifyCreation(this.$t('front.permissions.creationSuccess'));
          this.profiles.push(response);
          this.fetchNotePermission();
          this.showPopinAdd = false;
          this.accordionOpenedIndex = '';
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSave')));
    },
    updatePermissions() {
      const array: ProfilePermission[] = [];
      this.permissionsChecked.forEach((permission: Permission) => {
        array.push({
          idProfile: this.profileOpened,
          idPermission: permission.id,
        });
      });
      this.permissionsParentChecked.forEach((permission: Permission) => {
        array.push({
          idProfile: this.profileOpened,
          idPermission: permission.id,
        });
      });
      const currentProfile = this.profiles
        .find((profile: Profile) => profile.id === this.profileOpened);
      const profileWithPermissions: ProfileWithPermissions = {
        profile: currentProfile,
        permissions: array,
      };

      const currentNotePermission = this.notePermission.find((item: NotePermissionByProfile) => item.idProfile === this.profileOpened);

      this.permissionsApi
        .updateProfilePermissions(this.profileOpened, profileWithPermissions, currentNotePermission)
        .then((response: NotePermissionByProfile[]) => {
          this.$refs.notifications.notifySuccess();
          this.getPermissionsByProfile();
          this.notePermission = response;
        })
        .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSave')));
    },
    handlePermission(indexParent: number) {
      if (!this.isChildChecked) {
        if (this.accordionParentIndex.includes(indexParent)) {
          this.accordionParentIndex = this.accordionParentIndex
            .filter((index: number) => index !== indexParent);
        } else {
          this.accordionParentIndex.push(indexParent);
        }
      } else {
        this.isChildChecked = false;
      }
    },
    handleParentCheck(permission: Permission, indexParent: number) {
      const index = this.permissionsParentChecked.indexOf(permission);
      if (index >= 0) {
        this.permissionsParentChecked.splice(index, 1);
        // Uncheck all children permissions
        this.permissionsChecked = this.permissionsChecked
          .filter((item: Permission) => item.parentPermissionId !== permission.id);
      } else {
        this.permissionsParentChecked.push(permission);
        // Check all children permissions
        const parentPermission = this.permissions
          .filter((item: Permission) => item.parentPermissionId === permission.id && this.getSubPermissionAllowedToShow(item));
        parentPermission.forEach((childrenPermission: Permission) => {
          this.permissionsChecked.push(childrenPermission);
        });
        // RG : Si on check une permission parent, on affiche toutes ses permissions enfants
        if (!this.accordionParentIndex.includes(indexParent)) {
          this.accordionParentIndex.push(indexParent);
        }
      }
    },
    handleCheck(permission: Permission) {
      this.isChildChecked = true;
      const index = this.permissionsChecked.indexOf(permission);
      if (index >= 0) {
        this.permissionsChecked.splice(index, 1);
      } else {
        const permissionParent = this.permissions
          .filter((item: Permission) => item.id === permission.parentPermissionId)[0];
        this.permissionsParentChecked.push(permissionParent);
        this.permissionsChecked.push(permission);
      }
    },
    getIdProfileFromName(name: string) {
      const profileIndex = this.profiles.findIndex((item: Profile) => item.name === name);
      if (profileIndex >= 0) {
        return this.profiles[profileIndex].id;
      }
      return -1;
    },
    getPermissionAllowedToShow(permission: Permission) {
      const idPermissionAdmin = this.getIdProfileFromName('Union Admin');
      const displayAdmin = idPermissionAdmin >= 0 && this.profileOpened === idPermissionAdmin;

      const hideAdmin = permission.shortName !== PERMISSIONS.ADMIN;
      const hideProtectedPermission = permission.shortName !== PERMISSIONS.PROTECTED;

      if (displayAdmin) {
        return hideProtectedPermission;
      }
      return hideProtectedPermission && hideAdmin;
    },
    isCooperativeProfile() {
      const idProfileCooperative = this.getIdProfileFromName('Coopérative');
      return idProfileCooperative >= 0
          && this.profileOpened === idProfileCooperative;
    },
    getSubPermissionAllowedToShow(permission: Permission) {
      const hideFiltreGamme = permission.shortName !== PERMISSIONS.FILTRE_GAMME;
      const hideFiltreGammeBalance = permission.shortName !== PERMISSIONS.FILTRE_GAMME_BALANCE;

      if (!this.isCooperativeProfile()) {
        return hideFiltreGamme && hideFiltreGammeBalance;
      }
      return true;
    },
    getPermissionsToDisplayByProfile() {
      // Only display admin permission with profile 'Union Admin'
      return this.permissionsParent
        .filter((item: Permission) => this.getPermissionAllowedToShow(item));
    },
    getSubPermissionsToDisplayByProfile(permission: Permission) {
      return this.permissions
        .filter((item: Permission) => this.getSubPermissionAllowedToShow(item)
          && item.parentPermissionId === permission.id
          && !this.permissionsNotInMVP.includes(item.shortName));
    },
    getCheckedNoteByProfile(idProfile: string) {
      const permission = this.getNotePermissionByProfile(idProfile);
      const array = [];
      const firstPermission = permission[0];
      if (firstPermission.oneScore !== null) {
        array.push(this.notes[PackageNote.OneScore]);
      }
      if (firstPermission.enjeux !== null) {
        array.push(this.notes[PackageNote.Enjeu]);
      }
      if (firstPermission.enjeuxSpecifiques !== null) {
        array.push(this.notes[PackageNote['Enjeux specifiques']]);
      }
      this.notesChecked = array;
      this.dataLoaded = true;
    },
    getNotePermissionByProfile(idProfile: string) {
      return this.notePermission
        .filter(
          (notePermission: NotePermissionByProfile) => notePermission.idProfile === idProfile,
        );
    },
    getPermissionByProfileAndNote(idProfile: string, index: number) {
      let returnValue = -1;
      const permission = this.getNotePermissionByProfile(idProfile);
      if (permission.length > 0) {
        const firstPermission = permission[0];
        switch (index) {
          case PackageNote.OneScore:
            returnValue = this.labels.indexOf(firstPermission.oneScore);
            break;
          case PackageNote.Enjeu:
            returnValue = this.labels.indexOf(firstPermission.enjeux);
            break;
          case PackageNote['Enjeux specifiques']:
            returnValue = this.labels.indexOf(firstPermission.enjeuxSpecifiques);
            break;
          default:
            break;
        }
        return returnValue;
      }
      return null;
    },
    profilIsDeletable() {
      return !this.permissionsParentChecked
        .some((item: Permission) => item.shortName === PERMISSIONS.PROTECTED);
    },
    fetchNotePermission() {
      this.profileApi.fetchNotePermission()
        .then((response: NotePermissionByProfile[]) => {
          this.notePermission = response;
        });
    },
    updateNotePermission(indexNote: number, sliderValue: number) {
      const copy = [...this.notePermission];
      const index = copy.findIndex((item: NotePermissionByProfile) => item.idProfile === this.profileOpened);
      if (index < 0) {
        return;
      }
      switch (indexNote) {
        case PackageNote.OneScore:
          copy[index].oneScore = this.labels[sliderValue];
          break;
        case PackageNote.Enjeu:
          copy[index].enjeux = this.labels[sliderValue];
          break;
        case PackageNote['Enjeux specifiques']:
          copy[index].enjeuxSpecifiques = this.labels[sliderValue];
          break;
        default:
          break;
      }
      this.notePermission = copy;
    },
  },
  mounted() {
    this.getProfiles();
    this.getEntities();
    this.getPermissions();
    this.fetchNotePermission();
  },
})
export default class PermissionsManagement extends Vue {
}
</script>

<style lang="scss" scoped>
.permissions-management {
  width: 100%;
  padding: 0 var(--spacing-default);
  position: relative;

  .spinner-border {
    margin-top: 20%;
  }

  .page-title {
    text-align: left;
    padding: var(--spacing-default) 0;
  }

  .add-profile {
    position: absolute;
    right: 40px;
    top: var(--spacing-small);
  }

  .accordion-container {
    margin: var(--spacing-default) 0;

    .actions {
      display: flex;
      justify-content: center;
      margin: var(--spacing-default);

      button {
        margin: var(--spacing-small);
      }
    }

    .spinner-border {
      margin: 20% 49%;
    }
  }

  h4 {
    text-transform: uppercase;
    font-family: 'SourceSansProLight', sans-serif;
    margin-bottom: var(--spacing-default);
  }

  form {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-bigger) 0;

    .required {
      color: $danger;
      font-size: var(--spacing-small);
      padding-left: var(--spacing-small);
      margin-top: -10px;
    }

    .actions-boutons {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    div {
      display: flex;
      margin-bottom: var(--spacing-small);

      label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 60%;
        text-align: left;
      }

      input {
        border-radius: 4px;
        border: 1px solid lightgrey;
        width: 60%;
        max-width: 400px;
      }
    }
  }

  .user-list {
    padding-bottom: var(--spacing-medium);
    font-family: 'SourceSansProRegular', sans-serif;
  }

  .text {
    font-size: var(--font-size-small);
    color: #606060;
  }

  .select {
    height: 40px;
    width: 250px;
  }

  .fonctionnalite-container {
    display: flex;
    flex-direction: row;
    margin-left: 40px;

    .column {
      width: 100%;
      margin-right: 10%;

      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: var(--spacing-small) 0;

        label {
          font-size: var(--font-size-small);
          width: 90%;
          height: 35px;
          padding-top: 3px;
        }
      }

      .div-parent-permission {
        position: relative;
        margin-top: -15px;

        .checkbox {
          margin: 30px var(--spacing-small) 0;
          border: none;
          background: none;
          height: fit-content;
          padding: 0;
        }
      }

      .div-children-permission {
        input {
          margin: auto 0;
        }

        .checkbox {
          margin: 0 var(--spacing-small);
          border: none;
          background: none;
          height: fit-content;
          padding: 0;
        }
      }
    }
  }
}

.input-parent {
  position: absolute;
  top: 35px;
}

.permissions-wrapper {
  display: flex;
  flex-direction: column;
}

.note-container {
  .note-row {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .form-container {
      display: flex;

      .checkbox {
        margin: 0 var(--spacing-small);
        border: none;
        background: none;
        height: fit-content;
        padding: 0;
        width: 65px;
      }

      p {
        margin: 0 40px;
        width: 150px;
      }
    }
  }
}

</style>

<style lang="scss">
.permissions-management {
  .accordion-container {
    flex-direction: column;
    display: flex;
    padding-left: var(--spacing-bigger);

    .accordion {
      position: relative;
      width: 97%;
      margin: 0;

      .accordion-title {
        font-family: 'RobotoCondensedRegular', sans-serif;
        text-transform: initial;
        font-size: 22px;
      }

      .icon-right {
        position: absolute;
        right: 50px;
        top: 25px;
      }
    }

    .panel {
      width: 97%;
      margin: 0;
    }
  }
}
</style>
