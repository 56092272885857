import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDownMenu = _resolveComponent("DropDownMenu")!

  return (_openBlock(), _createBlock(_component_DropDownMenu, {
    userName: _ctx.firstName + ' ' + _ctx.lastName + _ctx.getRole(),
    iconName: "user",
    options: _ctx.options
  }, null, 8, ["userName", "options"]))
}