<template>
  <div @click="clickOutside">
    <notifications ref="notifications" />
    <div class="tile-container">
      <div class="gamme-label" v-if="showGammeLabel">
        {{ $t('front.product.productDetails.gamme') }}
      </div>
      <div class="action-container" v-if="showPopinAction === product.product.id">
<!--        TODO : uncomment when we will have the right to download product fiche -->
<!--        <div v-if="canDownloadFiche()" @click="downloadFiche">-->
<!--          {{ $t('front.product.productDetails.downloadProduct') }}-->
<!--        </div>-->
        <div v-if="canHaveAddBalance()" @click="addToBalance(product.product.id)">
          {{ $t('front.product.histogram.addToBalance') }}
        </div>
      </div>
      <div v-if="!product.product.isVisible" class="pastille">
        <p>{{ $t('front.product.masked') }}</p>
      </div>
      <div class="action-icon" @click="showActions">
        <i class="fas fa-ellipsis-v"></i>
      </div>
      <div
        v-bind:class="['product', product.product.isVisible ? '' : 'masked']"
        @click="() => handleDetails(product.product.id)"
      >
        <div class="product-container">
          <div class="catalog-flag" v-if="product.product.isInCatalog">
            {{ $t('front.menuFront.gamme') }}
          </div>
          <div class="product-header">
          <span class="product-name">
            {{ product.product.name }}
          </span>
          </div>
          <div class="product-header">
          <span class="product-supplier">
            {{ product.product.supplier ? `(${product.product.supplier})` : '' }}
          </span>
          </div>
          <div class="product-info">
            <span class="info info--amm">
            <img
              class="product-logo"
              alt="PPP logo"
              v-if="isPPP() && havePermissionShowPPPIcon()"
              src="@/assets/PPP_color.svg"
            />
            <img
              class="product-logo semence"
              alt="Seed logo"
              v-if="isSemence() && havePermissionShowSemenceIcon()"
              src="@/assets/semence_color.svg"
            />
            <span v-if="product.product.amm">
              {{ $t('front.product.amm') }} {{ product.product.amm }}
            </span>
            <span v-else-if="product.product.gnis">
              {{ $t('front.product.gnis') }} {{ product.product.gnis }}
            </span>
            </span>
            <span class="info info--segment" v-if="isPPP()">
              {{ product.product.segment }}
              {{ product.product.segment && product.product.segmentSpecific ? ', ' : ''}}
              {{ product.product.segmentSpecific }}
            </span>
            <span class="info info--segment" v-else>
              {{ product.product.culture }}
              {{ product.product.culture && product.product.segmentSpecific ? ', ' : ''}}
              {{ product.product.segmentSpecific }}
            </span>
          </div>
        </div>
      </div>
      <div class="score" @click="() => handleDetails(product.product.id)">
        <DotLoader class="dot-loader" v-if="!logoLoaded"/>
        <div class="image-container" v-if="logo.logoOneScore && logoLoaded">
          <img class="img" alt="One score jauges image" :src="logo.logoOneScore">
        </div>
        <div class="image-container" v-else-if="logo.logoOneScoreAlone && logoLoaded">
          <img class="img" alt="One score image" :src="logo.logoOneScoreAlone">
        </div>
        <div class="update-score">
          <span v-if="!!product.lastDataUpdate">
            {{
              $t('front.product.updateData',
                { date: dayjs(product.lastDataUpdate).format('DD/MM/YYYY') })
            }}
          </span>
        </div>
      </div>
      <div class="button-container">
        <Button
          v-if="canCompare === true"
          :label="$t('front.actions.compare')"
          :onClick="compare">
          <chart-box-plus-outline class="icon" />
        </Button>
        <Button
          v-if="canRemoveFavorite === true"
          :label="$t('front.actions.removeFavorite')"
          :onClick="removeFavorite">
          <close class="icon" />
        </Button>
        <Button
          v-if="canDownloadLogo === true"
          :label="$t('front.actions.downloadLogo')"
          :onClick="downloadLogo">
          <download class="icon" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DotLoader from '@/components/DotLoader.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import dayjs from 'dayjs';
import { Options, Vue } from 'vue-class-component';
import {
  ChartBoxPlusOutline, Close, Download, CheckboxMarked, CheckboxBlankOutline,
} from 'mdue';
import Button from '@/components/Button.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import { inject, PropType } from 'vue';
import ProductsApi, {
  LOGO_TYPES, Product, PRODUCT_TYPE, ScoredProduct,
} from '@/api/products/ProductsApi';
import Notifications from '@/components/Notifications.vue';
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import { BALANCE_SOCIETALE_RECHERCHE_ROUTE } from '@/router/routes';

@Options({
  components: {
    DotLoader,
    Button,
    Download,
    Close,
    ChartBoxPlusOutline,
    CheckboxMarked,
    CheckboxBlankOutline,
    Notifications,
  },
  props: {
    product: {
      type: Object as PropType<ScoredProduct>,
      required: true,
    },
    canCompare: Boolean,
    canRemoveFavorite: Boolean,
    canDownloadLogo: Boolean,
    canCheck: Boolean,
    checked: Boolean,
    showPopinAction: String,
    showGammeLabel: Boolean,
  },
  data() {
    const productsApi = getGlobalInstance(ProductsApi);
    const authorization = inject('authorization');
    return {
      productsApi,
      authorization,
      logo: {
        logoOneScore: '',
        logoOneScoreAlone: '',
        logoAlimentation: '',
        logoAgriculteur: '',
        logoEnvironnement: '',
      },
      logoLoaded: true,
      permissions: [],
    };
  },
  methods: {
    isPPP() {
      return this.product.product.type === PRODUCT_TYPE.PPP;
    },
    isSemence() {
      return this.product.product?.type?.toUpperCase() === PRODUCT_TYPE.SEMENCE;
    },
    addToBalance(id: string) {
      this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE, params: { id } });
    },
    canDownloadFiche() {
      return this.permissions.includes(PERMISSIONS.TELECHARGER_FICHE);
    },
    canHaveAddBalance() {
      return this.permissions.includes(PERMISSIONS.PAGE_COMPARATEUR)
          && this.permissions.includes(PERMISSIONS.AJOUTER_BALANCE_DETAIL);
    },
    downloadFiche() {
      this.$parent.downloadFiche(this.product.product.id);
    },
    showActions() {
      this.$parent.showPopinAction = this.$parent.showPopinAction
        ? undefined : this.product.product.id;
    },
    compare() {
      SprintHelperModule.buttonInDev();
    },
    removeFavorite() {
      SprintHelperModule.buttonInDev();
    },
    downloadLogo() {
      SprintHelperModule.buttonInDev();
    },
    handleDetails(idProduct: string) {
      if (this.permissions.includes(PERMISSIONS.DETAIL_PRODUIT)) {
        this.$parent.displayDetails = idProduct;
      } else if (!this.permissions.includes(PERMISSIONS.DETAIL_PRODUIT)) {
        this.$refs.notifications.notifyWarning(this.$t('notifications.notAllowed'));
      }
    },
    handleClick() {
      if (this.canCheck) {
        if (this.checked) {
          if (this.$parent.checkedList.length === this.$parent.products.length) {
            this.$parent.checkedAll = false;
          }
          const index = this.$parent.checkedList.indexOf(this.product.product.id);
          this.$parent.checkedList.splice(index, 1);
        } else {
          this.$parent.checkedList.push(this.product.product.id);
        }
      } else {
        this.$refs.notifications.notifyError(this.$t('notifications.oneTypeTileProduct'));
      }
    },
    dayjs,
    fetchLogo(logoType: string) {
      this.logoLoaded = false;
      this.productsApi.fetchLogo(this.product.product.id, logoType)
        .then((response: Response) => response.blob())
        .then((response: Blob) => {
          this.logoLoaded = true;
          switch (logoType) {
            case LOGO_TYPES.ONESCORE: {
              this.logo.logoOneScore = URL.createObjectURL(response);
              break;
            }
            case LOGO_TYPES.ONESCORE_ALONE: {
              this.logo.logoOneScoreAlone = URL.createObjectURL(response);
              break;
            }
            default:
              break;
          }
        });
    },
    fetchAllLogos() {
      const permissionLogoOneScore: boolean = this.permissions.includes(PERMISSIONS.NOTE_ONESCORE);
      const permissionLogoEnjeux: boolean = this.permissions.includes(PERMISSIONS.JAUGES_ENJEUX);
      if (permissionLogoOneScore && permissionLogoEnjeux) {
        this.fetchLogo(LOGO_TYPES.ONESCORE);
      } else if (permissionLogoOneScore) {
        this.fetchLogo(LOGO_TYPES.ONESCORE_ALONE);
      }
    },
    havePermissionShowPPPIcon() {
      return this.permissions.includes(PERMISSIONS.MARCHE_PPP);
    },
    havePermissionShowSemenceIcon() {
      return this.permissions.includes(PERMISSIONS.MARCHE_SEMENCE);
    },
  },
  mounted() {
    this.permissions = parseJwt(this.authorization);
    this.fetchAllLogos();
  },
  watch: {
    product: {
      handler() {
        this.fetchAllLogos();
      },
    },
  },
})
export default class ProductTileFront extends Vue {
  product!: Product;

  canCompare!: boolean;

  canRemoveFavorite!: boolean;

  canDownloadLogo!: boolean;
}
</script>

<style lang="scss" scoped>
$primary: var(--grey-5);
$border-color: var(--grey-shadow);
.tile-container {
  width: 296px;
  height: 348px;
  border: solid $border-color 0.5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin: 25px 16px 25px 0;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 6px var(--grey-shadow);

  .gamme-label {
    text-transform: uppercase;
    color: var(--white);
    background-color: $success;
    position: absolute;
    top: 0;
    transform: translate(15%, -50%);
    padding: 7px 20px;
    border-radius: 16px;
    font-size: var(--font-size-hint);
    font-family: "TTNormsLight", sans-serif;
  }

  .action-container {
    position: absolute;
    box-shadow: 0 3px 6px var(--grey-shadow);
    right: 32px;
    background-color: white;
    z-index: 1;
    top: -1px;
    border: 1px solid var(--grey-lightest);
    font-family: "TTNormsRegular", sans-serif;

    :first-child {
      padding: 10px 15px 5px 15px;

      &:hover {
        background-color: var(--grey-lightest);
      }
    }

    :last-child {
      padding: 5px 15px 10px 15px;

      &:hover {
        background-color: var(--grey-lightest);
      }
    }
  }

  .action-container {
    position: absolute;
    box-shadow: 0 3px 6px var(--grey-shadow);
    right: -180px;
    background-color: white;
    z-index: 1;
    top: -1px;
    border: 1px solid var(--grey-1);
    font-family: "TTNormsRegular", sans-serif;

    :first-child {
      padding: 10px 15px 5px 15px;

      &:hover {
        background-color: var(--grey-1);
      }
    }

    :last-child {
      padding: 5px 15px 10px 15px;

      &:hover {
        background-color: var(--grey-1);
      }
    }
  }

  .action-icon {
    width: 25px;
    display: flex;
    align-self: flex-end;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 50%;

    .fas.fa-ellipsis-v {
      color: var(--grey-2);
      padding: 5px 5px 5px 10px;
    }

    &:hover {
      background-color: var(--grey-1);
    }
  }

  .product {
    max-height: 179px;
    color: $primary;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-bottom: solid $border-color 2px;

    .product-container {
      margin-left: 20px;
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      height: 139px;

      .product-header {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .product-name {
          font-size: var(--font-size-default);
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 242px;
          font-family: 'TTNormsRegular', sans-serif;
          color: $primary;
        }
      }

      .product-info {
        display: flex;
        flex-direction: column;
        font-size: small;

        .info {
          margin-top: 5px;
          font-size: var(--font-size-small);
          color: $primary;
          font-family: 'TTNormsLight', sans-serif;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &--amm {
            display: flex;
            width: 242px;

            .product-logo {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin: auto 8px auto 0;
              width: 16px;
              height: 21px;
              object-fit: cover;

              &.semence {
                width: 17px;
              }
            }
          }

          &--segment {
            width: 242px;
          }
        }
      }

      .product-update {
        font-size: var(--font-size-smaller);
        font-style: italic;
        font-weight: 200;
        font-family: 'TTNormsLight', sans-serif;
        color: $primary;
        margin-top: 10px;
      }

      .catalog-flag {
        color: black;
        background-color: white;
        width: fit-content;
        padding: 5px;
        border-radius: var(--border-radius-default);
      }
    }
  }

  .score {
    .image-container {
      transform: translateY(-30%);
    }

    .update-score {
      position: absolute;
      bottom: 10px;
      text-align: center;
      width: 100%;

      span {
        font-size: var(--font-size-smaller);
        font-style: italic;
        font-weight: 200;
        font-family: 'TTNormsLight', sans-serif;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--grey-4);
      }
    }

    .dot-loader {
      padding-top: 40px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;

    button {
      margin: 2px;
    }
  }

  .checkbox {
    border: none;
    background: none;
    height: fit-content;

    &.forbidden {
      cursor: not-allowed;
    }
  }
}

</style>
