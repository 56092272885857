import ApiHttpClient from '@/api/ApiHttpClient';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';

export type Links = {
  cguLink: string;
  legalLink: string;
  confidentialityLink: string;
}

export type VideoLink = {
  value: string;
}

export default class ConfigurationApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ConfigurationApi | undefined;

  private readonly httpClient: ApiHttpClient;

  public static getInstance(): ConfigurationApi {
    if (ConfigurationApi.INSTANCE === undefined) {
      ConfigurationApi.INSTANCE = new ConfigurationApi();
    }

    return ConfigurationApi.INSTANCE;
  }

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetchLinks() {
    return this
      .httpClient
      .restRequest<Links>(HttpMethod.GET, '/configuration/liens')
      .execute();
  }

  getVideoTutorielVideo(index: number) {
    return this
      .httpClient
      .restRequest<VideoLink>(HttpMethod.GET, `/configuration/videos/tutoriel/${index}`)
      .execute();
  }
}
