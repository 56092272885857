<template>
  <div class="cooperative-management">
    <router-view/>
    <h3 class="page-title">{{ $t('admin.cooperative.title') }}</h3>
    <div class="cooperative-search">
      <div class="searchbar">
        <SearchbarComponent
          class="cooperative-searchbar"
          :text="$t('admin.cooperative.search')"
        />
      </div>
      <div class="cooperative-search--actions">
      <Button :onClick="fetchCooperatives" :label="$t('common.button.search')" color="dark">
        <magnify class="icon"/>
      </Button>
      <Button :onClick="addCooperative" :label="$t('common.button.add')" color="secondary">
        <plus class="icon" />
      </Button>
      </div>
    </div>
    <div class="cooperative-list">
       <span class="search-results" v-if="!dataLoaded">
        {{ $t('common.loader')}}
      </span>
      <span class="search-results" v-else>
        {{ $t('admin.users.search.results', { count: cooperatives.length }) }}
      </span>
      <hr/>
      <div
        class="tile"
        v-for="cooperative in cooperatives.slice(0, tileToDisplay)"
        :key="cooperative.id"
      >
        <CooperativeTile :cooperative="cooperative" />
      </div>
      <div class="spinner-border" role="status" v-if="!dataLoaded"/>
    </div>
    <Pagination
      v-if="tileToDisplay !== cooperatives.length && dataLoaded"
      :label="$t('common.button.displayMore')"
      :nbrTileDefault="nbrTileDefault"
      :totalItem="cooperatives.length"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Eye, Magnify, Plus } from 'mdue';
import CooperativeTile from '@/views/admin/cooperative/CooperativeTile.vue';
import * as SprintHelperModule from '@/SprintHelperModule';
import CooperativesApi, { Cooperative } from '@/api/cooperatives/CooperativesApi';
import Pagination from '@/components/Pagination.vue';
import SearchbarComponent from '@/components/SearchbarComponent.vue';
import { COOPERATIVE_DETAIL_ROUTE } from '@/router/routes';

@Options({
  components: {
    Button,
    CooperativeTile,
    Eye,
    Plus,
    Magnify,
    Pagination,
    SearchbarComponent,
  },
  data() {
    const cooperativesApi = getGlobalInstance(CooperativesApi);
    return {
      cooperativesApi,
      cooperatives: [],
      nbrTileDefault: 15,
      tileToDisplay: this.nbrTileDefault,
      search: '',
      dataLoaded: false,
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    handleSearch() {
      this.fetchCooperatives();
    },
    fetchCooperatives() {
      return this.cooperativesApi
        .fetch(this.search)
        .then((response: Cooperative[]) => {
          this.dataLoaded = true;
          this.cooperatives = response;
          const displayedTiles = Math.max(this.tileToDisplay || 0, this.nbrTileDefault);
          this.tileToDisplay = Math.min(displayedTiles, response.length);
        });
    },
    addCooperative() {
      this.$router.push({
        name: COOPERATIVE_DETAIL_ROUTE,
        params: { id: 'new' },
      });
    },
  },
  mounted() {
    this.fetchCooperatives();
  },
  watch: {
    $route() {
      this.$nextTick(this.fetchCooperatives);
    },
  },
})
export default class CooperativeManagement extends Vue {
}
</script>

<style lang="scss" scoped>

.cooperative-management {
  width: 100%;

  .page-title {
    text-align: left;
    padding: var(--spacing-default);
  }

  .cooperative-search {
      display: flex;
      width: 90%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: var(--spacing-bigger) 5% 0;
      .searchbar {
        font-family: "SourceSansProLight", sans-serif;
        width: 70%;
      }
      .button {
        margin-top: var(--spacing-default);
      }
    .cooperative-search--actions {
      display: flex;
      justify-content: space-around;
    }
  }

  .cooperative-list {
    width: 99%;
    padding: var(--spacing-default);
    text-align: left;
    .spinner-border {
      margin: 10% 48%;
    }
    .search-results {
      font-size: 18px;
      font-family: 'LatoLight', sans-serif;
    }

    .tile {
      margin-bottom: var(--spacing-default);
    }
  }
}

</style>
