<template>
  <div class="range r600">
    <div :class="['slider-label', disabled ? 'disabled' : '']">
      <span :style="style" v-for="label in labels" :key="label">{{ label }}</span>
    </div>
    <div class="field">
      <input
        type="range"
        class="r500"
        :min="min"
        :max="max"
        v-model="slider"
        step="1"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    min: Number,
    max: Number,
    labels: Array,
    disabled: Boolean,
    defaultValue: Number,
    indexNote: Number,
    updateNotePermission: Function,
  },
  data() {
    return {
      slider: this.defaultValue >= 0 ? this.defaultValue : this.min,
    };
  },
  computed: {
    style() {
      const range = (this.max - this.min);
      const rond = (30 / range) * -(this.sliderMin - (range / 2));
      // taille totale 140px et 44px/2 pour la moitié de la div
      return `left: ${this.sliderMin * (400 / range) - 22 + rond}px`;
    },
  },
  watch: {
    slider: {
      handler() {
        this.updateNotePermission(this.indexNote, this.slider);
      },
      deep: true,
    },
  },
})
export default class SliderRangeWithText extends Vue {

}
</script>

<style lang="scss">
$range: 80px;
$span: 44px;
$color: var(--grey-5);
$input: 20px;

.range {
  height: $range;
  border-radius: 10px;
  padding: 0 65px 0 45px;

  &.r600 {
    width: 65% !important;
    margin: 0;
    box-shadow: none;
  }

  .slider-label {
    position: relative;
    height: 0;
    display: flex;
    justify-content: space-between;
    width: 110%;
    margin-left: -5%;
    padding-top: 10px;

    &.disabled {
      span {
        color: lightgrey;
      }
    }
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 1;

    .value {
      position: absolute;
      font-size: 15px;
      color: $color;
      font-weight: 300;
    }

    .range input::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: $input;
      height: $input;
      border-radius: 50%;
      background: $color;
      border: 1px solid $color;
      cursor: pointer;
    }

    .range input::-moz-range-thumb {
      -webkit-appearance: none;
      width: $input;
      height: $input;
      border-radius: 50%;
      background: $color;
      border: 1px solid $color;
      cursor: pointer;
    }

    .range input::-moz-range-progress{
      background: $color;
    }
  }
}
input[type="range"] {
  position: absolute;

  &.r500 {
    width: 100%;
  }
}

input[type="range"]::-webkit-slider-thumb {
  position: relative;
  z-index: 100;
}
</style>
