<template>
  <div class="input-group">
    <span class="input-group-icon">
        <magnify class="icon" />
    </span>
    <input
        v-bind:style="[buttonColor ? {border: 0 } : {}]"
        type="text"
        class="form-control"
        :placeholder="getPlaceholder()"
        v-model="researchInput"
        v-on:keyup.enter="$emit('handleSearch', researchInput)"
    >
    <button
        v-bind:style="[buttonColor ? {color: `#FFF`, background: buttonColor, borderColor: buttonColor,} : {}]"
        class="btn btn-outline-primary"
        v-on:click="$emit('handleSearch', researchInput)"
    >
      {{ $t('common.button.search') }}
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Magnify } from 'mdue';

@Options({
  components: {
    Magnify,
  },
  props: {
    buttonColor: String,
    initialValue: String,
    selected: Object,
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue?.label !== oldValue?.label) {
        this.researchInput = '';
      }
    },
    initialValue() {
      if (this.initialValue) {
        this.researchInput = this.initialValue;
      }
    },
  },
  data() {
    return {
      researchInput: '',
    };
  },
  methods: {
    getPlaceholder() {
      if (this.selected) {
        return this.$t(`front.balanceSocietale.creation.recherche.placeholderTab${this.selected.maleWord ? 'Male' : ''}`,
          { selectedTab: this.selected.label });
      }
      return this.$t('front.balanceSocietale.creation.recherche.placeholder');
    },
  },
})
export default class ResearchBar extends Vue {
}
</script>

<style lang="scss" scoped>
.input-group {
  width: var(--search-bar-width);
  margin: 0 auto;
  height: var(--search-bar-height);

  .input-group-icon {
    position: absolute;
    z-index: 5;
    padding: var(--spacing-medium) var(--spacing-default);

    .icon {
      font-size: var(--font-size-medium);
    }
  }

  .form-control {
    border: 2px solid var(--grey-5);
    height: var(--search-bar-height);
    font-family: "TTNormsLight", sans-serif;
    padding: 0 61px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      border: 2px solid var(--grey-5);
      -webkit-box-shadow: none;
    }
  }

  .btn.btn-outline-primary {
    border-radius: 0;
    background-color: var(--grey-5);
    color: $light;
    font-size: var(--font-size-smaller);
    border-color: var(--grey-5);
    font-family: "TTNormsRegular", sans-serif;
    height: 100%;
    align-self: flex-end;
  }
}

</style>
