import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4be1f57e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "range" }
const _hoisted_2 = { class: "slider-value" }
const _hoisted_3 = { class: "slider-value-span left" }
const _hoisted_4 = { class: "slider-value-span right" }
const _hoisted_5 = { class: "field" }
const _hoisted_6 = ["min", "max"]
const _hoisted_7 = ["min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.notes.min')) + " " + _toDisplayString(_ctx.sliderMin), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.notes.max')) + " " + _toDisplayString(_ctx.sliderMax), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createElementVNode("input", {
        class: "custom-slider custom-slider-grey",
        type: "range",
        min: _ctx.min,
        max: _ctx.max,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sliderMin) = $event)),
        onChange: _cache[1] || (_cache[1] = (e) => _ctx.checkMinSliderValue(e.target.value)),
        step: "1"
      }, null, 40, _hoisted_6), [
        [_vModelText, _ctx.sliderMin]
      ]),
      _withDirectives(_createElementVNode("input", {
        class: "custom-slider",
        type: "range",
        min: _ctx.min,
        max: _ctx.max,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sliderMax) = $event)),
        onChange: _cache[3] || (_cache[3] = (e) => _ctx.checkMaxSliderValue(e.target.value)),
        step: "1"
      }, null, 40, _hoisted_7), [
        [_vModelText, _ctx.sliderMax]
      ])
    ])
  ]))
}