import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05486aa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_ButtonNoBorder = _resolveComponent("ButtonNoBorder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeSelectionFiltered(), (field, index) => {
        return (_openBlock(), _createElementBlock("div", { key: field }, [
          _createVNode(_component_ButtonNoBorder, {
            locked: _ctx.lockButtons,
            label: field.label,
            color: _ctx.getColorFromType(field.type),
            onClick: ($event: any) => (_ctx.handleSelection(index)),
            selected: field.selected
          }, null, 8, ["locked", "label", "color", "onClick", "selected"])
        ]))
      }), 128))
    ])
  ], 64))
}