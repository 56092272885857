<template>
  <div v-bind:class="[isPopinFiltre ? 'accordion-container--note' : 'accordion-container']">
    <button
      v-bind:class="['accordion', collapseAccordion ? 'active' : '', type ?? type, '']"
      @click="handleClick($event)"
    >
      <div class="selected"></div>
      <MDBIcon class="icon-left" :icon="iconLeft"></MDBIcon>
      <span class="accordion-title">{{ title }}</span>
      <MDBIcon :icon="getIconRight()" class="icon-right"></MDBIcon>
    </button>
    <div v-bind:class="['panel', collapseAccordion ? 'active' : '']">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MDBIcon } from 'mdb-vue-ui-kit';

@Options({
  components: {
    MDBIcon,
  },
  props: {
    isPopinFiltre: Boolean,
    isEnjeuSpe: Boolean,
    title: String,
    isActive: Boolean,
    iconLeft: String,
    iconRight: String,
    selected: Boolean,
    index: Number,
    type: String,
    id: Number,
  },
  data() {
    return {
      collapseAccordion: true,
    };
  },
  methods: {
    handleClick(e: Event) {
      this.collapseAccordion = !this.collapseAccordion;
      if (this.type === 'unique' && this.index !== undefined) {
        if (this.collapseAccordion) {
          this.$parent.accordionOpenedIndex = this.index;
        } else {
          this.$parent.accordionOpenedIndex = '';
        }
      }
      if (typeof this.$parent.handleClickAccordion === 'function') {
        this.$parent.handleClickAccordion(this.id, e);
      }
    },
    getIconRight() {
      // specific for productScore
      if (this.iconRight === 'angle-right') {
        return this.collapseAccordion ? 'angle-down' : 'angle-right';
      }
      return this.iconRight;
    },
  },
  mounted() {
    this.collapseAccordion = this.isActive;
  },
  updated() {
    if (this.index !== undefined) {
      this.collapseAccordion = this.isActive;
    }
  },
})
export default class Accordion extends Vue {
}

</script>

<style lang="scss" scoped>

$margin-content: var(--spacing-bigger);

.accordion-container {
  border-bottom: 1px solid var(--grey-1);
  .accordion {
    background-color: white;
    color: var(--grey-7);
    cursor: pointer;
    padding: var(--spacing-default);
    width: -webkit-fill-available;
    border: none;
    text-align: left;
    outline: none;
    font-size: var(--font-size-default);
    transition: 0.4s;
    text-transform: uppercase;
    height: 70px;
    margin-left: $margin-content;

    .accordion-title {
      font-family: 'TTNormsRegular', sans-serif;
    }
  }

  .accordion:hover {
    background-color: var(--grey-1);
  }

  .panel {
    margin-left: $margin-content;
    padding: 0 var(--spacing-default);
    display: none;
    background-color: white;
    overflow: hidden;
    text-align: left;
  }

  .panel.active {
    display: block;
  }
}
.accordion-container--note {
  border-bottom: 1px solid grey;
  .accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    font-family: 'SourceSansProRegular', sans-serif;
    text-align: left;
    color: black;
    cursor: pointer;
    padding: var(--spacing-default);
    width: -webkit-fill-available;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: var(--font-size-small);
    height: 60px;
    .accordion-title {
      font-family: 'TTNormsRegular', sans-serif;
      width: 90%;
    }

  }
  .selected {
    display: none;
  }
  .icon-left {
    display: none;
  }
  icon-right {
    display: flex;
  }
  .accordion:hover {
    background-color: var(--grey-1);
  }
  .panel {
    display: none;
    background-color: white;
    overflow: hidden;
  }

  .panel.active {
    display: block;
  }

  &.ALIMENTATION {
    .accordion {
      background: $tertiary;
      color: var(--white);
    }
  }

  &.AGRICULTEUR {
    .accordion {
      background: $info;
      color: var(--white);
    }
  }

  &.ENVIRONNEMENT {
    .accordion {
      background: $success;
      color: var(--white);
    }
  }
}
@-moz-document url-prefix() {
  .accordion {
    i {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
    }
  }

}
</style>
