<template>
  <notifications ref="notifications" />
  <div class="wrapper">
    <h4 class="title">
      {{ $t('admin.cooperative.users.subtitleCount', { count: users.length }) }}
    </h4>
    <table class="table table-borderless" v-if="users.length > 0">
      <thead>
      <tr>
        <th class="text-td">{{ $t('admin.cooperative.users.firstName') }}</th>
        <th class="text-td">{{ $t('admin.cooperative.users.lastName') }}</th>
        <th></th>
        <th>{{ $t('admin.cooperative.users.referent') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(user, index) in users" :key="index">
        <td class="text-td">{{ user.firstName }}</td>
        <td class="text-td text-uppercase">{{ user.lastName }}</td>
        <td class="button-td">
          <Button @click="() => handleDetails(user.id)">
            <MDBIcon iconstyle="fab" icon="eye"></MDBIcon>
            {{ $t('admin.cooperative.users.accessButton') }}
          </Button>
        </td>
        <td>
          <div v-if="user.isReferent">
            <MDBRadio
              :formCheck="false"
              name="radio"
              v-model="user.id"
              :value="user.id"
            />
          </div>
          <div v-else>
            <MDBRadio
              :formCheck="false"
              name="radio"
              v-model="radioRef"
              :value="user.id"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="users.length === 0" class="message-sprint">
      <h3>{{ $t('admin.cooperative.users.noUsers') }}</h3>
    </div>
    <div class="buttons-wrapper" v-if="users.length > 0">
      <Button :label="$t('common.button.save')" color="primary" :onClick="handleSave">
        <content-save class="icon"/>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import CooperativesApi, { CooperativeUser } from '@/api/cooperatives/CooperativesApi';
import UsersApi from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import Drawer from '@/components/Drawer.vue';
import Notifications from '@/components/Notifications.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { UTILISATEUR_DETAIL_ROUTE } from '@/router/routes';
import * as SprintHelperModule from '@/SprintHelperModule';
import { MDBIcon, MDBRadio } from 'mdb-vue-ui-kit';
import { ContentSave } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Button,
    MDBRadio,
    MDBIcon,
    Drawer,
    ContentSave,
    Notifications,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    const cooperativesApi = getGlobalInstance(CooperativesApi);
    return {
      usersApi,
      cooperativesApi,
      radioRef: '',
      users: [],
      userToUpdate: {},
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    fetchUsers(id: string) {
      return this.cooperativesApi
        .fetchCooperativeUsers(id)
        .then((response: CooperativeUser[]) => {
          this.users = response;
        });
    },
    handleDetails(id: string) {
      this.$router.push({
        name: UTILISATEUR_DETAIL_ROUTE,
        params: { id },
      });
    },
    handleSave() {
      this.users.forEach((user: CooperativeUser) => {
        if (user.id === this.radioRef) {
          this.userToUpdate = user;
          this.userToUpdate.isReferent = true;
        }
      });
      if (this.userToUpdate.id) {
        this.usersApi.updateUser(this.userToUpdate)
          .then(() => {
            this.$refs.notifications.notifySuccess();
          })
          .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSave')));
      }
    },
  },
  mounted() {
    this.fetchUsers(this.$route.params.id);
  },
})
export default class CooperativeUsersList extends Vue {
  users: CooperativeUser[] = [];
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin: var(--spacing-bigger);
  display: flex;
  flex-direction: column;
}

h4.title {
  text-align: left;
  text-transform: uppercase;
  margin: var(--spacing-small) 0 var(--spacing-bigger) 0;
  font-family: 'SourceSansProLight', sans-serif;
}

.button-td {
  justify-content: center;
  display: flex;

  button {
    // Honnetement pas trop sûr du pourquoi,
    // sinon le bouton se retrouve sur 2 lignes
    display: block;
  }
}

.text-td {
  text-align: left;
  padding-left: 0 !important;
}

td {
  font-size: var(--font-size-small);
  &.text-uppercase {
    text-transform: uppercase;
  }
}

th {
  font-family: 'SourceSansProSemiBold', sans-serif;
  font-size: var(--font-size-smaller);
}

i {
  margin-right: var(--spacing-small);
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.validate-button {
  margin: var(--spacing-bigger);
}
</style>
