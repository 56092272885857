import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4814cf17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = { class: "input-group-icon" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_magnify, { class: "icon" })
    ]),
    _withDirectives(_createElementVNode("input", {
      id: "search-input",
      type: "text",
      class: "form-control",
      placeholder: _ctx.$t('common.button.search'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.researchInput) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('handleSearch', _ctx.researchInput)), ["enter"]))
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.researchInput]
    ])
  ]))
}