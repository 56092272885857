<template>
  <div class="accordion-container">
    <button
      v-bind:class="['accordion', collapseAccordion ? 'active' : '', type ?? type]"
      @click="handleClick"
    >
      <span class="accordion-title">{{ sortUsed }}</span>
      <MDBIcon icon="angle-down" class="icon-right"></MDBIcon>
    </button>
    <div v-bind:class="['panel', collapseAccordion ? 'active' : '']">
      <div v-for="sort in sortList" :key="sort">
        <button @click="() => handleSort(sort)">{{ sort }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import { inject } from 'vue';
import { MDBIcon } from 'mdb-vue-ui-kit';
import FournisseursApi, { PRODUCT_SORT } from '@/api/fournisseurs/FournisseursApi';
import { Authorization, parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';

@Options({
  components: {
    MDBIcon,
  },
  props: {
    sortUsed: String,
  },
  data() {
    const fournisseursApi = getGlobalInstance(FournisseursApi);
    const authorization: Authorization | undefined = inject('authorization');
    const sortList: string[] = [];
    if (authorization !== undefined) {
      const permissions: string[] = parseJwt(authorization.value);
      if (permissions.includes(PERMISSIONS.NOTE_ONESCORE)) {
        sortList.push(PRODUCT_SORT.NoteCroissanteOneScore);
        sortList.push(PRODUCT_SORT.NoteDecroissanteOneScore);
      }
      if (permissions.includes(PERMISSIONS.NOTES_ENJEUX)) {
        sortList.push(
          PRODUCT_SORT.NoteCroissanteAgriculture,
          PRODUCT_SORT.NoteDecroissanteAgriculture,
          PRODUCT_SORT.NoteCroissanteAlimentation,
          PRODUCT_SORT.NoteDecroissanteAlimentation,
          PRODUCT_SORT.NoteCroissanteEnvironnement,
          PRODUCT_SORT.NoteDecroissanteEnvironnement,
        );
      }
    }
    sortList.push(PRODUCT_SORT.AtoZ, PRODUCT_SORT.ZtoA);
    return {
      fournisseursApi,
      collapseAccordion: false,
      authorization,
      sortList,
    };
  },
  methods: {
    handleClick() {
      this.collapseAccordion = !this.collapseAccordion;
    },
    handleReset() {
      this.$emit('setSort', PRODUCT_SORT.TrierPar);
    },
    handleSort(sort: string) {
      this.$parent.products = [];
      this.$parent.tileToDisplay = this.$parent.nbrTileDefault;
      this.$parent.sortUsed = sort;
      this.handleClick();
      if (typeof this.$parent.fetchProducts === 'function') {
        this.$parent.fetchProducts();
      } else if (typeof this.$parent.handleSearch === 'function') {
        this.$parent.handleSearch(this.$parent.researchInput);
      }
      this.$emit('setSort', sort);
    },
  },
})
export default class ProductSort extends Vue {
}

</script>

<style lang="scss" scoped>

$margin-content: var(--spacing-bigger);

.accordion-container {
  width: 300px;

  .accordion {
    background-color: white;
    color: var(--grey-7);
    cursor: pointer;
    width: -webkit-fill-available;
    border: none;
    outline: none;
    font-size: var(--font-size-default);
    transition: 0.4s;
    text-transform: uppercase;
    height: 40px;
    margin-right: $margin-content;
    border-bottom: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .accordion-title {
      font-family: 'TTNormsRegular', sans-serif;
      font-size: var(--font-size-smaller);
      color: $primary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
      text-align: left;
    }
  }

  .accordion:hover {
    background-color: var(--grey-1);
  }

  .panel {
    padding: 0 var(--spacing-default);
    background-color: #f7f7f7;
    overflow: hidden;
    text-align: left;
    display: none;
    position: absolute;
    z-index: 10;

    button {
      border: none;
      background: none;
      padding: 5px 0;
      width: 100%;
      text-align: left;
    }

    button:hover {
      background: #efefef;
    }
  }

  .panel.active {
    display: block;
  }
}
</style>
