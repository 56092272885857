import { HttpPlumeResponse } from '@/lib/plume-http/client/PlumeHttpResponse';
import PlumeHttpClientHelpers from '@/lib/plume-http/client/PlumeHttpClientHelpers';
import HttpRequest from '@/lib/simple-http-request-builder/HttpRequest';

export interface HttpClientDelegate {
  unauthorizedAccessReceived(): void;
}

export default class OneScoreHttpClientHelper extends PlumeHttpClientHelpers {
  public static delegate: HttpClientDelegate;

  static serverErrorHandler<T>(response: Response): Promise<HttpPlumeResponse<T>> {
    if (response.status === 403) {
      OneScoreHttpClientHelper.delegate.unauthorizedAccessReceived();
    }

    return super.serverErrorHandler(response);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static executeRest<T>(httpRequest: HttpRequest<any>) {
    return PlumeHttpClientHelpers
      .execute(httpRequest)
      .then(OneScoreHttpClientHelper.serverErrorHandler)
      .catch(PlumeHttpClientHelpers.networkErrorCatcher) as Promise<HttpPlumeResponse<T>>;
  }
}
