<template>
  <span />
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
  methods: {
    notifySuccess() {
      this.$toasts.success(this.$t('notifications.success'));
    },
    notifyCreation(message) {
      this.$toasts.success(message);
    },
    notifyError(message) {
      this.$toasts.error(`Erreur: ${message}`);
    },
    notifyInfo() {
      this.$toasts.base(this.$t('notifications.inaccessible'));
    },
    notifyWarning(message) {
      this.$toasts.warning(`Alerte: ${message}`);
    },
  },
})
export default class Notifications extends Vue {
}
</script>
