<template>
  <notifications ref="notifications"/>
  <img
    alt="login-background"
    class="background-image"
    :src="require(`@/assets/ble.jpg`)"
  />
  <div class="login-page">
    <div class="login-container">
      <div v-if="showLoginPopin">
        <h4 class="login-title">{{ $t('front.login.title') }}</h4>
        <p class="error-message" v-if="message !== ''">{{ message }}</p>
        <div class="not-error" v-else/>
        <form class="form-connexion" @submit.prevent="loginAction">
          <div class="champs-connexion">
            <div class="container-input">
              <MDBInput class="champs-form" type="email" autocomplete="username"
                        :label="loginPlaceholder" bg size="lg" v-model="login"
                        v-on:focus="setFocusEmail(true)" v-on:blur="setFocusEmail(false)"
              />
              <div class="dot" v-if="login && !needCodeVerification" @click="drainEmail">
                <i class="fa fa-times"/>
              </div>
            </div>
          </div>
          <div class="champs-connexion">
            <div class="container-input">
              <div class="dot" v-if="password && !needCodeVerification" :onClick="drainPassword">
                <i class="fa fa-times" onClick="drainPassword"/>
              </div>
              <MDBInput class="champs-form" type="password" autocomplete="current-password"
                        :label="passwordPlaceholder" bg size="lg"
                        v-on:focus="setFocusPassword(true)"
                        v-on:blur="setFocusPassword(false)" v-model="password"/>
            </div>
          </div>
          <div class="action-mdp-oublie" :onClick="forgottenPassword">
            {{ $t('front.login.button.forgot') }}
          </div>
          <div class="btn-container">
            <Button
              type="submit"
              :class="loaderLogin ? 'disabled' : 'submit'"
              :label="$t('front.login.button.login')"
              :loading="loaderLogin"
            />
          </div>
        </form>
      </div>
      <CGUPopin
          v-else-if="showCGUPopin === true"
          @close="closeCGUPopin"
          @validateCGU="validateCGU"
      />
      <RecoverPassword v-else :forgottenPassword="forgottenPassword"/>
      <CodeValidationLogin
          v-if="needCodeVerification"
          :goToHome="() => goToHome(user?.isFirstConnexion)"
      />
    </div>
  </div>
  <img
    alt="logo-onescore"
    class="logo-onescore"
    :src="require(`@/assets/logo_marque_onescore_white.png`)"
  />
</template>

<script lang="ts">
import AuthentApi, { LoginResponse, parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import UsersApi, { User } from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import Notifications from '@/components/Notifications.vue';
import Popin from '@/components/Popin.vue';
import { HttpPlumeError } from '@/lib/plume-http/client/PlumeHttpResponse';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import {
  ACCUEIL,
  BALANCE_SOCIETALE_ROUTE,
  LOGIN,
  NO_ACCESS,
  PRODUCT_LIST_ADMIN_ROUTE,
  PRODUCT_ROUTE,
} from '@/router/routes';
import CGUPopin from '@/views/front/login/CGUPopin.vue';
import CodeValidationLogin from '@/views/front/login/CodeValidationLogin.vue';
import RecoverPassword from '@/views/front/login/RecoverPassword.vue';
import { MDBInput } from 'mdb-vue-ui-kit';
import { inject } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Button,
    Popin,
    MDBInput,
    RecoverPassword,
    CGUPopin,
    CodeValidationLogin,
    Notifications,
  },
  data() {
    const authorization = inject('authorization');
    const authentApi = getGlobalInstance(AuthentApi);
    const usersApi = getGlobalInstance(UsersApi);
    return {
      authentApi,
      usersApi,
      authorization,
      showLoginPopin: this.$route.name === LOGIN,
      focusedEmail: false,
      focusedPassword: false,
      needCodeVerification: false,
      login: '',
      password: '',
      message: '',
      user: null,
      showCGUPopin: false,
      loginPlaceholder: this.$t('front.login.placeholder.login'),
      passwordPlaceholder: this.$t('front.login.placeholder.password'),
      token: '',
      loaderLogin: false,
    };
  },
  emits: ['authenticated'],
  methods: {
    setFocusEmail(focus: boolean) {
      this.focusedEmail = focus;
    },
    setFocusPassword(focus: boolean) {
      this.focusedPassword = focus;
    },
    drainEmail() {
      this.login = '';
    },
    drainPassword() {
      this.password = '';
    },
    loginAction() {
      this.loaderLogin = true;
      this.authentApi.login({
        login: this.login,
        password: this.password,
      })
        .then((loginResponse: LoginResponse) => {
          if (loginResponse.token === '') {
            this.needCodeVerification = true;
            this.$refs.notifications.notifyCreation(
              this.$t('front.login.validationCode.mailSent'),
            );
          } else {
            this.token = loginResponse.token;
            this.checkCGUAcceptance();
          }
          this.loaderLogin = false;
        })
        .catch((error: HttpPlumeError) => {
          this.message = this.$t(`front.login.error.${error.errorCode}`, { field: error.statusArguments ? error.statusArguments[0] : '' }, this.$t('formulaireErrors.common'));
          this.loaderLogin = false;
        });
    },
    checkCGUAcceptance() {
      this.usersApi.fetchMe(this.token)
        .then((user: User) => {
          if (user.hasAcceptedCgu) {
            this.goToHome(user.isFirstConnexion);
          } else {
            this.user = user;
            this.showLoginPopin = false;
            this.showCGU();
          }
        })
        .catch(() => {
          this.message = this.$t('formulaireErrors.common');
        });
    },
    forgottenPassword() {
      this.showLoginPopin = !this.showLoginPopin;
    },
    goToHome(isFirstConnexion: boolean) {
      this.$emit('authenticated', this.token);
      const permissions: string[] = parseJwt(this.authorization);

      if (permissions.includes(PERMISSIONS.PAGE_ACCUEIL)) {
        if (isFirstConnexion) {
          this.$router.push({ name: ACCUEIL, query: { openTutorial: true } });
        } else {
          this.$router.push({ name: ACCUEIL });
        }
      } else if (permissions.includes(PERMISSIONS.PAGE_RECHERCHE)) {
        this.$router.push({ name: PRODUCT_ROUTE });
      } else if (permissions.includes(PERMISSIONS.PAGE_COMPARATEUR)) {
        this.$router.push({ name: BALANCE_SOCIETALE_ROUTE });
      } else if (permissions.includes(PERMISSIONS.ADMIN)) {
        this.$router.push({ name: PRODUCT_LIST_ADMIN_ROUTE });
      } else {
        this.$router.push({ name: NO_ACCESS });
      }
    },
    closeCGUPopin() {
      this.showCGUPopin = false;
      this.showLoginPopin = true;
    },
    showCGU() {
      this.showCGUPopin = true;
    },
    validateCGU() {
      this.user.hasAcceptedCgu = true;
      this.usersApi.updateCgu(this.user?.id, this.token)
        .then(() => {
          this.showCGUPopin = false;
          this.goToHome(this.user?.isFirstConnexion);
        })
        .catch(() => {
          this.message = this.$t('formulaireErrors.common');
        });
    },
  },
})
export default class Login extends Vue {
  user?: User;
}
</script>

<style lang="scss" scoped>
.background-image {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  object-fit: cover;
  top: 0;
  background: linear-gradient(#{var(--header-color)}) no-repeat center;
}

.login-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
}

.login-container {
  margin: 0 auto;
  position: relative;
  width: 458px;
  height: auto;
  min-height: 370px;
  background: white;
  display: flex;
  flex-direction: column;

  .error-message {
    margin: -1rem 0 0;
    display: flex;
    justify-content: center;
  }

  .not-error {
    display: initial;
   }

  .login-title {
    font-family: TTNormsBold, sans-serif;
    text-transform: uppercase;
    color: var(--grey-5);
    display: flex;
    justify-content: center;
    padding-top: 30px;
    position: relative;
    margin: 0 auto 30px;
    width: 80%;
  }

  .form-connexion {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 248px;
    width: 318px;
    margin: auto;

    ::v-deep .champs-connexion {
      width: 100%;

      .container-input {
        width: 100%;
        height: auto;
        position: relative;

        .dot {
          position: absolute;
          right: .5rem;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: rgba(58, 60, 64, 0.10);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 1;

          i {
            display: inline-block;
            font-size: 12px;
            cursor: pointer;
          }
        }

      }

      .form-outline {
        .form-control.form-control-lg ~ .form-label {
          top: 50%;
          transform: translateY(-50%);
          color: rgba(88, 96, 100, 0.70);
          font-family: TTNormsLight, sans-serif;
          text-align: left;
          padding: 0 3px;
        }

        .form-control.form-control-lg.active,
        .form-control.form-control-lg:focus {
          & ~ .form-label {
            font-family: TTNormsRegular, sans-serif;
            color: $connexion-primary;
            font-size: var(--font-size-hint);
            text-align: left;
            padding-left: 3px;
            transform: translateY(-50%) translateY(-60%);
          }
        }

        .form-notch .form-notch-middle {
          display: none;
        }
      }

      input {
        font-family: TTNormsRegular, sans-serif;
        height: var(--btn-height-big);
        padding: 20px 30px 0 15px
      }
      input::-webkit-credentials-auto-fill-button {
        background-color: white;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
      input::-webkit-caps-lock-indicator {
        content: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    width: 193px;
    margin: 15px auto 0;

    button {
      background-color: $connexion-primary;
      color: var(--white);
      width: 100%;
    }
  }

  .action-mdp-oublie {
    font-family: TTNormsRegular, sans-serif;
    cursor: pointer;
    display: flex;
    margin-top: -0.5rem;
    text-decoration: underline;
    color: $mdp-oublie;
    font-size: var(--font-size-smaller);
  }

  button > text {
    width: 100%;
  }
}

.logo-onescore {
  bottom: 30px;
  right: 38px;
  width: 341px;
  position: absolute;
}
</style>
