<template>
  <div class="cgu-container">
    <div class="container">
      <h4 class="title">{{ $t('front.login.cgu.title') }}</h4>
      <p class="content">
        {{ $t('front.login.cgu.desc') }}
        <br/>
        <span>
          {{ $t('front.login.cgu.desc2') }}
          <a target="_blank" :href="links.cguLink">{{ $t('front.login.cgu.link') }}</a>
        </span>
      </p>
      <div class="checkbox-container" @click="handleCheckbox()">
        <checkbox-marked v-if="checked" class="icon"/>
        <checkbox-blank-outline v-if="!checked" class="icon"/>
        <p>{{ $t('front.login.cgu.text') }}</p>
      </div>

      <div class="footer">
        <div class="buttons">
          <div class="btn-container">
            <Button class="btn-secondary" :label="$t('common.button.cancel')"
                    @click="$emit('close')"
            />
          </div>
          <div class="btn-container">
            <Button
              :label="$t('front.login.cgu.validate')"
              :disabled="!checked"
              @click="$emit('validateCGU')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AuthentApi from '@/api/authentication/AuthentApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { ArrowLeft, CheckboxBlankOutline, CheckboxMarked } from 'mdue';
import { MDBInput } from 'mdb-vue-ui-kit';
import ConfigurationApi, { Links } from '@/api/configuration/ConfigurationApi';

@Options({
  components: {
    Button,
    ArrowLeft,
    MDBInput,
    CheckboxMarked,
    CheckboxBlankOutline,
  },
  data() {
    const configurationApi = getGlobalInstance(ConfigurationApi);
    const authentApi = getGlobalInstance(AuthentApi);
    return {
      authentApi,
      configurationApi,
      checked: false,
      login: '',
      message: '',
      errorMessage: '',
      links: {},
    };
  },
  methods: {
    handleCheckbox() {
      this.checked = !this.checked;
    },
    setFocusEmail(focus: boolean) {
      this.focusedEmail = focus;
    },
    drainEmail() {
      this.login = '';
    },
    recoverPassword() {
      this.message = '';
      this.authentApi.recoverPassword({
        login: this.login,
      })
        .then(() => {
          this.message = this.$t('front.forgorPassword.desc2');
        })
        .catch(() => {
          this.errorMessage = this.$t('formulaireErrors.common');
        });
    },
  },
  mounted() {
    this.configurationApi.fetchLinks()
      .then((links: Links) => {
        this.links = links;
      });
  },
})
export default class CGUPopin extends Vue {
}
</script>

<style lang="scss" scoped>
.cgu-container {
  background: white;
  z-index: 300;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 400px;

    .title {
      font-family: 'TTNormsBold', sans-serif;
      text-transform: uppercase;
      color: var(--grey-5);
      display: flex;
      justify-content: center;
      padding-top: 30px;
      position: sticky;
      padding-bottom: var(--spacing-default);
    }

    .content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      text-align: justify;
      margin: auto 10px;
    }

    .checkbox-container {
      display: flex;
      align-items: center;

      svg {
        width: 10%;
      }

      p {
        width: 90%;
        text-align: left;
        font-size: 12px;
        margin: auto;
      }

    }

    .footer {
      padding-top: 10px;

      p {
        margin: 10px 10px 0;
        font-size: 13px;
        text-align: left;
      }

      .buttons {
        justify-content: center;
        display: flex;

        .btn-container {
          display: flex;
          justify-content: center;
          margin: 10px;

          button {
            background-color: $connexion-primary;
            color: var(--white);
            width: 100%;
          }

          & .btn-secondary {
            background-color: var(--white);
            color: $connexion-primary;
            border: 1px solid $connexion-primary;
          }
        }
      }
    }
  }
}

</style>
