import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-727d8732"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { id: "nav" }
const _hoisted_3 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_Drawer, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Close, { color: "white" }),
        _createElementVNode("h3", null, _toDisplayString(_ctx.fournisseur.name), 1)
      ])
    ]),
    page: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode(_component_router_link, {
          to: { name: _ctx.FOURNISSEUR_DETAIL_ROUTE, params: { id: _ctx.$route.params.id } },
          replace: ""
        }, null, 8, ["to"]), [
          [_directive_t, 'admin.fournisseur.users.details']
        ]),
        _withDirectives(_createVNode(_component_router_link, {
          to: { name: _ctx.FOURNISSEUR_PRODUCT_ROUTE, params: { id: _ctx.$route.params.id } },
          replace: ""
        }, null, 8, ["to"]), [
          [_directive_t, 'admin.fournisseur.users.products']
        ]),
        _withDirectives(_createVNode(_component_router_link, {
          to: { name: _ctx.FOURNISSEUR_USERS_ROUTE, params: { id: _ctx.$route.params.id } },
          replace: ""
        }, null, 8, ["to"]), [
          [_directive_t, 'admin.fournisseur.users.list']
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ]),
    _: 1
  }))
}