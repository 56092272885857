import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da6efe56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fas fa-angle-right"
}
const _hoisted_2 = {
  key: 1,
  class: "dot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_blank_outline = _resolveComponent("checkbox-blank-outline")!
  const _component_checkbox_marked = _resolveComponent("checkbox-marked")!

  return (_openBlock(), _createElementBlock("div", {
    class: "btn-container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(['action', _ctx.disabled ? 'disabled' : ''])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
      (_ctx.filterNumber === 0 && _ctx.isArrow)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.filterNumber > 0 && _ctx.isArrow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "+" + _toDisplayString(_ctx.filterNumber), 1))
        : _createCommentVNode("", true),
      (!_ctx.isArrow && !_ctx.boxChecked)
        ? (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
            key: 2,
            class: "icon"
          }))
        : _createCommentVNode("", true),
      (!_ctx.isArrow && _ctx.boxChecked)
        ? (_openBlock(), _createBlock(_component_checkbox_marked, {
            key: 3,
            class: "icon"
          }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}