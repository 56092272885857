import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00582b5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-page" }
const _hoisted_2 = { class: "login-container" }
const _hoisted_3 = { class: "login-title" }
const _hoisted_4 = { class: "error-message subtitle-message" }
const _hoisted_5 = { class: "error-message complement-message" }
const _hoisted_6 = {
  class: "container-input",
  id: "container-input"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('front.login.validationCode.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('front.login.validationCode.subtitle')), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('front.login.validationCode.complement')), 1),
          _createElementVNode("form", {
            class: "form-connexion",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.codeValidationAction && _ctx.codeValidationAction(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("input", {
                class: "form-control form-control-lg champs-form",
                value: _ctx.code1,
                type: "number",
                maxlength: "1",
                size: "lg",
                onKeyup: _cache[0] || (_cache[0] = (e) => _ctx.focusNext(e, 'code1'))
              }, null, 40, _hoisted_7),
              _createElementVNode("input", {
                class: "form-control form-control-lg champs-form",
                type: "number",
                maxlength: "1",
                size: "lg",
                value: _ctx.code2,
                onKeyup: _cache[1] || (_cache[1] = (e) => _ctx.focusNext(e, 'code2'))
              }, null, 40, _hoisted_8),
              _createElementVNode("input", {
                class: "form-control form-control-lg champs-form",
                id: "input-3",
                type: "number",
                maxlength: "1",
                size: "lg",
                value: _ctx.code3,
                onKeyup: _cache[2] || (_cache[2] = (e) => _ctx.focusNext(e, 'code3'))
              }, null, 40, _hoisted_9),
              _createElementVNode("input", {
                class: "form-control form-control-lg champs-form",
                id: "input-4",
                type: "number",
                maxlength: "1",
                size: "lg",
                value: _ctx.code4,
                onKeyup: _cache[3] || (_cache[3] = (e) => _ctx.focusNext(e, 'code4'))
              }, null, 40, _hoisted_10)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Button, {
                class: "send-button",
                onClick: _ctx.sendAgainCodeVerification
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('front.login.validationCode.sendAgain')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_Button, { type: "submit" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('front.login.validationCode.validate')), 1)
                ]),
                _: 1
              })
            ])
          ], 32)
        ])
      ])
    ])
  ], 64))
}