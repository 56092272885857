import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45449c48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popin-container" }
const _hoisted_2 = { class: "bandeau" }
const _hoisted_3 = {
  key: 0,
  class: "compo-choice"
}
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "checkbox-label" }
const _hoisted_6 = { class: "checkbox-label" }
const _hoisted_7 = { class: "espace-recherche" }
const _hoisted_8 = { class: "panier" }
const _hoisted_9 = {
  key: 0,
  class: "spinner-border",
  role: "status"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_marked_outline = _resolveComponent("checkbox-marked-outline")!
  const _component_checkbox_blank_outline = _resolveComponent("checkbox-blank-outline")!
  const _component_ResearchBarFiltre = _resolveComponent("ResearchBarFiltre")!
  const _component_TagFiltrePanier = _resolveComponent("TagFiltrePanier")!
  const _component_BalanceTileFiltre = _resolveComponent("BalanceTileFiltre")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("i", {
      class: "fas fa-caret-left",
      style: _normalizeStyle(_ctx.cssVars)
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.title',
          { type: _ctx.filterFieldSelected.label.toUpperCase() }
        )), 1),
      (_ctx.isCompo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.associationChoice')), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "checkbox",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAlone && _ctx.handleAlone(...args)))
              }, [
                (_ctx.showAlone())
                  ? (_openBlock(), _createBlock(_component_checkbox_marked_outline, {
                      key: 0,
                      class: "icon"
                    }))
                  : (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
                      key: 1,
                      class: "icon"
                    }))
              ]),
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.alone')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "checkbox",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAssociated && _ctx.handleAssociated(...args)))
              }, [
                (_ctx.showAssociated())
                  ? (_openBlock(), _createBlock(_component_checkbox_marked_outline, {
                      key: 0,
                      class: "icon"
                    }))
                  : (_openBlock(), _createBlock(_component_checkbox_blank_outline, {
                      key: 1,
                      class: "icon"
                    }))
              ]),
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('front.balanceSocietale.filtre.composition.associated')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ResearchBarFiltre, {
          onHandleSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearch($event))),
          inputValue: _ctx.inputSearchValue
        }, null, 8, ["inputValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterFieldSelected.filters, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.label
          }, [
            _createVNode(_component_TagFiltrePanier, {
              product: item.label,
              onClearItem: ($event: any) => (_ctx.clearItem(index, item.label))
            }, null, 8, ["product", "onClearItem"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$parent.dataLoaded ? 'espace-resultat loaded' : 'espace-resultat'])
      }, [
        (!_ctx.$parent.dataLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "elements-resulat",
                  key: product
                }, [
                  _createVNode(_component_BalanceTileFiltre, {
                    label: product.label,
                    type: product.type,
                    checked: (_ctx.filterFieldSelected.filters).some((item) => item.label === product.label),
                    onAddItem: ($event: any) => (_ctx.addItem(product.type, product.label)),
                    onRemoveFilter: ($event: any) => (_ctx.clearItem($event, product.label))
                  }, null, 8, ["label", "type", "checked", "onAddItem", "onRemoveFilter"])
                ]))
              }), 128))
            ]))
      ], 2)
    ])
  ], 64))
}