<template>
  <Drawer>
    <template v-slot:header>
      <div class="header">
        <div class="headband">
          <Close @click="closeDrawer"/>
          <img alt="onescore logo" src="@/assets/logo_one_score_marque.png"/>
        </div>
        <div class="product">
          <img class="image-background" alt="Concern logo" :src="require(`@/assets/ble.jpg`)"/>
          <div class="header-content">
            <div class="title">
              <img
                alt="PPP logo"
                v-if="isPPP() && havePermissionShowPPPIcon()"
                src="@/assets/PPP_blanc.svg"
              />
              <img
                alt="Seed logo"
                v-if="isSemence() && havePermissionShowSemenceIcon()"
                src="@/assets/semence_blanc.svg"
              />
              <h2>{{ productInfo.product.name }}</h2>
            </div>
            <div class="subtitle">
              <span>
                {{ productInfo.product.supplier ? `${productInfo.product.supplier}` : '' }}
              </span>
              <span v-if="isPPP()">
                {{ productInfo.product.segment }}
                {{(productInfo.product.segment && productInfo.product.segmentSpecific) ? `, ` : '' }}
                {{ productInfo.product.segmentSpecific }}
              </span>
              <span v-else>
                {{ productInfo.product.culture }}
                {{(productInfo.product.culture && productInfo.product.segmentSpecific) ? `, ` : '' }}
                {{ productInfo.product.segmentSpecific }}
              </span>
              <span v-if="productInfo.product.amm">
                {{ $t('front.product.productDetails.amm', { amm: productInfo.product.amm }) }}
              </span>
              <span v-else-if="productInfo.product.gnis">
                {{ $t('front.product.productDetails.gnis', { gnis: productInfo.product.gnis }) }}
              </span>
            </div>
<!--            TODO : uncomment when we will have the right to download product fiche -->
<!--            <div class="actions">-->
<!--              <Button-->
<!--                v-if="canDownloadFiche()"-->
<!--                class="button-secondary"-->
<!--                :label="(loaderFiche || !scores || !comparisonProduct) ? ''-->
<!--                  : $t('front.product.productDetails.downloadProduct')"-->
<!--                :onClick="downloadFiche"-->
<!--                :loading="loaderFiche || !scores || !comparisonProduct"-->
<!--              />-->
<!--            </div>-->
          </div>
        </div>
        <!-- Popin confirmation products masked -->
        <Popin
          v-if="showPopin === true"
          :title="$t('front.product.popin.title')"
          :text="$t('front.product.popin.text', { nbr: 1 })"
          :hasCancel="true"
          @close="closePopin"
          :isFront="true"
        >
          <template v-slot:container>
            <p class="text" v-if="productInfo.product.isVisible">
              {{ $t('front.product.popin.text', { nbr: 1 }) }}
            </p>
            <p class="text" v-else>{{ $t('front.product.popin.textVisible', { nbr: 1 }) }}</p>
          </template>
          <template v-slot:button>
            <Button
              :label="productInfo.product.isVisible
               ? $t('front.product.productManagement.hide')
               : $t('front.product.productManagement.visible')"
              color="primary"
              :onClick="productInfo.product.isVisible ? confirmationMasked : confirmationVisible"
              :hasCancel="true"
            >
              <eye class="icon"/>
            </Button>
          </template>
        </Popin>
        <Popin
          v-if="showCGUPopin === true"
          :title="$t('front.product.productDetails.cgu.title')"
          :hasCancel="true"
          @close="showCGUPopin = false"
          :isFront="true"
        >
          <template v-slot:container>
            <p class="text">
              <a target="_blank" :href="links.cguLink">{{ $t('front.product.productDetails.cgu.desc') }}</a>
            </p>
            <p class="text">
              {{ $t('front.product.productDetails.cgu.text') }}
            </p>
          </template>
          <template v-slot:button>
            <Button
              color="primary"
              :label="$t('front.product.productDetails.cgu.validate')"
              @click="exportLogos"
            />
          </template>
        </Popin>
      </div>
    </template>
    <template v-slot:page>
      <div v-bind:class="getContentClass()">
        <div class="scoring">
          <div class="title-band">
            {{ $t('front.product.productDetails.scoring.title') }}
          </div>
          <div class="subtitle">
            <span class="last-update" v-if="productInfo.lastDataUpdate">
              {{
                $t('front.product.productDetails.scoring.updateData',
                  { date: dayjs(productInfo.lastDataUpdate).format('DD/MM/YYYY') })
              }}
            </span>
            <span class="last-update" v-if="productInfo.lastScoringUpdate">
              {{
                $t('front.product.productDetails.scoring.updateScore',
                  { date: dayjs(productInfo.lastScoringUpdate).format('DD/MM/YYYY') })
              }}
            </span>
            <div class="button-container">
              <Button
                v-if="canDownloadLogo()"
                :label="$t('front.product.productDetails.scoring.downloadLogo')"
                @click="showCGUPopin = true"
                :loading="loaderDownload"
                class="float-right button-secondary"
              />
            </div>
          </div>
          <div class="enjeu-container">
            <div class="main-enjeu" v-if="havePermissionAccessNote()">
              <img class="img" v-if="logo.logoOneScore" alt="OneScore jauge image"
                   :src="logo.logoOneScore"
              >
              <img class="img" v-else-if="logo.logoOneScoreAlone" alt="OneScore image"
                   :src="logo.logoOneScoreAlone"
              >
            </div>
            <div class="enjeu" v-if="havePermissionShowNoteEnjeu()">
              <img class="img" v-if="logo.logoAlimentation" alt="Alimentation image"
                   :src="logo.logoAlimentation"
              >
              <img class="img" v-if="logo.logoAgriculteur" alt="Agriculteur image"
                   :src="logo.logoAgriculteur"
              >
              <img class="img" v-if="logo.logoEnvironnement" alt="Environnement image"
                   :src="logo.logoEnvironnement"
              >
            </div>
          </div>
        </div>
        <div class="product-info-section" v-if="havePermissionComparateurProduit()">
          <div class="title-band-container" @click="() => showBalance = !showBalance">
            <div class="title-band title-band--balance">
              {{ $t('front.product.productDetails.comparison.title') }}
            </div>
            <MDBIcon class="icon" :icon="showBalance ? 'angle-down' : 'angle-right'"></MDBIcon>
          </div>
          <div
            v-if="!scores || !comparisonProduct"
            class="spinner-border data-loading" role="status"
          />
          <div v-if="showBalance && scores !== undefined && comparisonProduct !== undefined">
            <div class="subtitle">
              <div class="button-container">
                <Button
                  :label="$t('front.product.productDetails.comparison.addToBalance')"
                  @click="addToBalance(idProduct)"
                  v-if="havePermissionAddToBalance()"
                  class="float-right button-secondary"
                />
              </div>
            </div>
            <section ref="histogram" v-if="scores !== undefined && comparisonProduct !== undefined">
              <div class="div-flex-row">
                <div class="select-container">
                  <select
                    @change="(e) => handleSelectComparison(e.target.selectedIndex)"
                    v-model="comparisonCriteria"
                  >
                    <option v-for="(option, index) in compareTo" :key="option" :value="option" :disabled="!option">
                      {{ getDiplayedCriteriaByIndex(index, option) }}
                    </option>
                  </select>
                </div>
                <Button
                  class="balance"
                  :label="$t('front.product.histogram.addToBalance')"
                  v-if="!isInBalance && havePermissionAddToBalance()"
                  @click="addToBalance(idProduct)"
                />
              </div>
              <div v-if="loadHisto" class="spinner-border data-loading" role="status" />
              <div class="missing-histogram" v-if="!showHistogram() && !loadHisto">{{ $t('front.product.histogram.permission') }}</div>
              <div v-if="showHistogram() && !loadHisto">
                <histogram
                  :oneScore="scores.oneScore"
                  :alimentation="scores.alimentation"
                  :agriculteur="scores.agriculteur"
                  :environment="scores.environnement"
                  :yLabels="['0', '20', '40', '50', '60', '80', '100']"
                  :comparisonOneScore="comparisonProduct.oneScore"
                  :comparisonAlimentation="comparisonProduct.alimentation"
                  :comparisonAgriculteur="comparisonProduct.agriculteur"
                  :comparisonEnvironnement="comparisonProduct.environnement"
                />
              </div>
              <div class="legend" v-if="showHistogram() && !loadHisto">
                <div class="legend-block">
                  <div class="legend-square"/>
                  <span>{{
                      $t('front.product.histogram.dispersion',
                        { criteria: comparisonCriteria })
                    }}</span>
                </div>
                <div class="legend-block">
                  <div class="legend-round"/>
                  <span>{{
                      $t('front.product.histogram.avg',
                        { criteria: comparisonCriteria })
                    }}</span>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="product-info-section" v-if="havePermissionIndicateurs()">
          <div class="title-band-container" @click="() => showIndicateur = !showIndicateur">
            <div class="title-band title-band--indicateur">
              {{ $t('front.product.productDetails.indicatorsDetails.title') }}
            </div>
            <MDBIcon class="icon" :icon="showIndicateur ? 'angle-down' : 'angle-right'"></MDBIcon>
          </div>
          <div class="indicateur-content" v-if="showIndicateur">
            <div class="spinner-border data-loading" v-if="!indicators" role="status"/>
            <div class="button-container" v-if="indicators !== undefined">
              <Button
                :label="$t('front.product.productDetails.indicatorsDetails.download')"
                :onClick="exportScore"
                class="button-secondary float-right"
                v-if="havePermissionDownloadDetailIndicateur()"
                :loading="indicateurIsLoading"
              />
            </div>
            <div class="table" v-if="indicators !== undefined">
              <div class="main-score alone">
                <div>{{ $t(`front.product.productDetails.indicatorsDetails.name.${indicators.label.toLowerCase()}`) }}</div>
                <div>{{ indicators.score }}</div>
              </div>
              <div class="score-container">
                <div class="score-category" v-for="indicator in indicators.children" v-bind:key="indicator.label">
                  <div v-bind:class="['main-score', `main-score--${indicator.label}`]">
                    <div>{{ $t(`front.product.productDetails.indicatorsDetails.name.${indicator.label.toLowerCase()}`) }}</div>
                    <div>{{ indicator.score }}</div>
                  </div>
                  <div class="enjeu-container" v-for="enjeu in indicator.children" v-bind:key="enjeu.label">
                    <div v-bind:class="['enjeu-score', `enjeu-score--${indicator.label}`]">
                      <div>{{ $t(`front.product.productDetails.indicatorsDetails.name.${enjeu.label.toLowerCase()}`) }}</div>
                      <div>{{ enjeu.score }}</div>
                    </div>
                    <div class="sous-enjeu" v-for="sousenjeu in enjeu.children" v-bind:key="sousenjeu.label">
                      <div class="label">{{ $t(`front.product.productDetails.indicatorsDetails.name.${sousenjeu.label.toLowerCase()}`) }}</div>
                      <div>{{ sousenjeu.score }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="footer-img"
          alt="Concern logo" :src="require(`@/assets/footer_fiche_produit.png`)"
        />
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { MDBIcon } from 'mdb-vue-ui-kit';
import { Options, Vue } from 'vue-class-component';
import Accordion from '@/components/Accordion.vue';
import Button from '@/components/Button.vue';
import {
  ArrowLeft, CloudDownload, Download, Eye, Sprout,
} from 'mdue';
import Drawer from '@/components/Drawer.vue';
import Close from '@/components/Close.vue';
import ProductsApi, {
  ComparisonProduct,
  IndicatorsScoreProduct,
  LOGO_TYPES,
  PRODUCT_TYPE,
  ScoredProduct,
  ScoreProduct,
} from '@/api/products/ProductsApi';
import Popin from '@/components/Popin.vue';
import CGUPopin from '@/views/front/login/CGUPopin.vue';
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import Histogram from '@/views/admin/product/Histogram.vue';
import { inject } from 'vue';
import { BALANCE_SOCIETALE_RECHERCHE_ROUTE, BALANCE_SOCIETALE_RESULTAT_ROUTE } from '@/router/routes';
import { download, getFilenameFromResponse } from '@/util';
import html2canvas from 'html2canvas';
import ConfigurationApi, { Links } from '@/api/configuration/ConfigurationApi';
import dayjs from 'dayjs';

@Options({
  components: {
    Accordion,
    Button,
    Download,
    ArrowLeft,
    Sprout,
    Eye,
    CloudDownload,
    Drawer,
    Close,
    Popin,
    CGUPopin,
    Histogram,
    MDBIcon,
  },
  props: {
    idProduct: 0,
  },
  data() {
    const configurationApi = getGlobalInstance(ConfigurationApi);
    const productsApi = getGlobalInstance(ProductsApi);

    const authorization = inject('authorization');
    return {
      configurationApi,
      productsApi,
      productInfo: {
        lastUpdateScoring: '',
        product: {
          name: '',
          provider: '',
          amm: '',
          segment: '',
          culture: '',
          segmentSpecific: '',
          type: '',
          isVisible: true,
        },
      },
      showPopin: false,
      masked: [],
      logo: {
        logoOneScore: '',
        logoOneScoreAlone: '',
        logoAlimentation: '',
        logoAgriculteur: '',
        logoEnvironnement: '',
      },
      scores: {
        oneScore: 0,
        alimentation: 0,
        agriculteur: 0,
        environnement: 0,
      },
      logoLoaded: false,
      compareTo: [] as string[],
      showCGUPopin: false,
      comparisonProduct: undefined,
      comparisonCriteria: undefined,
      authorization,
      permissions: [],
      loaderFiche: false,
      indicators: undefined,
      loaderDownload: false,
      loadHisto: false,
      indicateurIsLoading: false,
      showBalance: true,
      showIndicateur: false,
      links: {},
    };
  },
  methods: {
    getContentClass() {
      let css = 'product-score';
      if (this.loaderFiche) {
        css += ' disabled';
      }
      return css;
    },
    showHistogram(): boolean {
      return this.comparisonProduct.oneScore
        || (this.comparisonProduct.alimentation
        && this.comparisonProduct.agriculteur
        && this.comparisonProduct.environnement);
    },
    isPPP() {
      return this.productInfo?.product?.type === PRODUCT_TYPE.PPP;
    },
    isSemence() {
      return this.productInfo?.product?.type?.toUpperCase() === PRODUCT_TYPE.SEMENCE;
    },
    isInBalance() {
      return BALANCE_SOCIETALE_RESULTAT_ROUTE.valueOf() === this.$route.name;
    },
    addToBalance(id: string) {
      this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE, params: { id } });
    },
    canDownloadLogo() {
      return this.permissions.includes(PERMISSIONS.TELECHARGER_LOGOS);
    },
    canDownloadFiche() {
      return this.permissions.includes(PERMISSIONS.TELECHARGER_FICHE);
    },
    getCriteriaPrefixByIndex(index: number) {
      let prefix;
      if (this.isPPP()) {
        switch (index) {
          case 0:
            prefix = this.$t('front.product.productDetails.criteria.prefix.marche');
            break;
          case 1:
            prefix = this.$t('front.product.productDetails.criteria.prefix.segment');
            break;
          case 2:
            prefix = this.$t('front.product.productDetails.criteria.prefix.segmentSpecific');
            break;
          default:
            prefix = '';
            break;
        }
      } else
      if (index === 0) {
        prefix = this.$t('front.product.productDetails.criteria.prefix.marche');
      } else if (index === this.compareTo.length - 1) {
        prefix = this.$t('front.product.productDetails.criteria.prefix.segmentSpecific');
      } else {
        prefix = this.$t('front.product.productDetails.criteria.prefix.culture');
      }
      return prefix ? (`${prefix} - `) : '';
    },
    getDisplayedCriteriaValue(value: string) {
      if (!value) {
        return this.$t('front.product.histogram.missing');
      }
      let displayedValue;
      switch (value) {
        case this.$t('front.product.productDetails.criteria.PPP'):
          displayedValue = this.$t('front.product.productDetails.PPP');
          break;
        case this.$t('front.product.productDetails.criteria.SEMENCE'):
          displayedValue = this.$t('front.product.productDetails.SEMENCE');
          break;
        default:
          displayedValue = value;
          break;
      }
      return displayedValue;
    },
    getDiplayedCriteriaByIndex(index: number, value: string) {
      const prefix = this.getCriteriaPrefixByIndex(index);
      const displayedValue = this.getDisplayedCriteriaValue(value);
      return `${prefix}${displayedValue}`;
    },
    fetchProductById() {
      return this.productsApi
        .fetchById(this.idProduct)
        .then((response: ScoredProduct) => {
          this.productInfo = response;
          this.comparisonCriteria = response.product.type;
          this.getCompareToConsideringPermissions(response);
          if (this.havePermissionComparateurProduit()) {
            this.fetchComparisonProduct(0);
          } else {
            this.comparisonProduct = {};
          }
        });
    },
    getCompareToConsideringPermissions(response: ScoredProduct) {
      if (this.isPPP()) {
        this.compareTo[0] = response.product.type;
        this.compareTo[1] = response.product.segment;
        this.compareTo[2] = response.product.segmentSpecific;
      } else {
        // Pour les semences on doit gérer des entrées par cultures
        this.compareTo[0] = response.product.type;
        const cultures = response.product.culture.split(' + ');
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= cultures.length; i++) {
          this.compareTo[i] = cultures[i - 1];
        }
        this.compareTo[cultures.length + 1] = response.product.segmentSpecific;
      }
    },
    fetchProducts() {
      return this.productsApi
        .fetch({
          market: '', segment: '', composition: '', culture: '', segmentSpecific: '', supplier: '',
        })
        .then((response: ScoredProduct[]) => {
          this.masked = [];
          response.forEach((productInfo) => {
            if (!productInfo.product.isVisible) {
              this.masked.push(productInfo.product.id);
            }
          });
        });
    },
    async confirmationMasked() {
      await this.fetchProducts();
      this.masked.push(this.idProduct);
      await this.productsApi.updateProducts(this.masked)
        .then(() => {
          this.productInfo.product.isVisible = !this.productInfo.product.isVisible;
        });
      this.closePopin();
    },
    async confirmationVisible() {
      await this.fetchProducts();
      const index = this.masked.indexOf(this.idProduct);
      this.masked.splice(index, 1);
      await this.productsApi.updateProducts(this.masked)
        .then(() => {
          this.productInfo.product.isVisible = !this.productInfo.product.isVisible;
        });
      this.closePopin();
    },
    openPopin() {
      this.showPopin = true;
    },
    closePopin() {
      this.showPopin = false;
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      this.$parent.fetchProducts();
      this.$parent.displayDetails = 0;
    },
    handleSelectComparison(index: string) {
      this.fetchComparisonProduct(index);
    },
    dayjs,
    exportScore() {
      this.indicateurIsLoading = true;
      this.productsApi
        .exportScore(this.idProduct)
        .then((response: Response) => {
          const excelName = getFilenameFromResponse(response);
          response.blob().then((blob: Blob) => {
            download(blob, excelName);
          });
        })
        .finally(() => {
          this.indicateurIsLoading = false;
        });
    },
    exportLogos() {
      this.loaderDownload = true;
      this.showCGUPopin = false;
      this.productsApi
        .exportLogos(this.idProduct)
        .then((response: Response) => {
          response.blob().then((blob: Blob) => {
            const date = dayjs().format('YYMMDD');
            download(blob, `${date}_logos_OneScore_${this.productInfo.product.name}.zip`);
            this.loaderDownload = false;
          });
        });
    },
    fetchLogo(logoType: string) {
      this.productsApi.fetchLogo(this.idProduct, logoType)
        .then((response: Response) => response.blob())
        .then((response: Blob) => {
          switch (logoType) {
            case LOGO_TYPES.ONESCORE: {
              this.logo.logoOneScore = URL.createObjectURL(response);
              break;
            }
            case LOGO_TYPES.ONESCORE_ALONE: {
              this.logo.logoOneScoreAlone = URL.createObjectURL(response);
              break;
            }
            case LOGO_TYPES.ALIMENTATION: {
              this.logo.logoAlimentation = URL.createObjectURL(response);
              break;
            }
            case LOGO_TYPES.AGRICULTEUR: {
              this.logo.logoAgriculteur = URL.createObjectURL(response);
              break;
            }
            case LOGO_TYPES.ENVIRONNEMENT: {
              this.logo.logoEnvironnement = URL.createObjectURL(response);
              break;
            }
            default:
              break;
          }
        });
    },
    fetchAndDownloadPdf() {
      return new Promise((resolve, reject) => {
        if (this.$refs.histogram) {
          html2canvas(this.$refs.histogram)
            .then((canvas) => {
              const b64Balance = canvas.toDataURL('image/png', 1);
              this.productsApi.fetchPdf(this.idProduct, b64Balance)
                .then((response: Response) => response.blob())
                .then((blob: Blob) => {
                  this.downloadPDF(blob);
                  resolve(undefined);
                })
                .catch(reject);
            })
            .catch(reject);
        } else {
          this.productsApi.fetchPdf(this.idProduct, '')
            .then((response: Response) => response.blob())
            .then((blob: Blob) => {
              this.downloadPDF(blob);
              resolve(undefined);
            })
            .catch(reject);
        }
      });
    },
    downloadPDF(blob: Blob) {
      const today = dayjs().format('YYMMDD');
      download(blob, `${today}_FICHE_ONESCORE_${this.productInfo.product.name}.pdf`);
    },
    fetchAllLogos() {
      if (this.permissions.includes(PERMISSIONS.JAUGES_ENJEUX)) {
        this.fetchLogo(LOGO_TYPES.ONESCORE);
      }
      if (this.permissions.includes(PERMISSIONS.NOTE_ONESCORE)) {
        this.fetchLogo(LOGO_TYPES.ONESCORE_ALONE);
      }
      if (this.permissions.includes(PERMISSIONS.NOTES_ENJEUX)) {
        this.fetchLogo(LOGO_TYPES.ALIMENTATION);
        this.fetchLogo(LOGO_TYPES.AGRICULTEUR);
        this.fetchLogo(LOGO_TYPES.ENVIRONNEMENT);
      }
    },
    setLogoLoaded() {
      this.logoLoaded = this.logo.logoOneScore !== ''
        && this.logo.logoAlimentation !== ''
        && this.logo.logoAgriculteur !== ''
        && this.logo.logoEnvironnement !== '';
    },
    closeDrawer() {
      this.$parent.displayDetails = 0;
    },
    fetchProductScoreById() {
      return this.productsApi
        .fetchProductScoreById(this.idProduct)
        .then((response: ScoreProduct) => {
          this.scores.oneScore = response.noteOneScore;
          this.scores.alimentation = response.noteAlimentation;
          this.scores.agriculteur = response.noteAgriculteur;
          this.scores.environnement = response.noteEnvironnement;
        });
    },
    fetchComparisonProduct(index: number) {
      this.loadHisto = true;
      return this.productsApi
        .fetchComparisonProduct((this.getCriteriaPrefixByIndex(index) + this.comparisonCriteria))
        .then((response: ComparisonProduct) => {
          this.loadHisto = false;
          this.comparisonProduct = response;
        });
    },
    havePermissionComparateurProduit() {
      return this.permissions.includes(PERMISSIONS.COMPARATEUR_PAGE_DETAIL);
    },
    havePermissionIndicateurs() {
      return this.permissions.includes(PERMISSIONS.INDICATEURS);
    },
    havePermissionDownloadDetailIndicateur() {
      return this.permissions.includes(PERMISSIONS.TELECHARGER_DETAIL_INDICATEUR);
    },
    havePermissionAddToBalance() {
      return this.permissions.includes(PERMISSIONS.PAGE_COMPARATEUR)
          && this.permissions.includes(PERMISSIONS.AJOUTER_BALANCE_DETAIL);
    },
    havePermissionAccessNote() {
      return this.permissions.includes(PERMISSIONS.NOTE_ONESCORE);
    },
    havePermissionShowPPPIcon() {
      return this.permissions.includes(PERMISSIONS.MARCHE_PPP);
    },
    havePermissionShowSemenceIcon() {
      return this.permissions.includes(PERMISSIONS.MARCHE_SEMENCE);
    },
    havePermissionShowNoteEnjeu() {
      return this.permissions.includes(PERMISSIONS.NOTES_ENJEUX);
    },
    downloadFiche() {
      if (this.havePermissionComparateurProduit() && !this.showBalance) {
        this.showBalance = true;
      }
      this.loaderFiche = true;
      this.fetchAndDownloadPdf()
        .then(() => {
          this.loaderFiche = false;
        });
    },

    fetchDetailScoreOfProduct() {
      this.productsApi.fetchDetailProductScoreById(this.idProduct)
        .then((response: IndicatorsScoreProduct) => {
          this.indicators = response;
        });
    },
    clickCgu() {
      window.open(this.configurationApi.links.cguLink);
    },
  },
  mounted() {
    this.permissions = parseJwt(this.authorization);
    this.fetchProductById();
    this.fetchAllLogos();
    this.fetchProductScoreById();
    this.fetchDetailScoreOfProduct();
    this.showPopin = false;
    this.configurationApi.fetchLinks()
      .then((links: Links) => {
        this.links = links;
      });
  },
  watch: {
    logo: {
      handler() {
        this.setLogoLoaded();
      },
      deep: true,
    },
  },
})
export default class ProductDetailsFront extends Vue {
}
</script>

<style lang="scss" scoped>
$primary: var(--grey-5);
.product-score {
  width: 100%;
  background: white;
  position: absolute;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .title-band {
    width: 50%;
    background: $tertiary;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: var(--font-size-very-big);
    text-transform: uppercase;
    font-family: 'TTNormsLight', sans-serif;

    &--balance {
      background: $success;
    }

    &--indicateur {
      background: $info;
    }
  }

  .button-container {
    position: absolute;
    right: 0;
    top: 0;

    button {
      margin-top: 0;
    }
  }

  .scoring {
    background-color: white;
    color: var(--grey-7);
    margin: var(--spacing-bigger) 0;
    text-align: left;
    outline: none;
    cursor: initial;
    position: relative;

    .subtitle {
      .last-update {
        font-family: 'TTNormsLight', sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 30px;
      }
    }

    .enjeu-container {
      display: flex;
      flex-direction: column;

      margin: var(--spacing-bigger) 10%;

      .main-enjeu {
        display: flex;
        justify-content: center;

        img {
          margin-top: -120px; // Pour compenser le fait que l'image ne soit pas centrée
          width: 45%;
          height: 100%;
        }
      }

      .enjeu {
        display: flex;
        justify-content: space-around;

        img {
          width: 30%;
          height: 100%;
        }
      }
    }
  }

  .subtitle {
    font-family: 'TTNormsLight', sans-serif;
    display: flex;
    padding: var(--spacing-default) 0;
    margin-left: var(--spacing-biggest);
    position: relative;
  }

  .product-info-section {
    margin: var(--spacing-bigger) 0;

    .data-loading {
      margin: var(--spacing-bigger);
    }

    .title-band-container {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .icon {
        position: absolute;
        right: 50px;
        font-size: var(--font-size-default);
      }
    }
  }

  .indicateur-content {
    position: relative;
  }

  table {
    margin-top: 40px;
    margin-bottom: var(--spacing-default);
    padding: var(--spacing-bigger) var(--spacing-default) var(--spacing-default);

    th {
      font-family: 'TTNormsRegular', sans-serif;
      font-size: 17px;
      text-transform: uppercase;

      &.th-1 {
        width: 100px;
      }

      &.th-5 {
        width: 600px;
      }
    }

    tr {
      height: 50px;

      th, td {
        border-bottom: 1px solid var(--grey-1);
        font-family: 'TTNormsRegular', sans-serif;

        &.enjeu {
          text-transform: uppercase;
          padding-left: 8px;
        }

        &.sous-enjeu {
          padding-left: 32px;
        }

        &.indicateur {
          padding-left: 64px;
        }
      }
    }
  }

  .accordion-title {
    font-family: 'LatoLight', sans-serif;
    font-size: 23px;
    text-transform: uppercase;
  }

  .footer-img {
    width: 100%;
  }
}

.header {
  flex-direction: column;
  height: 325px;
  background-color: var(--grey-5);
  color: white;
  border-bottom: 2px solid var(--grey-1);

  .headband {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    background: white;
    position: relative;

    img {
      width: 170px;
    }

    .close {
      color: var(--grey-5);
      position: absolute;
      text-align: left;
      top: inherit;
      padding-top: var(--spacing-small);
      display: flex;
      font-size: var(--font-size-small);
      text-transform: uppercase;
      cursor: pointer;

      .text {
        margin-left: var(--spacing-small);
      }
    }
  }

  .product {
    height: 250px;
    position: relative;

    .image-background {
      width: 100%;
      height: 250px;
      display: block;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      top: 0;
      left: 0;
    }

    .header-content {
      display: flex;
      flex-direction: column;
      //justify-content: space-between; // TODO : uncomment when we will have the right to download product fiche
      justify-content: space-around; // TODO : delete when we will have the right to download product fiche
      width: 100%;
      height: 100%;
      background: linear-gradient(#{var(--header-color-darker)}) no-repeat center;
      padding: var(--spacing-bigger);
      position: relative;
      z-index: 1;

      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 15px;

        img {
          width: 40px;
          height: 100%;
          object-fit: cover;
          margin-right: 10px;
        }

        h2 {
          font-size: var(--font-size-medium);
          font-weight: bold;
          margin: 0;
        }
      }

      .subtitle {
        margin-top: -(var(--spacing-small));

        span {
          font-family: 'TTNormsRegular', sans-serif;
          font-size: var(--font-size-default);
          margin: 0 var(--spacing-default);
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
          background-color: white;

          &:hover {
            background-color: var(--grey-3);
          }
        }
      }
    }
  }
}

.button-primary {
  background-color: $primary;
  color: white;
  border: 0.5px solid white;
}

.button-primary:hover {
  background-color: var(--grey-5);
}

.button-secondary {
  background-color: white;
  color: $primary;
  border: 0.5px solid $primary;
}

.float-right {
  margin-left: auto;
  margin-right: 50px;
}

.popin-container {
  .button {
    border: 1px solid $primary !important;
  }

  .text {
    color: $primary;
    text-align: justify;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 300px;
  }
}

.popin-container {
  button {
    height: var(--btn-height-big);
  }

  .text {
    text-align: justify;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 300px;
  }
}

.indicators-details {
  .download-indicateurs {
    display: flex;
    width: 100%;
    justify-content: end;
  }
}

.table {
  padding: 0 var(--spacing-default);
  font-family: 'TTNormsRegular', sans-serif;

  .main-score {
    display: flex;
    justify-content: space-between;
    background-color: $primary;
    color: white;
    font-size: 25px;
    width: 100%;
    padding: 5px 20px 5px 10px;
    margin-top: 20px;
    font-weight: bold;

    &--ALIMENTATION {
      background-color: $tertiary;
    }

    &--AGRICULTEUR {
      background-color: $info;
    }

    &--ENVIRONNEMENT {
      background-color: $success;
    }

    &.alone {
      width: calc(30% - 10px);
    }
  }

  .score-container {
    display: flex;
    width: 100%;

    .score-category {
      padding-right: 10px;
      width: 30%;

      &:last-child {
        padding-right: 0;
      }

      .enjeu-container {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 10px 0;

        .enjeu-score {
          display: flex;
          justify-content: space-between;
          font-size: 18px;

          &--ALIMENTATION {
            color: $tertiary;
          }

          &--AGRICULTEUR {
            color: $info;
          }

          &--ENVIRONNEMENT {
            color: $success;
          }
        }

        .sous-enjeu {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          padding-left: 20px;
          margin-top: 5px;

          .label {
            color: $primary;
            text-align: left;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media print {
  html, body, * {
    -webkit-print-color-adjust: exact;
    height: auto;
    overflow: unset;
  }
  .indicators-details {
    display: none;
  }
  button {
    display: none !important;
  }

  @page {
    size: A3;
    margin: 0;
  }
}
</style>

<style lang="scss">
.product-score {
  .accordion-container {
    border-bottom: none;

    .accordion {
      position: relative;
      width: 100%;
      margin-left: 0;
      padding-left: var(--spacing-biggest);

      .accordion-title {
        font-family: 'LatoLight', sans-serif;
        font-size: 23px;
        text-transform: uppercase;
      }

      .icon-right {
        position: absolute;
        right: 50px;
        top: 25px;
      }
    }
  }
}

section {
  padding-bottom: 10px;

  .div-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .balance {
      border: 1px solid $primary;
      width: 200px;
      margin-right: 50px;
    }
  }

  .histogram {
    font-family: 'TTNormsRegular', sans-serif;

    .y-legend {
      font-family: 'TTNormsRegular', sans-serif;
      font-size: var(--font-size-small);
    }
  }

  .compare-to {
    font-size: var(--font-size-small);
    font-family: 'TTNormsLight', sans-serif;
    margin-right: var(--spacing-default);
  }

  .select-container {
    width: 70%;
    margin: auto;
    text-align: initial;

    select {
      font-size: var(--font-size-small);
      font-family: 'TTNormsLight', sans-serif
    }
  }

  .x-label {
    font-family: 'TTNormsRegular', sans-serif;
  }

  .missing-histogram {
    padding: 40px 0 30px 0;
  }

  .legend {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: 0 auto var(--spacing-bigger);
    font-family: 'TTNormsRegular', sans-serif;

    .legend-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: var(--spacing-very-big);

      .legend-square {
        height: 12px;
        width: 12px;
        background: black;
        margin-right: var(--spacing-small);
      }

      .legend-round {
        height: 10px;
        width: 10px;
        background: black;
        border-radius: 50%;
        margin-right: var(--spacing-small);
      }
    }
  }

}
</style>
