import {
  ComparisonProduct,
  Criterion,
  IndicatorsScoreProduct,
  Market,
  MarketSegment,
  ProductResultList,
  ScoredProduct,
  ScoreProduct,
} from '@/api/products/ProductsApi';
import HttpMethod from '../../lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export default class ProductsAdminApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ProductsAdminApi | undefined;

  public static getInstance(): ProductsAdminApi {
    if (ProductsAdminApi.INSTANCE === undefined) {
      ProductsAdminApi.INSTANCE = new ProductsAdminApi();
    }

    return ProductsAdminApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetchLogo(idProduct: string, logoType: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/admin/products/${idProduct}/logos/${logoType}`)
      .execute();
  }

  fetchComparisonProduct(comparisonCriteria: string) {
    return this
      .httpClient
      .restRequest<ComparisonProduct>(HttpMethod.GET, `/admin/products/comparison/${comparisonCriteria}`)
      .execute();
  }

  fetchProductScoreById(idProduct: string) {
    return this
      .httpClient
      .restRequest<ScoreProduct>(HttpMethod.GET, `/admin/products/${idProduct}/score`)
      .execute();
  }

  fetchById(id: string) {
    return this
      .httpClient
      .restRequest<ScoredProduct>(HttpMethod.GET, `/admin/products/${id}`)
      .execute();
  }

  fetchDetailProductScoreById(idProduct: string) {
    return this
      .httpClient
      .restRequest<IndicatorsScoreProduct>(HttpMethod.GET, `/admin/products/${idProduct}/indicateurs`)
      .execute();
  }

  fetchAdmin(criterion: Criterion) {
    return this
      .httpClient
      .restRequest<ProductResultList>(
        HttpMethod.GET,
        '/admin/products',
      )
      .queryParams([
        ['market', criterion.market],
        ['segment', criterion.segment],
        ['segmentSpecific', criterion.segmentSpecific],
        ['culture', criterion.culture],
        ['composition', criterion.composition],
        ['supplier', criterion.supplier],
        ['limit', criterion.limit],
        ['offset', criterion.offset],
        ['productSort', criterion.productSort],
      ])
      .execute();
  }

  fetchMarketSegmentEnum() {
    return this
      .httpClient
      .restRequest<MarketSegment[]>(HttpMethod.GET, '/admin/products/segments')
      .execute();
  }

  fetchMarketList() {
    return this
      .httpClient
      .restRequest<Market[]>(HttpMethod.GET, '/admin/products/markets')
      .execute();
  }

  fetchSupplierList() {
    return this
      .httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/products/suppliers')
      .execute();
  }

  fetchSegmentSpecificList() {
    return this
      .httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/products/segmentSpecific')
      .execute();
  }

  updateProductsAdmin(productList: string[]) {
    return this
      .httpClient
      .restRequest<void>(HttpMethod.PUT, '/admin/products')
      .jsonBody(productList)
      .execute();
  }

  exportLogos(idProduct: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/admin/products/${idProduct}/logos/download`)
      .execute();
  }

  exportScore(idProduct: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/admin/products/${idProduct}/exportIndicateurs`)
      .execute();
  }

  exportAllScoreAdmin(criterion: Criterion) {
    return this
      .httpClient
      .rawRequest(HttpMethod.POST, '/admin/products/exportAllIndicateurs')
      .queryParams([
        ['market', criterion.market],
        ['segment', criterion.segment],
        ['segmentSpecific', criterion.segmentSpecific],
        ['culture', criterion.culture],
        ['composition', criterion.composition],
        ['supplier', criterion.supplier],
        ['productSort', criterion.productSort]])
      .execute();
  }

  fetchPdf(idProduct: string, base64Balance: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.POST, `/admin/products/${idProduct}/pdf`)
      .jsonBody({ base64Balance })
      .execute();
  }

  fetchCultureList() {
    return this
      .httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/products/culture')
      .execute();
  }

  fetchCompositionList() {
    return this
      .httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/products/composition')
      .execute();
  }
}
