import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74a592ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { id: "nav" }
const _hoisted_5 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_Drawer, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Close, { color: "white" }),
        (_ctx.$route.params.id === 'new')
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t('admin.utilisateur.title')), 1))
          : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.utilisateur.firstName) + " " + _toDisplayString(_ctx.utilisateur.lastName), 1))
      ])
    ]),
    page: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_router_link, {
          to: { name: _ctx.UTILISATEUR_DETAIL_ROUTE, params: { id: _ctx.$route.params.id } },
          replace: ""
        }, null, 8, ["to"]), [
          [_directive_t, 'admin.fournisseur.users.details']
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ]),
    _: 1
  }))
}