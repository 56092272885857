<template>
  <notifications ref="notifications"/>
  <div class="container">
    <div class="close" :onClick="forgottenPassword">
      <arrow-left/>
      <p class="text">{{ $t('front.login.button.return') }}</p>
    </div>
    <h4 class="password-forgot-title">{{ $t('front.forgotPassword.title') }}</h4>
    <div v-if="showNewPassword" class="content height-content">
      <p class="message-newpassword">{{ $t('front.newPassword.desc') }}</p>
      <p class="message-characteristic-password">{{ $t('front.newPassword.desc2') }}</p>
      <form @submit.prevent="savePassword">
        <div class="form-connexion">
          <div class="champs-connexion">
            <div class="container-input">
              <MDBInput class="champs-form" v-model="newPassword" type="password"
                        :label="$t('front.newPassword.new')" bg size="lg"
              />
              <div class="dot" v-if="newPassword" @click="drainPassword">
                <i class="fa fa-times"/>
              </div>
              <div class="erreurs">{{ errors.password }}</div>
            </div>
            <div class="container-input">
              <MDBInput class="champs-form" v-model="newPasswordConfirmation" type="password"
                        :label="$t('front.newPassword.confirmNew')" bg size="lg"
              />
              <div class="dot" v-if="newPasswordConfirmation" @click="drainPasswordConfirmation">
                <i class="fa fa-times"/>
              </div>
              <div class="erreurs">{{ errors.passwordConfirmation }}</div>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <Button class="action" type="submit" :label="$t('common.button.save')"/>
        </div>
      </form>
    </div>
    <div v-else-if="message" class="content">
      <p class="error-message">{{ errorMessage }}</p>
      <p class="message">{{ message }}</p>
    </div>
    <div v-else class="content">
      <p class="error-message">{{ errorMessage }}</p>
      <p class="password-forgot-desc">{{ $t('front.forgotPassword.desc') }}</p>
      <form @submit.prevent="recoverPassword">
        <div class="form-connexion" @submit.prevent="loginAction">
          <div class="champs-connexion height-content">
            <div class="container-input">
              <MDBInput class="champs-form" type="email" autocomplete="username"
                        :label="$t('front.login.placeholder.login')" bg size="lg" v-model="login"
              />
              <div class="dot" v-if="login" @click="drainEmail">
                <i class="fa fa-times"/>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <Button class="action" type="submit" :label="$t('front.login.button.recover')"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import AuthentApi from '@/api/authentication/AuthentApi';
import UsersApi from '@/api/users/UsersApi';
import Button from '@/components/Button.vue';
import Notifications from '@/components/Notifications.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { LOGIN, RECOVER_PASSWORD } from '@/router/routes';
import { validatePassword, validatePasswordConfirmation } from '@/utils/FormValidator';
import { MDBInput } from 'mdb-vue-ui-kit';
import { ArrowLeft } from 'mdue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Button,
    ArrowLeft,
    MDBInput,
    Notifications,
  },
  data() {
    const authentApi = getGlobalInstance(AuthentApi);
    const usersApi = getGlobalInstance(UsersApi);

    return {
      authentApi,
      usersApi,
      login: '',
      message: '',
      errorMessage: '',
      showNewPassword: this.$route.name === RECOVER_PASSWORD,
      newPassword: '',
      newPasswordConfirmation: '',
      errors: {},
    };
  },
  props: {
    forgottenPassword: Function,
  },
  methods: {
    drainEmail() {
      this.login = '';
    },
    drainPassword() {
      this.newPassword = '';
    },
    drainPasswordConfirmation() {
      this.newPasswordConfirmation = '';
    },
    recoverPassword() {
      this.message = '';
      this.authentApi.recoverPassword({
        login: this.login,
      })
        .then(() => {
          this.message = this.$t('front.forgotPassword.desc2');
          this.errorMessage = '';
        })
        .catch(() => {
          this.message = this.$t('front.forgotPassword.desc2');
        });
    },
    savePassword() {
      if (this.checkForm()) {
        this.usersApi.savePassword({
          newPassword: this.newPassword,
          newPasswordConfirmation: this.newPasswordConfirmation,
          token: this.$route.params.token,
        })
          .then(() => {
            this.$refs.notifications.notifySuccess();
            this.$parent.showLoginPopin = true;
            this.$router.push({ name: LOGIN });
          })
          .catch(() => this.$refs.notifications.notifyError(this.$t('notifications.errorSaveOrExpired')));
      }
    },
    checkForm() {
      this.errors = {};
      let isFormValid = true;
      if (!validatePassword(this.newPassword)) {
        this.errors.password = this.$t('formulaireErrors.passwordValidation');
        isFormValid = false;
      }
      if (!validatePasswordConfirmation(this.newPassword, this.newPasswordConfirmation)) {
        this.errors.passwordConfirmation = this.$t('formulaireErrors.passwordConfirmationValidation');
        isFormValid = false;
      }
      return isFormValid;
    },
  },
})
export default class RecoverPassword extends Vue {
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 338px;
  background: white;

  .close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--grey-5);
    display: flex;

    .text {
      margin: auto;
      text-transform: uppercase;
      font-size: var(--font-size-hint);
      color: var(--grey-5);
    }
  }

  .password-forgot-title {
    font-family: 'TTNormsBold', sans-serif;
    text-transform: uppercase;
    color: var(--grey-5);
    display: flex;
    justify-content: center;
    padding-top: 60px;
    position: relative;
    margin-bottom: 30px;
  }

  .height-content {
    height: auto !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .message {
      margin: 2rem 0;
    }
    .message-newpassword {
      margin: auto;
    }

    .password-forgot-desc {
      width: 80%;
      margin: auto;
    }

    .error-message {
      color: $danger;
      position: absolute;
      top: 85px;
      left: 20px;
    }

    form {
      .form-connexion {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 318px;
        margin: auto;

        ::v-deep .champs-connexion {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: 150px;

          .container-input {
            width: 100%;
            height: auto;
            position: relative;
            margin: 5px 0;

            .dot {
              position: absolute;
              right: .5rem;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: rgba(58, 60, 64, 0.10);
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              z-index: 1;

              i {
                display: inline-block;
                font-size: 12px;
                cursor: pointer;
              }
            }

            .erreurs {
              color: $danger;
              position: absolute;
              padding-left: var(--spacing-very-big);
              font-size: var(--font-size-hint);
            }
          }

          .form-outline {
            .form-control.form-control-lg ~ .form-label {
              top: 50%;
              transform: translateY(-50%);
              color: rgba(88, 96, 100, 0.70);
              font-family: 'TTNormsLight', sans-serif;
              text-align: left;
              padding: 0 3px;
              width: auto;
            }

            .form-control.form-control-lg.active.champs-form {
              font-family: 'TTNormsRegular', sans-serif;
            }

            .form-control.form-control-lg.active,
            .form-control.form-control-lg:focus {
              & ~ .form-label {
                font-family: 'TTNormsRegular', sans-serif;
                color: $connexion-primary;
                font-size: var(--font-size-hint);
                text-align: left;
                padding-left: 3px;
                transform: translateY(-50%) translateY(-60%);
              }
            }

            .form-notch .form-notch-middle {
              display: none;
            }
          }

          input {
            height: var(--btn-height-big);
            padding: 20px 30px 0 15px;
          }
          input::-webkit-credentials-auto-fill-button {
            background-color: white;
            visibility: hidden;
            pointer-events: none;
            position: absolute;
            right: 0;
          }
        }
      }

      .btn-container {
        display: flex;
        justify-content: center;
        margin: 20px auto 20px;

        .action {
          background-color: $connexion-primary;
          color: var(--white);
        }
      }
    }
  }
}

</style>
