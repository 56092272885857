<template>
  <div class="close" @click="close" :style="style">
    <arrow-left />
    <span class="text">{{ $t('common.button.close') }}</span>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { ArrowLeft } from 'mdue';

@Options({
  components: {
    ArrowLeft,
  },
  props: {
    color: String,
  },
  computed: {
    style() {
      return `color: ${this.color}`;
    },
  },
  methods: {
    close() {
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      const route = this.$route.fullPath.split(`/${this.$route.params.id}`)[0];
      this.$router.push(route);
    },
  },
})

export default class Close extends Vue {}
</script>

<style lang="scss">
.close {
  font-size: var(--font-size-small);
  position: absolute;
  left: 50px;
  top: 35px;
  opacity: 1;
  cursor: pointer;
  z-index: 10;
  .text {
    margin-left: var(--spacing-small);
    font-weight: lighter;
    font-family: 'TTNormsRegular', sans-serif;
    font-size: 18px;
  }
}
</style>
