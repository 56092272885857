import HttpMethod from '../../lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export type Entity = {
  id: string;
  name: string;
  type: string;
  primaryColor: string;
  secondaryColor: string;
  idPackage: string;
}

export const ENTITY_TYPES = {
  FOURNISSEUR: 'Fournisseur',
  COOPERATIVE: 'Coopérative',
  UNION: 'Union',
  UNION_ADMIN: 'Union Admin',
};

export default class EntityApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: EntityApi | undefined;

  public static getInstance(): EntityApi {
    if (EntityApi.INSTANCE === undefined) {
      EntityApi.INSTANCE = new EntityApi();
    }

    return EntityApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetchDistinctEntities() {
    return this
      .httpClient
      .restRequest<string[]>(HttpMethod.GET, '/admin/entities/distinct')
      .execute();
  }

  fetchEntityTypes() {
    return this
      .httpClient
      .restRequest<Array<string>>(HttpMethod.GET, '/admin/utilisateurs/entity-types')
      .execute();
  }

  fetchEntityNamesByType(entityType: string) {
    return this
      .httpClient
      .restRequest<Entity[]>(HttpMethod.GET, `/admin/entities/${entityType}`)
      .execute();
  }
}
