import ApiHttpClient from '@/api/ApiHttpClient';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';

export default class MissingDataApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: MissingDataApi | undefined;

  public static getInstance(): MissingDataApi {
    if (MissingDataApi.INSTANCE === undefined) {
      MissingDataApi.INSTANCE = new MissingDataApi();
    }

    return MissingDataApi.INSTANCE;
  }

  private httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  exportMissingData() {
    return this
      .httpClient
      .rawRequest(HttpMethod.POST, '/admin/missingData/export')
      .execute();
  }
}
