import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a27480ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "display-none" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "block" }
const _hoisted_5 = { class: "text-td" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "block" }
const _hoisted_9 = { class: "text-td" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "text-td" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "text-td" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "image-upload-container" }
const _hoisted_16 = {
  key: 1,
  class: "preview"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "block" }
const _hoisted_20 = { class: "text-td" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { class: "text-td" }
const _hoisted_23 = { class: "label" }
const _hoisted_24 = { class: "text-td" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "text-td" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "display-none" }
const _hoisted_29 = { class: "buttons-wrapper block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_ColorPreviewInput = _resolveComponent("ColorPreviewInput")!
  const _component_plus = _resolveComponent("plus")!
  const _component_close = _resolveComponent("close")!
  const _component_content_save = _resolveComponent("content-save")!
  const _component_Button = _resolveComponent("Button")!
  const _component_backspace = _resolveComponent("backspace")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('admin.fournisseur.informations.title')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('admin.fournisseur.informations.desc')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('admin.fournisseur.informations.packageLabel')), 1)
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("select", {
                    name: "package",
                    id: "package",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packages, (packageItem, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: packageItem.id
                      }, _toDisplayString(packageItem.name), 9, _hoisted_7))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.selected]
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('admin.fournisseur.details.whiteLabelProductInfo')), 1),
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('admin.fournisseur.color.primary')), 1)
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_ColorPreviewInput, {
                    color: _ctx.fournisseur.primaryColor
                  }, null, 8, ["color"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('admin.fournisseur.color.secondary')), 1)
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_ColorPreviewInput, {
                    color: _ctx.fournisseur.secondaryColor
                  }, null, 8, ["color"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('admin.fournisseur.color.image')), 1)
                ]),
                _createElementVNode("td", _hoisted_15, [
                  _createElementVNode("input", {
                    type: "file",
                    name: "fields[assetsFieldHandle][]",
                    id: "assetsFieldHandle",
                    class: "overflow-hidden",
                    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
                    ref: "file",
                    accept: ".jpg,.jpeg,.png,.svg,.JPG,.JPEG,.PNG,.SVG"
                  }, null, 544),
                  (_ctx.fileList.length === 0)
                    ? (_openBlock(), _createElementBlock("label", {
                        key: 0,
                        for: "assetsFieldHandle",
                        class: "image-container cursor-pointer",
                        onDragover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args))),
                        onDragleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
                        onDrop: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)))
                      }, [
                        _createVNode(_component_plus, { class: "big-icon" })
                      ], 32))
                    : _createCommentVNode("", true),
                  (_ctx.fileList.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        (_ctx.urlImage)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: _ctx.urlImage,
                              alt: "preview-uploaded-logo"
                            }, null, 8, _hoisted_17))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
                        }, [
                          _createVNode(_component_close)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.$t('admin.fournisseur.referent.title')), 1),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("table", null, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('admin.fournisseur.referent.lastName')), 1)
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  disabled: "",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.referent.lastName) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.referent.lastName]
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_22, [
                _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('admin.fournisseur.referent.firstName')), 1)
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  disabled: "",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.referent.firstName) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.referent.firstName]
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('admin.fournisseur.referent.phone')), 1)
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  disabled: "",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.referent.phoneNumber) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.referent.phoneNumber]
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_26, [
                _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('admin.fournisseur.referent.mail')), 1)
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  disabled: "",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.referent.email) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.referent.email]
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, [
          _createVNode(_component_Button, {
            onClick: () => _ctx.$refs.notifications.notifyInfo(),
            label: _ctx.$t('common.button.save'),
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_content_save, { class: "icon" })
            ]),
            _: 1
          }, 8, ["onClick", "label"]),
          _createVNode(_component_Button, {
            onClick: _ctx.todoClick,
            label: _ctx.$t('common.button.cancel'),
            color: "secondary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_backspace, { class: "icon" })
            ]),
            _: 1
          }, 8, ["onClick", "label"])
        ])
      ])
    ])
  ], 64))
}