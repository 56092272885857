import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export type UserInfo = {
  id: string;
  firstname: string;
  lastname: string;
  entityType: string;
  profileName?: string;
  entityName: string;
  packageLabel?: string;
}

export type UserProfile = {
  id: string;
  name: string;
}

export type UserShort = {
  lastName: string;
  firstName: string;
  id: string;
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  lastConnection: Date;
  idEntity: string;
  entityType: string;
  entityName: string;
  idProfile: string;
  isReferent: boolean;
  packageLabel: string;
  hasAcceptedCgu: boolean;
  profileName: string;
  isFirstConnexion: boolean;
};

export type UserChangePassword = {
  newPassword: string;
  newPasswordConfirmation: string;
  token: string;
}

export default class UsersApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: UsersApi | undefined;

  private static USER_BASE_URL = '/admin/utilisateurs';

  public static getInstance(): UsersApi {
    if (UsersApi.INSTANCE === undefined) {
      UsersApi.INSTANCE = new UsersApi();
    }

    return UsersApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  createNewUser(user: User) {
    return this
      .httpClient
      .restRequest<User>(HttpMethod.POST, `${UsersApi.USER_BASE_URL}`)
      .jsonBody(user)
      .execute();
  }

  deleteUser(id: string) {
    return this
      .httpClient
      .restRequest(HttpMethod.DELETE, `${UsersApi.USER_BASE_URL}/${id}`)
      .execute();
  }

  fetch() {
    return this
      .httpClient
      .restRequest<UserInfo[]>(HttpMethod.GET, `${UsersApi.USER_BASE_URL}`)
      .execute();
  }

  resetCGU() {
    return this
      .httpClient
      .restRequest<UserInfo[]>(HttpMethod.PUT, '/admin/utilisateurs/cgu')
      .execute();
  }

  fetchById(id: string) {
    return this
      .httpClient
      .restRequest<User>(HttpMethod.GET, `${UsersApi.USER_BASE_URL}/${id}`)
      .execute();
  }

  fetchMe(token?: string) {
    const request = this
      .httpClient
      .restRequest<User>(HttpMethod.GET, `${UsersApi.USER_BASE_URL}/moi`);

    // On peut se retrouver a faire cet appel avantd'être authentifié pour valider les CGU
    if (token) {
      request.headers({ authorization: `Bearer ${token}` });
    }
    return request.execute();
  }

  updateUser(user: User, token?: string) {
    const request = this
      .httpClient
      .restRequest<User>(HttpMethod.PUT, `${UsersApi.USER_BASE_URL}/${user.id}`)
      .jsonBody(user);

    // On peut se retrouver a faire cet appel avant d'être authentifié pour valider les CGU
    if (token) {
      request.headers({ authorization: `Bearer ${token}` });
    }
    return request.execute();
  }

  updateCgu(id: string, token?: string) {
    const request = this
      .httpClient
      .restRequest(HttpMethod.PUT, `${UsersApi.USER_BASE_URL}/${id}/cgu`)
      .headers({ authorization: `Bearer ${token}` });
    return request.execute();
  }

  fetchProfiles() {
    return this
      .httpClient
      .restRequest<UserProfile[]>(HttpMethod.GET, '/admin/profils')
      .execute();
  }

  findUsersByCriterion(lastname: string, firstname: string, profile: string) {
    return this
      .httpClient
      .restRequest<UserInfo[]>(HttpMethod.GET, `${UsersApi.USER_BASE_URL}/search`)
      .queryParams([['lastname', lastname], ['firstname', firstname], ['profile', profile]])
      .execute();
  }

  savePassword(userPassword: UserChangePassword) {
    return this
      .httpClient
      .restRequest(HttpMethod.PUT, `${UsersApi.USER_BASE_URL}/password`)
      .jsonBody(userPassword)
      .execute();
  }
}
