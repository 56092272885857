export type HttpPlumeError = {
  errorCode: string;
  statusArguments?: string[];
};

export type HttpPlumeResponse<T> = {
  error?: HttpPlumeError;
  response?: T;
};

export const genericError = {
  errorCode: 'INTERNAL_ERROR',
};

export const networkError = {
  errorCode: 'NETWORK_ERROR',
};

export const forbiddenError = {
  errorCode: 'FORBIDDEN_ERROR',
};

export const notFoundError = {
  errorCode: 'NOT_FOUND',
};
