import ApiHttpClient from '@/api/ApiHttpClient';
import { ProductResultList } from '@/api/products/ProductsApi';
import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';

export type BalanceBean = {
  type: string;
  label: string;
  selected?: boolean;
  filters?: BalanceBean[];
  productIdList: string[];
  association?: string;
  noteMin?: number;
  noteMax?: number;
  noteAvg?: number;
  logoScore?: string;
  id?: string;
}

export type BalanceFrontBean = {
  balanceList: BalanceBean[];
  nbrResults: number;
}

export type BalanceLabelShort = {
  label: string;
  maleWord: boolean;
}

export const ASSOCIATION_TYPES = {
  NORMAL: 'NORMAL',
  MINIMA: 'MINIMA',
  EXCLUSIVE: 'EXCLUSIVE',
};

export const FIELD_TYPES = {
  MARCHE: 'MARCHE',
  CULTURE: 'CULTURE',
  SEGMENT: 'SEGMENT',
  SEGMENT_SPECIFIC: 'SEGMENT_SPECIFIC',
  FOURNISSEUR: 'FOURNISSEUR',
  PRODUIT: 'PRODUIT',
  COMPOSITION: 'COMPOSITION',
  NOTES: 'NOTES',
  GAMME: 'GAMME',
};

export const FILTER_TYPE = {
  MARCHE: {
    type: FIELD_TYPES.MARCHE,
    color: '#F39D20',
    filters: [FIELD_TYPES.MARCHE, FIELD_TYPES.GAMME],
  },
  CULTURE: {
    type: FIELD_TYPES.CULTURE,
    color: '#75B72B',
    filters: [
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.COMPOSITION,
      FIELD_TYPES.GAMME,
      FIELD_TYPES.CULTURE,
    ],
  },
  SEGMENT: {
    type: FIELD_TYPES.SEGMENT,
    color: '#9C29B7',
    filters: [
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.GAMME,
    ],
  },
  SEGMENT_SPECIFIC: {
    type: FIELD_TYPES.SEGMENT_SPECIFIC,
    color: '#3D8AF7',
    filters: [
      FIELD_TYPES.CULTURE,
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.GAMME,
    ],
  },
  FOURNISSEUR: {
    type: FIELD_TYPES.FOURNISSEUR,
    color: '#0C59CF',
    filters: [
      FIELD_TYPES.COMPOSITION,
      FIELD_TYPES.CULTURE,
      FIELD_TYPES.FOURNISSEUR,
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.GAMME,
    ],
  },
  PRODUIT: {
    type: FIELD_TYPES.PRODUIT,
    color: '#EEE612',
    filters: [
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.COMPOSITION,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.CULTURE,
      FIELD_TYPES.FOURNISSEUR,
      FIELD_TYPES.GAMME,
    ],
  },
  COMPOSITION: {
    type: FIELD_TYPES.COMPOSITION,
    color: '#FA3622',
    filters: [
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.COMPOSITION,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.CULTURE,
      FIELD_TYPES.FOURNISSEUR,
      FIELD_TYPES.GAMME,
    ],
  },
  NOTES: {
    type: FIELD_TYPES.NOTES,
    color: '#ec22fa',
    filters: [
      FIELD_TYPES.MARCHE,
      FIELD_TYPES.SEGMENT_SPECIFIC,
      FIELD_TYPES.COMPOSITION,
      FIELD_TYPES.SEGMENT,
      FIELD_TYPES.CULTURE,
      FIELD_TYPES.FOURNISSEUR,
      FIELD_TYPES.NOTES,
      FIELD_TYPES.GAMME,
    ],
  },
};

const nbrTileDefault = 15;

export default class BalanceApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: BalanceApi | undefined;

  private static USER_BASE_URL = '/front/balance';

  public static getInstance(): BalanceApi {
    if (BalanceApi.INSTANCE === undefined) {
      BalanceApi.INSTANCE = new BalanceApi();
    }

    return BalanceApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  findProductBalanceById(id: string) {
    return this
      .httpClient
      .restRequest<BalanceBean>(HttpMethod.GET, `${BalanceApi.USER_BASE_URL}/product/${id}`)
      .execute();
  }

  findProductsByType(
    type: string,
    filters: BalanceBean[],
    limit?: number,
    searchValue?: string,
  ) {
    return this
      .httpClient
      .restRequest<BalanceFrontBean>(HttpMethod.POST, `${BalanceApi.USER_BASE_URL}/search`)
      .queryParams([
        ['type', type],
        ['limit', limit || nbrTileDefault],
        ['searchValue', searchValue || ''],
      ])
      .jsonBody(filters)
      .execute();
  }

  fetchResearchFields(isBalance: boolean, isFiltre: boolean) {
    return this
      .httpClient
      .restRequest<BalanceBean[]>(HttpMethod.PUT, `${BalanceApi.USER_BASE_URL}/fields`)
      .queryParams([['isBalance', isBalance], ['isFiltre', isFiltre]])
      .execute();
  }

  getBalanceScores(typeEnjeu: string, products: BalanceBean[]) {
    return this
      .httpClient
      .restRequest<BalanceBean[]>(HttpMethod.POST, `${BalanceApi.USER_BASE_URL}/scores`)
      .queryParams([['typeEnjeu', typeEnjeu]])
      .jsonBody(products)
      .execute();
  }

  fetchLogoProduct(products: BalanceBean[]) {
    return this
      .httpClient
      .restRequest<BalanceBean[]>(HttpMethod.POST, `${BalanceApi.USER_BASE_URL}/product`)
      .jsonBody(products)
      .execute();
  }

  findProductsByFilterSearch(
    filters: BalanceBean[],
    limit: number,
    offset: number,
    value: string,
    productSort: string,
  ) {
    return this
      .httpClient
      .restRequest<ProductResultList>(HttpMethod.POST, `${BalanceApi.USER_BASE_URL}/filterSearch`)
      .queryParams([['limit', limit], ['offset', offset], ['searchValue', value], ['productSort', productSort]])
      .jsonBody({ filters })
      .execute();
  }

  findFiltersByFilterCategory(type: string, isProductFilter: boolean) {
    return this
      .httpClient
      .restRequest<BalanceFrontBean>(HttpMethod.GET, `${BalanceApi.USER_BASE_URL}/filters`)
      .queryParams([['type', type], ['isProductFilter', isProductFilter]])
      .execute();
  }
}
