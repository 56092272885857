export function validatePhone(phoneInput: string) {
  return phoneInput.match(RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/));
}

export function validateEmail(mailInput: string) {
  return mailInput.match(RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/));
}

export function validatePassword(passwordInput: string) {
  return passwordInput.match(RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*?[#?!@$%.,_+;:<>/^&*-])[A-Za-z\d#?!@$%.,_+;:<>/^&*-]{8,}$/));
}

export function validatePasswordConfirmation(
  passwordInput: string,
  passwordConfirmationInput: string,
) {
  return passwordInput === passwordConfirmationInput;
}
