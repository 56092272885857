import HttpMethod from '@/lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export type Lock = {
  creationDate: string;
}

export default class ImportApi {
  private static USER_BASE_URL = '/admin/import';

  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ImportApi | undefined;

  public static getInstance(): ImportApi {
    if (ImportApi.INSTANCE === undefined) {
      ImportApi.INSTANCE = new ImportApi();
    }

    return ImportApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  current() {
    return this
      .httpClient
      .restRequest<Lock>(HttpMethod.GET, `${ImportApi.USER_BASE_URL}`)
      .execute();
  }

  last() {
    return this
      .httpClient
      .restRequest<Lock>(HttpMethod.GET, `${ImportApi.USER_BASE_URL}/last`)
      .execute();
  }

  import(): Promise<Response> {
    return this
      .httpClient
      .rawRequest(HttpMethod.POST, `${ImportApi.USER_BASE_URL}/global`)
      .execute();
  }
}
