import { PERMISSIONS } from '@/api/authentication/AuthentApi';
import HttpMethod from '../../lib/simple-http-request-builder/HttpMethod';
import ApiHttpClient from '../ApiHttpClient';

export const ONE_SCORE = 'ONE_SCORE';

export const PRODUCT_TYPE = {
  PPP: 'PPP',
  SEMENCE: 'SEMENCE',
};

export type Product = {
  id: string;
  name: string;
  type: 'SEMENCE' | 'PPP';
  supplier: string;
  amm: string;
  gnis: string;
  segment: string;
  culture: string;
  segmentSpecific: string;
  isInCatalog: boolean;
  isVisible: boolean;
  isValid: boolean;
};

export type Market = {
  name: string;
  label: string;
};

export type MarketSegment = {
  id: string;
  label: string;
};

export type ScoreProduct = {
  noteOneScore: number;
  noteAlimentation: number;
  noteAgriculteur: number;
  noteEnvironnement: number;
  noteQualitesNutritionnelles?: number;
  noteQualitesSanitaires?: number;
  noteRemunerationJusteEtDurable?: number;
  noteQualiteDeVie?: number;
  noteBiodiversite?: number;
  noteCarbone?: number;
}

export type IndicatorsScoreProduct = {
  label: string;
  score: number;
  children: IndicatorsScoreProduct[];
}

export type ScoredProduct = {
  product: Product;
  lastScoringUpdate: string;
  lastDataUpdate: string;
  scoreProduct: ScoreProduct;
};

export type ProductResultList = {
  productsList: ScoredProduct[];
  nbrProducts: number;
};

export type Criterion = {
  market: string;
  segment: string;
  composition: string;
  culture: string;
  segmentSpecific: string;
  supplier: string;
  offset: number;
  limit: number;
  productSort: string;
};

export type ComparisonProductByScoreType = {
  min: number;
  avg: number;
  max: number;
}

export type SousEnjeu = {
  name: string;
  label: string;
  permissions: string[];
}

export type Enjeu = {
  name: string;
  label: string;
  permissions: string[];
  color: string;
  image: string;
  sousEnjeux?: SousEnjeu[];
}

export type ComparisonProduct = {
  oneScore: ComparisonProductByScoreType;
  alimentation: ComparisonProductByScoreType;
  agriculteur: ComparisonProductByScoreType;
  environnement: ComparisonProductByScoreType;
}

export const NBR_ELEMENTS_RETURNED = 15;

export const ALL_ENJEUX = [
  {
    name: ONE_SCORE,
    label: 'OneScore',
  },
  {
    name: 'ALIMENTATION',
    label: 'Alimentation',
  },
  {
    name: 'AGRICULTEUR',
    label: 'Agriculteur',
  },
  {
    name: 'ENVIRONNEMENT',
    label: 'Environnement',
  },
  {
    name: 'VIE',
    label: 'Qualité de vie',
  },
  {
    name: 'CARBONE',
    label: 'Carbone',
  },
  {
    name: 'SANITAIRE',
    label: 'Qualités sanitaires',
  },
  {
    name: 'NUTRITION',
    label: 'Qualités nutritionnelles',
  },
  {
    name: 'REMUNERATION',
    label: 'Rémunération juste et durable',
  },
  {
    name: 'BIODIVERSITE',
    label: 'Biodiversité',
  },
];

export const ENJEUX_TYPES: Enjeu[] = [
  {
    name: ONE_SCORE,
    label: 'Onescore',
    permissions: [
      PERMISSIONS.FILTRE_NOTE_ONESCORE,
      PERMISSIONS.CONSULTER_ONESCORE_BALANCE,
    ],
    color: '#586166',
    image: 'logo_onescore_marque',
  },
  {
    name: 'AGRICULTEUR',
    label: 'Agriculteur',
    permissions: [
      PERMISSIONS.FILTRE_NOTE_AGRICULTEUR,
      PERMISSIONS.CONSULTER_AGRICULTEUR_BALANCE,
    ],
    color: '#005297',
    image: 'logo_enjeu_agriculteur',
    sousEnjeux: [
      {
        name: 'REMUNERATION',
        label: 'Rémunération juste et durable',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_REMUNERATION,
          PERMISSIONS.CONSULTER_REMUNERATION_BALANCE,
        ],
      },
      {
        name: 'VIE',
        label: 'Qualité de vie',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_VIE,
          PERMISSIONS.CONSULTER_VIE_BALANCE,
        ],
      },
    ],
  },
  {
    name: 'ALIMENTATION',
    label: 'Alimentation',
    permissions: [
      PERMISSIONS.FILTRE_NOTE_ALIMENTATION,
      PERMISSIONS.CONSULTER_ALIMENTATION_BALANCE,
    ],
    color: '#FBBF2F',
    image: 'logo_enjeu_alimentation',
    sousEnjeux: [
      {
        name: 'SANITAIRE',
        label: 'Qualités sanitaires',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_SANITAIRE,
          PERMISSIONS.CONSULTER_SANITAIRE_BALANCE,
        ],
      },
      {
        name: 'NUTRITION',
        label: 'Qualités nutritionnelles',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_NUTRITION,
          PERMISSIONS.CONSULTER_NUTRITIONNEL_BALANCE,
        ],
      },
    ],
  },
  {
    name: 'ENVIRONNEMENT',
    label: 'Environnement',
    permissions: [
      PERMISSIONS.FILTRE_NOTE_ENVIRONNEMENT,
      PERMISSIONS.CONSULTER_ENVIRONNEMENT_BALANCE,
    ],
    color: '#5DB67D',
    image: 'logo_enjeu_environnement',
    sousEnjeux: [
      {
        name: 'BIODIVERSITE',
        label: 'Biodiversité',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_BIODIVERSITE,
          PERMISSIONS.CONSULTER_BIODIVERSITE_BALANCE,
        ],
      },
      {
        name: 'CARBONE',
        label: 'Carbone',
        permissions: [
          PERMISSIONS.FILTRE_NOTE_CARBONE,
          PERMISSIONS.CONSULTER_CARBONE_BALANCE,
        ],
      },
    ],
  },
];

export const LOGO_TYPES = {
  ONESCORE_ALONE: 'one-score-alone',
  ONESCORE: 'one-score',
  ALIMENTATION: 'alimentation',
  AGRICULTEUR: 'agriculteur',
  ENVIRONNEMENT: 'environnement',
};

export default class ProductsApi {
  // eslint-disable-next-line no-use-before-define
  private static INSTANCE: ProductsApi | undefined;

  public static getInstance(): ProductsApi {
    if (ProductsApi.INSTANCE === undefined) {
      ProductsApi.INSTANCE = new ProductsApi();
    }

    return ProductsApi.INSTANCE;
  }

  private readonly httpClient: ApiHttpClient;

  constructor() {
    this.httpClient = ApiHttpClient.getInstance();
  }

  fetch(criterion: Criterion) {
    return this
      .httpClient
      .restRequest<ProductResultList>(
        HttpMethod.GET,
        '/products',
      )
      .queryParams([
        ['market', criterion.market],
        ['segment', criterion.segment],
        ['segmentSpecific', criterion.segmentSpecific],
        ['culture', criterion.culture],
        ['composition', criterion.composition],
        ['supplier', criterion.supplier],
        ['limit', criterion.limit],
        ['offset', criterion.offset],
        ['productSort', criterion.productSort],
      ])
      .execute();
  }

  fetchProductSearch(value: string, productSort: string) {
    return this
      .httpClient
      .restRequest<ProductResultList>(HttpMethod.GET, '/products/search')
      .queryParams([['searchValue', value], ['productSort', productSort]])
      .execute();
  }

  fetchById(id: string) {
    return this
      .httpClient
      .restRequest<ScoredProduct>(HttpMethod.GET, `/products/${id}`)
      .execute();
  }

  updateProducts(productList: string[]) {
    return this
      .httpClient
      .restRequest<void>(HttpMethod.PUT, '/products')
      .jsonBody(productList)
      .execute();
  }

  exportScore(idProduct: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/products/${idProduct}/exportIndicateurs`)
      .execute();
  }

  exportLogos(idProduct: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/products/${idProduct}/logos/download`)
      .execute();
  }

  fetchLogo(idProduct: string, logoType: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.GET, `/products/${idProduct}/logos/${logoType}`)
      .execute();
  }

  fetchPdf(idProduct: string, base64Balance: string) {
    return this
      .httpClient
      .rawRequest(HttpMethod.POST, `/products/${idProduct}/pdf`)
      .jsonBody({ base64Balance })
      .execute();
  }

  fetchProductScoreById(idProduct: string) {
    return this
      .httpClient
      .restRequest<ScoreProduct>(HttpMethod.GET, `/products/${idProduct}/score`)
      .execute();
  }

  fetchDetailProductScoreById(idProduct: string) {
    return this
      .httpClient
      .restRequest<IndicatorsScoreProduct>(HttpMethod.GET, `/products/${idProduct}/indicateurs`)
      .execute();
  }

  fetchComparisonProduct(comparisonCriteria: string) {
    return this
      .httpClient
      .restRequest<ComparisonProduct>(HttpMethod.GET, `/products/comparison/${comparisonCriteria}`)
      .execute();
  }
}
