<template>
  <div
    class="balance-tile-container"
    v-bind:style="[{ background: getBackgroundColor() }]"
  >
    <div class="infos">
      <div class="product">
        <div class="color-band" v-bind:style="getColorFromType(product.type)">
          <label>{{ product.label?.toLowerCase() }}</label>
        </div>
        <label class="filter-label"
               v-if="globalFilters && globalFilters.length > 0 && !isProduct">
          {{
            $t('front.balanceSocietale.creation.products',
              {
                criterions: getCriterionText(globalFilters),
                association: getAssociationText(),
                count: product.productIdList.length,
                nbr: product.productIdList.length
              }
            )
          }}
        </label>
        <label class="filter-label" v-else-if="!isProduct">
          {{
            $t('front.balanceSocietale.creation.productsList',
              {
                count: product.productIdList.length,
                nbr: product.productIdList.length,
              }
            )
          }}
        </label>
      </div>
    </div>
    <Button v-if="isInBasket" @click="removeProduct" class="action remove-btn">
      <img alt="balance" :src="require('@/assets/logo_balance_blanc.png')"/>
      <span class="text">{{ $t('common.button.remove') }}</span>
    </Button>
    <Button v-if="!isInBasket && havePermissionAddToBalance()" @click="addProduct" class="action">
      <img alt="balance" :src="require('@/assets/logo_balance_bleu.png')"/>
      <span class="text">{{ $t('common.button.add') }}</span>
    </Button>
  </div>
</template>

<script lang="ts">
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { CheckboxBlankOutline, CheckboxMarkedOutline } from 'mdue';
import {
  ASSOCIATION_TYPES,
  BalanceBean,
  FIELD_TYPES,
  FILTER_TYPE,
} from '@/api/balance/BalanceApi';
import { inject } from 'vue';

@Options({
  components: {
    Button,
    CheckboxBlankOutline,
    CheckboxMarkedOutline,
  },
  props: {
    isProduct: Boolean,
    product: Object,
    isInBasket: Boolean,
  },
  data() {
    const globalFilters = inject('globalFilters');
    const authorization = inject('authorization');
    return {
      globalFilters,
      authorization,
      criterions: [],
    };
  },
  methods: {
    getAssociationText() {
      let associated = '';
      // On recupere l'index du filtre composition dans la liste des filtres du produit
      const indexFilter = this.product.filters.map((e: BalanceBean) => e.type)
        .indexOf(FIELD_TYPES.COMPOSITION);
      // On regarde si association != undefined (car 0 si composition seule et 1 si associee)
      if (this.product.filters
        && this.product.filters[indexFilter]
        && this.product.filters[indexFilter].association !== undefined) {
        // On recupere le texte en fonction de l'etat du filtre (associé ou non)
        associated = this.getAssociationType(this.product.filters[indexFilter].association);
      }
      return associated;
    },
    getAssociationType(association: string) {
      if (association === ASSOCIATION_TYPES.EXCLUSIVE) {
        return `(${this.$t('front.balanceSocietale.filtre.composition.alone')})`;
      }
      if (association === ASSOCIATION_TYPES.MINIMA) {
        return `(${this.$t('front.balanceSocietale.filtre.composition.associated')})`;
      }
      return '';
    },
    getCriterionText(criterions: BalanceBean[]) {
      this.criterions = [];
      const criterionText: string[] = [];
      criterions.forEach((criterion: BalanceBean) => {
        criterionText.push(criterion.label);
        this.criterions.push(criterion);
      });
      this.product.filters = this.criterions;
      return `"${criterionText.join(', ')}"`;
    },
    getColorFromType(type: keyof typeof FILTER_TYPE) {
      return { background: FILTER_TYPE[type].color };
    },
    addProduct() {
      const index = this.$parent.checked.map((e: BalanceBean) => e.label)
        .indexOf(this.product.label);
      this.$parent.checked.splice(index, 1);
      if (this.$parent.basketProducts.length < 5) {
        this.$parent.basketProducts.push({
          id: this.product.id,
          type: this.product.type,
          label: this.product.label,
          filters: this.criterions,
          productIdList: this.product.productIdList,
        });
        this.$parent.errorLimit = false;
      } else {
        this.$parent.errorLimit = true;
      }
    },
    removeProduct() {
      this.$parent.errorLimit = false;
      const index = this.isProduct ? this.$parent.basketProducts
        .map((e: BalanceBean) => e.id)
        .indexOf(this.product.id)
        : this.$parent.basketProducts
          .map((e: BalanceBean) => e.label)
          .indexOf(this.product.label);
      this.$parent.basketProducts.splice(index, 1);
    },
    getBackgroundColor() {
      if (this.isInBasket) {
        return '#5961650D';
      }
      return 'white';
    },
    havePermissionAddToBalance() {
      const permissions: string[] = parseJwt(this.authorization);
      return permissions.includes(PERMISSIONS.BALANCE_AJOUTER_BALANCE);
    },
  },
  mounted() {
    if (!this.globalFilters) {
      this.globalFilters = [];
    }
  },
})
export default class BalanceTile extends Vue {
  product?: BalanceBean[];
}
</script>

<style lang="scss" scoped>
$light-blue: #0052970D;
$light-grey: #5961650D;
$button-color: #005297;

.balance-tile-container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infos {
    display: flex;
    flex-direction: row;

    .product {
      margin-top: -10px;
      margin-left: var(--spacing-default);

      .color-band {
        height: 25px;
        width: 5px;
        border-radius: 30px;
        margin-top: -10px;

        label {
          margin-left: var(--spacing-default);
          margin-bottom: -10px;
          font-family: 'SourceSansProRegular', sans-serif;
          color: var(--grey-7);
          width: 350px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 20vw;
          text-transform: capitalize;
        }

        @media screen and (max-width: 1024px) {
          label {
            width: 220px;
          }
        }
      }

      .filter-label {
        position: absolute;
        font-size: var(--font-size-hint);
        margin-left: var(--spacing-default);
        font-style: italic;
        white-space: break-spaces;
        max-width: 24vw;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
      }
    }
  }

  .action {
    margin: 0 var(--spacing-big);
    color: $button-color;
    box-shadow: none !important;
    border: 1px solid $button-color;
    border-radius: 0;
    height: var(--btn-height-default);
    min-width: 141px;
    justify-content: center;
    text-transform: none;

    &.remove-btn {
      color: white;
      background: $button-color;
    }

    img {
      height: 24px;
      margin-right: var(--spacing-big);
    }

    .text {
      font-size: var(--font-size-smaller);
      font-family: 'TTNormsLight', sans-serif;
    }
  }
}

</style>
