import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44c43902"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-container" }
const _hoisted_2 = { class: "fournisseur" }
const _hoisted_3 = { class: "fournisseur-info" }
const _hoisted_4 = { class: "product-count" }
const _hoisted_5 = { class: "user-count" }
const _hoisted_6 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.fournisseur.name), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('admin.fournisseur.list.productCount', { count: _ctx.fournisseur.productNumber })), 1),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('admin.fournisseur.list.userCount', { count: _ctx.fournisseur.userCount })), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: _ctx.$t('admin.fournisseur.list.details'),
        color: "white",
        title: _ctx.$t('admin.fournisseur.list.detailsTitle'),
        onClick: _cache[0] || (_cache[0] = () => _ctx.handleDetails(_ctx.fournisseur.id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_magnify, { class: "icon" })
        ]),
        _: 1
      }, 8, ["label", "title"])
    ])
  ]))
}