import 'mdb-vue-ui-kit/css/mdb.min.css';
import TailwindComponent from '@/components/TailwindComponent.vue';
import VueMyToasts from 'vue-my-toasts';
// eslint-disable-next-line import/no-unresolved
import './toast.css';
import { createApp } from 'vue';
import StoragePlugin from 'vue-web-storage';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

createApp(App).use(i18n)
  .use(store)
  .use(StoragePlugin, {
    // It will register two different instances
    // this.$sessionStorage
    // this.$localStorage
    prefix: 'os_',
    drivers: ['session', 'local'], // default 'local'
  })
  .use(VueMyToasts, {
    // component: BootstrapComponent,
    component: TailwindComponent,
    options: {
      width: '400px',
      position: 'top-right',
      padding: '1rem',
      duration: 3000,
    },
  })
  .use(router)
  .mount('#app');
