<template>
  <MDBInput
    :formOutline="false"
    :placeholder="text"
    class="input"
    v-model="input"
    inputGroup="lg"
    type="text"
    v-on:keyup.enter="handleSearch"
  >
    <template #prepend>
        <span class="input-group-text">
          <magnify class="icon"/>
        </span>
    </template>
    <MDBBtn :ripple="false" @click="clear">
      <close-circle/>
    </MDBBtn>
  </MDBInput>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MDBInput, MDBBtn } from 'mdb-vue-ui-kit';
import { Magnify, CloseCircle } from 'mdue';
import { ref } from 'vue';

@Options({
  components: {
    MDBInput,
    MDBBtn,
    Magnify,
    CloseCircle,
  },
  props: {
    text: String,
  },
  data() {
    const input = ref('');

    return {
      input,
    };
  },
  methods: {
    clear() {
      this.input = '';
    },
    handleSearch() {
      this.$parent.handleSearch();
    },
  },
  watch: {
    input: {
      handler() {
        this.$parent.search = this.input;
      },
      deep: true,
    },
  },
})
export default class SearchbarComponent extends Vue {

}
</script>

<style lang="scss">
.input {
  outline: none;
  box-shadow: none !important;
  border: none;
}

.btn {
  box-shadow: none !important;
}

.input-group-text {
  border: none;

  .icon {
    font-size: var(--font-size-default);
  }
}
.input-group {
  .input {
    border: none !important;
    height: var(--btn-height-default) !important;
  }
  .btn {
    margin-top: -6px;
  }
}

.fournisseur-search {
  .input-group {
    background: white;
    border: 1px solid var(--grey-2);
    border-radius: 4px;
    input {
      padding-left: 10px;
    }
  }
}

.cooperative-search {
  .input-group {
    background: white;
    border: 1px solid var(--grey-2);
    border-radius: 4px;
    input {
      padding-left: 10px;
    }
  }
}

</style>
