<template>
  <div class="footer-container" v-bind:class="{'admin': isAdmin}">
    <div class="contacts">
      <img alt="onescore logo" src="@/assets/logo_one_score_marque_blanche.png" />
      <div class="line-grey" />
      <div class="newsletter">
        <label for="email">{{ $t('front.footer.newsletter') }}</label>
        <div>
          <input type="text" placeholder="Email" id="email" />
          <button @click="todoClick">{{ $t('common.button.valid') }}</button>
        </div>
      </div>
      <div class="line-grey" />
      <div class="links">
        <p>{{ $t('front.footer.oneScore') }}</p>
        <p>{{ $t('front.footer.about') }}</p>
        <p>{{ $t('front.footer.community') }}</p>
        <p>{{ $t('front.footer.faq') }}</p>
      </div>
    </div>
    <div class="footer">
      <div class="rights">
        <copyright class="icon" />
        <p>{{ $t('front.footer.rights') }}</p>
      </div>
      <span>|</span>
      <p class="link" @click="clickCgu">{{ $t('front.footer.cgu') }}</p>
      <span>|</span>
      <p class="link" @click="clickLegalInfos">{{ $t('front.footer.legal-infos') }}</p>
      <span>|</span>
      <p class="link" @click="clickConfidentality">{{ $t('front.footer.confidentiality') }}</p>
      <span>|</span>
      <p class="link" @click="openTutorialPopin">{{ $t('front.footer.tutorial') }}</p>
    </div>
    <!-- Popin de tutoriel -->
    <TutorialPopin
        v-if="showTutorialPopin"
        :close="closeTutorialPopin"
    />
  </div>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import TutorialPopin from '@/tutorial/TutorialPopin.vue';
import { Options, Vue } from 'vue-class-component';
import { Copyright } from 'mdue';
import * as SprintHelperModule from '@/SprintHelperModule';
import ConfigurationApi, { Links } from '@/api/configuration/ConfigurationApi';

@Options({
  components: {
    Copyright,
    TutorialPopin,
  },
  props: {
    isAdmin: Boolean,
  },
  data() {
    const configurationApi = getGlobalInstance(ConfigurationApi);
    return {
      configurationApi,
      links: {},
      showTutorialPopin: false,
    };
  },
  methods: {
    todoClick() {
      SprintHelperModule.buttonInDev();
    },
    clickCgu() {
      window.open(this.links.cguLink);
    },
    clickLegalInfos() {
      window.open(this.links.legalLink);
    },
    clickConfidentality() {
      window.open(this.links.confidentialityLink);
    },
    openTutorialPopin() {
      this.showTutorialPopin = true;
    },
    closeTutorialPopin() {
      this.showTutorialPopin = false;
    },
  },
  mounted() {
    this.configurationApi.fetchLinks()
      .then((links: Links) => {
        this.links = links;
      });

    if (this.$route.query.openTutorial) {
      this.openTutorialPopin();
    }
  },
})

export default class FooterTemplate extends Vue {
}
</script>

<style lang="scss" scoped>
.footer-container {
  position: relative;

  &.admin {
    background: var(--background-admin);
  }

  .icon {
    margin-right: 4px;
  }

  .contacts {
    clip-path: polygon(0 30%, 100% 10%, 100% 100%, 0 100%);
    background: var(--grey-5);
    height: 230px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      margin-top: var(--spacing-very-big);
      height: 100px;
      position: absolute;
      left: 0;
      top: 60px;
    }

    .line-grey {
      background: #95979a;
      width: 2px;
      height: 150px;
      margin-top: 35px;
    }

    .newsletter {
      display: none;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      label {
        color: white;
        margin: var(--spacing-big) 0;
      }

      button {
        border: 1px solid white;
        background: transparent;
        color: white;
        padding: 0 var(--spacing-big);
        height: 32px;
        border-radius: 4px;
        margin: 0 10px;
      }

      input {
        border-radius: 4px;
        height: 32px;
        border: none;
        padding-left: var(--spacing-medium);
      }
    }

    .links {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 100px;
      margin-left: -50px;
      margin-top: 25px;

      p {
        color: white;
        margin: 4px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'TTNormsRegular', sans-serif;
    background: var(--grey-7);
    color: white;
    height: 51px;

    p {
      margin: 0;
    }

    span {
      margin: var(--spacing-big);
    }

    .link {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .rights {
      display: flex;
      align-items: center;
    }
  }
}

</style>
