<template>
  <MDBBtn
    v-bind:style="[(isSelected === enjeu.label || (isSousEnjeuSelected() && !isDetail))
    ? {background: enjeu.color} : {},
    isDetail ? {height: '60px'} : {height: '100px'}]"
    v-bind:class="[disabled ? 'disabled' : '']"
    :disabled="disabled"
  >
    <img
      v-if="enjeu.image && !isDetail"
      v-bind:class="[(isSelected === enjeu.label || (isSousEnjeuSelected() && !isDetail))
        ? 'selected': '']"
      class="enjeu-logo"
      alt="enjeu logo"
      :src="require(`@/assets/${enjeu.image}.svg`)"
    />
    <div
      v-else-if="enjeu.label"
      v-bind:style="[isDetail && (isSelected !== enjeu.label)
        ? {color: enjeu.color} : {color: '#FFF'}]"
      class="enjeu-logo"
    >{{enjeu.label}}</div>
  </MDBBtn>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { MDBBtn } from 'mdb-vue-ui-kit';

@Options({
  components: {
    MDBBtn,
  },
  props: {
    disabled: Boolean,
    enjeu: Object,
    isDetail: Boolean,
    isSelected: String,
  },
  methods: {
    isSousEnjeuSelected() {
      return this.$parent.isSousEnjeuSelected(this.enjeu);
    },
  },
})
export default class ButtonEnjeuBalance extends Vue {
  selected?: boolean;
}
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  margin: 0;
  box-shadow: 0 3px 6px var(--grey-shadow) !important;
  border-radius: 0;
  background: white;

  &.disabled {
    background: var(--grey-6);
    .enjeu-logo {
      filter: brightness(0) invert(1);
    }
  }

  .enjeu-logo {
    width: 100px;
    font-family: "TTNormsBold";
    font-size: 10px;

    &.selected {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
