<template>
  <Drawer>
    <template v-slot:header>
      <div class="header">
        <Close color="white"/>
        <h3 v-if="$route.params.id === 'new'">{{ $t('admin.utilisateur.title') }}</h3>
        <h3 v-else>{{ utilisateur.firstName}} {{ utilisateur.lastName}}</h3>
      </div>
    </template>
    <template v-slot:page>
      <div id="nav">
        <router-link
          :to="{ name: UTILISATEUR_DETAIL_ROUTE, params: { id: $route.params.id } }"
          v-t="'admin.fournisseur.users.details'"
          replace
        />
      </div>
      <div class="page-container">
        <router-view/>
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import Drawer from '@/components/Drawer.vue';
import Close from '@/components/Close.vue';
import { UTILISATEUR_DETAIL_ROUTE, UTILISATEUR_PRODUCT_ROUTE } from '@/router/routes';
import UsersApi, { User } from '@/api/users/UsersApi';

@Options({
  components: {
    Drawer,
    Close,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    return {
      utilisateur: {},
      UTILISATEUR_DETAIL_ROUTE,
      UTILISATEUR_PRODUCT_ROUTE,
      usersApi,
    };
  },
  methods: {
    fetchUtilisateur(id: string) {
      return this.usersApi
        .fetchById(id)
        .then((response: User) => {
          this.utilisateur = response;
        });
    },
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.fetchUtilisateur(this.$route.params.id);
    }
  },
  updated() {
    if (this.$route.params.id !== 'new') {
      this.fetchUtilisateur(this.$route.params.id);
    }
  },
})

export default class Utilisateur extends Vue {
}

</script>

<style lang="scss" scoped>
$height-header-fournisseur: var(--header-width);

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: $height-header-fournisseur;
  background: $dark;
  color: white;
  text-transform: none;
  z-index: 2;

  h3 {
    font-size: 18px;
    margin: 0;
  }
}

#nav {
  display: flex;
  padding: 0;
  margin: 0 var(--spacing-bigger);
  border-bottom: 1px solid var(--grey-1);
  margin-top: $height-header-fournisseur;

  a {
    margin: 0 var(--spacing-bigger);
    min-width: 180px;
    padding: var(--spacing-bigger);
    font-weight: normal;

    &.router-link-active {
      border-bottom: 3px solid black;
      color: initial;
    }
  }
}

.page-container {
  margin: 0 var(--spacing-bigger);
}
</style>
