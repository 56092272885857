<template>
  <div class="no-access-container">
    {{ $t('noAccess.title') }}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class NoAccess extends Vue {
}
</script>

<style lang="scss" scoped>
.no-access-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $primary;
  color: $white;
  font-size: var(--font-size-big);
  font-family: 'TTNormsRegular', sans-serif;
}
</style>
