<template>
  <notifications ref="notifications" />
  <div class="container">
    <div class="title">
      <div class="desc">{{ $t('front.balanceSocietale.accueil.bouton.desc') }}</div>
    </div>
    <div v-if="videoLink" class="video">
      <video width="600" controls :src="videoLink"></video>
    </div>
    <div class="button" v-if="havePermissionLancerBalance()">
      <Button
        class="action"
        :label="$t('front.balanceSocietale.accueil.bouton.title')"
        :onClick="handleClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import ConfigurationApi, { VideoLink } from '@/api/configuration/ConfigurationApi';
import Button from '@/components/Button.vue';
import Notifications from '@/components/Notifications.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { BALANCE_SOCIETALE_RECHERCHE_ROUTE } from '@/router/routes';
import { inject } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    Button,
    Notifications,
  },
  data() {
    const configurationApi = getGlobalInstance(ConfigurationApi);
    const authorization = inject('authorization');
    return {
      configurationApi,
      authorization,
      videoLink: '',
    };
  },
  methods: {
    handleClick() {
      const permissions: string[] = parseJwt(this.authorization);
      if (permissions.includes(PERMISSIONS.PAGE_COMPARATEUR)) {
        this.$router.push({ name: BALANCE_SOCIETALE_RECHERCHE_ROUTE });
      } else {
        this.$refs.notifications.notifyWarning(this.$t('notifications.notAllowed'));
      }
    },
    havePermissionLancerBalance() {
      const permissions: string[] = parseJwt(this.authorization);
      return permissions.includes(PERMISSIONS.LANCER_BALANCE);
    },
  },
  mounted() {
    this.configurationApi.getVideoTutorielVideo(3)
      .then((videoLink: VideoLink) => {
        this.videoLink = videoLink.value;
      });
  },
})

export default class AccueilBalance extends Vue {
}

</script>

<style lang="scss" scoped>
$primary: #005297;

.container {
  display: flex;
  padding-bottom: var(--spacing-bigger);
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;

  .title {
    .desc {
      width: var(--search-bar-width);
      font-family: 'TTNormsRegular', sans-serif;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-default);

    .action {
      background-color: $primary;
      color: var(--white);
      border: none;
      box-shadow: none !important;
    }
  }

  .video {
    padding-top: 20px;
  }
  .page-container {
    flex: 1;
  }
}

</style>
