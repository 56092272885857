<template>
  <div class="header-page">
    <img alt="Concern logo" :src="require(`@/assets/${image}`)" />
    <div class="foreground-color">
      <div class="title">{{ title }}</div>
      <div class="subtitle">
        <h4>{{ subtitle1 }}</h4>
        <h4>{{ subtitle2 }}</h4>
      </div>
      <ResearchBar
          v-if="showSearchBar"
          v-on:handleSearch="handleSearch($event)"
          :buttonColor="colorButtonResearch"
          :initialValue="inputValueFromHome"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BalanceApi from '@/api/balance/BalanceApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import ResearchBar from '@/views/front/ResearchBar.vue';
import { PRODUCT_ROUTE } from '@/router/routes';
import ProductsApi, { NBR_ELEMENTS_RETURNED, ProductResultList } from '@/api/products/ProductsApi';
import { inject } from 'vue';

@Options({
  props: {
    title: String,
    subtitle1: String,
    subtitle2: String,
    image: String,
  },
  components: {
    ResearchBar,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);
    const productsApi = getGlobalInstance(ProductsApi);

    const globalFilters = inject('globalFilters');
    const filterFieldSelected = inject('filterFieldSelected');
    return {
      balanceApi,
      productsApi,
      filterFieldSelected,
      globalFilters,
      colorButtonResearch: '#005297',
      showSearchBar: this.$route.name === PRODUCT_ROUTE,
      inputValueFromHome: '',
    };
  },
  methods: {
    searchTriggeredManually(value: string) {
      if (value || value === '') {
        this.$parent.searchLaunched = true;
      }
    },
    handleSearch(value: string) {
      this.$parent.dataLoaded = false;
      let researchInput = '';
      this.searchTriggeredManually(value);
      if (value) {
        researchInput = value;
      } else if (this.$route.params.value) {
        researchInput = this.$route.params.value;
        this.inputValueFromHome = this.$route.params.value;
        this.$route.params.value = '';
      }
      if ((this.globalFilters?.length > 0) && this.filterFieldSelected.type) {
        this.balanceApi
          .findProductsByFilterSearch(this.globalFilters, NBR_ELEMENTS_RETURNED, 0, value)
          .then((response: ProductResultList) => {
            this.handleFetchedProduct(response, researchInput);
          });
      } else {
        this.productsApi.fetchProductSearch(researchInput)
          .then((response: ProductResultList) => {
            this.handleFetchedProduct(response, researchInput);
          });
      }
    },
    handleFetchedProduct(productsResponse: ProductResultList, researchInput: string) {
      this.$parent.products = productsResponse.productsList;
      this.$parent.nbrResultProducts = productsResponse.nbrProducts;
      this.$parent.dataLoaded = true;
      this.$parent.tileToDisplay = this.$parent.nbrTileDefault;
      this.$parent.researchInput = researchInput;
    },
  },
  mounted() {
    this.handleSearch();
    this.productsApi
      .fetchProductSearch('')
      .then((response: ProductResultList) => {
        this.$parent.allProducts = response;
      });
  },
})

export default class HeaderTemplate extends Vue {
}
</script>

<style lang="scss">
.header-page {
  width: 100%;
  height: var(--header-page-heigth);

  img {
    width: 100%;
    height: var(--header-page-heigth);
    display: block;
    position: absolute;
    object-fit: cover;
  }

  .input-group {
    margin-top: -9.5rem;
  }

  .foreground-color {
    width: 100%;
    position: relative;
    height: var(--header-page-heigth);
    background: linear-gradient(#{var(--header-color)}) no-repeat center;

    .title {
      position: relative;
      top: 108px;
      font-size: var(--font-size-title-page);
      font-family: 'TTNormsMedium', sans-serif;
      color: white;
    }

    .subtitle {
      line-height: 23px;
      position: relative;
      margin: 120px auto;
      height: 97px;
      width: var(--header-page-subtitle-width);
      font-size: var(--font-size-subtitle-page);
      font-family: 'TTNormsRegular', sans-serif;
      color: white;
    }
  }
}
</style>
