const download = (blob: Blob, filename: string) => {
  const fileURL = window.URL.createObjectURL(blob);
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute(
    'download',
    filename,
  );
  document.body.appendChild(fileLink);
  fileLink.click();
  window.URL.revokeObjectURL(fileURL);
};

const getFilenameFromResponse = (response: Response) => {
  const valueFileName = response.headers.get('Content-Disposition');
  return valueFileName != null ? valueFileName.substring(valueFileName.indexOf('=') + 1) : '';
};

// eslint-disable-next-line import/prefer-default-export
export { download, getFilenameFromResponse };
