import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eef16e6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "missing-data-management" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "missing-data-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_download = _resolveComponent("download")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, { ref: "notifications" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('admin.missingData.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('admin.missingData.subtitle')), 1),
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('admin.missingData.download'),
        color: "primary",
        class: "download-button",
        loading: _ctx.exportMissingDataLoading,
        onClick: _ctx.exportMissingData
      }, {
        default: _withCtx(() => [
          (!_ctx.exportMissingDataLoading)
            ? (_openBlock(), _createBlock(_component_download, {
                key: 0,
                class: "icon"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["label", "loading", "onClick"])
    ])
  ], 64))
}