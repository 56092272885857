import NoAccess from '@/views/front/accueil/NoAccess.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import FournisseurGamme from '@/views/admin/fournisseur/FournisseurGamme.vue';
import DetailsFournisseur from '@/views/admin/fournisseur/DetailsFournisseur.vue';
import FournisseurUsersList from '@/views/admin/fournisseur/FournisseurUsersList.vue';
import FournisseurManagement from '@/views/admin/fournisseur/FournisseurManagement.vue';
import Fournisseur from '@/views/admin/fournisseur/Fournisseur.vue';
import DetailsUtilisateur from '@/views/admin/user/DetailsUtilisateur.vue';
import Utilisateur from '@/views/admin/user/Utilisateur.vue';
import ProductManagement from '@/views/admin/product/ProductManagement.vue';
import ProductDetails from '@/views/admin/product/ProductDetails.vue';
import Admin from '@/views/admin/Admin.vue';
import Front from '@/views/front/Front.vue';
import Product from '@/views/front/product/Product.vue';
import Login from '@/views/front/login/Login.vue';
import AccueilBalance from '@/views/front/balance-societale/AccueilBalance.vue';
import BalanceMain from '@/views/front/balance-societale/BalanceMain.vue';
import PermissionsManagement from '@/views/admin/permissions/PermissionsManagement.vue';
import UserManagement from '@/views/admin/user/UserManagement.vue';
import Balance from '@/views/front/balance-societale/Balance.vue';
import BalanceRecherche from '@/views/front/balance-societale/BalanceRecherche.vue';
import BalanceComparateur from '@/views/front/balance-societale/BalanceComparateur.vue';
import CooperativeManagement from '@/views/admin/cooperative/CooperativeManagement.vue';
import Cooperative from '@/views/admin/cooperative/Cooperative.vue';
import CooperativeGamme from '@/views/admin/cooperative/CooperativeGamme.vue';
import CooperativeUsersList from '@/views/admin/cooperative/CooperativeUsersList.vue';
import DetailsCooperative from '@/views/admin/cooperative/DetailsCooperative.vue';
import Accueil from '@/views/front/accueil/Accueil.vue';

import {
  BALANCE_SOCIETALE_ROUTE,
  FOURNISSEUR_DETAIL_ROUTE,
  FOURNISSEUR_LIST_ROUTE,
  FOURNISSEUR_PRODUCT_ROUTE,
  FOURNISSEUR_USERS_ROUTE,
  HOME_ROUTE,
  PERMISSIONS_ROUTE,
  PRODUCT_ROUTE,
  PRODUCT_DETAILS_ADMIN_ROUTE,
  PRODUCT_LIST_ADMIN_ROUTE,
  USERS_LIST_ROUTE,
  UTILISATEUR_DETAIL_ROUTE,
  UTILISATEUR_PRODUCT_ROUTE,
  LOGIN,
  RECOVER_PASSWORD,
  BALANCE_SOCIETALE_ACCUEIL_ROUTE,
  BALANCE_SOCIETALE_MAIN_ROUTE,
  BALANCE_SOCIETALE_RECHERCHE_ROUTE,
  BALANCE_SOCIETALE_RESULTAT_ROUTE,
  COOPERATIVE_LIST_ROUTE,
  COOPERATIVE_DETAIL_ROUTE,
  COOPERATIVE_PRODUCT_ROUTE,
  COOPERATIVE_USERS_ROUTE,
  MISSING_DATA_ROUTE,
  ACCUEIL, UPDATE_CGU_ROUTE, UPDATE_SCORING_ROUTE, NO_ACCESS,
} from '@/router/routes';
import MissingDataManagement from '@/views/admin/missingData/MissingDataManagement.vue';
import UpdateCGU from '@/views/admin/updateCGU/UpdateCGU.vue';
import UpdateScoring from '@/views/admin/updateScoring/UpdateScoring.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', // TODO: C'est temporaire pour le sprint1. A terme la home sera la home du front
    name: HOME_ROUTE,
    component: Front,
    children: [
      {
        path: 'connexion',
        alias: '/',
        name: LOGIN,
        component: Login,
      },
      {
        path: 'recuperation-mdp/:token',
        name: RECOVER_PASSWORD,
        component: Login,
      },
      {
        path: 'accueil',
        name: ACCUEIL,
        component: Accueil,
      },
      {
        path: 'no-access',
        name: NO_ACCESS,
        component: NoAccess,
      },
      {
        path: 'produits',
        name: PRODUCT_ROUTE,
        component: Product,
      },
      {
        path: 'balance',
        name: BALANCE_SOCIETALE_ROUTE,
        component: Balance,
        children: [
          {
            path: '',
            name: BALANCE_SOCIETALE_ACCUEIL_ROUTE,
            component: AccueilBalance,
          },
          {
            path: 'new',
            name: BALANCE_SOCIETALE_MAIN_ROUTE,
            component: BalanceMain,
            children: [
              {
                path: 'recherche',
                name: BALANCE_SOCIETALE_RECHERCHE_ROUTE,
                component: BalanceRecherche,
              },
              {
                path: 'comparateur',
                name: BALANCE_SOCIETALE_RESULTAT_ROUTE,
                component: BalanceComparateur,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: 'produits',
        name: PRODUCT_LIST_ADMIN_ROUTE,
        component: ProductManagement,
      },
      {
        path: 'produits/:id',
        name: PRODUCT_DETAILS_ADMIN_ROUTE,
        component: ProductDetails,
      },
      {
        path: ':about',
        name: 'About',
        component: () => import('../views/About.vue'),
      },
      {
        path: 'fournisseurs',
        name: FOURNISSEUR_LIST_ROUTE,
        component: FournisseurManagement,
        children: [
          {
            path: ':id',
            name: 'Fournisseur',
            component: Fournisseur,
            children: [
              {
                path: 'details',
                name: FOURNISSEUR_DETAIL_ROUTE,
                component: DetailsFournisseur,
              },
              {
                path: 'gamme',
                name: FOURNISSEUR_PRODUCT_ROUTE,
                component: FournisseurGamme,
              },
              {
                path: 'liste',
                name: FOURNISSEUR_USERS_ROUTE,
                component: FournisseurUsersList,
              },
            ],
          },
        ],
      },
      {
        path: 'cooperatives',
        name: COOPERATIVE_LIST_ROUTE,
        component: CooperativeManagement,
        children: [
          {
            path: ':id',
            name: 'Cooperative',
            component: Cooperative,
            children: [
              {
                path: 'details',
                name: COOPERATIVE_DETAIL_ROUTE,
                component: DetailsCooperative,
              },
              {
                path: 'gamme',
                name: COOPERATIVE_PRODUCT_ROUTE,
                component: CooperativeGamme,
              },
              {
                path: 'liste',
                name: COOPERATIVE_USERS_ROUTE,
                component: CooperativeUsersList,
              },
            ],
          },
        ],
      },
      {
        path: 'utilisateurs',
        name: USERS_LIST_ROUTE,
        component: UserManagement,
        children: [
          {
            path: ':id',
            name: 'Utilisateur',
            component: Utilisateur,
            children: [
              {
                path: 'detail',
                name: UTILISATEUR_DETAIL_ROUTE,
                component: DetailsUtilisateur,
              },
              {
                path: 'produits',
                name: UTILISATEUR_PRODUCT_ROUTE,
                component: () => import('../views/About.vue'), // TODO ajouter le bon composant
              },
            ],
          },
        ],
      },
      {
        path: 'permissions',
        name: PERMISSIONS_ROUTE,
        component: PermissionsManagement,
      },
      {
        path: 'missing-data',
        name: MISSING_DATA_ROUTE,
        component: MissingDataManagement,
      },
      {
        path: 'update-cgu',
        name: UPDATE_CGU_ROUTE,
        component: UpdateCGU,
      },
      {
        path: 'update-scoring',
        name: UPDATE_SCORING_ROUTE,
        component: UpdateScoring,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
