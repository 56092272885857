<template>
  <div
    class="balance-tile-container"
    v-bind:style="[{ background: getBackgroundColor() }]"
  >
    <div class="infos">
      <button class="checkbox" @click="handleClick">
        <checkbox-marked-outline v-if="checked" class="icon"/>
        <checkbox-blank-outline v-else class="icon"/>
      </button>
      <div class="color-band" v-bind:style="getColorFromType(type)" @click="handleClick">
        <label>{{ label?.toLowerCase() }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { CheckboxBlankOutline, CheckboxMarkedOutline } from 'mdue';
import { BalanceBean, FILTER_TYPE } from '@/api/balance/BalanceApi';

@Options({
  components: {
    Button,
    CheckboxBlankOutline,
    CheckboxMarkedOutline,
  },
  props: {
    type: String,
    label: String,
    checked: Boolean,
  },
  methods: {
    getColorFromType(type: keyof typeof FILTER_TYPE) {
      return { background: FILTER_TYPE[type].color };
    },
    handleClick() {
      if (this.checked) {
        const index = this.$parent.globalFilters.map((e: BalanceBean) => e.label)
          .indexOf(this.label);
        this.$emit('removeFilter', index);
      } else {
        this.$emit('addItem');
      }
    },
    getBackgroundColor() {
      if (this.checked) {
        return '#0052970D';
      }
      return 'white';
    },
  },
})
export default class BalanceTile extends Vue {
}
</script>

<style lang="scss" scoped>
$light-blue: #0052970D;
$light-grey: #5961650D;
$button-color: #005297;

.balance-tile-container {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infos {
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkbox {
      border: none;
      background: none;
      height: fit-content;

      .icon {
        margin: 0 10px;

        &.disabled {
          cursor: not-allowed;
        }
      }
    }

    .color-band {
      height: 25px;
      width: 5px;
      border-radius: 30px;
      cursor: pointer;
      label {
        cursor: pointer;
        margin-left: 15px;
        font-family: 'SourceSansProRegular', sans-serif;
        color: var(--grey-7);
        width: 230px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
    }
  }
}
</style>
