<template>
  <div class="color-preview-container">
    <input
      type="text"
      v-model="colorModel"
      maxlength="7"
      placeholder="#000000"
    />
    <input class="color-preview" type="color" v-model="colorModel" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    color: String,
  },
  data() {
    return {
      colorModel: '',
    };
  },
  watch: {
    color: {
      handler() {
        this.colorModel = this.color;
      },
      deep: true,
    },
  },
})
export default class ColorPreviewInput extends Vue {
  color!: string;
}
</script>

<style lang="scss" scoped>
.color-preview-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  position: relative;
  input {
    height: 35px;
    &.color-preview {
      padding: 0;
      margin: 0;
      border: 0;
      position: absolute;
      right: 5px;
      height: 70%;
      width: 25px;
    }
  }
}
</style>
