<template>
  <i class="fas fa-caret-left" :style="cssVars"></i>
  <div class="popin-container">
    <p class="bandeau">
      {{
        $t('front.balanceSocietale.filtre.title',
          { type: filterFieldSelected.label.toUpperCase() }
        )
      }}
    </p>
    <div class="compo-choice" v-if="isCompo">
      <div class="title">
        <label>{{ $t('front.balanceSocietale.filtre.composition.associationChoice') }}</label>
      </div>
      <div class="checkbox-label">
        <button class="checkbox" @click="handleAlone">
          <checkbox-marked-outline
            v-if="showAlone()"
            class="icon"
          />
          <checkbox-blank-outline v-else class="icon" />
        </button>
        <label>{{ $t('front.balanceSocietale.filtre.composition.alone') }}</label>
      </div>
      <div class="checkbox-label">
        <button class="checkbox" @click="handleAssociated">
          <checkbox-marked-outline
            v-if="showAssociated()"
            class="icon"
          />
          <checkbox-blank-outline v-else class="icon" />
        </button>
        <label>{{ $t('front.balanceSocietale.filtre.composition.associated') }}</label>
      </div>
    </div>
    <div class="espace-recherche">
      <ResearchBarFiltre v-on:handleSearch="handleSearch($event)" :inputValue="inputSearchValue" />
    </div>
    <div class="panier">
      <div v-for="(item, index) in filterFieldSelected.filters" :key="item.label">
        <TagFiltrePanier :product="item.label" v-on:clearItem="clearItem(index, item.label)" />
      </div>
    </div>
    <div v-bind:class="[$parent.dataLoaded ? 'espace-resultat loaded' : 'espace-resultat']">
      <div class="spinner-border" role="status" v-if="!$parent.dataLoaded" />
      <div v-else>
        <div class="elements-resulat" v-for="product in products" :key="product">
          <BalanceTileFiltre
            :label="product.label"
            :type="product.type"
            :checked="(filterFieldSelected.filters).some((item) => item.label === product.label)"
            v-on:addItem="addItem(product.type, product.label)"
            v-on:removeFilter="clearItem($event, product.label)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BalanceApi, {
  ASSOCIATION_TYPES,
  BalanceBean,
  BalanceFrontBean,
  FIELD_TYPES,
  FILTER_TYPE,
} from '@/api/balance/BalanceApi';
import Button from '@/components/Button.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import BalanceTileFiltre from '@/views/front/balance-societale/BalanceTileFiltre.vue';
import ResearchBarFiltre from '@/views/front/balance-societale/ResearchBarFiltre.vue';
import TagFiltrePanier from '@/views/front/balance-societale/TagFiltrePanier.vue';
import { CheckboxBlankOutline, CheckboxMarkedOutline } from 'mdue';
import { inject } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    ResearchBarFiltre,
    BalanceTileFiltre,
    TagFiltrePanier,
    Button,
    CheckboxBlankOutline,
    CheckboxMarkedOutline,
  },
  data() {
    const balanceApi = getGlobalInstance(BalanceApi);

    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    const currentFilters = inject('currentFilters');

    return {
      balanceApi,
      associationStatus: ASSOCIATION_TYPES.NORMAL,
      isCompo: false,
      products: [],
      productsByType: [],
      filterFieldSelected,
      globalFilters,
      currentFilters,
      inputSearchValue: '',
    };
  },
  props: {
    filterIndex: Number,
  },
  methods: {
    showAlone() {
      return this.associationStatus === ASSOCIATION_TYPES.EXCLUSIVE;
    },
    showAssociated() {
      return this.associationStatus === ASSOCIATION_TYPES.MINIMA;
    },
    handleAlone() {
      this.associationStatus = this.associationStatus === ASSOCIATION_TYPES.EXCLUSIVE
        ? ASSOCIATION_TYPES.NORMAL
        : ASSOCIATION_TYPES.EXCLUSIVE;
      this.updateAssociationStatus();
    },
    handleAssociated() {
      this.associationStatus = this.associationStatus === ASSOCIATION_TYPES.MINIMA
        ? ASSOCIATION_TYPES.NORMAL
        : ASSOCIATION_TYPES.MINIMA;
      this.updateAssociationStatus();
    },
    updateAssociationStatus() {
      const filters: BalanceBean[] = [];
      this.globalFilters
        .forEach((filter: BalanceBean) => {
          if (filter.type === FIELD_TYPES.COMPOSITION) {
            filters.push({
              ...filter,
              association: this.associationStatus,
            });
          } else {
            filters.push(filter);
          }
        });
      this.globalFilters = filters;
      this.filterFieldSelected.association = this.associationStatus;
    },
    handleSearch(inputLabel: string) {
      this.inputSearchValue = inputLabel;
      this.products = this.productsByType.balanceList
        .filter((product: BalanceBean) => product.label.toLowerCase()
          .includes(inputLabel.toLowerCase()));
    },
    clearItem(index: number, label: string) {
      this.filterFieldSelected.filters.splice(
        this.filterFieldSelected.filters.map((e: BalanceBean) => e.label)
          .indexOf(label),
        1,
      );
      const currentFilterSelected = this.currentFilters
        .filter((field: BalanceBean) => field.type === this.filterFieldSelected.type);
      const indexFilterSelected = this.currentFilters.indexOf(currentFilterSelected[0]);
      this.currentFilters[indexFilterSelected].filters.splice(
        this.filterFieldSelected.filters.map((e: BalanceBean) => e.label)
          .indexOf(label),
        1,
      );
      this.globalFilters.splice(
        this.globalFilters.map((e: BalanceBean) => e.label)
          .indexOf(label),
        1,
      );
      if (this.filterFieldSelected.filters.length === 0) {
        this.associationStatus = ASSOCIATION_TYPES.NORMAL;
        this.filterFieldSelected.association = ASSOCIATION_TYPES.NORMAL;
      }
    },
    addItem(type: string, label: string) {
      if (this.alone !== this.associated) {
        this.filterFieldSelected.association = !this.alone;
      } else {
        this.filterFieldSelected.association = undefined;
      }
      const item = {
        type,
        label,
        association: this.associationStatus,
      };

      this.filterFieldSelected.filters.push(item);
      this.globalFilters.push(item);

      const index = this.currentFilters
        .findIndex((field: BalanceBean) => field.type === this.filterFieldSelected.type);
      if (index < 0) {
        return;
      }
      if (this.currentFilters[index].filters) {
        this.currentFilters[index].filters.push(item);
      } else {
        this.currentFilters[index].filters = [item];
      }
    },
    initializeCompositionAssociation() {
      if (this.globalFilters?.length > 0) {
        const compositionFilters: BalanceBean[] = this.globalFilters
          .filter((filter: BalanceBean) => filter.type === FIELD_TYPES.COMPOSITION);
        if (compositionFilters.length > 0) {
          if (compositionFilters
            .some((filter: BalanceBean) => filter.association === ASSOCIATION_TYPES.EXCLUSIVE)) {
            this.associationStatus = ASSOCIATION_TYPES.EXCLUSIVE;
          } else if (compositionFilters
            .some((filter: BalanceBean) => filter.association === ASSOCIATION_TYPES.MINIMA)) {
            this.associationStatus = ASSOCIATION_TYPES.MINIMA;
          }
        }
      }
    },
  },
  computed: {
    cssVars() {
      return {
        '--triangle-margin': `${this.filterIndex}px`,
      };
    },
  },
  mounted() {
    this.initializeCompositionAssociation();
    if (!this.globalFilters) {
      this.globalFilters = [];
    }
    if (!this.currentFilters) {
      this.currentFilters = [];
    }
    if (!this.filterFieldSelected) {
      this.filterFieldSelected = [];
    }
    if (!this.filterFieldSelected.filters) {
      this.filterFieldSelected.filters = [];
    }
    this.isCompo = (this.filterFieldSelected.type === FILTER_TYPE.COMPOSITION.type);
    this.balanceApi.findFiltersByFilterCategory(this.filterFieldSelected.type, false)
      .then((response: BalanceFrontBean) => {
        this.$parent.dataLoaded = true;
        this.productsByType = response;
        this.products = response.balanceList;
      });
    if (!this.filterFieldSelected.filters) {
      this.filterFieldSelected.filters = [];
    }
  },
  watch: {
    filterFieldSelected: {
      handler() {
        if (!this.filterFieldSelected.filters) {
          this.filterFieldSelected.filters = [];
        }
        this.isCompo = (this.filterFieldSelected.type === FILTER_TYPE.COMPOSITION.type);
        this.balanceApi.findFiltersByFilterCategory(this.filterFieldSelected.type, false)
          .then((response: BalanceFrontBean) => {
            this.$parent.dataLoaded = true;
            this.products = response.balanceList;
          });
      },
      deep: true,
    },
    filterIndex: {
      handler() {
        this.inputSearchValue = '';
      },
    },
  },
})

export default class PopinFiltresBalanceSearch extends Vue {
  selected?: BalanceBean;
}

</script>

<style lang="scss" scoped>
$primary: #005297;
.fas.fa-caret-left {
  font-size: 50px;
  position: absolute;
  left: 20vw;
  color: $primary;
  top: var(--triangle-margin);
}

.popin-container {
  background-color: var(--white);
  width: 320px;
  position: absolute;
  z-index: 1;
  left: 22vw;
  min-height: 400px;
  border: 1px solid $primary;
  top: 0;

  .bandeau {
    width: 100%;
    height: 50px;
    background-color: $primary;
    margin-bottom: 10px;
    color: var(--white);
    text-transform: uppercase;
    padding-top: var(--spacing-medium);
  }

  .espace-resultat {
    height: 300px;

    &.loaded {
      overflow-y: auto;
    }

    .spinner-border {
      margin-top: 30px;
    }
  }

  .panier {
    display: flex;
    flex-flow: wrap;
    margin-left: 4px;
  }

  .compo-choice {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
    justify-content: space-around;
    margin-bottom: 5px;

    .title {
      padding-left: 10px;
    }

    label {
      font-size: 13px;
    }

    .checkbox-label {
      .checkbox {
        border: none;
        background: none;
        height: fit-content;
        padding: 0;

        .icon {
          margin: 0 10px;

          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
