<template>
  <div class="tile-container">
    <div class="fournisseur">
      <label>{{ fournisseur.name }}</label>
    </div>
    <div class="fournisseur-info">
      <label class="product-count">
        {{ $t('admin.fournisseur.list.productCount', { count: fournisseur.productNumber }) }}
      </label>
      <label class="user-count">
        {{ $t('admin.fournisseur.list.userCount', { count: fournisseur.userCount }) }}
      </label>
    </div>
    <div class="button-container">
      <Button
        :label="$t('admin.fournisseur.list.details')"
        color="white"
        :title="$t('admin.fournisseur.list.detailsTitle')"
        @click="() => handleDetails(fournisseur.id)"
      >
        <magnify class="icon" />
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import { Magnify } from 'mdue';
import { PropType } from 'vue';
import { FOURNISSEUR_DETAIL_ROUTE } from '@/router/routes';
import { Fournisseur } from '@/api/fournisseurs/FournisseursApi';

@Options({
  components: {
    Button,
    Magnify,
  },
  props: {
    fournisseur: {
      type: Object as PropType<Fournisseur>,
      required: true,
    },
  },
  methods: {
    handleDetails(id: string) {
      this.$router.push({ name: FOURNISSEUR_DETAIL_ROUTE, params: { id } });
    },
  },
})
export default class FournisseurTile extends Vue {
  fournisseur!: Fournisseur;
}
</script>

<style lang="scss" scoped>
.tile-container {
  height: 100px;
  border: solid var(--grey-2) 1px;
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-bigger);
  background-color: white;

  label {
    font-size: var(--font-size-small);
  }

  .fournisseur {
    text-transform: uppercase;
    width: 15%;
    text-align: left;

    label {
      width: 14rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'SourceSansProSemiBold', sans-serif;
    }
  }

  .fournisseur-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    margin-right: 5%;
    label {
      padding: var(--spacing-bigger);
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      border: solid var(--grey-1) 1px;
      border-top: none;
    }
  }
}
</style>
