<template>
  <div class="header-container">
    <div class="header-page">
      <img alt="Concern logo" :src="require(`@/assets/${$t('front.home.image')}`)"/>
      <div class="foreground-color">
        <div class="title">{{ $t('front.home.title') }}</div>
        <div class="title">{{ $t('front.home.title2') }}</div>
      </div>
        <ResearchBar
            v-if="hasPermissionsSearchBar()"
            v-on:handleSearch="handleSearch($event)"
            :buttonColor="colorButtonResearch"
        />
      </div>
    </div>
  <div class="page-container"/>
  <FooterTemplate/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ResearchBar from '@/views/front/ResearchBar.vue';
import FooterTemplate from '@/views/front/FooterTemplate.vue';
import { PRODUCT_ROUTE } from '@/router/routes';
import { inject } from 'vue';
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';

  @Options({
    components: {
      ResearchBar,
      FooterTemplate,
    },
    methods: {
      hasPermissionsSearchBar() {
        const permissions: string[] = parseJwt(this.authorization);
        return permissions.includes(PERMISSIONS.RECHERCHE);
      },
      handleSearch(value: string) {
        this.$router.push({ name: PRODUCT_ROUTE, params: { value } });
      },
    },
    data() {
      const authorization = inject('authorization');
      return {
        colorButtonResearch: '#005297',
        authorization,
      };
    },
  })
export default class Accueil extends Vue {
}
</script>

<style lang="scss" scoped>
  .header-container {
    position: relative;
  }
  .header-page {
    width: 100%;
    height: var(--header-page-heigth);
    img {
      width: 100%;
      height: var(--header-page-heigth);
      display: block;
      position: absolute;
      object-fit: cover;
    }
    .input-group {
      margin-top: -98px;
    }

    .foreground-color {
      width: 100%;
      position: relative;
      height: var(--header-page-heigth);
      background: linear-gradient(#{var(--header-color)}) no-repeat center;

      .title {
        position: relative;
        top: 98px;
        font-size: var(--font-size-title-page);
        font-family: 'TTNormsMedium', sans-serif;
        color: white;
      }
    }
  }
</style>
