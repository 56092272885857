import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27453122"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-advanced-search" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "search-criteria" }
const _hoisted_4 = { for: "lastname" }
const _hoisted_5 = { class: "search-criteria" }
const _hoisted_6 = { for: "firstname" }
const _hoisted_7 = { class: "input-container" }
const _hoisted_8 = { class: "search-criteria" }
const _hoisted_9 = { for: "profile" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_magnify = _resolveComponent("magnify")!
  const _component_Button = _resolveComponent("Button")!
  const _component_plus = _resolveComponent("plus")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('admin.users.search.lastname')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            name: "lastname",
            id: "lastname",
            class: "input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lastname) = $event)),
            maxlength: "50"
          }, null, 512), [
            [_vModelText, _ctx.lastname]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('admin.users.search.firstname')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            name: "firstname",
            id: "firstname",
            class: "input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstname) = $event)),
            maxlength: "50"
          }, null, 512), [
            [_vModelText, _ctx.firstname]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('admin.users.search.profile')), 1),
          _withDirectives(_createElementVNode("select", {
            name: "profile",
            id: "profile",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profileSelected) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: option,
                value: option
              }, _toDisplayString(option.name), 9, _hoisted_10))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.profileSelected]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('common.button.search'),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_magnify, { class: "icon" })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_Button, {
        onClick: _ctx.addUser,
        label: _ctx.$t('common.button.add'),
        color: "secondary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_plus, { class: "icon" })
        ]),
        _: 1
      }, 8, ["onClick", "label"])
    ])
  ], 32))
}