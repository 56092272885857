<template>
  <notifications ref="notifications" />

  <div @click="clickOutside">
    <div class="espace-recherche">
      <div class="title">
        <div class="desc">{{ $t('front.balanceSocietale.creation.desc') }}</div>
      </div>
      <ProductTypeSelection v-on:selected="handleSelection($event)" :lockButtons="!dataLoaded" />
      <p className="search-details" v-if="selected">
        {{
          $t('front.balanceSocietale.creation.recherche.details',
            { productType: selected.label.toLowerCase() })
        }}
      </p>
      <ResearchBar v-on:handleSearch="handleSearch($event)" :selected="getSelectedLabel()" />
      <p class="disclaimer">{{ $t('front.balanceSocietale.creation.recherche.disclaimer') }}</p>
    </div>
    <div class="resultat-recherche">
      <FiltresBalanceSearch
        :showPopinFiltres="showPopinFiltres"
        :disable="!dataLoaded"
      />
      <div class="balance-page">
        <div class="results">
          <h4 v-if="dataLoaded && havePermissionAfficherNombreProduits()">
            {{ $t(`front.balance.${selected.type}`, { count: nbrResults }) }}
          </h4>
          <h4 v-if="!dataLoaded">{{ $t('common.loader') }}</h4>
        </div>
        <div v-if="dataLoaded">
          <h4 v-if="!havePermissionAfficherResultatSelection()">
            {{ $t('notifications.permission') }}
          </h4>
          <div
            class="tile-container"
            v-for="product in products"
            :key="product.id"
          >
            <BalanceTile
              v-if="isProduct(product)
                && product.productIdList.length > 0
                && havePermissionAfficherResultatSelection()"
              :product="product"
              :isProduct="isProduct(product)"
              v-bind:isInBasket="basketProducts
            .some((item) => item.id === product.id)"
            />
            <BalanceTile
              v-else-if="havePermissionAfficherResultatSelection()"
              :product="product"
              :isProduct="isProduct(product)"
              v-bind:isInBasket="basketProducts
            .some((item) => item.label === product.label)"
            />
          </div>
        </div>
        <div v-else>
          <div class="spinner-border" role="status" />
        </div>
        <Pagination
          v-if="dataLoaded
            && nbrResults > 0
            && tileToDisplay < nbrResults
            && havePermissionAfficherResultatSelection()"
          :label="$t('common.button.showMore')"
          :nbrTileDefault="nbrTileDefault"
          :totalItem="nbrResults"
          :loading="showMoreDataLoading"
        />
      </div>
      <Panier
          :basket="basketProducts"
          :errorLimit="errorLimit"
          v-on:clearAll="clearAll"
          v-on:clearItem="clearItem($event)"
          v-bind:class="[havePermissionAfficherProduit() ? '' : 'hide-panier']"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { parseJwt, PERMISSIONS } from '@/api/authentication/AuthentApi';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import BalanceTile from '@/components/BalanceTile.vue';
import ResearchBar from '@/views/front/ResearchBar.vue';
import ProductTypeSelection from '@/views/front/balance-societale/ProductTypeSelection.vue';
import BalanceApi, {
  BalanceBean,
  BalanceFrontBean,
  BalanceLabelShort,
  FIELD_TYPES,
} from '@/api/balance/BalanceApi';
import HeaderTemplate from '@/views/front/HeaderTemplate.vue';
import Panier from '@/views/front/balance-societale/Panier.vue';
import FiltresBalanceSearch from '@/views/front/balance-societale/FiltresBalanceSearch.vue';
import {
  MinusBox, CheckboxBlankOutline, CheckboxMarked, Play,
} from 'mdue';
import FooterTemplate from '@/views/front/FooterTemplate.vue';
import { inject } from 'vue';
import UsersApi, { User } from '@/api/users/UsersApi';
import { ENTITY_TYPES } from '@/api/entity/EntityApi';
import Notifications from '@/components/Notifications.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    BalanceTile,
    MinusBox,
    CheckboxBlankOutline,
    CheckboxMarked,
    Play,
    ResearchBar,
    ProductTypeSelection,
    HeaderTemplate,
    Panier,
    FooterTemplate,
    FiltresBalanceSearch,
    Notifications,
    Pagination,
  },
  data() {
    const usersApi = getGlobalInstance(UsersApi);
    const balanceApi = getGlobalInstance(BalanceApi);

    const basketProducts = inject('basketProducts');
    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    const currentFilters = inject('currentFilters');
    const authorization = inject('authorization');
    return {
      usersApi,
      balanceApi,
      dataLoaded: false,
      showMoreDataLoading: false,
      filterFieldSelected,
      basketProducts,
      globalFilters,
      currentFilters,
      authorization,
      errorLimit: false,
      products: [],
      nbrResults: 0,
      checked: [],
      nbrTileDefault: 15,
      tileToDisplay: this.nbrTileDefault,
      selected: undefined,
      productsByType: [],
      showPopinFiltres: false,
      isFournisseur: false,
      searchValue: '',
      permissions: [] as string[],
    };
  },
  methods: {
    isProduct(product: BalanceBean) {
      return product.type === FIELD_TYPES.PRODUIT;
    },
    // e.path is deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickOutside(e: any) { // A type : Pointer Event
      // If click anywhere except on filter part, close popin
      const paths = e.path || (e.composedPath && e.composedPath()); // Pour Safari
      // e.path is deprecated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (paths.filter((path: any) => path.className === 'filtre-container').length === 0) {
        this.showPopinFiltres = false;
      }
    },
    showMore() {
      this.showMoreDataLoading = true;
      this.handleSearch(this.searchValue);
    },
    getSelectedLabel(): BalanceLabelShort | undefined {
      let maleWord = false;
      if (this.selected?.type === FIELD_TYPES.MARCHE
      || this.selected?.type === FIELD_TYPES.SEGMENT
      || this.selected?.type === FIELD_TYPES.SEGMENT_SPECIFIC
      || this.selected?.type === FIELD_TYPES.FOURNISSEUR
      || this.selected?.type === FIELD_TYPES.PRODUIT) {
        maleWord = true;
      }
      if (this.selected?.label) {
        return {
          label: this.selected.label.toLowerCase(),
          maleWord,
        };
      }
      return undefined;
    },
    handleSearch(inputLabel: string) {
      this.searchValue = inputLabel;
      this.balanceApi.findProductsByType(
        this.selected.type,
        this.globalFilters,
        this.tileToDisplay,
        inputLabel,
      )
        .then((response: BalanceFrontBean) => {
          this.nbrResults = response.nbrResults;
          this.showMoreDataLoading = false;

          // On ne souhaite afficher que les BalanceBean
          // qui sont liés à des produits visibles et valides
          // L'id de ces produits étant contenus dans l'attribut productIdList
          this.products = response.balanceList.filter(
            (product: BalanceBean) => product.productIdList
              && product.productIdList.length > 0,
          );
        });
    },
    handleSelection(event: BalanceBean) {
      if (event && this.selected) {
        // Si on clique sur le même onglet on ne lance pas la recherche
        if (event.type === this.selected.type) {
          return;
        }
        // Si on change d'onglet, on réinitialise le contenu de la barre de recherche
        this.searchValue = '';
      }
      this.dataLoaded = false;
      this.showPopinFiltres = false;
      this.globalFilters = null;
      this.selected = event;
      this.filterFieldSelected.filters = [];
      this.currentFilters.forEach((filterType: BalanceBean, index: number) => {
        this.currentFilters[index].filters = [];
      });
      this.balanceApi.findProductsByType(this.selected.type, [])
        .then((response: BalanceFrontBean) => {
          this.productsByType = response.balanceList;
          const nbrProductIdList = response.balanceList
            .filter((product: BalanceBean) => product.productIdList
              && product.productIdList.length === 0).length;
          // On ne souhaite afficher que les BalanceBean
          // qui sont liés à des produits visibles et valides
          // L'id de ces produits étant contenus dans l'attribut productIdList
          this.products = response.balanceList
            .filter((product: BalanceBean) => product.productIdList
              && product.productIdList.length > 0);
          // Mise à jour du nombre de résultat suite au filtre
          this.nbrResults = response.nbrResults - nbrProductIdList;

          this.dataLoaded = true;
          this.tileToDisplay = Math.min(this.nbrTileDefault, response.nbrResults - nbrProductIdList);
        });
    },
    clearAll() {
      this.errorLimit = false;
      this.basketProducts.splice(0, this.basketProducts.length);
    },
    clearItem(index: number) {
      this.errorLimit = false;
      this.basketProducts.splice(index, 1);
    },
    havePermissionAfficherNombreProduits() {
      return this.permissions.includes(PERMISSIONS.RESULTATS_RECHERCHE);
    },
    havePermissionAfficherResultatSelection() {
      return this.permissions.includes(PERMISSIONS.RESULTATS_SELECTION);
    },
    havePermissionAfficherProduit() {
      return this.permissions.includes(PERMISSIONS.AFFICHER_PRODUIT_BALANCE);
    },
  },
  mounted() {
    this.permissions = parseJwt(this.authorization);
    this.usersApi.fetchMe().then((response: User) => {
      this.isFournisseur = response.entityType === ENTITY_TYPES.FOURNISSEUR;
    });
    if (!this.basketProducts) {
      this.basketProducts = [];
    }
    if (!this.filterFieldSelected) {
      this.filterFieldSelected = [];
    }
    this.currentFilters = [];
    if (this.$route.params.id) {
      this.balanceApi.findProductBalanceById(this.$route.params.id)
        .then((response: BalanceBean) => {
          this.basketProducts.push({
            id: response.id,
            type: response.type,
            label: response.label,
            filters: [],
            productIdList: response.productIdList,
          });
        });
    }

    this.balanceApi.fetchResearchFields(true, false)
      .then((response: BalanceBean[]) => {
        if (response.length > 0) {
          this.handleSelection({ type: response[0].type, label: response[0].label });
        } else {
          this.dataLoaded = true;
        }
      });
  },
  watch: {
    globalFilters: {
      handler() {
        if (this.globalFilters) {
          this.dataLoaded = false;
          this.balanceApi
            .findProductsByType(this.selected.type,
              this.globalFilters,
              this.tileToDisplay,
              this.searchValue || '')
            .then((response: BalanceFrontBean) => {
              const nbrProductIdList = response.balanceList.filter(
                (product: BalanceBean) => product.productIdList
                  && product.productIdList.length === 0,
              ).length;
              // On ne souhaite afficher que les BalanceBean
              // qui sont liés à des produits visibles et valides
              // L'id de ces produits étant contenus dans l'attribut productIdList
              this.products = response.balanceList.filter(
                (product: BalanceBean) => product.productIdList
                  && product.productIdList.length > 0,
              );
              // Mise à jour du nombre de résultats suite au filtre
              this.nbrResults = response.nbrResults - nbrProductIdList;
              this.dataLoaded = true;
            })
            .catch(() => {
              this.$refs.notifications.notifyError(this.$t('notifications.internalError'));
            });
        }
      },
      deep: true,
    },
  },
})

export default class BalanceRecherche extends Vue {
  products?: BalanceBean[];

  productsByType?: BalanceBean[];

  checked?: BalanceBean[];

  currentFilters?: BalanceBean[];
}
</script>

<style lang="scss" scoped>
$primary: #005297;
$grey: #222E30;

.espace-recherche {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--spacing-very-big);

  .title {
    padding-bottom: var(--spacing-medium);

    .desc {
      width: 100%;
      font-family: 'TTNormsRegular', sans-serif;
    }
  }

  .disclaimer {
    padding-top: 5px;
    font-size: var(--font-size-smaller);
    color: var(--grey-2);
  }
}

.resultat-recherche {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .balance-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-very-big);
    padding-left: 40px;
    padding-right: 40px;

    .spinner-border {
      margin-top: 100px;
    }

    .results {
      text-align: left;
      width: var(--search-bar-width);

      h4 {
        text-transform: uppercase;
      }
    }

    .tile-container {
      width: var(--search-bar-width);
    }

    .infos {
      margin: 0 var(--spacing-big);
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;

      p {
        color: white;
        margin: 0;
        font-family: 'TTNormsRegular', sans-serif;
      }

      .icon {
        color: white;
      }
    }

    .action {
      button {
        color: white;
        letter-spacing: 0.5px;
        font-family: 'TTNormsRegular', sans-serif;
        font-size: var(--font-size-small);
        margin: 0 var(--spacing-big);
      }
    }

    button {
      border: none;
      background: none;
      height: fit-content;
      font-family: 'TTNormsLight', sans-serif;

      &.btn-show-more {
        color: $grey;
        box-shadow: none !important;
        border: 1px solid $grey;
        border-radius: 0;
        height: var(--btn-height-default);
        width: 170px;
        justify-content: center;
        font-size: var(--font-size-smaller);
        font-family: 'TTNormsLight', sans-serif;
        margin: var(--spacing-small);
      }
    }
  }

  .hide-panier {
    visibility: hidden;
  }
}
</style>
