import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb55bf34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popin" }
const _hoisted_2 = {
  key: 0,
  class: "actions"
}
const _hoisted_3 = {
  key: 1,
  class: "actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "popin-mask",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickOutside && _ctx.clickOutside(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "header",
        style: _normalizeStyle([_ctx.color ? {color: `#FFF`, background: _ctx.color } : {}])
      }, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
      ], 4),
      _createElementVNode("div", {
        class: _normalizeClass(['popin-container', _ctx.isFront ? 'front' : ''])
      }, [
        _renderSlot(_ctx.$slots, "container", {}, undefined, true),
        (!_ctx.isFront)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.hasCancel !== false)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.$t('common.button.cancel'),
                    color: "secondary",
                    onClick: _ctx.closePopin
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "button", {}, undefined, true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.hasCancel !== false)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.$t('common.button.cancel'),
                    class: _normalizeClass(['action supp', _ctx.isFront ? 'button front' : 'button']),
                    onClick: _ctx.closePopin
                  }, null, 8, ["label", "class", "onClick"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "button", {}, undefined, true)
            ]))
      ], 2)
    ])
  ]))
}