<template>
  <notifications ref="notifications" />
  <div class="wrapper">
    <Button
      class="delete-button"
      :label="$t('admin.cooperative.informations.delete')"
      color="primary"
      @Click="showPopinDelete = true"
    >
      <delete class="icon" />
    </Button>
    <form @submit.prevent="handleSubmit">
      <div class="block">
        <h4 class="title">{{ $t('admin.cooperative.informations.title') }}</h4>
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.cooperative.informations.name') }}</span>
              <MDBIcon class="required" icon="star-of-life"></MDBIcon>
            </td>
            <td>
              <input type="text" v-model="cooperative.name" required maxlength="50"><br>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-if="$route.params.id !== 'new'" class="display-none">
          {{ $t('admin.cooperative.informations.desc') }}</p>
        <div v-if="$route.params.id !== 'new'" class="display-none">
          <table>
            <tbody>
            <tr>
              <td class="text-td">
                <span class="label">{{ $t('admin.cooperative.informations.packageLabel') }}</span>
              </td>
              <td>
                <select name="package" id="package" v-model="selected">
                  <option
                    v-for="(packageItem, index) in packages"
                    :key="index"
                    v-bind:value="packageItem.id">
                    {{ packageItem.name }}
                  </option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="display-none" v-if="$route.params.id !== 'new'">
          <p>{{ $t('admin.cooperative.details.whiteLabelProductInfo') }}</p>
          <table>
            <tbody>
            <tr>
              <td class="text-td">
                <span class="label">{{ $t('admin.cooperative.color.primary') }}</span>
              </td>
              <td>
                <ColorPreviewInput :color="cooperative.primaryColor" />
              </td>
            </tr>
            <tr>
              <td class="text-td">
                <span class="label">{{ $t('admin.cooperative.color.secondary') }}</span>
              </td>
              <td>
                <ColorPreviewInput :color="cooperative.secondaryColor" />
              </td>
            </tr>
            <tr>
              <td class="text-td">
                <span class="label">{{ $t('admin.cooperative.color.image') }}</span>
              </td>
              <td class="image-upload-container">
                <input
                  type="file"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="overflow-hidden"
                  @change="onChange"
                  ref="file"
                  accept=".jpg,.jpeg,.png,.svg,.JPG,.JPEG,.PNG,.SVG"
                />
                <label
                  v-if="fileList.length === 0"
                  for="assetsFieldHandle"
                  class="image-container cursor-pointer"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <plus class="big-icon" />
                </label>
                <div class="preview" v-if="fileList.length > 0">
                  <img v-if="urlImage" :src="urlImage" alt="preview-uploaded-logo" />
                  <button @click="remove">
                    <close />
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4 class="title" v-if="$route.params.id !== 'new'">
        {{ $t('admin.cooperative.referent.title') }}</h4>
      <div class="block" v-if="$route.params.id !== 'new'">
        <table>
          <tbody>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.cooperative.referent.lastName') }}</span>
            </td>
            <td><input disabled v-model="referent.lastName" /></td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.cooperative.referent.firstName') }}</span>
            </td>
            <td><input disabled v-model="referent.firstName" /></td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.cooperative.referent.phone') }}</span>
            </td>
            <td><input disabled v-model="referent.phoneNumber" /></td>
          </tr>
          <tr>
            <td class="text-td">
              <span class="label">{{ $t('admin.cooperative.referent.mail') }}</span>
            </td>
            <td><input disabled v-model="referent.email" /></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="buttons-wrapper block">
        <Button
          type="submit"
          :label="$route.params.id === 'new'
            ? $t('common.button.create')
            : $t('common.button.save')"
          color="primary"
        >
          <content-save class="icon" />
        </Button>
        <Button :onClick="close" :label="$t('common.button.cancel')" color="secondary">
          <backspace class="icon" />
        </Button>
      </div>
    </form>
    <ConfirmationPopin
      v-if="showPopinDelete"
      :title="$t('front.permissions.deleteProfile')"
      :content="$t('admin.cooperative.informations.deleteText')"
      :handleDelete="deleteCooperative"
      :close="closePopin"
    />
  </div>
</template>

<script lang="ts">
import ConfirmationPopin from '@/components/ConfirmationPopin.vue';
import getGlobalInstance from '@/lib/vue-di/vue-di-adapter';
import { Options, Vue } from 'vue-class-component';
import ColorPreviewInput from '@/components/ColorPreviewInput.vue';
import Button from '@/components/Button.vue';
import {
  Backspace, Close, ContentSave, Delete, Plus,
} from 'mdue';
import Drawer from '@/components/Drawer.vue';
import CooperativesApi, {
  CooperativeUser, CooperativePackage, Cooperative,
} from '@/api/cooperatives/CooperativesApi';
import Notifications from '@/components/Notifications.vue';
import { HttpPlumeError } from '@/lib/plume-http/client/PlumeHttpResponse';
import { User } from '@/api/users/UsersApi';

@Options({
  components: {
    Button,
    Backspace,
    ContentSave,
    ColorPreviewInput,
    Drawer,
    Plus,
    Close,
    Delete,
    Notifications,
    ConfirmationPopin,
  },
  props: {
    selected: String,
  },
  data() {
    const cooperativesApi = getGlobalInstance(CooperativesApi);
    return {
      cooperativesApi,
      referent: {},
      packages: [],
      selected: '',
      fileList: [],
      urlImage: '',
      cooperative: {},
      showPopinDelete: false,
    };
  },
  methods: {
    close() {
      const { body } = document;
      body.classList.remove('scrollbar-hidden');
      this.$router.back();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      // /*TODO Reload la liste des coopératives*/
    },
    deleteCooperative() {
      const { id } = this.$route.params;
      this.cooperativesApi.deleteCooperative(id)
        .then(() => {
          this.$refs.notifications.notifySuccess();
          this.close();
        })
        .catch(() => {
          this.$refs.notifications.notifyError(this.$t('notifications.errorDelete'));
        });
    },
    closePopin() {
      this.showPopinDelete = false;
    },
    fetchCooperative(id: string) {
      return this.cooperativesApi.fetchCoop(id)
        .then((response: Cooperative) => {
          this.cooperative = response;
          this.selected = response.idPackage;
        });
    },
    onChange() {
      this.fileList = this.$refs.file.files;
      this.urlImage = URL.createObjectURL(this.$refs.file.files[0]);
    },
    remove() {
      this.fileList = [];
    },
    dragover(event: DragEvent) {
      event.preventDefault();
      const target = event.currentTarget as HTMLInputElement;
      if (!target.classList.contains('hover')) {
        target.classList.add('hover');
      }
    },
    dragleave(event: DragEvent) {
      const target = event.currentTarget as HTMLInputElement;
      target.classList.remove('hover');
    },
    drop(event: DragEvent) {
      event.preventDefault();
      const target = event.currentTarget as HTMLInputElement;
      this.$refs.file.files = event.dataTransfer?.files ?? [];
      this.onChange();
      target.classList.remove('hover');
    },
    notify(type: string, message: string) {
      this.$toasts[type](message);
    },
    fetchReferent(id: string) {
      return this.cooperativesApi
        .fetchReferentUser(id)
        .then((response: User) => {
          this.referent = response ?? {};
        });
    },
    handleSubmit() {
      if (this.$route.params.id === 'new') {
        this.cooperativesApi.createNewCooperative(this.cooperative)
          .then(() => {
            this.$refs.notifications.notifyCreation(this.$t('admin.cooperative.creation'));
            this.close();
          })
          .catch((error: HttpPlumeError) => {
            this.$refs.notifications.notifyError(this.$t(`formulaireErrors.${error.errorCode}`));
            this.errors.email = this.$t(`formulaireErrors.${error.errorCode}`);
          });
      } else {
        this.cooperativesApi.updateCooperative(this.cooperative)
          .then(() => {
            this.$refs.notifications.notifySuccess();
            this.close();
          })
          .catch((error: HttpPlumeError) => {
            this.$refs.notifications.notifyError(this.$t(`formulaireErrors.${error.errorCode}`));
            this.errors.email = this.$t(`formulaireErrors.${error.errorCode}`);
          });
      }
      return true;
    },
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.fetchCooperative(this.$route.params.id);
      this.fetchReferent(this.$route.params.id);
    }
  },
})
export default class DetailsCooperative extends Vue {
  selected!: string;

  referent?: CooperativeUser;

  packages: CooperativePackage[] = [];

  cooperative?: Cooperative;
}
</script>

<style lang="scss" scoped>
$alignement: 250px;
$image-upload-color: #929292;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-smaller);
  position: relative;

  .delete-button {
    position: absolute;
    right: 0;
  }

  .display-none {
    display: none;
  }

  .block {
    margin-bottom: var(--spacing-bigger);
    width: 90%;

    .image-container {
      height: 150px;
      width: 150px;
      border-radius: 8px;
      background: $image-upload-color;
      display: flex;
      justify-content: center;
      align-items: center;

      .big-icon {
        height: 50px;
        width: 50px;
        color: white;
      }

      &.hover {
        background: darken($image-upload-color, 10%);
      }
    }

    .preview {
      display: flex;
      flex-direction: row;
      height: 150px;
      width: 150px;
      align-items: flex-end;

      button {
        margin-left: 5px;
        height: 28px;
        width: 28px;
        background: $image-upload-color;
        border-radius: 50%;
        border: 1px solid $image-upload-color;
        color: white;
      }

      img {
        max-height: 150px;
      }
    }
  }

  .title {
    text-transform: uppercase;
    margin: var(--spacing-small) 0 var(--spacing-bigger) 0;
    font-family: 'SourceSansProLight', sans-serif;
  }

  select {
    height: 40px;
    width: 250px;
  }

  table {
    table-layout: auto;
    width: 700px;
    border-collapse: separate;
    border-spacing: 0 var(--spacing-default);

    .text-td {
      text-align: right;
      padding-right: var(--spacing-default);
    }

    .image-upload-container {
      display: flex;
      flex-direction: row;
    }

    input {
      height: 40px;
      width: 400px;
      padding: var(--spacing-medium);

    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

input {
  &.overflow-hidden {
    display: none;
    overflow: hidden;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
