<template>
  <div class="range">
    <div class="slider-value">
      <span class="slider-value-span left">
        {{$t('front.balanceSocietale.filtre.notes.min')}} {{ sliderMin }}
      </span>
      <span class="slider-value-span right">
        {{$t('front.balanceSocietale.filtre.notes.max')}} {{ sliderMax }}
      </span>
    </div>
    <div class="field">
      <input
        class="custom-slider custom-slider-grey"
        type="range"
        :min="min"
        :max="max"
        v-model="sliderMin"
        @change="(e) => checkMinSliderValue(e.target.value)"
        step="1"
      />
      <input
        class="custom-slider"
        type="range"
        :min="min"
        :max="max"
        v-model="sliderMax"
        @change="(e) => checkMaxSliderValue(e.target.value)"
        step="1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { inject } from 'vue';
import { BalanceBean } from '@/api/balance/BalanceApi';

@Options({
  props: {
    noteLabel: String,
    initialSliderMin: Number,
    initialSliderMax: Number,
  },
  data() {
    const filterFieldSelected = inject('filterFieldSelected');
    const globalFilters = inject('globalFilters');
    return {
      filterFieldSelected,
      globalFilters,
      min: '0',
      max: '100',
      sliderMin: this.initialSliderMin,
      sliderMax: this.initialSliderMax,
    };
  },
  methods: {
    checkMinSliderValue(value: number) {
      if (value > this.sliderMax && this.sliderMax !== '100') {
        this.sliderMax = value;
      }
      this.sliderMin = value;

      if (this.sliderMin === '0' && this.sliderMax === '100') {
        this.removeNoteFilter(this.noteLabel);
      } else {
        this.addNoteFilter(this.noteLabel, this.sliderMin, this.sliderMax);
      }
    },
    checkMaxSliderValue(value: number) {
      if (value < this.sliderMin && this.sliderMin !== '0') {
        this.sliderMin = value;
      }
      this.sliderMax = value;

      if (this.sliderMin === '0' && this.sliderMax === '100') {
        this.removeNoteFilter(this.noteLabel);
      } else {
        this.addNoteFilter(this.noteLabel, this.sliderMin, this.sliderMax);
      }
    },
    removeNoteFilter(label: string) {
      if (this.globalFilters.length > 0) {
        const labelIndexGlobalFilter: number = this.globalFilters
          .findIndex((field: BalanceBean) => field.label === label);

        if (labelIndexGlobalFilter >= 0) {
          this.globalFilters.splice(labelIndexGlobalFilter, 1);
        }
      }
      if (this.filterFieldSelected.filters.length > 0) {
        const labelIndexFilter: number = this.filterFieldSelected.filters
          .findIndex((field: BalanceBean) => field.label === label);
        if (labelIndexFilter >= 0) {
          this.filterFieldSelected.filters.splice(labelIndexFilter, 1);
        }
      }
    },
    addNoteFilter(label: string, noteMin: string, noteMax: string) {
      this.removeNoteFilter(label);

      this.filterFieldSelected.filters.push({
        type: 'NOTES',
        label,
        noteMin,
        noteMax,
      });
      this.globalFilters.push({
        type: 'NOTES',
        label,
        noteMin,
        noteMax,
        association: this.filterFieldSelected.association,
      });
    },
  },
})
export default class SliderRangeComponent extends Vue {

}
</script>

<style scoped lang="scss">
$range: 80px;
$span: 70px;
// Pour être iso avec la couleur bleue par défaut qui n'a pas été changée
$color: var(--grey-5);
$border-color: #888;
$cursor-color-blue: #498bff;
$cursor-color-lightblue: #8cbbff;
$input: 20px;

@mixin track() {
  width: 100%; height: 100%;
  background: none; /* get rid of Firefox track background */
}

@mixin thumb() {
  width: $input;
  height: $input;
  border-radius: 50%;
  background: linear-gradient($cursor-color-lightblue, $cursor-color-blue, $cursor-color-lightblue);
  border: 1px solid $border-color;
  cursor: pointer;
  pointer-events: auto;
}

.slider-value {
  .slider-value-span {
    position: absolute;
    top: 0;
    font-family: 'TTNormsRegular', sans-serif;

    &.left { left: 20px; }
    &.right { right: 25px; }
  }
}

.field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  overflow: hidden;

  .value {
    position: absolute;
    font-size: var(--font-size-smaller);
    color: $color;
    font-family: 'TTNormsRegular', sans-serif;
    &.left {
      left: 45px;
    }
    &.right {
      right: -185px;
    }
  }
}

.sr-only {
  position: absolute;
  clip-path: inset(50%)
}

input[type='range'] {
  height: 10px;
  width: 200px;

  &.custom-slider-grey {
    height: 10px;
    background: var(--grey-2);
  }
  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb, & {
    -webkit-appearance: none;
    margin-top: -3px;
  }

  grid-column: 1;
  grid-row: 2;
  margin: 0;
  background: none; /* get rid of white Chrome background */
  color: #000;
  font: inherit; /* fix too small font-size in both Chrome & Firefox */
  pointer-events: none;
  padding: none;

  &::-webkit-slider-runnable-track { @include track }
  &::-moz-range-track { @include track }

  &::-webkit-slider-thumb { @include thumb }
  &::-moz-range-thumb { @include thumb }
}
</style>
